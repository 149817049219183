import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'cart-detail-strip',
  templateUrl: './cart-detail-strip.html',
  standalone: false,
})
export class CartDetailStripComponent {
  @Input('numberOfProductsInCart') numberOfProductsInCart: any;
  @Input('user') user: any;
  @Input('cashBalance') cashBalance: any;
  @Input('cart') cart: any;
  @Input('totalSp') totalSp: any;
  @Output() closeStrip: EventEmitter<any> = new EventEmitter();
  constructor(
    private eventLogger: EventLoggerService,
    private router: Router,
  ) {}

  goToCartNav(): void {
    this.eventLogger.cleverTapEvent(
      'click',
      JSON.stringify({ pageName: 'sticky-bar-cart-shop-click' }),
    );
    this.router.navigate(['cart']);
  }
}
