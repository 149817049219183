import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'notification-permission-sheet',
  templateUrl: './notification-permission-sheet.html',
  styleUrls: [],
  standalone: false,
})
export class NotificationPermissionSheetComponent implements OnInit {
  /**
     * The constructor injects the data passed to the bottom sheet using Angular's dependency injection.
     * The 'new-cap' linting rule is disabled for this line because it doesn't apply in this case,
     * as the uppercase letter is part of the Angular-specific token MAT_BOTTOM_SHEET_DATA.
   */
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  public appConfig: AppConfig,
  private bottomSheetRef: MatBottomSheetRef,
  private eventLogger: EventLoggerService,
  ) {}
  content: string = '';
  ngOnInit(): void {
    this.content = this.data.content;
    this.eventLogger.cleverTapEvent('popUpView', JSON.stringify({ name: 'notification-permission-popup', pageName: `${this.content}` }));
  }

  async openNativePopup(): Promise<void> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'notification-popup-continue' }));
    if (this.data?.callback) this.data.callback();
    this.bottomSheetRef.dismiss();
  }

  closedPopup(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'notification-popup-closed' }));
    this.bottomSheetRef.dismiss();
  }

  mayBeLater(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'notification-popup-maybe-later' }));
    this.bottomSheetRef.dismiss();
  }
}
