import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WindowRefService } from '@services/window-ref-service';

@Component({
  selector: 'single-selector',
  templateUrl: './single-selector.html',
  standalone: false,
})
export class SingleSelectorComponent {
  chat: any;
  selectedIndex: any = -1;
  inputVal: any;
  loading: boolean = true;
  textInputAvailable: boolean = false;
  @Input('allowAnimation') allowAnimation: boolean = false;
  @Input('data')
  set data(data: any) {
    this.chat = data;
    delete this.inputVal;
    this.selectedIndex = -1;
    let textIndex = -1;
    const inputs = data.get('Inputs') || [];
    inputs.forEach((each: any, index: any): void => {
      if (each.type === 'TEXT') {
        this.textInputAvailable = true;
        textIndex = index;
      }
    });
    inputs.push(...inputs.splice(textIndex, 1));
    this.chat.set('Inputs', (inputs));
  }
  @Output() message: EventEmitter<any> = new EventEmitter();

  constructor(private window: WindowRefService) {}

  sendMessage(input?: string): void {
    if (this.selectedIndex >= 0) {
      const index = this.selectedIndex;
      if ((this.chat.get('Inputs')[index].type === 'TEXT' && !(input || this.inputVal))) return;
      this.message.emit({ chat: this.chat, index, input: (input || this.inputVal) });
      if ((this.chat.get('Inputs')[index].action === 'TREE')) delete this.chat;
    }
  }

  scrollToBottom(): void {
    const scrollView = this.window.nativeWindow.document.getElementById('messageContainer');
    setTimeout((): void => {
      if (scrollView.scroll instanceof Function) {
        scrollView.scroll(0, scrollView.scrollHeight);
      }
    }, 0);
  }
}
