import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { WindowRefService } from '@services/window-ref-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { LoggingService } from '@services/logging-service/logging-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { Subscription } from 'rxjs';
import { AppConfig } from '../app.config';

@Component({
  selector: 'user-refer',
  templateUrl: './user-refer.html',
  styleUrls: ['./user-refer.scss'],
  standalone: false,
})
export class UserReferComponent {
  subscriptions: Array<Subscription> = [];
  images: Array<any> = [];
  tabData: any = { count: 2, selectedTab: 0 };
  activeTab: number = 0;
  user: any;
  cashValue: number;
  type: string = 'INVITE';
  showSuccessPopup: boolean = false;
  constructor(private conn: ConnectionService,
    private windowRef: WindowRefService,
    private broadcast: BroadcastService,
    private eventLogger: EventLoggerService,
    private route: ActivatedRoute,
    private appWebBridge: AppWebBridgeService,
    private appConfig: AppConfig,
    private loggingService: LoggingService) {
  }

  async ngOnInit(): Promise<any> {
    this.user = this.conn.getActingUser();
    this.route.queryParams.subscribe((data: any): void => {
      if (data.tab === 'invite') this.activeTab = 0;
      if (data.tab === 'faq') {
        this.activeTab = 1;
        this.type = 'FAQ';
      }
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'refer', section: data.tab }));
      this.tabData.selectedTab = this.activeTab;
    });
    this.cashValue = this.appConfig.Shared.REFERRAL_CASH_VALUE;
    this.subscriptions.push(
      this.broadcast.on<any>('CONTACT_DETAILS_UPDATE').subscribe(async (data: any): Promise<void> => {
        let name: string;
        let number: string;

        try {
          ({ name, number } = typeof data === 'string' ? JSON.parse(data.replace(/\\/g, '')) : data);
        } catch (error) {
          this.broadcast.broadcast('NOTIFY', { message: 'Failed to parse data' });
          return;
        }

        if (name && number) {
          // 1. Remove spaces and hyphens from the mobile number
          number = number.replace(/[\s-]/g, '');

          // 2. Remove +91 from the mobile number if present
          if (number.startsWith('+91')) {
            number = number.substring(3);
          }

          try {
            await this.conn.referNewUserWithNumber(name, number);
            this.showSuccessPopup = true;
          } catch (error) {
            this.broadcast.broadcast('NOTIFY', { message: 'Failed to refer new user. Please try again' });
          }
        } else {
          // 3. Check if user has denied the contacts permission
          /* eslint-disable-next-line no-lonely-if */
          if (data.error && data.error === 'permission_denied') {
            this.broadcast.broadcast('NOTIFY', { message: 'Contacts permission denied. Please allow in the settings' });
          } else {
            // failed to fetch contacts
            this.broadcast.broadcast('NOTIFY', { message: 'Failed to fetch contacts' });
          }
        }
      }),
    );
  }

  tabSwitch(direction: string): void {
    if (direction === 'LEFT' && this.activeTab < this.tabData.count - 1) {
      this.activeTab += 1;
    }
    if (direction === 'RIGHT' && this.activeTab > 0) {
      this.activeTab -= 1;
    }
    this.tabData.selectedTab = this.activeTab;
  }

  async share(): Promise<any> {
    const user = this.conn.getActingUser();
    this.eventLogger.trackEvent('refer_and_earn_clicked', {
      mode: 'REFER_AND_EARN',
      username: user.get('username'),
      type: 'REFER',
    });
    this.broadcast.broadcast('SHARE', { type: 'REFER' });
  }

  active(index: number): void {
    if (index === this.activeTab) return;
    this.activeTab = index;
  }

  copyText(): void {
    const code = this.windowRef.nativeWindow.document.getElementById('referralCode');
    code.select();
    this.windowRef.nativeWindow.document.execCommand('copy');
    this.broadcast.broadcast('NOTIFY', { message: 'Copied' });
  }

  openContactBook(): void {
    this.appWebBridge.notifyContactsOpen();
  }

  closePopup(): void {
    this.showSuccessPopup = false;
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
