import { Component, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { LocalStorageService } from '@services/local-storage-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { EventLoggerService } from '@services/event-logger-service';
import { CookieService } from '@services/cookie-service';
import { BroadcastService } from '@services/broadcast-service';
import { SentryErrorHandler } from '@services/sentry-logger';
import { LoginService } from '@services/login-service/login.service';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { AuthConfig } from '../../app-constant-types';

declare const grecaptcha: any;
const reCAPTCHAKey = '6Le9rtAmAAAAAAJNpaytF64IHj5rrRtK7iXbcEOe';

@Component({
  selector: 'onboarding-number',
  templateUrl: './onboarding-mobile-number.html',
  standalone: false,
})
export class OnboardingMobileNumberComponent {
  reCAPTCHAKey: string = reCAPTCHAKey;
  subscriptions: Array<Subscription> = [];
  ui: { loading: boolean } = { loading: false };
  phoneNumber: string;
  startTimer: number;

  constructor(
    private conn: ConnectionService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private appWebBridge: AppWebBridgeService,
    private eventLogger: EventLoggerService,
    private cookies: CookieService,
    private broadcast: BroadcastService,
    private zone: NgZone,
    private sentryErrorHandler: SentryErrorHandler,
    private loginService: LoginService,
    public commonUtilityHelper: CommonUtilityHelper) {
  }

  ngOnInit(): void {
    this.startTimer = new Date().getTime();
    this.phoneNumber = this.localStorageService.getValue('CureSkin/mobileNumber') || '';
    this.appWebBridge.notifyRequestSmsPermission();
    this.subscriptions.push(this.broadcast.on<string>('AUTO_FILL_NUMBER')
      .subscribe((phone: string): void => this.zone.run((): void => {
        if (this.phoneNumber === phone) return;
        this.phoneNumber = phone;
        if (this.phoneNumber.length === 12 && this.phoneNumber.startsWith('91')) {
          this.phoneNumber = this.phoneNumber.substring(2);
        }
      })));

    /**
     * This broadcast sends you object with all necessary data to login (i.e source, mobile number, otp, ids, etc).
     * This is used for 'Login with TrueCaller' feature. Truecaller authenticates the user and sends the data from android.
     * So in this case, we ignore number/otp method and login directly with truecaller auth.
     */
    this.subscriptions.push(this.broadcast.on<string>('GET_AUTHENTICATION_INFO')
      .subscribe((authInfo: string): void => this.zone.run((): void => {
        this.handleGetAuthenticationInfo(JSON.parse(JSON.stringify(authInfo)));
      })));
    const time = new Date().getTime() - this.startTimer;
    this.eventLogger.trackEvent('onboarding_number_page_loading_time', { timeInMilliSec: time });
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'onboarding-mobile-number' }));
    this.appWebBridge.logEventInBranchAndFirebaseFromiOS({
      branch: { name: 'pageOpenMobilenumber' },
      firebase: { name: 'pageOpenMobilenumber' },
    });
  }

  private async handleGetAuthenticationInfo(authInfo: AuthConfig): Promise<void> {
    try {
      this.ui.loading = true;
      await this.loginService.authenticateUser(JSON.parse(JSON.stringify(authInfo)));
    } catch (error) {
      if (error instanceof Error) {
        this.sentryErrorHandler.handleError(error.toString());
      }
      this.eventLogger.trackEvent('auth_failure', { error: error.toString() });
    } finally {
      this.ui.loading = false;
    }
  }

  async requestOTP(): Promise<void> {
    if (this.phoneNumber.toString().length > 10) {
      this.broadcast.broadcast('NOTIFY', { message: 'Enter a valid 10 digit mobile number.' });
      return;
    }

    grecaptcha.enterprise.ready(async (): Promise<void> => {
      this.ui.loading = true;
      this.localStorageService.setValue('CureSkin/mobileNumber', this.phoneNumber);
      const deviceId = this.cookies?.get('deviceId');
      const source = this.appWebBridge.requestOSInformation();
      this.eventLogger.trackEvent('otp_requested', { device_id: deviceId, mobileNumber: this.phoneNumber });
      this.eventLogger.timeEvent('otp_requested_time', true);
      this.appWebBridge.requestStartSMSListener();
      this.eventLogger.trackInElasticSearch({
        added: new Date(),
        type: 'webApp',
        message: `device_id: ${deviceId} , mobileNumber: ${this.phoneNumber}`,
        event: 'REQUEST_OTP',
      });
      try {
        const recaptchaToken: string = await grecaptcha.enterprise.execute(this.reCAPTCHAKey, { action: 'requestOTP' });
        await this.conn.requestOTP(
          `${this.phoneNumber}`,
          source,
          { recaptcha: { action: 'requestOTP', token: recaptchaToken } });
        this.eventLogger.timeEvent('otp_requested_time');
        this.eventLogger.trackEvent('saved_mobile_number', {});
        const time = new Date().getTime() - this.startTimer;
        this.eventLogger.trackEvent('time_spent_on_filling_number', { timeInMilliSec: time });
        this.router.navigate(['/onboarding/otp']);
      } catch (error) {
        this.ui.loading = false;
        this.broadcast.broadcast('NOTIFY', { message: error.toString() });
        if (error instanceof Error) {
          this.sentryErrorHandler.handleError(error.toString());
        }
      }
    });
  }

  navigateTo(url: string): void {
    this.conn.navigateToURL(url);
  }

  back(): void {
    this.navigateTo('/language');
  }

  ngOnDestroy(): void {
    this.localStorageService.delete('CureSkin/language');
    this.unsubscribe();
  }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
