import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { DataStoreService } from '@services/data-store-service';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';
import { TimeService } from '@services/time-service';
import SwiperCore, { Scrollbar, SwiperOptions } from 'swiper';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { LatestReportIds } from '../../app-constant-types';
import { ExtendedFollowUpReport, ExtendedFollowUps, ServerAPIModels } from '../../server-api.constants';
import { AppConfig } from '../../app.config';

SwiperCore.use([Scrollbar]);

@Component({
  selector: 'user-followup',
  templateUrl: './user-followup.html',
  styles: [':host {@apply tw-block }'],
  standalone: false,
})
export class UserFollowupComponent {
  followUp: any = {};
  loading: boolean = true;
  orderPlaced: boolean = false;
  reports: Array<any> = [];
  user: any;
  @Input('hideTitle') hideTitle: boolean;
  showTakeFollowUpBanner: boolean;
  activeRegimenFound: boolean;
  isFollowUpReady: boolean = false;
  newFollowUpUIExperiment: boolean = false;
  doctorName: string;
  doctorImage: string;
  userAllReportsWithCheckUpNumber: ExtendedFollowUpReport[];
  userAllFollowUps: Array<ServerAPIModels['userFollowUps']> = [];
  extendedUserAllFollowUps: ExtendedFollowUps[];
  latestReportIds: LatestReportIds;
  isProcessPopUpOpen: boolean = false;
  isNotifyMePopUpOpen: boolean = false;
  isHairSpecialist: boolean = false;
  isSkinSpecialist: boolean = false;

  carouselConfig: SwiperOptions = {
    slidesPerView: 1,
    autoHeight: true,
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
    pagination: true,
    loop: true,
  };
  followUpCarouselConfig: SwiperOptions = {
    slidesPerView: 1,
    initialSlide: this.userAllFollowUps.length > 1 ? this.userAllFollowUps.length - 1 : 0,
    autoHeight: false,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      200: {
        slidesPerView: 1.2,
      },
      240: {
        slidesPerView: 1.5,
      },
      320: {
        slidesPerView: 2.1,
      },
      360: {
        slidesPerView: 2.41,
      },
      400: {
        slidesPerView: 2.6,
      },
      420: {
        slidesPerView: 2.8,
      },
      480: {
        slidesPerView: 3,
      },
    },
  };

  constructor(private conn: ConnectionService,
              private router: Router,
              private dataStore: DataStoreService,
              private broadcast: BroadcastService,
              private eventLogger: EventLoggerService,
              private timeService: TimeService,
              public appConfig: AppConfig,
              readonly changeDetectionRef: ChangeDetectorRef,
              protected commonUtil: CommonUtilityHelper) {
  }

  async ngOnInit(): Promise<any> {
    this.user = await this.conn.getActingUser();
    const experiments = await this.conn.findUserActiveExperiments();
    experiments.forEach((exp: any): void => {
      if (exp.key === 'new_follow_up_flow') {
        this.newFollowUpUIExperiment = true;
        this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ name: 'new-follow-up-flow' }));
      }
    });
    if (this.newFollowUpUIExperiment) {
      await this.getUserCheckupData();
      await this.getDoctorDetails();
      this.getDoctorSpeciality(this.userAllReportsWithCheckUpNumber);
    }
    await this.fetchFollowup();
    const regimens: any[] = await this.conn.fetchRegimens();
    this.orderPlaced = !!regimens.find((each: any): boolean => each.orderPlaced);
    this.activeRegimenFound = !!regimens.find((each: any): boolean => each.active);
    await this.loadFollowupReports();
    if (this.activeRegimenFound) this.showTakeFollowUpBanner = await this.conn.shouldShowFollowupBanner();
    this.conn.updateLastActiveTime('FOLLOW_UP_TAB');
    this.updateInitialSlide();
    this.loading = false;
  }

  private updateInitialSlide(): void {
    this.followUpCarouselConfig.initialSlide = this.userAllFollowUps.length > 1 ? this.userAllFollowUps.length - 1 : 0;
  }

  async loadFollowupReports(): Promise<any> {
    const where: any = { user: this.user, isSecondaryComparison: false };
    this.reports = await this.conn.fetchFollowUpReports({ where, descending: 'createdAt', include: ['doctor'] });
  }

  async fetchFollowup(): Promise<any> {
    let followUp: any = this.dataStore.get('FOLLOW_UP');
    if (!followUp) {
      followUp = await this.conn.findNextFollowUp();
      this.dataStore.set('FOLLOW_UP', followUp);
    }
    if (followUp) {
      this.followUp = followUp;
      this.followUp.date = followUp.get('effectiveFollowUpDate') || followUp.get('nextFollowUpDate');
    }

    // Initially this.followup is empty object {}, so check if object exist and has a get function.
    // eslint-disable-next-line
    if (!this.followUp || typeof this.followUp?.get !== 'function' || JSON.stringify(this.followUp) === '{}') return;

    if (this.followUp?.get('nextFollowUpDate')
      && this.timeService.differenceInDays(this.followUp?.get('nextFollowUpDate'), new Date()) <= 2) {
      this.isFollowUpReady = true;
      return;
    }

    this.isFollowUpReady = this.followUp?.get('isPreviousFollowUpSkipped') && !this.followUp?.get('ready');

    this.changeDetectionRef.detectChanges();
  }

  async requestFollowUp(): Promise<any> {
    try {
      const followUp: any = this.dataStore.get('FOLLOW_UP');
      if (followUp) {
        await this.conn.requestFollowUp({ followUpId: followUp.id });
      }
      this.conn.navigateToURL('/user?tab=chat');
    } catch (err) {
      this.broadcast.broadcast('NOTIFY', { message: err.toString() });
    }
  }

  startFollowUp(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'followUp-page-start-followUp' }));
    this.router.navigate([`/chatV2/${this.followUp.id}`], {
      queryParams: { type: 'followUp' },
    });
  }

  startFollowUpLong(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'followUp-long-page-start-followUp' }));
    this.router.navigate([`/chatV2/${this.followUp.id}`], {
      queryParams: { type: 'followUp' },
    });
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  buyRegimen(): void {
    this.router.navigate(['/user'], { queryParams: { tab: 'regimen' } });
  }

  assignCheckupNumbers(reports: Array<ServerAPIModels['userFollowUpReports']>): ExtendedFollowUpReport[] {
    return reports.map((report: ServerAPIModels['userFollowUpReports'], index: number): any => ({
      ...report,
      checkupNumber: reports.length - index,
    }));
  }

  private assignGroupDetails(reports: ExtendedFollowUpReport[]): ExtendedFollowUpReport[] {
    let groupCounter = 0; // Initialize a group ID counter

    // Group reports by 'createdAt' timestamp, using the timestamp as the key
    const groupedReports: { [key: string]: ExtendedFollowUpReport[] } = {};

    // Step 1: Group reports by 'createdAt' date
    reports.forEach((report: ExtendedFollowUpReport): void => {
      const createdAt = new Date(report.createdAt).getTime().toString(); // Get the 'createdAt' time in ms as string

      if (!groupedReports[createdAt]) {
        groupedReports[createdAt] = [];
      }
      groupedReports[createdAt].push(report);
    });

    // Step 2: Iterate through each group of reports, assign group details
    return reports.map((report: ExtendedFollowUpReport): ExtendedFollowUpReport => {
      const createdAt = new Date(report.createdAt).getTime().toString();
      const group = groupedReports[createdAt];

      // If the group exists and has more than 1 report, it's a group
      if (group && group.length > 1) {
        const indexInGroup = group.indexOf(report);

        // Increment the group counter for the group when processing the first element
        if (indexInGroup === 0) {
          groupCounter += 1;
        }

        // Check if any report in the group is unread
        const unreadExistsInGroup = group.some((groupReport: ExtendedFollowUpReport): boolean => !groupReport.read);

        // Return a new report object with the group properties assigned
        return {
          ...report,
          isGroup: true,
          group_id: groupCounter, // All group members share the same group ID
          groupAnchor: indexInGroup === 0, // First report in the group is the group anchor
          ...(unreadExistsInGroup
            ? { unreadExistsInGroup: true } // Only add unreadExistsInGroup if it's the anchor and unread exists
            : {}),
        };
      }

      // For reports that are not part of any group
      groupCounter += 1; // Each individual report gets its own group ID
      return {
        ...report,
        isGroup: false,
        group_id: groupCounter, // Unique group ID even if it's not in a group
        groupAnchor: true, // Single report, so it is the anchor
      };
    });
  }

  private assignTimelineInfo(reports: ExtendedFollowUpReport[]): ExtendedFollowUpReport[] {
    const updatedReports = [...reports]; // Clone the reports array to avoid mutating original

    updatedReports.forEach((currentReport: ExtendedFollowUpReport, currentIndex: number): void => {
      // Default values for timeline info
      // eslint-disable-next-line no-param-reassign
      currentReport.firstHalfTimelineActive = false;
      // eslint-disable-next-line no-param-reassign
      currentReport.secondHalfTimelineActive = false;

      // 1. If there are no reports before current, and it's unread, not grouped, and anchor
      if (currentIndex === 0 && !currentReport.read && !currentReport.isGroup && currentReport.groupAnchor) {
        // eslint-disable-next-line no-param-reassign
        currentReport.firstHalfTimelineActive = true;
      }

      // 2. If there are no reports before current, it's grouped, unread exists in group, and anchor
      if (currentIndex === 0 && currentReport.unreadExistsInGroup && currentReport.isGroup && currentReport.groupAnchor) {
        // eslint-disable-next-line no-param-reassign
        currentReport.firstHalfTimelineActive = true;
      }

      // 3. If there are reports before current, and it's unread, not grouped, and anchor
      if (currentIndex > 0 && !currentReport.read && !currentReport.isGroup && currentReport.groupAnchor) {
        // eslint-disable-next-line no-param-reassign
        currentReport.firstHalfTimelineActive = true;
        const previousAnchorIndex = this.findPreviousGroupAnchor(currentIndex, updatedReports);
        if (previousAnchorIndex !== -1) {
          // Set timeline info between anchors
          this.setTimelineActiveBetweenAnchors(previousAnchorIndex, currentIndex, updatedReports);
          // Set secondHalfTimelineActive of previous anchor
          updatedReports[previousAnchorIndex].secondHalfTimelineActive = true;
        }
      }

      // 4. If there are reports before current, it's grouped, unread exists in group, and anchor
      if (currentIndex > 0 && currentReport.unreadExistsInGroup && currentReport.isGroup && currentReport.groupAnchor) {
        // eslint-disable-next-line no-param-reassign
        currentReport.firstHalfTimelineActive = true;
        const previousAnchorIndex = this.findPreviousGroupAnchor(currentIndex, updatedReports);
        if (previousAnchorIndex !== -1) {
          // Set timeline info between anchors
          this.setTimelineActiveBetweenAnchors(previousAnchorIndex, currentIndex, updatedReports);
          // Set secondHalfTimelineActive of previous anchor
          updatedReports[previousAnchorIndex].secondHalfTimelineActive = true;
        }
      }
    });

    return updatedReports; // Return updated reports with timeline info
  }

  // Helper function to find previous group anchor
  private findPreviousGroupAnchor(currentIndex: number, reports: ExtendedFollowUpReport[]): number {
    for (let i = currentIndex - 1; i >= 0; i -= 1) { // Avoid using i--
      if (reports[i].groupAnchor) {
        return i; // Return the index of the previous group anchor
      }
    }
    return -1; // Return -1 if no previous anchor is found
  }

  // Refined setTimelineActiveBetweenAnchors function
  private setTimelineActiveBetweenAnchors(startIndex: number, endIndex: number, reports: ExtendedFollowUpReport[]): void {
    // Loop through all reports between the start and end index
    for (let i = startIndex + 1; i < endIndex; i += 1) { // Use i += 1 instead of i++
      const report = reports[i]; // Work with the cloned array
      if (report) {
        // eslint-disable-next-line no-param-reassign
        reports[i] = {
          ...report, // Clone the object before updating
          firstHalfTimelineActive: true,
          secondHalfTimelineActive: true,
        };
      }
    }
  }

  async getUserCheckupData(): Promise<void> {
    const response = await this.conn.getUserCheckupData();

    // Remove skipped followUps and sort by created date
    response.followUps = this.filterAndSortFollowUps(response?.followUps);

    // Separate and process reports by type
    const faceReports = this.filterAndSortReports(response?.reports, this.appConfig.Shared.Regimen.Class.FACE);
    const hairReports = this.filterAndSortReports(response?.reports, this.appConfig.Shared.Regimen.Class.HAIR);

    // Assign checkup numbers to reports
    const faceReportsWithNumbers = this.assignCheckupNumbers(faceReports);
    const hairReportsWithNumbers = this.assignCheckupNumbers(hairReports);

    // Update latest report IDs
    this.updateLatestReportIds(faceReportsWithNumbers, hairReportsWithNumbers);
    // Combine and sort all reports
    this.userAllFollowUps = response.followUps;
    // Add remaining followup days calculation
    this.extendedUserAllFollowUps = this.userAllFollowUps.map(
      (followUp: ServerAPIModels['userFollowUps']): ExtendedFollowUps => ({
        ...followUp,
        remainingFollowUpDays: this.timeService.differenceInDays(
          new Date((followUp.nextFollowUpDate as unknown as { iso: string })?.iso),
          new Date(),
        ),
      }));
    // Combine and sort all reports
    let allReports = [...faceReportsWithNumbers, ...hairReportsWithNumbers];
    allReports = this.sortAllReports(allReports);

    // Update latest report IDs
    this.updateLatestReportIds(faceReportsWithNumbers, hairReportsWithNumbers);
    // Assign group details
    this.userAllReportsWithCheckUpNumber = this.assignGroupDetails(allReports);

    // Assign timeline information after group details are assigned
    this.userAllReportsWithCheckUpNumber = this.assignTimelineInfo(this.userAllReportsWithCheckUpNumber);
  }

  public filterAndSortFollowUps(followUps: ServerAPIModels['userFollowUps'][]): ServerAPIModels['userFollowUps'][] {
    return followUps
      ?.filter((followUp: ServerAPIModels['userFollowUps']): boolean => followUp.State !== this.appConfig.Shared.Followup.State.SKIP)
      .sort((a: ServerAPIModels['userFollowUps'],
        b: ServerAPIModels['userFollowUps']): number => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  }

  public filterAndSortReports(reports: ServerAPIModels['userFollowUpReports'][], type: string): ServerAPIModels['userFollowUpReports'][] {
    return reports
      .filter((report: ServerAPIModels['userFollowUpReports']): boolean => report.type === type)
      .sort((a: ServerAPIModels['userFollowUpReports'],
        b: ServerAPIModels['userFollowUpReports']): number => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }

  public updateLatestReportIds(faceReportsWithNumbers: ExtendedFollowUpReport[],
    hairReportsWithNumbers: ExtendedFollowUpReport[]): void {
    this.latestReportIds = {
      latestFaceReportId: faceReportsWithNumbers[0]?.objectId,
      latestHairReportId: hairReportsWithNumbers[0]?.objectId,
    };
  }

  public sortAllReports(reports: ExtendedFollowUpReport[]): ExtendedFollowUpReport[] {
    return reports.sort(
      (a: ExtendedFollowUpReport,
        b: ExtendedFollowUpReport): number => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
  }

  async getDoctorDetails(): Promise<void> {
    let doctor: Table.User;
    if (this.user?.get('allocatedDoctor')) {
      doctor = await this.conn.findUserByObjectId(this.user.get('allocatedDoctor')?.id);
    }
    this.doctorName = doctor.get('DoctorDisplayName') || doctor.get('username');
    this.doctorImage = doctor.get('doctorDisplayImage');
  }

  getDoctorSpeciality(reports: Array<ExtendedFollowUpReport>): void {
    this.isSkinSpecialist = reports?.some((report: ExtendedFollowUpReport):
    boolean => report.type === this.appConfig.Shared.Regimen.Class.FACE);
    this.isHairSpecialist = reports?.some((report: ExtendedFollowUpReport):
    boolean => report.type === this.appConfig.Shared.Regimen.Class.HAIR);
  }

  openReportOfId(reportId: string): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'view-report-from-new-follow-up-card-list' }));
    this.router.navigate([`/user/report/${reportId}`]);
  }

  async startFollowUpOfId(followUp: ExtendedFollowUps): Promise<void> {
    const calledFromActiveCard: boolean = false;
    const eventName: string = calledFromActiveCard ? 'start-follow-up-from-new-follow-up-card-list'
      : 'resume-follow-up-from-new-follow-up-card-list';
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: eventName }));
    if (followUp.State !== this.appConfig.Shared.Followup.State.WAITING_FOR_IMAGE) {
      await this.conn.createFollowUp();
    }
    await this.router.navigate([`/chatV2/${followUp?.objectId}`], {
      queryParams: { type: 'followUp' },
    });
  }

  toggleProcessPopup(): void {
    const eventName = this.isProcessPopUpOpen ? 'in-process-pop-up-new-follow-up-closed' : 'in-process-pop-up-new-follow-up';
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: eventName }));
    this.isProcessPopUpOpen = !this.isProcessPopUpOpen;
  }

  toggleNotifyMePopup(): void {
    const eventName = this.isNotifyMePopUpOpen ? 'notify-me-pop-up-new-follow-up-closed' : 'notify-me-pop-up-new-follow-up';
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: eventName }));
    this.isNotifyMePopUpOpen = !this.isNotifyMePopUpOpen;
  }

  showNotifyMeCard(followUp: ExtendedFollowUps): boolean {
    return followUp?.State === this.appConfig.Shared.Followup.State.PENDING
      && !followUp?.ready
      && !followUp?.isPreviousFollowUpSkipped
      && !followUp?.isAnytimeFollowUpEnabled
      && (followUp.remainingFollowUpDays > 2 || followUp.remainingFollowUpDays < 0);
  }

  showFollowUpActiveCard(followUp: ExtendedFollowUps): boolean {
    const remainingFollowUpDays: number = followUp?.remainingFollowUpDays;
    const isFollowUpFinished = followUp?.State === this.appConfig.Shared.Followup.State.FINISHED;
    const isFollowUpPending = followUp?.State === this.appConfig.Shared.Followup.State.PENDING;
    const isFollowUpNotReady = !followUp?.ready;
    const isFollowUpEnabled = (!!followUp?.isPreviousFollowUpSkipped || !!followUp?.isAnytimeFollowUpEnabled)
      || (remainingFollowUpDays <= 2 && remainingFollowUpDays >= 0);
    return !isFollowUpFinished && isFollowUpPending && isFollowUpEnabled && isFollowUpNotReady;
  }

  showReportInProcessCard(followUp: ExtendedFollowUps): boolean {
    return followUp?.ready && (followUp?.State === this.appConfig.Shared.Followup.State.PENDING);
  }

  showFollowUpWaitingForImageCard(followUp: ExtendedFollowUps): boolean {
    return followUp?.State === this.appConfig.Shared.Followup.State.WAITING_FOR_IMAGE;
  }

  showReportReadyCard(followUp: ExtendedFollowUps): boolean {
    return (followUp?.followUpReportStatus?.FACE || followUp?.followUpReportStatus?.HAIR)
      && followUp?.State === this.appConfig.Shared.Followup.State.FINISHED;
  }
}
