import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'product-item',
  templateUrl: './product-item.html',
  standalone: false,
})
export class ProductItemComponent implements OnInit {
  orderPlaced: any;
  active: any;
  @Input() user: any;
  @Input() userLanguage: any;
  @Input('product') main: any;
  @Input('activeExperiments') activeExperiments: any[];
  @Input('regimen')
  set regimenData(regimen: any) {
    this.orderPlaced = regimen.orderPlaced;
    this.active = regimen.active;
  }
  @Input() itemIndex: any;
  @Output() playVideo: EventEmitter<any> = new EventEmitter();
  @Output() openCarousal: EventEmitter<any> = new EventEmitter<number>();

  product: any;
  purpose: any;
  instructions: any;
  instructionSet: any;
  showProductDescription: boolean = false;
  ingredients: any;
  expansionPanel: { application: boolean, advice: boolean } = { advice: false, application: false };

  async ngOnInit(): Promise<any> {
    if (this.itemIndex === 0) this.expansionPanel = { advice: true, application: true };

    this.instructionSet = this.main.instructionSet || {};
    Object.keys(this.instructionSet).forEach((each: any): void => {
      if (each && this.instructionSet[each] && typeof this.instructionSet[each] === 'string') {
        this.instructionSet[each] = this.instructionSet[each].trim().replace('N/A', '').trim();
      }
    });
    this.activeExperiments.forEach((each: any): void => {
      this.showProductDescription = true;
    });
    this.instructions = this.main.instruction || [];
    this.product = this.main.product;
    this.purpose = this.product.purpose;
    if (typeof this.product.ingredients === 'string') this.ingredients = this.product.ingredients;
  }

  toggleExpansion(panelName: string): any {
    return this.expansionPanel[panelName] = !this.expansionPanel[panelName];
  }

  onImageClick(event: Event, index: number): void {
    event.stopPropagation(); // Prevent the click event from bubbling up
    this.openCarousal.emit(index);
  }
}
