<div class="tw-block tw-bg-gray-100">
  <!-- Regimen Hero-->
  <section class="tw-pb-8 tw-bg-grid-layout tw-bg-repeat-x tw-bg-top tw-mb-2" *ngIf="is2499Upsell && appConfig.Shared.Regimen.Class.FACE === regimen.class" [ngClass]="backgroundColor" (click)="clickOnRegimenImage()">
    <img class="tw-block tw-mb-5" *ngIf="regimen?.regimenCoverImage" [src]="regimen?.regimenCoverImage" appPinchZoom="appPinchZoom" />
    <h3 class="tw-font-body tw-font-bold tw-text-center tw-text-500 tw-text-gray-900 tw-mb-4" *ngIf="appConfig.Shared.Regimen.Class.FACE === regimen.class" i18n="@@skinKitReady">
      {{patientName}}, your skin <br />treatment kit is ready!
    </h3>
    <h3 class="tw-font-body tw-font-bold tw-text-center tw-text-500 tw-text-gray-900 tw-mb-4" *ngIf="appConfig.Shared.Regimen.Class.HAIR === regimen.class" i18n="@@hairKitReady">
      {{patientName}}, your hair <br />treatment kit is ready!
    </h3>
    <!-- Regimen offer banner -->
    <app-regimen-offer-product-banner *ngIf="((newRegimenPricingExperiment && !regimen?.orderPlaced) || regimen?.extraProducts?.length)" (click)="$event?.stopPropagation(); regimenOfferProductEvent();" class="tw-mx-4 tw-mb-4">
      <app-regimen-offer-tag><span i18n="@@limitedTimeOfferPouch">Limited time offer</span></app-regimen-offer-tag>
      <regimen-offer-product-title><span i18n="@@freePouch">+ Free Pouch</span></regimen-offer-product-title>
      <regimen-offer-product-caption><span i18n="@@withYourPurchase">with your treatment kit purchase</span></regimen-offer-product-caption>
    </app-regimen-offer-product-banner>
    <!-- Regimen offer banner -->
    <!-- Regimen Tags -->
    <ul class="tw-px-4 tw-flex tw-flex-row tw-justify-between tw-items-start tw-gap-1" *ngIf="newRegimenIconsExperiment && doesAllTagsContainRebrandedImages">
      <li class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-gap-1.5" *ngFor="let item of regimen.regimenTag">
        <div class="tw-relative tw-z-[1] gradient-border after:tw-content-[''] after:tw-absolute after:tw-w-full after:tw-h-full after:tw-top-1/2 after:tw-left-1/2 after:tw-rounded-full after:tw-p-[21.5px] after:tw-z-[-1]  after:[background-image:linear-gradient(to_right,var(--from-color)_10%,var(--to-color)_100%)] after:tw-animate-rotate"
        [style.--from-color]="getFromColor()"
        [style.--to-color]="getToColor()">
          <img class="tw-h-10 tw-w-10" [src]="item.rebrandedImageV2" />
        </div>
        <p class="tw-font-body tw-text-200 tw-text-gray-900 tw-text-center tw-break-words">
          {{ item.title }}
        </p>
      </li>
    </ul>
    <ul class="tw-px-4 tw-flex tw-flex-row tw-justify-between tw-items-start tw-gap-1" *ngIf="!newRegimenIconsExperiment || (newRegimenIconsExperiment && !doesAllTagsContainRebrandedImages)">
      <li class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-gap-1.5" *ngFor="let item of regimen.regimenTag">
        <img class="tw-h-10 tw-w-10" [src]="item.rebrandedImage" />
        <p class="tw-font-body tw-text-200 tw-text-gray-900 tw-text-center tw-break-words">
          {{ item.title }}
        </p>
      </li>
    </ul>
  </section>
  <section class="tw-pb-8 tw-bg-grid-layout tw-bg-repeat-x tw-bg-top tw-mb-2" *ngIf="!is2499Upsell || appConfig.Shared.Regimen.Class.HAIR === regimen.class" [ngClass]="{'tw-bg-orange-100':  appConfig.Shared.Regimen.Class.HAIR === regimen.class, 'tw-bg-purple-100':  appConfig.Shared.Regimen.Class.FACE === regimen.class }" (click)="clickOnRegimenImage()">
    <img class="tw-block tw-mb-5" *ngIf="regimen?.regimenCoverImage" [src]="regimen?.regimenCoverImage" appPinchZoom="appPinchZoom" />
    <h3 class="tw-font-body tw-font-bold tw-text-center tw-text-500 tw-text-gray-900 tw-mb-4" *ngIf="appConfig.Shared.Regimen.Class.FACE === regimen.class" i18n="@@skinKitReady">
      {{patientName}}, your skin <br />treatment kit is ready!
    </h3>
    <h3 class="tw-font-body tw-font-bold tw-text-center tw-text-500 tw-text-gray-900 tw-mb-4" *ngIf="appConfig.Shared.Regimen.Class.HAIR === regimen.class" i18n="@@hairKitReady">
      {{patientName}}, your hair <br />treatment kit is ready!
    </h3>
    <!-- Regimen offer banner -->
    <app-regimen-offer-product-banner *ngIf="((newRegimenPricingExperiment && !regimen?.orderPlaced) || regimen?.extraProducts?.length)" (click)="$event?.stopPropagation(); regimenOfferProductEvent();" class="tw-mx-4 tw-mb-4">
      <app-regimen-offer-tag><span i18n="@@limitedTimeOfferPouch">Limited time offer</span></app-regimen-offer-tag>
      <regimen-offer-product-title><span i18n="@@freePouch">+ Free Pouch</span></regimen-offer-product-title>
      <regimen-offer-product-caption><span i18n="@@withYourPurchase">with your treatment kit purchase</span></regimen-offer-product-caption>
    </app-regimen-offer-product-banner>
    <!-- Regimen offer banner -->
    <ul class="tw-px-4 tw-flex tw-flex-row tw-justify-between tw-items-start tw-gap-1" *ngIf="regimen.regimenTag && regimen.regimenTag[0]?.rebrandedImage">
      <li class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-gap-1.5" *ngFor="let item of regimen.regimenTag">
        <img class="tw-h-10 tw-w-10" [src]="item.rebrandedImage" />
        <p class="tw-font-body tw-text-200 tw-text-gray-900 tw-text-center tw-break-words">
          {{ item.title }}
        </p>
      </li>
    </ul>
  </section>
  <choose-plan class="tw-fixed tw-left-0 tw-right-0 tw-z-10" *ngIf="is2499Upsell && !regimen?.orderPlaced" [ngClass]="{ 'tw-bottom-[74px]': regimen?.orderPlaced, 'tw-bottom-[94px]': !regimen?.orderPlaced }" style="background: rgba(255, 255, 255, 1)" [regimen]="regimen" (onSelectPlan)="changeRegimenKitInfo($event)" (onReadMore)="readMoreAboutPlans()" (hideVariants)="is2499Upsell = false"></choose-plan>
  <!-- Kit items-->
  <product-list *ngIf="regimen.morning[0]?.product?.title" [swapInstructionReadMoreButtons]="swapInstructionReadMoreButtons" [products]="products" (onClick)="clickOnProductImage()" (onReadMoreClick)="readMoreAboutProducts()" (onInstructionClick)="seeInstructions()"></product-list>
  <!-- Doctor card-->
  <section class="tw-mx-6 tw-my-6 tw-py-4 tw-bg-white tw-rounded-xl tw-shadow-z1 tw-overflow-hidden tw-relative" *ngIf="allocatedDoctor" (click)="readMoreAboutDoctor()">
    <div class="tw-relative">
      <div class="tw-px-6 tw-py-2 tw-mb-3 tw-bg-orange-100 tw-rounded-tr-lg tw-rounded-br-lg tw-w-content tw-flex tw-flex-row tw-items-center tw-gap-2">
        <cs-icon class="tw-w-5 tw-h-5 tw-text-orange-500" [iconName]="'check_circle'"></cs-icon>
        <p class="tw-font-body tw-font-bold tw-text-200 tw-text-orange-400" *ngIf="appConfig.Shared.Regimen.Class.HAIR === regimen.class" i18n="@@yourHairSpecialist"> Your hair specialist </p>
        <p class="tw-font-body tw-font-bold tw-text-200 tw-text-orange-400" *ngIf="appConfig.Shared.Regimen.Class.FACE === regimen.class" i18n="@@yourSkinSpecialist"> Your skin specialist </p>
      </div>
      <div class="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-pl-6 tw-pr-4 tw-justify-between">
        <div>
          <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-gray-900 tw-mb-1">
            {{allocatedDoctor?.get('DoctorDisplayName')}}
          </h2>
          <p class="tw-font-body tw-text-300 tw-text-gray-400 tw-mb-2">
            {{conn.getQualifyText(allocatedDoctor)}}
          </p>
          <p class="tw-font-body tw-font-bold tw-text-200 tw-text-gray-900" i18n="@@noOfCureskinPatientTreatedSoFar"> Has treated {{allocatedDoctor?.get('patientsTreated') || 0}}+ <br />Cureskin patients </p>
        </div>
        <img class="tw-flex-none tw-h-28 tw-w-28 tw-rounded-full tw-bg-green-100 tw-object-contain" *ngIf="allocatedDoctor?.get('DoctorImageThumbnail')" [src]="allocatedDoctor.get('doctorDisplayImage')" alt="Doctor Image" />
      </div>
    </div>
  </section>
  <section class="tw-px-6 tw-bg-transparent tw-mb-6">
    <ng-container *ngIf="!pendingCallBannerExperiment">
      <div class="tw-pt-5 tw-pr-4 tw-pb-4 tw-pl-6 tw-bg-white tw-rounded-xl tw-shadow-z1" *ngIf="allocatedDoctor && regimen?.variantId !== 'variant_999'">
      <div class="tw-flex tw-flex-row tw-items-start tw-gap-3">
        <div class="tw-flex">
          <input class="tw-peer" id="switch" type="checkbox" [(ngModel)]="regimen.optedForDoctorCall" [disabled]="regimen.orderPlaced || regimen.active" />
          <label class="tw-m-0 tw-cursor-pointer tw-h-6 tw-w-10 tw-bg-gray-200 tw-block tw-rounded-full tw-relative after:tw-duration-300 after:tw-content-[''] after:tw-absolute after:tw-top-[2px] after:tw-left-[2px] after:tw-h-5 after:tw-w-5 after:tw-bg-white after:tw-rounded-full peer-checked:tw-bg-green-100 after:peer-checked:tw-left-[-2px] after:peer-checked:tw-bg-green-400 after:peer-checked:tw-translate-x-full" for="switch" (click)="onOptForDoctorCall()"></label>
        </div>
        <div>
          <h2 class="tw-font-body tw-font-bold tw-text-300 tw-text-gray-900 tw-mb-1" i18n="@@optForCallFromDoctor"> Opt for a call from your doctor </h2>
          <p class="tw-font-body tw-text-200 tw-text-gray-900" *ngIf="!regimen.optedForDoctorCall" i18n="@@doctorWillCallWith24HourNew"> Want to speak to your assigned doctor? Select this option and get a call from your doctor post the purchase of the kit. </p>
          <p class="tw-font-body tw-text-200 tw-text-gray-900" *ngIf="regimen.optedForDoctorCall" i18n="@@doctorWillShareVoiceNote"> Please place your order now. {{allocatedDoctor?.get('DoctorDisplayName')}} will call you within the next 24-48 hours (before the shipping of your kit). </p>
        </div>
      </div>
    </div>
    </ng-container>
    <ng-container *ngIf="pendingCallBannerExperiment && !hidePendingCallBanner">
      <ng-container *ngIf="!pendingCallState">
        <app-request-call-banner [experimentVariantData]="pendingCallBannerExperimentVariantData" [regimen]="regimen" (pendingCallCreated)="onPendingCallCreated()"></app-request-call-banner>
      </ng-container>
      <ng-container *ngIf="pendingCallState === this.appConfig.Shared.PendingCall.Status.Requested">
        <app-call-scheduled-banner></app-call-scheduled-banner>
      </ng-container>
      <ng-container *ngIf="pendingCallState === this.appConfig.Shared.PendingCall.Status.CallCompleted">
        <app-call-completed-banner></app-call-completed-banner>
      </ng-container>
      <ng-container *ngIf="pendingCallState === this.appConfig.Shared.PendingCall.Status.CallLater || pendingCallState === this.appConfig.Shared.PendingCall.Status.NotReachable">
        <app-doctor-call-unsuccessful-banner [status]="pendingCallState"></app-doctor-call-unsuccessful-banner>
      </ng-container>
    </ng-container>
  </section>
  <!-- Services-->
  <ng-container *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionUI else defaultPropositionUI"></ng-container>
  <ng-template #regimenNewPropositionUI>
    <div class="tw-mt-6 tw-mb-4">
      <regimen-proposition [regimenId]="regimen?.regimenId"
                           [regimenClass]="regimen?.class" [variantId]="regimen?.variantId" [totalSellingPrice]="regimen?.totalSP"
                           [productsCount]="products?.length" [doctorImageUrl]="allocatedDoctor?.get('DoctorImageThumbnail')" />
    </div>
  </ng-template>
  <ng-template #defaultPropositionUI>
    <section class="tw-pl-8 tw-pt-6 tw-pb-5 tw-pr-5 tw-bg-green-100 tw-mb-6">
      <h2 class="font-body tw-font-bold tw-text-500 tw-text-green-400 tw-pb-2 tw-border-b tw-border-gray-300 tw-mb-4" i18n="@@whatWillYouGet"> What will you get in ₹{{regimen.totalSP}}
      </h2>
      <ul class="tw-flex tw-flex-col tw-items-stretch tw-gap-9 tw-mb-6" (click)="readMoreAboutServices()">
        <ng-container *ngIf="!is2499Upsell">
          <li class="tw-flex tw-flex-row tw-items-center tw-gap-4">
            <img class="tw-flex-none tw-h-10 tw-w-10 tw-rounded-xl" [src]="regimen?.regimenCoverImage || 'https://cdn.cureskin.com/kit.png'" />
            <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-1">
              <p class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900" i18n="@@cureskinGuaranteeNew"> 100% Effectiveness </p>
              <p class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@clinicallyTestedIngredients"> Clinically-tested ingredients and customised products to ensure you get visible results. </p>
            </div>
            <cs-icon class="tw-flex-none tw-h-5 tw-w-5" [iconName]="'chevron_right'"></cs-icon>
          </li>
          <li class="tw-flex tw-flex-row tw-items-center tw-gap-4">
            <img class="tw-flex-none tw-h-10 tw-w-10 tw-rounded-xl" src="https://cdn.cureskin.com/dr-checkup.png" />
            <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-1">
              <p class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900" i18n="@@regDocCheckup"> Monthly Doctor Checkups </p>
              <p class="tw-font-body tw-text-200 tw-text-gray-900">
                <span *ngIf="regimen.class === appConfig.Shared.Regimen.Class.HAIR" i18n="@@monthlyDoctorDescNewHair">Free routine checkups with a Cureskin doctor to measure your hair progress in detail.</span>
                <span *ngIf="regimen.class === appConfig.Shared.Regimen.Class.FACE" i18n="@@monthlyDoctorDescNewSkin">Free routine checkups with a Cureskin doctor to measure your skin progress in detail.</span>
              </p>
            </div>
            <cs-icon class="tw-flex-none tw-h-5 tw-w-5" [iconName]="'chevron_right'"></cs-icon>
          </li>
          <li class="tw-flex tw-flex-row tw-items-center tw-gap-4">
            <img class="tw-flex-none tw-h-10 tw-w-10 tw-rounded-xl" src="https://cdn.cureskin.com/diet.png" />
            <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-1">
              <ng-container *ngIf="appConfig.Shared.Regimen.Class.FACE === regimen.class">
                <p class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900" i18n="@@lifeStyleGuidanceSkin"> Skin-friendly Diet Plan </p>
                <p class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@complimentaryMealPlansSkin"> Complimentary meal plans to make sure your daily diet contains food that boosts skin health. </p>
              </ng-container>
              <ng-container *ngIf="appConfig.Shared.Regimen.Class.HAIR === regimen.class">
                <p class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900" i18n="@@lifeStyleGuidanceHair"> Hair-friendly Diet Plan </p>
                <p class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@complimentaryMealPlansHair"> Complimentary meal plans to make sure your daily diet contains food that boosts hair health. </p>
              </ng-container>
            </div>
            <cs-icon class="tw-flex-none tw-h-5 tw-w-5" [iconName]="'chevron_right'"></cs-icon>
          </li>
          <li class="tw-flex tw-flex-row tw-items-center tw-gap-4">
            <img class="tw-flex-none tw-h-10 tw-w-10 tw-rounded-xl" src="https://cdn.cureskin.com/app/img/regimen-service-4.png" />
            <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-1">
              <p class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900" i18n="@@freeHomeDelivery"> Free Home Delivery On Online Payment </p>
              <p class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@freeHomeDeliveryDescNew"> No shipping charges or hidden costs. Only an effective treatment kit delivered right to your doorstep. </p>
            </div>
            <cs-icon class="tw-flex-none tw-h-5 tw-w-5" [iconName]="'chevron_right'"></cs-icon>
          </li>
          <li class="tw-flex tw-flex-row tw-items-center tw-gap-4">
            <img class="tw-flex-none tw-h-10 tw-w-10 tw-rounded-xl" src="https://cdn.cureskin.com/support.png" />
            <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-1">
              <p class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.FACE" i18n="@@personalSkinAdvisor"> Personal Skin Advisor </p>
              <p class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.HAIR" i18n="@@personalHairAdvisor"> Personal Hair Advisor </p>
              <p class="tw-font-body tw-text-200 tw-text-gray-900" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.FACE" i18n="@@personalSkinAdvisorDescNew"> A team of skin advisors made available 24X7 on chat, to solve your doubts and support your healthy skin journey. </p>
              <p class="tw-font-body tw-text-200 tw-text-gray-900" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.HAIR" i18n="@@personalHairAdvisorDesc"> A team of hair advisors made available 24X7 on chat, to solve your doubts and support your healthy hair journey. </p>
            </div>
            <cs-icon class="tw-flex-none tw-h-5 tw-w-5" [iconName]="'chevron_right'"></cs-icon>
          </li>
        </ng-container>
        <ng-container *ngIf="is2499Upsell">
          <ng-container *ngIf="regimen.class === appConfig.Shared.Regimen.Class.FACE">
            <li class="tw-flex tw-flex-row tw-items-center tw-gap-4" *ngFor="let service of variantServices[selectedPlan]; let i = index;">
              <img *ngIf="i === 0" class="tw-flex-none tw-h-10 tw-w-10 tw-rounded-xl" [src]="regimen?.regimenCoverImage || service['img']" />
              <img *ngIf="i !== 0" class="tw-flex-none tw-h-10 tw-w-10 tw-rounded-xl" [src]="service['img']" />
              <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-1">
                <p class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900">
                  {{ service[userLanguage][0] }}
                </p>
                <p class="tw-font-body tw-text-200 tw-text-gray-900">
                  {{ service[userLanguage][1]}}
                </p>
              </div>
              <cs-icon class="tw-flex-none tw-h-5 tw-w-5" [iconName]="'chevron_right'"></cs-icon>
            </li>
          </ng-container>
          <ng-container *ngIf="regimen.class === appConfig.Shared.Regimen.Class.HAIR">
            <li class="tw-flex tw-flex-row tw-items-center tw-gap-4" *ngFor="let service of hairServices[selectedPlan]; let i = index;">
              <img *ngIf="i === 0" class="tw-flex-none tw-h-10 tw-w-10 tw-rounded-xl" [src]="regimen?.regimenCoverImage || service['img']" />
              <img *ngIf="i !== 0" class="tw-flex-none tw-h-10 tw-w-10 tw-rounded-xl" [src]="service['img']" />
              <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-1">
                <p class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900">
                  {{ service[userLanguage][0] }}
                </p>
                <p class="tw-font-body tw-text-200 tw-text-gray-900">
                  {{ service[userLanguage][1]}}
                </p>
              </div>
              <cs-icon class="tw-flex-none tw-h-5 tw-w-5" [iconName]="'chevron_right'"></cs-icon>
            </li>
          </ng-container>
        </ng-container>
      </ul>
      <div class="tw-w-fit tw-flex tw-items-center tw-justify-center tw-gap-3 tw-px-4 tw-h-10 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-300 tw-text-green-500 tw-rounded-full" *ngIf="!regimen?.orderPlaced && is2499Upsell" (click)="readMoreAboutPlans()">
        <span i18n="@@readMore">Read More</span>
        <cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
      </div>
    </section>
  </ng-template>
  <ng-container *ngIf="regimen.class === appConfig.Shared.Regimen.Class.HAIR && user?.get('Gender') === appConfig.Shared.Gender.MALE">
    <img class="tw-px-4" src="https://cdn.cureskin.com/app/img/whatToExpect_{{user?.get('languagePreference') === 'hi' ? 'hi' : 'en'}}.gif" />
  </ng-container>
  <testimonial-wall [regimen]="regimen" [experiments]="experiments"></testimonial-wall>
  <app-faq-section></app-faq-section>
  <div class="tw-mt-6 tw-mx-6 tw-font-body tw-text-300 tw-pb-2" *ngIf="!regimen.orderPlaced" [ngStyle]="{'marginBottom': is2499Upsell ? '170px' : '0'}" (click)="startAgain()">
    <div class="tw-text-gray-500" i18n="@@forgotToShare"> Forgot to share some important information? </div>
    <div class="tw-text-gray-500" i18n="@@neverLate"> It's never too late! Simply re-answer the questions. </div>
    <hr class="tw-my-1" />
    <div class="tw-font-bold tw-text-gray-700" i18n="@@changeAnswers"> + Click Here to Change the answers </div>
    <div *ngIf="showRefundPolicyOption" [ngStyle]="{'marginBottom': is2499Upsell && showRefundPolicyOption ? '170px' : '0'}">
      <refund-policy></refund-policy>
    </div>
  </div>
</div>
