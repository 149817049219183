import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'shop-order-banners',
  templateUrl: './shop-order-banners.html',
  standalone: false,
})
export class ShopBannersComponent {
  @Input('productsInCartArray') productsInCartArray: any;
  @Input('numberOfProductsInCart') numberOfProductsInCart: any;
  @Input('cartDiscount') cartDiscount: any;
  @Input('totalSp') totalSp: number;
  @Input('userDrImage') userDrImage: string;
  @Input('rebrandedLevelFourBanners') rebrandedLevelFourBanners: boolean;
  @Output() goToCart: EventEmitter<any> = new EventEmitter();

  handleClick():void {
    this.goToCart.emit();
  }
}
