import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { LocalStorageService } from '@services/local-storage-service';
import { EventLoggerService } from '@services/event-logger-service';
import { BroadcastService } from '@services/broadcast-service';
import { interval, Subscription } from 'rxjs';
import { AppConfig } from '../../app.config';

type TabStatusI = {
  HOME: boolean;
  CHAT: boolean;
  FOLLOWUP: boolean;
  REGIMEN: boolean;
  EXPLORE: boolean;
  SHOP: boolean;
  SUPPORT: boolean;
};
type TabNameI = {
  HOME: string,
  CHAT: string,
  FOLLOWUP: string,
  REGIMEN: string,
  EXPLORE: string,
  SHOP: string,
};

@Component({
  selector: 'user-view',
  templateUrl: './user-view.html',
  styleUrls: ['./user-view.scss'],
  standalone: false,
})
export class UserViewComponent implements OnDestroy {
  tabs: TabNameI = {
    HOME: 'HOME',
    CHAT: 'CHAT',
    FOLLOWUP: 'FOLLOWUP',
    REGIMEN: 'REGIMEN',
    EXPLORE: 'EXPLORE',
    SHOP: 'SHOP',
  };
  userStatus: any = { PAID: false };
  isTabActive: TabStatusI = {} as TabStatusI;
  tabAnimation: TabNameI = <TabNameI>{};
  selectedIndex: number = -1;
  subscriptions: Array<Subscription> = [];
  isInternalUser: boolean = false;
  user: any;
  experiments: Array<any> = [];
  regimens: Array<any> = [];
  notification: any = {};
  homePage: boolean = false;
  isTabHasUpdates: Partial<TabStatusI> = {
    CHAT: false,
    FOLLOWUP: false,
    REGIMEN: false,
    EXPLORE: false,
    SUPPORT: false,
    SHOP: false,
  };
  isTabUpdatesFetched: boolean;
  hideBottomNavBar: boolean = false;
  exploreSectionExperiment: boolean = false;

  constructor(private conn: ConnectionService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorageService,
    private eventLogger: EventLoggerService,
    public appConfig: AppConfig,
    private broadcastService: BroadcastService) {
    this.broadcastService.on('DOUBTS_FULLSCREEN_VIDEO_ON').subscribe((): void => {
      this.hideBottomNavBar = true;
    });
    this.broadcastService.on('DOUBTS_FULLSCREEN_VIDEO_OFF').subscribe((): void => {
      this.hideBottomNavBar = false;
    });
  }

  /**
   * Subscribes to query param change and animates the tab visibility.
   * Hides the active tab and brings in new tab to visibility.
   * Fetches for other tab updates every 30 seconds.
   */
  async ngOnInit(): Promise<any> {
    this.user = this.conn.getActingUser();
    this.experiments = await this.conn.findUserActiveExperiments();
    this.isInternalUser = this.conn.isInternalUser();
    this.findUserStatus();
    this.subscriptions.push(this.route.queryParams.subscribe(async ({ tab, username }: { tab: string, username: string }): Promise<any> => {
      let index;
      switch (tab) {
        case 'explore': {
          index = 3;
          break;
        }
        case 'regimen': {
          index = 2;
          break;
        }
        case 'shop': {
          index = 4;
          break;
        }
        case 'followups':
        case 'chat': {
          index = 1;
          break;
        }
        case 'home':
        default:
          index = 0;
      }
      if (this.selectedIndex === index) return;
      if (this.selectedIndex === -1) {
        this.tabAnimation[this.getTabNameByIndex(index)] = 'trans-xy-0';
      } else if (this.selectedIndex > index) {
        this.tabAnimation[this.getTabNameByIndex(this.selectedIndex)] = 'trans-x-hide';
        this.tabAnimation[this.getTabNameByIndex(index)] = 'trans-xy-0';
      } else if (this.selectedIndex < index) {
        this.tabAnimation[this.getTabNameByIndex(this.selectedIndex)] = 'trans-x-hide';
        this.tabAnimation[this.getTabNameByIndex(index)] = 'trans-xy-0';
      }
      this.isTabActive[this.getTabNameByIndex(index)] = true;
      if (this.selectedIndex >= 0) this.isTabActive[this.getTabNameByIndex(this.selectedIndex)] = false;
      this.selectedIndex = index;
      setTimeout((): Promise<void> => this.getOtherTabUpdates(), 500);
    }));
    this.subscriptions.push(interval(30 * 1000).subscribe((): boolean => this.isTabUpdatesFetched = false));
    this.exploreSectionExperiment = this.experiments.find((experiment: any): any => experiment.key === 'explore_tab');
  }

  getTabNameByIndex(index: number): string {
    if (index === 0) return this.tabs.HOME;
    if (index === 1) return this.tabs.CHAT;
    if (index === 2) return this.tabs.REGIMEN;
    if (index === 4) return this.tabs.SHOP;
    return this.tabs.EXPLORE;
  }

  /**
   * Routes to specific tab by changing name of tab in query params.
   * @param {number} index - tab index that has to be routed to.
   * @returns {any}
   */
  onTabChange(index: number): any {
    this.findUserStatus();
    if (index === this.selectedIndex) return 0;
    let queryParams: any;
    switch (index) {
      case 3: {
        queryParams = { tab: 'explore' };
        break;
      }
      case 4: {
        queryParams = { tab: 'shop' };
        break;
      }
      case 2: {
        queryParams = { tab: 'regimen' };
        break;
      }
      case 0:
      default:
        queryParams = { tab: 'home' };
    }
    return this.router.navigate([], { queryParams, relativeTo: this.route });
  }

  /**
   * 1. Chat tab - Checks for new message in chat tab.
   * 2. Chat -> Followup tab - Checks for unread followup reports.
   * 3. Regimen tab - Checks for updates in regimen tab based of 'regimenUpdateVisited' flag in local storage.
   * 4. Explore tab - Checks for new feeds in explore tab.
   */
  async getOtherTabUpdates(): Promise<void> {
    if (this.isTabUpdatesFetched) return;
    this.isTabUpdatesFetched = true;
    if (!this.user) this.user = await this.conn.getActingUser();

    let where: any = { UserId: this.user?.get('username') };

    const unReadReports = await this.conn.countFollowUpReports({ where: { user: this.user, read: { $exists: false } } });
    this.isTabHasUpdates.FOLLOWUP = !!unReadReports;

    this.isTabHasUpdates.REGIMEN = this.localStorage.getValue('regimenUpdateVisited', 'true') === 'false';

    const exploreTabLastVisitedAt = this.localStorage.getJsonValue('CureSkin/lastActiveTime', '{}').EXPLORE_TAB;
    where = {};
    if (exploreTabLastVisitedAt) where.$gt = { createdAt: exploreTabLastVisitedAt };
  }

  stopPropagation(event: any): void {
    event.stopPropagation();
  }

  cameraClick(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-camera' }));
    this.navigateTo('/user/instantCheckup');
  }

  shopClick(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-shop' }));
  }

  needHelpClick(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-need-help-nav', pageName: this.route.snapshot.queryParams }));
    this.navigateTo('/support');
  }

  exploreClick(): void {
    this.eventLogger.cleverTapEvent('click-explore', JSON.stringify({ pageName: this.route.snapshot.queryParams }));
  }

  navigateTo(url: string): void {
    this.conn.navigateToURL(url);
  }

  async findUserStatus(): Promise<any> {
    if (this.user?.get('orderState') === 'DELIVERED') this.userStatus.PAID = true;
  }

  async setNewRegimenFlag(state: boolean): Promise<void> {
    this.findUserStatus();
    this.hideBottomNavBar = state;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
