import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ConnectionService } from '@services/connection-service';
import { fromSelectors } from '@store/selectors';
import { Store } from '@ngrx/store';
import { fromActions } from '@store/actions';
import { Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'support-view-edit-order',
  templateUrl: './support-view-edit-order.html',
  styleUrls: ['./support-view-edit-order.scss'],
  standalone: false,
})
export class SupportViewEditOrderComponent implements OnInit, OnDestroy {
  orders$: Observable<any> = this.store.select(fromSelectors.selectOrders);

  subscriptions: Array<Subscription> = [];

  constructor(
    public appConfig: AppConfig,
    public store: Store,
    private broadcast: BroadcastService,
    private router: Router,
    private connectionService: ConnectionService) {
    this.store.dispatch(fromActions.OrderFetchBegin());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((each: Subscription): void => each.unsubscribe());
    this.subscriptions = [];
  }

  ngOnInit(): void {
  }

  async viewOrder(order: any, event: any): Promise<void> {
    if (event) event.stopPropagation();
    await this.connectionService.navigateToURL(`/user/order/${order.objectId}`);
  }

  /**
   * When Edit order button is clicked in order section, We find the question with 'orderEdit' uniqueId and route to that question.
   */
  async editOrder(order: Record<string, any>, event: any): Promise<void> {
    event.stopPropagation();
    const queryParams = { orderId: order.objectId };
    await this.router.navigate(['/support', '1AwKF5sNGY'], { queryParams });
  }

  async reorder(order: any, event: any): Promise<void> {
    event.stopPropagation();
    const productsId: string[] = (order.productInfo as Array<any>)?.filter((each: any): boolean => each.SP > 0)
      .map((each: any): string => each.id);
    await this.connectionService.navigateToURL(`/user/order/reorder?products=${productsId.join(',')}`);
  }

  trackOrder(order: any, event: any): void {
    event.stopPropagation();
    if (!order.trackingURL) {
      this.broadcast.broadcast('NOTIFY', { message: 'Tracking link is not yet updated.' });
      return;
    }
    this.broadcast.broadcast('OPEN_IN_NEW_TAB', { url: order.trackingURL });
  }
}
