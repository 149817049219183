import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'prepaid-discount-sheet',
  templateUrl: './prepaid-discount-sheet.html',
  styleUrls: ['prepaid-discount-sheet.scss'],
  standalone: false,
})
export class PrepaidDiscountSheetComponent implements OnInit {
  /**
     * The constructor injects the data passed to the bottom sheet using Angular's dependency injection.
     * The 'new-cap' linting rule is disabled for this line because it doesn't apply in this case,
     * as the uppercase letter is part of the Angular-specific token MAT_BOTTOM_SHEET_DATA.
   */
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  public appConfig: AppConfig,
  private bottomSheetRef: MatBottomSheetRef) {}
  regimenClass: string = '';
  loading: boolean = false;
  showConfettiAnimation: boolean = true;
  ngOnInit(): void {
  }

  async handleClick(): Promise<void> {
  }

  handlePrepaidOptionClick(): void {
    if (this.data?.prepaidClick) {
      this.data.prepaidClick();
    }
  }

  handleCODOptionClick(): void {
    if (this.data?.codClick) {
      this.data.codClick();
    }
  }

  closeBottomSheet(): void {
    if (this.data?.onDismiss) {
      this.data.onDismiss();
    }
    this.bottomSheetRef.dismiss();
  }
}
