import { Component, ElementRef, ViewChild, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'c-audio',
  templateUrl: './c-audio.html',
  styleUrls: ['./c-audio.scss'],
  standalone: false,
})
export class CAudioComponent implements AfterViewInit {
  @Input('src') src: string;
  @Input('imageUrl') imageUrl: string;
  @Input('eventPrefix') eventPrefix: string = '';
  @Input('trackEvent') trackEvent: { event: string; data: Record<string, unknown>; };
  @Output('emitTrackEvent') emitTrackEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('slider') seekSlider: ElementRef;
  private static currentlyPlayingAudioComponent: CAudioComponent | null = null;
  audio: HTMLAudioElement;
  loading: boolean = false;
  currentPosition: string = '';
  isPlaying: boolean = false;
  raf: any;
  audioDuration: string = '0:00';

  constructor(private eventLoggerService: EventLoggerService) {}

  ngOnInit(): void {
    this.initialize();
  }

  whilePlaying = (): void => {
    this.seekSlider.nativeElement.value = this.audio.currentTime;
    this.updateCurrentPosition(this.seekSlider.nativeElement.value);
    this.raf = requestAnimationFrame(this.whilePlaying);
  };

  pauseAudio(): void {
    this.isPlaying = false;
    this.audio.pause();
    cancelAnimationFrame(this.raf);
  }

  playAudio(): void {
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'play-voice-note', eventPrefix: this.eventPrefix }));
    this.initialize();

    if (this.loading || !this.audio) {
      return;
    }

    // Pause and reset the UI of any currently playing audio
    if (CAudioComponent.currentlyPlayingAudioComponent && CAudioComponent.currentlyPlayingAudioComponent !== this) {
      CAudioComponent.currentlyPlayingAudioComponent.pauseAudio();
      CAudioComponent.currentlyPlayingAudioComponent.resetUI();
    }

    CAudioComponent.currentlyPlayingAudioComponent = this; // Set this audio as the currently playing one

    if (Math.floor(this.audio.currentTime) === Math.floor(this.audio.duration)) {
      this.audio.currentTime = 0;
    }

    this.audio.play();
    this.trackEventAction('play');
    this.isPlaying = true;
    requestAnimationFrame(this.whilePlaying);
  }

  private initialize(): void {
    if (this.audio || !this.src) {
      return;
    }
    this.audio = new Audio();
    this.audio.src = this.src;
    this.audio.loop = false;
    this.audio.autoplay = false;
    this.audio.load();
    this.loading = true;

    this.audio.addEventListener('canplay', (): void => {
      this.seekSlider.nativeElement.max = Math.floor(this.audio.duration);
      this.updateAudioDuration(Math.floor(this.audio.duration));
      this.seekSlider.nativeElement.value = 0;
      this.loading = false;
    }, false);

    this.audio.addEventListener('timeupdate', (): void => {
      if (!this.isPlaying) {
        return;
      }
      if (!this.raf) {
        this.seekSlider.nativeElement.value = this.audio.currentTime;
      }
      this.updateCurrentPosition(this.audio.currentTime);
    }, false);
  }

  private resetUI(): void {
    this.isPlaying = false;
    this.seekSlider.nativeElement.value = 0;
    this.updateCurrentPosition(0);
  }

  updateAudioDuration(duration: number): void {
    if (duration < 10) {
      this.audioDuration = `0:0${duration}`;
    } else if (duration > 9 && duration < 60) {
      this.audioDuration = `0:${duration}`;
    } else {
      const minutes = Math.floor(duration / 60);
      let seconds: any = duration % 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      this.audioDuration = `${minutes}:${seconds}`;
    }
  }

  calculateDisplayTime(sec: number): string {
    const minutes = Math.floor(sec / 60);
    const seconds = Math.floor(sec % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  ngAfterViewInit(): void {
    this.seekSlider.nativeElement.addEventListener('input', (): void => {
      this.updateCurrentPosition(this.seekSlider.nativeElement.value);
      if (!this.audio.paused) {
        cancelAnimationFrame(this.raf);
      }
    });

    this.seekSlider.nativeElement.addEventListener('change', (): void => {
      this.audio.currentTime = this.seekSlider.nativeElement.value;
      if (!this.audio.paused) {
        requestAnimationFrame(this.whilePlaying);
      }
    });
  }

  private stopAudio(): void {
    this.pauseAudio();
    this.seekSlider.nativeElement.value = 0;
    cancelAnimationFrame(this.raf);
    this.updateCurrentPosition(0);
    this.trackEventAction('completed');
  }

  private updateCurrentPosition(sec: number): void {
    if (sec && this.audio.currentTime === this.audio.duration) {
      this.stopAudio();
      return;
    }
    this.currentPosition = this.calculateDisplayTime(sec);
  }

  private trackEventAction(action: string): void {
    this.emitTrackEvent.emit(action);
    if (!this.trackEvent) {
      return;
    }
    this.eventLoggerService.trackEvent(`${this.trackEvent.event}${this.eventPrefix}Audio`, { ...this.trackEvent.data, action });
  }
}
