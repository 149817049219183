import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { EventLoggerService } from '@services/event-logger-service';
import { RegimenService } from '@services/regimen-service';
import { ApiClientConstant } from '@cureskin/api-client';
import { AppConfig } from 'client/app/app.config';
import { SwiperOptions } from 'swiper';

type VariantIdType = {
  [key: string]: string;
};

const VARIANT_ID = {
  variant_999: 'variant_999',
  variant_1499: 'variant_1499',
  variant_1999: 'variant_1999',
  variant_4999: 'variant_4999',
};

const REGIMEN_IMG_BACKGROUND_CLASS = {
  variant_999: 'tw-bg-teal-100',
  variant_1499: 'tw-bg-purple-100',
  variant_1999: 'tw-bg-orange-100',
  variant_4999: 'tw-bg-blue-100',
};

@Component({
  selector: 'regimen-proposition',
  templateUrl: './regimen-proposition.html',
  standalone: false,
})
export class RegimenPropositionComponent {
  @Input('regimenId') regimenId: string = '';
  @Input('regimenClass') regimenClass: string = '';
  @Input('variantId') variantId: string = '';
  @Input('totalSellingPrice') totalSellingPrice: number = 0;
  @Input('productsCount') productsCount: number = 0;
  @Input('doctorImageUrl') doctorImageUrl: string = '';
  variantIdConstants: VariantIdType = VARIANT_ID;
  backgroundColor: string = 'tw-bg-teal-100';
  showPopup: boolean = false;
  selectedCarouselItemIndex: number = 0;
  carouselConfig: SwiperOptions = {
    initialSlide: this.selectedCarouselItemIndex,
    slidesPerView: 1.2,
    spaceBetween: 20,
    autoHeight: false,
    speed: 600,
    pagination: false,
    centeredSlides: true,
    navigation: false,
    autoplay: false,
  };

  constructor(
    private eventLoggerService: EventLoggerService,
    private appConfig: AppConfig,
    private router: Router,
    private appWebBridge: AppWebBridgeService,
    private readonly regimenService: RegimenService,
  ) { }

  async ngOnChanges(): Promise<void> {
    this.updateBackgroundColor();
  }

  updateBackgroundColor(): void {
    this.backgroundColor = this.regimenService.getBackgroungColourBasedOnVariantId(this.variantId);
  }

  openPopup(i: number): void {
    this.selectedCarouselItemIndex = i;
    this.carouselConfig = {
      ...this.carouselConfig,
      initialSlide: this.selectedCarouselItemIndex,
    };
    this.showPopup = true;
  }

  closePopup(): void {
    this.showPopup = false;
  }

  async handleGetTreatmentKit(): Promise<void> {
    this.eventLoggerService.cleverTapEvent('buyNowRegimen', JSON.stringify({ regimenId: this.regimenId }));
    this.eventLoggerService.trackInParse('BUY_NOW', ApiClientConstant.Event.Type.ORDER);
    this.eventLoggerService.trackEvent('BUY_NOW_CLICKED');
    this.eventLoggerService.trackInFirebaseAndBranch('BUY_NOW_CLICKED');
    if (this.regimenClass === this.appConfig.Shared.Regimen.Class.HAIR) {
      this.eventLoggerService.trackEvent('BUY_NOW_CLICKED_HAIR');
      this.eventLoggerService.trackInFirebaseAndBranch('BUY_NOW_CLICKED_HAIR');
    }
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'instructions-buy-now-regimen' }));
    this.router.navigate(['/user/checkout'], { queryParams: { type: 'REGIMEN', regimenId: this.regimenId } });
    this.appWebBridge.logEventInBranchAndFirebaseFromiOS({
      branch: { name: 'BUY_NOW_CLICKED' },
      firebase: { name: 'BUY_NOW_CLICKED' },
    });
  }
}
