import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConnectionService } from '@services/connection-service';

@Component({
  selector: 'chat-v2',
  templateUrl: './chatV2.html',
  standalone: false,
})
export class ChatV2Component implements OnDestroy, OnInit {
  subscriptions: Array<Subscription> = [];
  type: 'consultationSession' | 'followUp' | 'supportTicket' | 'order';
  objectId: string;
  loading: boolean;
  user: any;
  consultationSession: any;
  followUp: any;
  order: any;
  supportTicket: any;
  queryUsername: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private connectionService: ConnectionService) {
    this.subscribeSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.map((each: Subscription): void => each.unsubscribe());
    this.subscriptions = [];
  }

  async ngOnInit(): Promise<void> {
  }

  private subscribeSubscriptions(): void {
    this.subscriptions.push(this.route.url.subscribe((urlSegments: Array<UrlSegment>): void => {
      if (urlSegments[0].path === 'ticket') {
        this.type = 'supportTicket';
        this.fetchObject();
      }
    }));
    this.subscriptions.push(this.route.params.subscribe((params: Params): void => {
      this.objectId = params.id;
      this.fetchObject();
    }));
    this.subscriptions.push(this.route.queryParams.subscribe((params: Params): void => {
      if (params.type) {
        this.type = params.type;
      }
      this.queryUsername = params.username;
      this.fetchObject();
    }));
  }

  private async fetchObject(): Promise<void> {
    if (!this.objectId || !this.type || this.loading) {
      return;
    }
    this.loading = true;
    this.user = await this.connectionService.getActingUser();
    switch (this.type) {
      case 'consultationSession': {
        [this.consultationSession] = await this.connectionService.fetchConsultationSessions({ where: { objectId: this.objectId } });
        break;
      }
      case 'followUp': {
        this.followUp = await this.connectionService.findFollowupById(this.objectId, {});
        if (!this.followUp) this.router.navigate(['/user']);
        break;
      }
      case 'order': {
        [this.order] = await this.connectionService.fetchOrders({ objectId: this.objectId });
        break;
      }
      case 'supportTicket': {
        this.supportTicket = await this.connectionService.findSupportTicketById(this.objectId, { translate: true });
        break;
      }
      default:
    }
    this.loading = false;
  }
}
