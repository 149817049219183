import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { WindowRefService } from '@services/window-ref-service';

@Component({
  selector: 'cs-button',
  templateUrl: './cs-button.html',
  styles: [':host{ display: block; }'],
  standalone: false,
})
export class ButtonComponent {
  loadingColor: string = '#fff';
  debounce: any;
  disabled: boolean;
  debouncePress: any;
  throttle: boolean;
  loading: boolean;
  @Input('clickId') clickId: string;
  @Input('class') class: any;
  @Input('loading')
  set setLoading(loading: boolean) {
    this.toggleLoading(loading);
  }
  @Input('disabled')
  set setDisable(status: boolean) {
    this.disabled = status;
    if (this.disabled) this.throttle = false;
  }
  @Input('params') params: any = {};
  @Output('callback') callback: EventEmitter<any> = new EventEmitter();
  @ViewChild('csbtn', { static: false }) csbtn: any;

  constructor(
  private window: WindowRefService,
  ) {}

  onClick(event: any): void {
    if (this.disabled || this.loading) return;
    clearInterval(this.debounce);
    clearInterval(this.debouncePress);
    this.debounce = setTimeout((): void => {
      const rippleEffect: any = Array.from(this.csbtn?.nativeElement.querySelectorAll('.ripple'));
      if (rippleEffect.length) rippleEffect.forEach((each: any): void => each.remove());
    }, 1000);
    const target = this.csbtn?.nativeElement.getBoundingClientRect();
    const left = `${event.x - target.x - (target.width / 2)}px`;
    const top = `${event.y - target.y - (target.width / 2)}px`;
    const span = this.window.nativeWindow.document.createElement('span');
    span.classList.add('ripple');
    if (this.class.includes('primary') && !this.class.includes('only-text')) span.classList.add('ripple-white');
    if (this.class.includes('secondary') || this.class.includes('only-text')) span.classList.add('ripple-black');
    span.style.left = left;
    span.style.top = top;
    span.style.height = `${target.width}px`;
    span.style.width = `${target.width}px`;
    this.csbtn?.nativeElement.appendChild(span);
    setTimeout((): void => this.performCallback(), 200);
  }

  performCallback(): void {
    if (this.throttle || this.disabled) return;
    if (this.callback) {
      this.throttle = true;
      this.callback.emit();
      setTimeout((): boolean => this.throttle = false, 2000);
    }
  }

  @HostListener('touchstart', ['$event'])
  handleTouchDown(event: any): void {
    if (this.disabled) return;
    clearInterval(this.debounce);
    clearInterval(this.debouncePress);
    this.debouncePress = setTimeout((): void => {
      const target = this.csbtn?.nativeElement.getBoundingClientRect();
      const left = `${event.touches[0].clientX - target.x - (target.width / 2)}px`;
      const top = `${event.touches[0].clientY - target.y - (target.width / 2)}px`;
      const span = this.window.nativeWindow.document.createElement('span');
      span.classList.add('ripple-press');
      if (this.class.includes('primary') && !this.class.includes('only-text')) span.classList.add('ripple-white');
      if (this.class.includes('secondary') || this.class.includes('only-text')) span.classList.add('ripple-black');
      span.style.left = left;
      span.style.top = top;
      span.style.height = `${target.width}px`;
      span.style.width = `${target.width}px`;
      this.csbtn?.nativeElement.appendChild(span);
    }, 300);
  }

  @HostListener('touchend', ['$event'])
  handleTouchEnd(event: any): void {
    clearInterval(this.debouncePress);
    const rippleEffect: any = Array.from(this.csbtn?.nativeElement?.querySelectorAll('.ripple-press'));
    if (rippleEffect.length) rippleEffect.forEach((each: any): void => each.remove());
  }

  toggleLoading(loading: boolean): void {
    if (this.class.includes('blue')) this.loadingColor = 'var(--primary)';
    else if (this.class.includes('secondary') && this.class.includes('filled')) this.loadingColor = '#000';
    else if (this.class.includes('secondary') && !this.class.includes('filled')) this.loadingColor = 'var(--text-secondary)';
    this.loading = loading;
  }
}
