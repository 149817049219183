import { Component, Input } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'take-photo-banner',
  templateUrl: './take-photo-banner.html',
  styles: ':host { @apply tw-flex tw-justify-between tw-p-4 tw-bg-teal-700 tw-rounded-4 tw-shadow-z4} ',
  standalone: false,
})

export class TakePhotoBannerComponent {
  @Input('userLanguage') userLanguage: any;

  constructor(public appConfig: AppConfig) {}
}
