<ng-container>
  <!-- <cross-regimen-banner *ngIf="regimen"></cross-regimen-banner> -->
  <div class="tw-w-full tw-h-full tw-font-body tw-bg-yellow-100 tw-pt-15" *ngIf="regimen">
  <img class="tw-mx-auto tw-w-20 tw-h-20" [src]="iconSrc" [class]="regimen.class"/>
  <div class="tw-p-6 tw-text-200" *ngIf="regimen.expired && regimen.class !== appConfig.Shared.Regimen.Class.HAIR" i18n="@@skinTreatmentExpired">
    Your treatment kit has expired. Your skin condition might have changed.
    Please click below to get a new treatment.
  </div>
  <div class="tw-p-6 tw-text-200" *ngIf="regimen.expired && regimen.class === appConfig.Shared.Regimen.Class.HAIR" i18n="@@hairTreatmentExpired">
    Your treatment kit has expired. Your hair condition might have changed.
    Please click below to get a new treatment kit.
  </div>
  <div class="tw-p-6 tw-text-200 tw-text-center" *ngIf="!regimen.expired && regimen.class !== appConfig.Shared.Regimen.Class.HAIR">
    <p class="tw-text-400 tw-font-body tw-font-bold" i18n="@@skinGetYourNewTreatment">
    Get started now with doctor given kit for your skin. It only takes 2
    minutes!
    </p>
  </div>
  <div class="tw-p-6 tw-text-200 tw-text-center" *ngIf="!regimen.expired && regimen.class === appConfig.Shared.Regimen.Class.HAIR">
    <p class="tw-text-300 tw-font-body tw-mb-4" i18n="@@hairGetYourNewTreatment">
    Get started now with doctor given kit for your hair. It only takes 2
    minutes!
    </p>
  </div>
  </div>
</ng-container>