import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { WindowRefService } from '@services/window-ref-service';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'app-article-view',
  templateUrl: './article-view.component.html',
  standalone: false,
})
export class ArticleViewComponent {
  private _article: any;
  isDiscontinued: boolean = false;
  buyNowExperiment: boolean = false;
  @Input() set article(article: any) {
    if (!article) return;
    // eslint-disable-next-line no-underscore-dangle
    this._article = article;
    this.loadData();
  }
  get article(): any {
    // eslint-disable-next-line no-underscore-dangle
    return this._article;
  }
  private _product: any;
  @Input() set product(product: any) {
    if (!product) return;
    // eslint-disable-next-line no-underscore-dangle
    this._product = product;
    if (this.product?.inventoryStatus === this.appConfig.Shared.Inventory.Type.DISCONTINUED) {
      this.isDiscontinued = true;
    }
    this.findDiscount();
  }
  get product(): any {
    // eslint-disable-next-line no-underscore-dangle
    return this._product;
  }

  @Input() experiments: any[];
  @Input() numberOfProductsInCart: number;
  private _productInCart: number;
  @Input() set productInCart(quantity: any) {
    // eslint-disable-next-line no-underscore-dangle
    this._productInCart = quantity;
  }
  // eslint-disable-next-line no-underscore-dangle
  get productInCart(): any { return this._productInCart; }
  private _user: any;
  @Input() set user(user: any) {
    if (!user) return;
    // eslint-disable-next-line no-underscore-dangle
    this._user = user;
    this.isPaidUser = user.get('orderState') === this.appConfig.Shared.User.OrderState.DELIVERED;
  }
  get user(): any {
    // eslint-disable-next-line no-underscore-dangle
    return this._user;
  }
  @Input() isAddRemovePending: boolean = false;

  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addToCart: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeFromCart: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('articleBlock', { static: false }) private articleBlock: ElementRef;

  discount: number;
  articleHeaderImage: string;
  cssLink: SafeResourceUrl;
  innerHTML: SafeResourceUrl;
  clickToAction: any;
  shareData: any;
  isPaidUser: boolean;
  showRefundPolicyOption: boolean = false;

  constructor(
    private dom: DomSanitizer,
    private windowRef: WindowRefService,
    private router: Router,
    private eventLogger: EventLoggerService,
    public appConfig: AppConfig,
    private conn: ConnectionService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.experiments = await this.conn.findUserActiveExperiments();
    this.experiments.forEach((each: any): void => {
      if (each.key === 'privacy_policy') {
        if (each.variant?.productIds.includes(this.product?.objectId)) {
          this.showRefundPolicyOption = true;
        }
      }
      if (each.key === 'buy_now_article_page') {
        this.buyNowExperiment = true;
      }
    });
  }

  loadData(): void {
    if (this.article.get('cssLink')) {
      this.cssLink = this.dom.bypassSecurityTrustResourceUrl(this.article.get('cssLink'));
    }
    if (this.article.get('scriptLink')) {
      const script = this.windowRef.nativeWindow.document.createElement('script');
      script.src = this.article.get('scriptLink');
      script.async = true;
      script.type = 'text/javascript';
      this.articleBlock.nativeElement.appendChild(script);
    }

    const articleID = this.article?.objectId || this.article?.id;
    const url = `https://cdn.cureskin.com/app/img/articleBanner/${articleID}.png`;
    const image = new Image();
    image.src = url;
    image.onload = (event: Event): void => {
      if (event) this.articleHeaderImage = url;
    };

    let html = this.article.get('html');
    html = html.toString().replace(/<a href/gi, '<router navigateTo');
    this.innerHTML = this.dom.bypassSecurityTrustHtml(html);
    this.clickToAction = this.article.get('clickToAction');
    this.shareData = this.article.get('shareData') || {};
    if (!articleID) {
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'no-article',
        productId: this.product?.objectId || this.product?.id }));
    } else {
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'article', articleId: articleID }));
    }
    setTimeout((): void => this.loadEvents());
  }

  findDiscount(): void {
    const totalMRP = this.product.mrp;
    const totalSP = this.product.price;
    if (totalMRP > totalSP) {
      this.discount = Math.floor(((totalMRP - totalSP) * 100) / totalMRP);
    } else this.discount = 0;
  }

  loadEvents(): any {
    const windowRef = this.windowRef.nativeWindow;
    const doc = windowRef.document;
    const lifestyle = doc.getElementById('lifestyle');
    const diet = doc.getElementById('diet') || (lifestyle && lifestyle.nextElementSibling);
    const name = doc.getElementById('name');
    const mobileNumber = doc.getElementById('mobileNumber');
    const question = doc.getElementById('question');
    const yes = doc.getElementById('yes');
    const no = doc.getElementById('no');

    windowRef.showDiet = (e: any): void => {
      let event = e;
      if (!event) {
        event = { target: doc.getElementById('b') };
      }
      if (diet.style.display === 'none') {
        diet.style.display = 'block';
        event.target.classList.add('selected');
        event.target.previousElementSibling.classList.remove('selected');
        lifestyle.style.display = 'none';
      }
    };

    windowRef.showLifestyle = (e: any): void => {
      let event = e;
      if (!event) {
        event = { target: doc.getElementById('a') };
      }
      if (lifestyle.style.display === 'none') {
        lifestyle.style.display = 'block';
        event.target.classList.add('selected');
        event.target.nextElementSibling.classList.remove('selected');
        diet.style.display = 'none';
      }
    };

    windowRef.yes = (): void => {
      question.style.display = 'none';
      yes.style.display = 'block';
    };

    windowRef.no = (): void => {
      question.style.display = 'none';
      no.style.display = 'block';
    };

    windowRef.changeText = (): void => {
      name.innerText = `Hello ${this.user.get('PatientName')}, spread the love.`;
      mobileNumber.innerText = `${this.user.get('MobileNumber')}`;
    };

    this.windowRef.nativeWindow.openUrl = this.open;
  }

  open(url: any, params?: any): void {
    this.router.navigate([url], params);
  }

  addProductToCart(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'article-add-to-cart',
      articleId: (this.article?.objectId || this.article?.id) }));
    this.addToCart.emit(this.product.objectId);
  }

  removeProductFromCart(): void {
    this.removeFromCart.emit(this.product.objectId);
  }

  handleBuyNow(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'new_buy_now_button_article_page' }));
    if (!this.productInCart?.quantity) {
      this.addProductToCart();
    }
    this.router.navigate(['cart']);
  }
}
