import { Component, Input } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'user-view-regimen-empty',
  templateUrl: './user-view-regimen-empty.html',
  standalone: false,
})
export class UserViewRegimenEmptyComponent {
  regimen: { [key: string]: unknown };
  @Input('regimen')
  set setRegimen(regimen: { [key: string]: unknown }) {
    this.regimen = regimen;
    this.setConcerns();
    this.setImageUrl();
  }
  @Input('user') user: any;
  loading: boolean;
  concerns: string[] = [];
  iconSrc: string;
  showCrossBanner: boolean = true;
  constructor(
    public appConfig: AppConfig,
    private conn: ConnectionService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.setImageUrl();
  }

  setImageUrl(): void {
    const regimenClass: string = this.regimen.class.toString();
    const isBodyRegimen = regimenClass === this.appConfig.Shared.Regimen.Class.BODY;
    this.user = this.conn.getActingUser();
    if (this.user?.get('Gender') === 'Female') {
      this.iconSrc = `https://cdn.cureskin.com/app/img/female-${regimenClass.toLowerCase()}.png`;
    } else {
      const lowerCaseRegimen = this.capitalizeWord(regimenClass.toLowerCase());
      this.iconSrc = `https://cdn.cureskin.com/app/img/Male%20${lowerCaseRegimen}_1.png`;
    }
    if (!isBodyRegimen) {
      if (this.user?.get('Gender') === 'Female') {
        this.iconSrc = `https://cdn.cureskin.com/app/img/female-${regimenClass.toLowerCase()}-new.png`;
      } else {
        this.iconSrc = `https://cdn.cureskin.com/app/img/male-${regimenClass.toLowerCase()}-new.png`;
      }
    }
  }

  capitalizeWord(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  setConcerns(): any {
    switch (this.regimen.class) {
      case this.appConfig.Shared.Regimen.Class.FACE: {
        this.concerns = [
          this.appConfig.Shared.String.DARKSPOTS,
          this.appConfig.Shared.String.ACNE_OR_PIMPLE,
          this.appConfig.Shared.String.DARK_CIRCLES,
          this.appConfig.Shared.String.DULL_SKIN,
          this.appConfig.Shared.String.MELASMA,
        ];
        break;
      }
      case this.appConfig.Shared.Regimen.Class.BODY: {
        this.concerns = [
          this.appConfig.Shared.String.STRETCH_MARKS,
          this.appConfig.Shared.String.RASH,
          this.appConfig.Shared.String.FUNGAL_INFECTION,
          this.appConfig.Shared.String.TINEA,
          this.appConfig.Shared.String.DRY_SKIN,
        ];
        break;
      }
      case this.appConfig.Shared.Regimen.Class.HAIR: {
        this.concerns = [
          this.appConfig.Shared.String.HAIRFALL,
          this.appConfig.Shared.String.DANDRUFF,
          this.appConfig.Shared.String.ITCHY_SCALP,
          this.appConfig.Shared.String.DRY_HAIR,
          this.appConfig.Shared.String.SCALP_PSORIASIS,
        ];
        break;
      }
      default:
        break;
    }
  }
}
