import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SingleSelectorService } from '@shared/single-selector/single-selector.service';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'notification-dialog',
  templateUrl: './notification.dialog.html',
  standalone: false,
})
export class NotificationDialogComponent implements OnInit {
  @Output('close') close: EventEmitter<any> = new EventEmitter<any>();
  @Input('notification') notification: any;
  isInternalUser: boolean;
  text: string;

  constructor(
    private singleSelectorService: SingleSelectorService,
    private eventLoggerService: EventLoggerService,
    private connectionService: ConnectionService,
    private broadcastService: BroadcastService) {
    this.isInternalUser = this.connectionService.isInternalUser();
  }

  ngOnInit(): void {
    this.text = this.isInternalUser ? (this.notification.messageInEnglish || this.notification.message) : this.notification.message;
  }

  closeDialog(): void {
    this.close.emit();
    this.broadcastService.broadcast('NAVIGATION_BACK');
  }

  navigateToURL(): void {
    if (this.notification.openURL.includes('&redirectTo=browser')) {
      const url = this.notification.openURL.substring(0, this.notification.openURL.indexOf('&redirectTo=browser'));
      this.broadcastService.broadcast('OPEN_IN_NEW_TAB', { url });
    }
    this.closeDialog();
    if (this.notification.track) {
      this.eventLoggerService.trackEvent(this.notification.track.event, this.notification.track.data);
    }
    setTimeout((): void => this.broadcastService.broadcast('NAVIGATE_TO_PATH', this.notification.openURL), 100);
  }

  onClickInputButton(input: any): void {
    this.closeDialog();
    setTimeout((): void => {
      this.singleSelectorService.openInAppAction(input);
    }, 100);
  }
}
