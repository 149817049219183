import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'user-assistant',
  template: '<p></p>',
  standalone: false,
})
export class UserAssistantComponent {
  constructor(private readonly router: Router) { }

  async ngOnInit(): Promise<any> {
    await this.router.navigate(['/home']);
  }
}
