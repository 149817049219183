import { Component, Inject, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'ccod-bottom-sheet',
  templateUrl: './ccod-bottom-sheet.html',
  styleUrls: ['./ccod-bottom-sheet.scss'],
  standalone: false,
})
export class CCODBottomSheetComponent {
  @Output('pay') pay: EventEmitter<any> = new EventEmitter<any>();
  @Input('data.orderData') orderData: any = {};
  isCCODChargeApplicable: boolean = false;
  codFee: number;
  orderTotal: number;
  discount: number = 0;
  codCharge: number;

  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  private eventLogger: EventLoggerService,
  private bottomSheetRef: MatBottomSheetRef) {}

  async ngOnInit(): Promise<void> {
    const transactionAmount = this.data.popupData?.get('TransactionAmount');
    this.codFee = transactionAmount ? transactionAmount / 100 : 0;
    this.orderTotal = this.data.popupData?.total;
    if (this.data?.isCCODChargeApplicable) {
      this.isCCODChargeApplicable = this.data.isCCODChargeApplicable;
      const chargesAndDiscounts = this.data.orderData?.get('chargesAndDiscounts');
      this.extractChargesAndDiscounts(chargesAndDiscounts);
    }
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'ccod-popup' }));
  }

  private extractChargesAndDiscounts(chargesAndDiscounts: any[]): void {
    if (chargesAndDiscounts) {
      chargesAndDiscounts.forEach((chargeOrDiscount: any): void => {
        if (chargeOrDiscount.service) {
          this.codCharge = chargeOrDiscount.amount;
        }
        if (chargeOrDiscount.coupon) {
          this.discount = chargeOrDiscount.amount;
        }
      });
    }
  }

  handleClick(): void {
    if (this.data?.callback) {
      this.data.callback();
      this.closeBottomSheet();
    }
  }

  closeBottomSheet(): void {
    this.bottomSheetRef.dismiss();
  }
}
