import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs/index';
import { DomSanitizer } from '@angular/platform-browser';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { fromSelectors } from '@store/selectors';
import { fromActions } from '@store/actions';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'support-ticket-list',
  templateUrl: './support-ticket-list.html',
  styleUrls: ['./support-ticket-list.scss'],
  standalone: false,
})
export class SupportTicketListComponent {
  user: any;
  subscription: Subscription[] = [];
  recentSupportTickets$: Observable<any[]> = this.store.select(fromSelectors.selectAllSupportTicket);
  inProcess$: Observable<boolean> = this.store.select(fromSelectors.selectRecentTicketFetchInProcess);

  constructor(private broadcast: BroadcastService,
    private conn: ConnectionService,
    private store: Store,
    public appConfig: AppConfig,
    public domSanitizer: DomSanitizer) {
    this.store.dispatch(fromActions.SupportRecentTicketFetchBegin({}));
  }

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
  }

  async viewQuery(query: { [key: string]: any }): Promise<void> {
    await this.conn.navigateToURL(`/support/ticket/${query.objectId}?username=${this.user.get('username')}`);
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  ngOnDestroy(): void {
    this.subscription.forEach((each: Subscription): void => each.unsubscribe());
  }
}
