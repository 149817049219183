import { Directive, ElementRef, EventEmitter, Inject, Input, Output } from '@angular/core';
import { EventLoggerService } from '@services/event-logger-service';
import { WindowRefService } from '@services/window-ref-service';

interface HairAIData {
  image: string;
  overlay: string;
  points: {
    pointA: Array<any>,
    pointB: Array<any>,
    pointC: { c1: Array<any>, c2: Array<any> },
    pointD: { d1: Array<any>, d2: Array<any> },
  };
}

@Directive({
  selector: '[appHairAiCanvas]',
  standalone: false,
})
export class HairAiCanvasDirective {
  @Input() set appHairAiCanvas(data: HairAIData) {
    if (!data) return;
    this.data = data;
    (<Window> this.windowRef.nativeWindow).performance.mark('instant-checkup-image-loading-start');
    this.image.src = data.image;
    if (!this.data.overlay) {
      this.isOverlayLoaded = true;
      return;
    }
    this.overlay.src = data.overlay;
  }

  @Output() hairAiLoaded: EventEmitter<boolean> = new EventEmitter();
  @Output() angleOfHairfall: EventEmitter<number> = new EventEmitter();
  @Output() topPositionForCaption: EventEmitter<number> = new EventEmitter();

  data: HairAIData;
  image: HTMLImageElement = new Image();
  isImageLoaded: boolean = false;
  overlay: HTMLImageElement = new Image();
  isOverlayLoaded: boolean = false;
  overlayBlinkCount: number = 0;

  constructor(
    private elementRef: ElementRef,
    private eventLogger: EventLoggerService,
    private windowRef: WindowRefService,
  ) {
    this.image.addEventListener('load', (): void => {
      this.isImageLoaded = true;
      this.draw();
    }, false);
    this.overlay.addEventListener('load', (): void => {
      this.isOverlayLoaded = true;
      this.draw();
    }, false);
  }

  getNativeElement(): HTMLCanvasElement {
    return this.elementRef.nativeElement as HTMLCanvasElement;
  }
  getContext(): CanvasRenderingContext2D {
    const elem: HTMLCanvasElement = this.elementRef.nativeElement;
    return elem.getContext('2d');
  }

  draw(): any {
    if (!this.isImageLoaded || !this.isOverlayLoaded) return;

    const context = this.getContext();
    context.globalCompositeOperation = 'destination-out';
    // const interval = setInterval((): void => {
    this.drawImages(context);
    // this.drawOverlay(context);
    // this.drawPoints_experiment_severity_by_angle(context);
    //   this.overlayBlinkCount += 1;
    //   if (this.overlayBlinkCount === 9) clearInterval(interval);
    // }, 600);
    (<Window> this.windowRef.nativeWindow).performance.mark('instant-checkup-image-loading-end');
    const measure: any = performance
      .measure('instant-checkup-duration', 'instant-checkup-image-loading-start', 'instant-checkup-image-loading-end');
    this.eventLogger.trackEvent('instant_checkup_hair_image_loading', {
      duration: measure.duration,
    });
    this.hairAiLoaded.emit(true);
  }

  drawImages(context: CanvasRenderingContext2D): void {
    this.getNativeElement().height = this.image.height;
    this.getNativeElement().width = this.image.width;
    context.drawImage(this.image, 0, 0);
  }

  drawOverlay(context: CanvasRenderingContext2D): void {
    if (!this.data.overlay || this.overlayBlinkCount % 2 === 0) return;
    if (this.image.height === this.overlay.height) {
      context.drawImage(this.overlay, 0, 0);
    } else {
      context.drawImage(this.overlay, 0, 0, this.image.width, this.image.height);
    }
  }

  drawPoints_experiment_severity_by_angle(context: CanvasRenderingContext2D): void {
    const { pointA, pointD, pointC }: any = this.data.points;
    if (!pointA || !pointC.c1 || !pointC?.c2) return;

    // if point C1 and C2 below the A then don't do any detection
    if (pointA[1] < pointC.c1[1] && pointA[1] < pointC.c2[1]) return;

    // Detected points are wrt to original image size but we use compressed image for frontend.
    // To realign the detected points we need to calculate the factor multiplier using original
    // size of image(using overlay image). We use uncompressed overaly.
    const pixelFactor = this.image.height / this.overlay.height;
    const fixedPixelSize = 1;
    const relativePixelSize = (fixedPixelSize / this.windowRef.nativeWindow.innerWidth) * this.image.width;
    const pixelSize = isNaN(relativePixelSize) ? fixedPixelSize : relativePixelSize;
    context.strokeStyle = '#ffffff';
    context.setLineDash([pixelSize * 4, pixelSize * 4]);
    context.lineWidth = pixelSize * 2;

    // calculation
    const slope = (pointD.d2[1] - pointD.d1[1]) / (pointD.d2[0] - pointD.d1[0]);

    const legLeftA = pointA[0] * slope;
    const legRightA = (this.elementRef.nativeElement.width - pointA[0]) * slope;
    let angleA1 = 0;
    let angleA2 = 0;

    if (pointA[1] > pointC.c1[1]) {
      const lengthAB1 = this.lengthBetweenPoints(pointA, [this.elementRef.nativeElement.width, (pointA[1] + legRightA)]);
      const lengthAC1 = this.lengthBetweenPoints(pointA, pointC.c1);
      const lengthB1C1 = this.lengthBetweenPoints([this.elementRef.nativeElement.width, (pointA[1] + legRightA)], pointC.c1);
      angleA1 = this.angleByTriangSides(lengthB1C1, lengthAB1, lengthAC1);
    }

    if (pointA[1] > pointC.c2[1]) {
      const lengthAB2 = this.lengthBetweenPoints(pointA, [0, (pointA[1] - legLeftA)]);
      const lengthAC2 = this.lengthBetweenPoints(pointA, pointC.c2);
      const lengthB2C2 = this.lengthBetweenPoints([0, (pointA[1] - legLeftA)], pointC.c2);
      angleA2 = this.angleByTriangSides(lengthB2C2, lengthAB2, lengthAC2);
    }

    this.topPositionForCaption.emit(pointD.d1[1] / pixelSize);
    this.angleOfHairfall.emit(angleA1 > angleA2 ? angleA1 : angleA2);
  }

  lengthBetweenPoints(pointA: number[], pointB: number[]): number {
    return Math.sqrt((pointA[0] - pointB[0]) * (pointA[0] - pointB[0]) + (pointA[1] - pointB[1]) * (pointA[1] - pointB[1]));
  }

  angleByTriangSides(a: number, b: number, c: number): number {
    return Math.acos((b * b + c * c - a * a) / (2 * b * c)) * (180 / Math.PI);
  }

  /**
   * Draw two parallel lines
   * one passing from both eyebrows and one parallel to the eyebrows line going through
   * lower point of hairline
   */
  drawPoints_ExperimentTwoLine(context: CanvasRenderingContext2D): void {
    const { pointA, pointD }: any = this.data.points;
    if (!pointA || !pointD || !pointD?.d2) return;
    // Detected points are wrt to original image size but we use compressed image for frontend.
    // To realign the detected points we need to calculate the factor multiplier using original
    // size of image(using overlay image). We use uncompressed overaly.
    const pixelFactor = this.image.height / this.overlay.height;
    const fixedPixelSize = 1;
    const relativePixelSize = (fixedPixelSize / this.windowRef.nativeWindow.innerWidth) * this.image.width;
    const pixelSize = isNaN(relativePixelSize) ? fixedPixelSize : relativePixelSize;
    context.strokeStyle = '#ffffff';
    context.setLineDash([pixelSize * 4, pixelSize * 4]);
    context.lineWidth = pixelSize * 2;

    // calculation
    const slope = (pointD.d2[1] - pointD.d1[1]) / (pointD.d2[0] - pointD.d1[0]);

    const legRightA = pointA[0] * slope;
    const legLeftA = (this.elementRef.nativeElement.width - pointA[0]) * slope;

    const legRightD = pointD.d1[0] * slope;
    const legLeftD = (this.elementRef.nativeElement.width - pointD.d1[0]) * slope;

    // line across D1
    context.beginPath();
    context.moveTo(0, (pointD.d1[1] - legRightD) * pixelFactor);
    context.lineTo(this.elementRef.nativeElement.width, (pointD.d1[1] + legLeftD) * pixelFactor);
    context.stroke();

    // horizontal line across A
    context.beginPath();
    context.moveTo(0, (pointA[1] - legRightA) * pixelFactor);
    context.lineTo(this.elementRef.nativeElement.width, (pointA[1] + legLeftA) * pixelFactor);
    context.stroke();
  }

  /**
   * Draw M shape by drawing all 5 points.
   * 2 on eyebrows 2 above eyebrwos on the hairline
   * and one in the middle
   */
  drawPoints(context: CanvasRenderingContext2D): void {
    const { pointA, pointB, pointC, pointD }: any = this.data.points;
    if (!pointA && !pointB) return;

    // Detected points are wrt to original image size but we use compressed image for frontend.
    // To realign the detected points we need to calculate the factor multiplier using original
    // size of image(using overlay image). We use uncompressed overaly.
    const pixelFactor = this.image.height / this.overlay.height;
    const fixedPixelSize = 1;
    const relativePixelSize = (fixedPixelSize / this.windowRef.nativeWindow.innerWidth) * this.image.width;
    const pixelSize = isNaN(relativePixelSize) ? fixedPixelSize : relativePixelSize;
    context.strokeStyle = '#0000ff';
    context.fillStyle = '#0000ff';
    context.setLineDash([pixelSize * 4, pixelSize * 4]);
    context.lineWidth = pixelSize * 2;

    // Draw circle A
    context.beginPath();
    context.arc(pointA[0] * pixelFactor, pointA[1] * pixelFactor, pixelSize * 3, 0, 2 * Math.PI);
    context.fill();
    context.beginPath();
    context.arc(pointC.c1[0] * pixelFactor, pointC.c1[1] * pixelFactor, pixelSize * 3, 0, 2 * Math.PI);
    context.fill();
    context.beginPath();
    context.arc(pointC.c2[0] * pixelFactor, pointC.c2[1] * pixelFactor, pixelSize * 3, 0, 2 * Math.PI);
    context.fill();

    // line A to B
    context.beginPath();
    context.moveTo(pointB[0] * pixelFactor, pointB[1] * pixelFactor);
    context.lineTo(pointA[0] * pixelFactor, pointA[1] * pixelFactor);
    context.stroke();

    // line D1 - C1 - A - C2 - D2
    context.beginPath();
    context.moveTo(pointD.d1[0] * pixelFactor, pointD.d2[1] * pixelFactor);
    context.lineTo(pointC.c1[0] * pixelFactor, pointC.c1[1] * pixelFactor);
    context.lineTo(pointA[0] * pixelFactor, pointA[1] * pixelFactor);
    context.lineTo(pointC.c2[0] * pixelFactor, pointC.c2[1] * pixelFactor);
    context.lineTo(pointD.d2[0] * pixelFactor, pointD.d2[1] * pixelFactor);
    context.stroke();

    // horizontal line across A
    context.beginPath();
    context.moveTo(0, pointA[1] * pixelFactor);
    context.lineTo(this.elementRef.nativeElement.width, pointA[1] * pixelFactor);
    context.stroke();
  }
}
