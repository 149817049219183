import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ConnectionService } from '@services/connection-service';
import { WindowRefService } from '@services/window-ref-service';
import { Languages } from '@cureskin/api-client/src/server-api';
import { AppConfig } from 'client/app/app.config';
import { firstValueFrom, from, Observable, of } from 'rxjs';
import { TranslateLastBotQuestionData } from '../../../app-constant-types';

type LanguageDetails = { code: string, displayName: string };

@Component({
  selector: 'language-change',
  templateUrl: './language-change.html',
  styleUrls: [],
  standalone: false,
})

export class LanguageChangeComponent implements OnInit {
  saveInProcess: boolean = false;
  languages: LanguageDetails[] = [];
  userCurrentLanguageCode: string = this.appConfig.Shared.Languages.EN;
  selectedLanguage: LanguageDetails = {
    code: this.appConfig.Shared.Languages.EN,
    displayName: this.appConfig.Shared.LanguagesLong.EN,
  };
  selectedLanguageUrl: string;

  /*
    * The constructor injects the data passed to the bottom sheet using Angular's dependency injection.
    * The 'new-cap' linting rule is disabled for this line because it doesn't apply in this case,
    * as the uppercase letter is part of the Angular-specific token MAT_BOTTOM_SHEET_DATA.
  */
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data:
    { translateLastBotQuestionData: Partial<TranslateLastBotQuestionData> },
    public appConfig: AppConfig,
    private readonly conn: ConnectionService,
    private readonly windowRef: WindowRefService,
    private readonly bottomSheetRef: MatBottomSheetRef<LanguageChangeComponent>,
  ) { }

  ngOnInit(): void {
    this.setLanguageDetails();
  }

  setLanguageDetails(): void {
    this.languages = [
      { code: this.appConfig.Shared.Languages.HI, displayName: this.appConfig.Shared.LanguagesLong.HI },
      { code: this.appConfig.Shared.Languages.EN, displayName: this.appConfig.Shared.LanguagesLong.EN },
      { code: this.appConfig.Shared.Languages.KN, displayName: this.appConfig.Shared.LanguagesLong.KN },
      { code: this.appConfig.Shared.Languages.TE, displayName: this.appConfig.Shared.LanguagesLong.TE },
      { code: this.appConfig.Shared.Languages.TA, displayName: this.appConfig.Shared.LanguagesLong.TA },
      { code: this.appConfig.Shared.Languages.MR, displayName: this.appConfig.Shared.LanguagesLong.MR },
    ];

    const user = this.conn.getActingUser();
    const userSelectedLangCode = user.get('languagePreference');
    this.userCurrentLanguageCode = userSelectedLangCode || this.appConfig.Shared.Languages.EN;
    this.selectedLanguage = {
      code: userSelectedLangCode || this.appConfig.Shared.Languages.EN,
      displayName: this.appConfig.Shared.LanguagesLong[userSelectedLangCode?.toUpperCase()
          || this.appConfig.Shared.Languages.EN.toUpperCase()],
    };
  }

  handleLanguageChange(language: LanguageDetails): void {
    if (this.saveInProcess) {
      return;
    }
    this.selectedLanguage = language;
  }

  closeBottomSheet(): void {
    if (this.saveInProcess) {
      return;
    }
    this.bottomSheetRef.dismiss();
  }

  updateSaveInProgress(value: boolean): void {
    this.saveInProcess = value;
    this.bottomSheetRef.disableClose = value;
  }

  async handleConfirmClick(): Promise<void> {
    try {
      if (this.selectedLanguage.code === this.userCurrentLanguageCode) {
        this.closeBottomSheet();
        return;
      }

      this.updateSaveInProgress(true);

      this.updateUserSelectedLanguage();

      const translateLastQuestionObservable = this.createTranslateLastQuestionObservable();
      await firstValueFrom(translateLastQuestionObservable);

      this.updateSaveProgressAndCloseBottomSheet();
      this.navigateToLanguageSpecificUrl();
    } catch (err) {
      this.updateSaveProgressAndCloseBottomSheet();
    }
  }

  createTranslateLastQuestionObservable(): Observable<any> {
    if (this.data.translateLastBotQuestionData) {
      return from(
        this.conn.translateBotQuestion({
          toLanguage: this.selectedLanguage.code as Languages,
          regimenClass: this.data.translateLastBotQuestionData?.regimenClass,
          questionId: this.data.translateLastBotQuestionData?.questionId,
        }).then((response: any): any => response),
      );
    }
    return of(null);
  }

  updateSaveProgressAndCloseBottomSheet(): void {
    this.updateSaveInProgress(false);
    this.closeBottomSheet();
  }

  navigateToLanguageSpecificUrl(): void {
    this.selectedLanguageUrl = this.constructUrlForSelectedLanguage();
    this.windowRef.nativeWindow.location.href = this.selectedLanguageUrl;
  }

  constructUrlForSelectedLanguage(): string {
    const domain = this.conn.getBaseUrl();
    let path = this.windowRef.nativeWindow.location.pathname;
    const queryParams = this.windowRef.nativeWindow.location.search;

    const currentLanguageCode = this.userCurrentLanguageCode;
    const newLanguageCode = this.selectedLanguage.code;
    const languagePathSegment = `/${currentLanguageCode}`;

    if (currentLanguageCode === this.appConfig.Shared.Languages.EN || !path.startsWith(languagePathSegment)) {
      path = `/${newLanguageCode}${path}`;
    } else {
      const replacement = newLanguageCode === this.appConfig.Shared.Languages.EN ? ''
        : `/${newLanguageCode}`;
      path = path.replace(languagePathSegment, replacement);
    }

    const finalUrl = `${domain}${path}${queryParams}`;
    return finalUrl;
  }

  async updateUserSelectedLanguage(): Promise<void> {
    const user = this.conn.getActingUser();
    if (user) {
      await this.conn.updateUserData({ languagePreference: this.selectedLanguage.code });
    }
  }
}
