import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'doctor-reply',
  templateUrl: './doctor-reply.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class DoctorReplyComponent implements OnInit {
  @Input('isRebrandingBanner') isRebrandingBanner: boolean;
  @Input('userDoctor') userDoctor: any;
  @Output('viewTicket') viewTicket: EventEmitter<any> = new EventEmitter();
  constructor(
    public appConfig: AppConfig,
  ) { }

  ngOnInit(): void {
  }

  handleClick(): void {
    this.viewTicket.emit();
  }
}
