import { Component, AfterContentChecked, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'multiline-ellipsis',
  templateUrl: './multiline-ellipsis.html',
  styleUrls: ['./multiline-ellipsis.scss'],
  standalone: false,
})
export class MultilineEllipsisComponent implements AfterContentChecked {
  @Input('text') multilineText: string;
  @Input('class') class: string = '';
  @Input('readMoreClass') readMoreClass: string = '';
  @Input('maxLine') maxLine: number = 3;
  @Input('lineHeight') lineHeight: number = 18; // 1.5 line height is 20px;
  @ViewChild('text', { static: true }) textElement: ElementRef;
  @Output('readMore') readMore: EventEmitter<void> = new EventEmitter<void>();
  truncateText: boolean = false;

  ngAfterContentChecked(): void {
    if (this.truncateText) {
      return;
    }
    // if the text takes more than two lines, truncate the text
    this.truncateText = this.textElement.nativeElement.offsetHeight / this.lineHeight > this.maxLine;
  }
}
