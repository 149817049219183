import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ButtonModule } from '@components/button';

@Component({
  selector: 'confirm-cod-order-bottom-sheet',
  templateUrl: './confirm-cod-order.component.html',
  imports: [ButtonModule, CommonModule],
})
export class ConfirmCodOrderComponent {
  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<ConfirmCodOrderComponent>,
    // eslint-disable-next-line new-cap
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { orderSp: number, payOnline: () => void, confirmCodOrder: () => void },
  ) {}

  confirmOrder(): void {
    this.data.confirmCodOrder();
    this.bottomSheetRef.dismiss();
  }

  closeBottomSheet(): void {
    this.bottomSheetRef.dismiss();
  }

  payOnline(): void {
    this.data.payOnline();
    this.bottomSheetRef.dismiss();
  }
}
