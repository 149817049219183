import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { Experiment } from '@cureskin/api-client/src/server-api';
import { TimeService } from '@services/time-service';
import { DeliveryDateService } from '@services/delivery-date-service/delivery-date.service';
import { AppConfig } from '../../app.config';

enum OrderTypeEnum {
  'completed',
  'active',
  'cancelled',
}

@Component({
  selector: 'user-order-list',
  templateUrl: './user-order-list.html',
  standalone: false,
})
export class UserOrderListComponent {
  orderType: typeof OrderTypeEnum = OrderTypeEnum;
  activeOrderType: OrderTypeEnum = OrderTypeEnum.active;
  user: any;
  orders: any[];
  isLoading: boolean = true;
  products: any = {};

  orderPaymentPendingStages: any[] = [...this.appConfig.Shared.Order.Stage.orderPaymentPendingStages];
  orderPlacedStages: any[] = [...this.appConfig.Shared.Order.Stage.orderPlacedStages];
  orderPackedStages: any[] = [...this.appConfig.Shared.Order.Stage.orderPackedStages];
  orderDispatchedStages: any[] = [...this.appConfig.Shared.Order.Stage.orderDispatchedStages];
  orderDeliveryFailedStages: any[] = [...this.appConfig.Shared.Order.Stage.orderDeliveryFailedStages];
  orderOutForDeliveryStages: any[] = [...this.appConfig.Shared.Order.Stage.orderOutForDeliveryStages];
  orderDeliveredStages: any[] = [...this.appConfig.Shared.Order.Stage.orderDeliveredStages];
  orderCanceledStages: any[] = [...this.appConfig.Shared.Order.Stage.orderCanceledStages];

  expectedDeliveryDateStages: any[] = [
    ...this.orderPlacedStages,
    ...this.orderPackedStages,
    ...this.orderDispatchedStages,
    ...this.orderDeliveryFailedStages,
    ...this.orderOutForDeliveryStages,
  ];

  orderPayments: Table.Payment[] = [];
  totalTransactionAmount: number = 0;
  experiments: Experiment[] = [];
  orderPageRegimenProposition: boolean = false;
  freeProducts: Array<any>;
  expectedDeliveryDateExperiment: boolean = false;
  ordersMap: any = new Map<string, any>();
  deliveryIntervalInDays: number = 2;
  refundRelatedStageOrdersMap: any = new Map<string, any>();

  constructor(
    private eventLogger: EventLoggerService,
    private broadcast: BroadcastService,
    private conn: ConnectionService,
    private route: ActivatedRoute,
    public appConfig: AppConfig,
    private router: Router,
    private readonly timeService: TimeService,
    private readonly deliveryDateService: DeliveryDateService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.user = await this.conn.getActingUser();
    this.activeOrderType = +(this.route.snapshot.queryParams?.tab || OrderTypeEnum.active);
    this.experiments = await this.conn.findUserActiveExperiments();
    this.experiments.forEach((exp: Experiment): any => {
      if (exp.key === 'order_page_regimen_proposition') {
        this.orderPageRegimenProposition = true;
      }
      if (exp.key === 'expected_delivery_date_frontend') {
        this.expectedDeliveryDateExperiment = true;
      }
    });
    this.orderCanceledStages = this.orderCanceledStages.filter(
      (stage: string): boolean => !this.appConfig.Shared.Order.Stage.orderRefundStages.includes(stage));
    await this.loadOrders();
  }

  changeOrderType(orderType: OrderTypeEnum): void {
    this.activeOrderType = orderType;
    this.router.navigate([], { queryParams: { tab: orderType }, replaceUrl: true, relativeTo: this.route });
    this.loadOrders();
  }

  private async loadOrders(): Promise<void> {
    this.isLoading = true;
    const where: any = { user: this.user };
    const select: any = ['orderNumber', 'type', 'amount', 'stage', 'regimen', 'regimenStateAtLastCheckpoint',
      'productInfo', 'paymentType', 'deliverOnDate', 'deliveredOn', 'canceledByUser', 'serviceInfo'];
    const inactiveOrderStage: any[] = [
      this.appConfig.Shared.Order.Stage.CANCELED,
      this.appConfig.Shared.Order.Stage.RETURNED,
      this.appConfig.Shared.Order.Stage.REFUND_PROCESSED,
      this.appConfig.Shared.Order.Stage.LOST_IN_TRANSIT,
      this.appConfig.Shared.Order.Stage.NONSERVICEABLEAREA,
      this.appConfig.Shared.Order.Stage.COURIER_NOT_AVAILABLE,
      this.appConfig.Shared.Order.Stage.RETURN_INITIATED,
      this.appConfig.Shared.Order.Stage.RETURN_REQUESTED,
      this.appConfig.Shared.Order.Stage.REFUND_REQUESTED,
      this.appConfig.Shared.Order.Stage.REFUND_INITIATED,
      this.appConfig.Shared.Order.Stage.BOT_CANCELED,
      this.appConfig.Shared.Order.Stage.COMPLETED,
      this.appConfig.Shared.Order.Stage.DELIVERED,
    ];
    const completedStages: any[] = [
      this.appConfig.Shared.Order.Stage.COMPLETED,
      this.appConfig.Shared.Order.Stage.DELIVERED,
    ];
    const cancelledStages: any[] = [
      this.appConfig.Shared.Order.Stage.CANCELED,
      this.appConfig.Shared.Order.Stage.RETURNED,
      this.appConfig.Shared.Order.Stage.REFUND_PROCESSED,
      this.appConfig.Shared.Order.Stage.LOST_IN_TRANSIT,
      this.appConfig.Shared.Order.Stage.NONSERVICEABLEAREA,
      this.appConfig.Shared.Order.Stage.COURIER_NOT_AVAILABLE,
      this.appConfig.Shared.Order.Stage.RETURN_INITIATED,
      this.appConfig.Shared.Order.Stage.RETURN_REQUESTED,
      this.appConfig.Shared.Order.Stage.REFUND_REQUESTED,
      this.appConfig.Shared.Order.Stage.REFUND_INITIATED,
      this.appConfig.Shared.Order.Stage.BOT_CANCELED,
    ];
    switch (this.activeOrderType) {
      case OrderTypeEnum.active: {
        where.stage = { $nin: inactiveOrderStage };
        break;
      }
      case OrderTypeEnum.completed: {
        where.stage = completedStages;
        break;
      }
      case OrderTypeEnum.cancelled: {
        where.stage = cancelledStages;
        break;
      }
      default: where.stage = { $nin: completedStages };
    }
    try {
      const orders = await this.conn.fetchOrders(where, select);
      this.ordersMap.clear();
      this.refundRelatedStageOrdersMap.clear();
      this.checkForRefundStatus(orders);
      // Fetch all products in all orders for once
      let productIds = orders.map((order: any): any => order.get('productInfo').map((product: any): any => product.id)).flat();
      productIds = Array.from(new Set(productIds));
      const products = await this.conn.findProducts({ objectId: { $in: productIds } });
      this.products = products.reduce((object: any, product: any): any => {
        // eslint-disable-next-line no-param-reassign
        object[product.id] = product;
        return object;
      }, {});

      await Promise.all(
        orders.map(async (order: any): Promise<void> => {
          this.orderPayments = await this.conn.findSuccessfulNonCODOrderPayments(order);
          await this.updateOrderAmountForCOD(order);
          order.get('productInfo').forEach((product: any): any => {
            // eslint-disable-next-line no-param-reassign
            product.image = this.getProductImage(product.id) || product.image;
          });
          this.setExpectedDeliveryDate(order);
        }),
      );
      this.orders = orders?.filter((order: any): any => (order.get('stage') !== this.appConfig.Shared.Order.Stage.INITIAL
        && order.get('type') !== 'PRODUCT') || order.get('stage') !== this.appConfig.Shared.Order.Stage.ONLINE_PAYMENT_PENDING);

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.eventLogger.logError(error);
      this.broadcast.broadcast('NOTIFY', { message: error.toString() });
    }
  }

  async updateOrderAmountForCOD(order: any): Promise<void> {
    this.totalTransactionAmount = this.orderPayments?.reduce((total: number, payment: Table.Payment): number => {
      const transactionAmount = Number(payment?.get('TransactionAmount') || 0) / 100;
      return total + transactionAmount;
    }, 0);
    if (order?.get('paymentType') === this.appConfig.Shared.Order.PaymentType.COD) {
      order?.set('amount', order.get('amount') - this.totalTransactionAmount);
    }
  }

  getProductImage(id: any): string {
    const productObj = this.products[id];
    if (!productObj) return '';

    const product = JSON.parse(JSON.stringify(productObj));
    let image = '';
    if (product?.rebrandedImageWithBackground?.length) {
      image = product.rebrandedImageWithBackground[product.rebrandedImageWithBackground.length - 1];
    } else if (product?.image) {
      // eslint-disable-next-line prefer-destructuring
      image = product.image;
    } else if (product?.rebrandedImageWithoutBackground?.length) {
      image = product.rebrandedImageWithoutBackground[product.rebrandedImageWithoutBackground.length - 1];
    }
    return image;
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  getFreeProductDetails(order: any): number {
    this.freeProducts = order?.get('productInfo')?.filter((product: any): any => product.SP === 0);
    return this.freeProducts.length;
  }

  async setExpectedDeliveryDate(order: any): Promise<void> {
    const currentStage = order.get('stage');
    const isEligibleStage: boolean = [
      ...this.orderPlacedStages,
      ...this.orderPackedStages,
      ...this.orderDispatchedStages,
    ].includes(currentStage);
    if (!isEligibleStage) return;
    if (this.expectedDeliveryDateExperiment) {
      try {
        const res = await this.conn.getExpectedDeliveryDateByOrderId(order.id);
        const responseDateISO = res?.expectedDeliveryDate;
        const expectedDeliveryDate = this.deliveryDateService.calculateExpectedDeliveryDate(
          responseDateISO,
          this.deliveryIntervalInDays,
        );
        this.ordersMap.set(order.id, { ...order, expectedDeliveryDate });
      } catch (error) {
        const expectedDeliveryDate = this.deliveryDateService.calculateExpectedDeliveryDate(
          new Date().toISOString(),
          this.deliveryIntervalInDays,
        );
        this.ordersMap.set(order.id, { ...order, expectedDeliveryDate });
      }
    } else {
      const expectedDeliveryDate = {
        from: this.timeService.addDays(new Date(order.get('createdAt')), 2),
        to: this.timeService.addDays(new Date(order.get('createdAt')), 2 + this.deliveryIntervalInDays),
      };
      this.ordersMap.set(order.id, expectedDeliveryDate);
    }
  }

  isCurrentOrderInRefundStages(orderId: string): boolean {
    return this.appConfig.Shared.Order.Stage.orderRefundStages.includes(this.refundRelatedStageOrdersMap.get(orderId)?.stage);
  }

  async checkForRefundStatus(orders: any): Promise<void> {
    await Promise.all(
      orders.map(async (order: any): Promise<void> => {
        if (order.get('paymentType') !== this.appConfig.Shared.Order.PaymentType.COD) {
          const latestOrderLog = await this.conn.fetchLatestOrderConsultationLog(order.id);
          if (latestOrderLog.stage) {
            this.refundRelatedStageOrdersMap.set(order.id, latestOrderLog);
          }
        }
      }),
    );
  }
}
