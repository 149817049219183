import { Component, Input, OnInit } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { TimeService } from '@services/time-service';
import { Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'incomplete-information-banner',
  templateUrl: './incomplete-information.html',
  styleUrls: ['./incomplete-information.scss'],
  standalone: false,
})
export class IncompleteInformationComponent implements OnInit {
  @Input('consultationSession') consultationSession: any;
  user: any;
  imageUrl: string;

  constructor(
    private broadcaster: BroadcastService,
    public appConfig: AppConfig,
    private connectionService: ConnectionService,
    private timeService: TimeService,
    private eventLogger: EventLoggerService,
    private router: Router) {}

  async ngOnInit(): Promise<void> {
    this.user = this.connectionService.getActingUser();
    if (this.user.get('languagePreference') === this.appConfig.Shared.Languages.HI) {
      this.imageUrl = 'https://cdn.cureskin.com/img/incomplete_chat_hindi.png';
    } else {
      this.imageUrl = 'https://cdn.cureskin.com/img/incomplete_chat_english.png';
    }
  }

  async openConsultation(): Promise<void> {
    this.eventLogger.trackEvent('INCOMPLETE_CONSULTATION_BANNER_CLICKED', { id: this.consultationSession.id });
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'incomplete-chat-banner' }));
    await this.connectionService.switchUserToState(
      `v4_${this.consultationSession.get('PrivateMainConcernClass').toLowerCase()}:`,
      { consultationSessionId: this.consultationSession.id });
    await this.router.navigate([`/chatV2/${this.consultationSession.id}`], {
      queryParams: { type: 'consultationSession', back: 'home' },
    });
  }
}
