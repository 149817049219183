import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'app-hair-ai-report',
  templateUrl: './hair-ai-report.component.html',
  standalone: false,
})
export class HairAiReportComponent implements OnChanges {
  @Input() set instantCheckup(instantCheckup: any) {
    this.data.image = instantCheckup?.compressedImagePath || instantCheckup?.imagePath;
    this.data.overlay = instantCheckup?.aiResponse?.hairMask?.object_url;

    if (instantCheckup?.aiResponse) {
      this.data.points.pointA = instantCheckup.aiResponse?.midHairlinePoint;
      this.data.points.pointB = instantCheckup.aiResponse?.midEyebrow;
      this.data.points.pointC.c1 = instantCheckup.aiResponse?.leftHairlineAboveEyebrow;
      this.data.points.pointC.c2 = instantCheckup.aiResponse?.rightHairlineAboveEyebrow;
      this.data.points.pointD.d1 = instantCheckup.aiResponse?.midOfLeftEyebrow;
      this.data.points.pointD.d2 = instantCheckup.aiResponse?.midOfRightEyebrow;
      this.data.result = instantCheckup.aiResponse?.result;
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'hair-ai-analysis' }));
    }
  }

  @Output() hairAiLoaded: EventEmitter<boolean> = new EventEmitter();

  captionTopPosition: string;
  data: any = {
    image: null,
    overlay: null,
    points: {
      pointA: null, pointB: null, pointC: { c1: null, c2: null }, pointD: { d1: null, d2: null },
    },
    result: null,
  };

  severityLevel: string = '';
  constructor(
    private readonly eventLogger: EventLoggerService,
    private readonly changeDetection: ChangeDetectorRef,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.instantCheckup?.currentValue) {
      this.updateData(changes.instantCheckup.currentValue);
      this.changeDetection.detectChanges();
    }
  }

  updateData(instantCheckup: any): void {
    if (!instantCheckup?.aiResponse) {
      this.data = {
        ...this.data,
        image: instantCheckup?.compressedImagePath || instantCheckup?.imagePath,
        overlay: null,
        points: {
          pointA: null,
          pointB: null,
          pointC: { c1: null, c2: null },
          pointD: { d1: null, d2: null },
        },
        result: null,
      };
      return;
    }
    this.data = {
      ...this.data,
      image: instantCheckup?.compressedImagePath || instantCheckup?.imagePath,
      overlay: instantCheckup.aiResponse.hairMask?.object_url,
      points: {
        pointA: instantCheckup.aiResponse.midHairlinePoint,
        pointB: instantCheckup.aiResponse.midEyebrow,
        pointC: {
          c1: instantCheckup.aiResponse.leftHairlineAboveEyebrow,
          c2: instantCheckup.aiResponse.rightHairlineAboveEyebrow,
        },
        pointD: {
          d1: instantCheckup.aiResponse.midOfLeftEyebrow,
          d2: instantCheckup.aiResponse.midOfRightEyebrow,
        },
      },
      result: instantCheckup.aiResponse.result,
    };
  }

  angleOfHairfall(angle: number): void {
    if (angle > 40) {
      this.severityLevel = 'Severe hair loss';
    } if (angle <= 40 && angle >= 15) {
      this.severityLevel = 'Moderate hairloss';
    } else {
      this.severityLevel = 'Mild hairloss';
    }
  }

  topPositionForCaption(top: number): void {
    this.captionTopPosition = top ? `${top + 40}px` : '40px';
  }

  processAiResult(): void {
    if (!this.data.result) return;
    this.severityLevel = this.data.result[0]?.ProblemName;
  }
}
