import { Component, Input, OnInit } from '@angular/core';
import { ConnectionService } from '@services/connection-service';

@Component({
  selector: 'doctor-profile',
  templateUrl: './doctor-profile.html',
  styleUrls: ['./doctor-profile.scss'],
  standalone: false,
})
export class DoctorProfileComponent implements OnInit {
  @Input('doctor') doctor: any;

  constructor(public conn: ConnectionService) {}

  ngOnInit(): void {
    this.updateDoctor();
  }

  async updateDoctor(): Promise<void> {
    if (this.doctor) {
      return;
    }
    const user = this.conn.getActingUser();
    if (!user.has('allocatedDoctor')) {
      return;
    }
    if (!user.get('allocatedDoctor').has('username')) {
      await user.get('allocatedDoctor').fetch();
    }
    this.doctor = user.get('allocatedDoctor');
  }
}
