import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'delete-photo-sheet',
  templateUrl: './delete-photo-sheet.html',
  styleUrls: [],
  standalone: false,
})
export class DeletePhotoSheetComponent implements OnInit {
  user: any;
  /**
     * The constructor injects the data passed to the bottom sheet using Angular's dependency injection.
     * The 'new-cap' linting rule is disabled for this line because it doesn't apply in this case,
     * as the uppercase letter is part of the Angular-specific token MAT_BOTTOM_SHEET_DATA.
   */
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  public appConfig: AppConfig,
  private bottomSheetRef: MatBottomSheetRef,
  private eventLogger: EventLoggerService,
  private conn: ConnectionService,
  ) {}
  content: string = '';
  ngOnInit(): void {
    this.eventLogger.cleverTapEvent('open', JSON.stringify({ name: 'delete-checkup-photo-pupup' }));
    this.user = this.conn.getActingUser();
    this.content = this.data.content;
  }

  async takeNewPhoto(): Promise<void> {
    if (this.data?.clickNewPhotoCallback) this.data.clickNewPhotoCallback();
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'delete-checkup-photo-pupup-take-new-photo' }));
    this.bottomSheetRef.dismiss();
  }

  closedPopup(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'delete-checkup-photo-pupup-close' }));
    this.bottomSheetRef.dismiss();
  }

  deleteCurrentPhoto(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'delete-checkup-photo-pupup-delete-photo' }));
    if (this.data?.deleteCallback) this.data.deleteCallback();
    this.bottomSheetRef.dismiss();
  }
}
