/* eslint-disable complexity */
import { Component, ElementRef, Input, NgZone, OnDestroy, ViewChild, ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import EXIF from 'exif-js';
import { Subscription } from 'rxjs/index';
import { ConnectionService } from '@services/connection-service';
import { WindowRefService } from '@services/window-ref-service';
import { BroadcastService } from '@services/broadcast-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { EventLoggerService } from '@services/event-logger-service';
import { ToolbarType } from '@components/toolbar/toolbar.type';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { LocalStorageService } from '@services/local-storage-service';
import { DataStoreService } from '@services/data-store-service';
import { CurrentComponentService } from '@services/current-component';
import Swiper, { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { AppConfig } from 'client/app/app.config';
import { problemsCopy } from '../../problem-details';

interface ConcernSeverity {
  'Mild': string;
  'Moderate': string;
  'Severe': string;
}

interface ConcernSeverityThreshold {
  [key: string]: {
    mild: number;
    moderate: number;
  };
}

@Component({
  selector: 'app-checkup-view',
  templateUrl: './checkup-view.component.html',
  standalone: false,
})
export class CheckupViewComponent implements OnInit, OnDestroy {
  @ViewChild('canvasImage', { static: false }) canvas: ElementRef;
  @ViewChild('canvasIssueMarking', { static: false }) canvasIssueMarking: ElementRef;
  @ViewChild('canvasHolder', { static: false }) canvasHolder: ElementRef;
  @ViewChild('problemNameContainer', { static: false }) problemNameContainer: ElementRef;
  @ViewChild('cameraInput', { static: true }) cameraInput: ElementRef;
  @ViewChild('content', { static: true }) content: ElementRef;
  @ViewChild('header', { static: true }) header: ElementRef;
  @ViewChild('footer', { static: true }) footer: ElementRef;
  @ViewChild('cardContainer') cardContainer: ElementRef;
  @ViewChild('swiperRef') swiper: SwiperComponent;
  @Input('experiments') experiments: any[];
  problemsCopy: { [key: string]: { [key: string]: { name: string, info: string } } } = problemsCopy;
  instantCheckup: any;
  result: any;
  detectedIssuesText: any;
  image: any;
  canvasContext: any;
  issueMarkingContext: any;
  orientation: any;
  subscriptions: Array<any> = [];
  user: any;
  checkupType: string;
  internalUser: any;
  queryUsername: any;
  toolbarIcons: Array<ToolbarType.Icon>;
  banner: any;
  nativeCameraImage: any;
  tag: any;
  followUp: any;
  retryImageCount: number = 0;
  minimumZoomExperiment: boolean;
  minimumAreaOfDetection: any = { max: { x: Number.MIN_VALUE, y: Number.MIN_VALUE }, min: { x: Number.MAX_VALUE, y: Number.MAX_VALUE } };
  ui: any = {};
  isRecentlyTaken: boolean;
  clickedOnConcerns: boolean;
  tourElement: HTMLElement;
  isConcernHelperExpEnabled: boolean;
  isFromListView: boolean;
  queryParams: { [key: string]: any };
  increaseContrastImage: boolean = false;
  contrastValue: number = 1;
  startTimer: number = 0;
  concernSeverity: ConcernSeverity = {
    Mild: 'Mild',
    Moderate: 'Moderate',
    Severe: 'Severe',
  };
  severity: string = this.concernSeverity.Moderate;
  concernSeverityThreshold: ConcernSeverityThreshold = {
    'Inflammatory Acne': {
      mild: 2,
      moderate: 5,
    },
    'Dark Spots': {
      mild: 4,
      moderate: 8,
    },
    Comedones: {
      mild: 4,
      moderate: 8,
    },
    'Acne Scars': {
      mild: 2,
      moderate: 5,
    },
    'Perioral Pigmentation': { // Always severe for now
      mild: 0,
      moderate: 0,
    },
    'Open Pores': { // Always Moderate for now
      mild: 0,
      moderate: 100,
    },
  };
  carouselConfig: SwiperOptions = {
    slidesPerView: 1.2,
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
    autoplay: false,
    on: {
      slideChange: (event: Swiper): any => {
        this.onSlideChange(event);
      },
    },
  };
  handleBlurPhotosExperiment: boolean = false;

  constructor(private conn: ConnectionService,
    private router: Router,
    public appConfig: AppConfig,
    private windowRef: WindowRefService,
    private route: ActivatedRoute,
    private broadcast: BroadcastService,
    private appWebBridge: AppWebBridgeService,
    private window: WindowRefService,
    public eventLogger: EventLoggerService,
    private zone: NgZone,
    public commonUtil: CommonUtilityHelper,
    private localStorageService: LocalStorageService,
    private dataStore: DataStoreService,
    private changeDetectionRef: ChangeDetectorRef,
    private currentComponent: CurrentComponentService) {
    this.toolbarIcons = [{
      name: this.appConfig.Toolbar.ICONS.DELETE,
      callback: (): void => this.deleteInstantCheckup(),
    }];
    this.currentComponent.set(this);
  }

  /**
   * Fetches experiments & instant checkup by the id mentioned in queryParams.
   */
  async ngOnInit(): Promise<any> {
    this.startTimer = new Date().getTime();
    this.reset();
    this.user = this.conn.getActingUser();
    if (!this.experiments?.length) this.experiments = await this.conn.findUserActiveExperiments();
    this.internalUser = this.conn.isInternalUser();
    this.subscriptions.push(this.route.queryParams.subscribe(async (params: any): Promise<void> => {
      this.queryParams = params;
      if (params.username) this.queryUsername = params.username;
      await this.loadInstantCheckupData();
    }));
    const time = new Date().getTime() - this.startTimer;
    this.eventLogger.trackEvent('skin_analysis_screen_loading_time', { timeInMilliSec: time, userId: this.user?.id });
  }

  /**
   * Reset the data when instant checkup id changes in query params.
   */
  reset(): void {
    this.isFromListView = this.route.snapshot.queryParams.fromList;
    if (this.canvas) this.canvas.nativeElement.style.display = 'none';
    this.result = [];
    this.retryImageCount = 0;
    delete this.instantCheckup;
    delete this.orientation;
    this.subscriptions = [];
    this.detectedIssuesText = [];
    this.ui = { selectedCard: null, imageLoaded: false, popUpModal: { open: false } };
  }

  /**
   * Subscribes to query params changes & resets the local variables.
   * 'fromList' query param is set when visited from instant checkup list page.
   */
  async loadInstantCheckupData(): Promise<void> {
    this.subscriptions.push(this.route.params.subscribe(async (data: any): Promise<void> => {
      this.reset();
      const timeBefore = this.windowRef.nativeWindow.performance.now();
      const [instantCheckup]: any = await this.conn.fetchInstantCheckup({ userId: this.queryUsername, id: [data.id] });
      const timeAfter = this.windowRef.nativeWindow.performance.now();
      if (instantCheckup) {
        this.isRecentlyTaken = !this.route.snapshot.queryParams.fromList;
        this.instantCheckup = { ...instantCheckup };
        this.checkupType = this.instantCheckup.type;
        if (this.instantCheckup.type === this.appConfig.Shared.InstantCheckup.Type.INVALID) {
          this.eventLogger.cleverTapEvent('face_not_found', JSON.stringify({ pageName: 'InstantCheckup' }));
        }
        if (this.checkupType === this.appConfig.Shared.InstantCheckup.Type.HAIR_FRONT) {
          this.eventLogger.trackEvent('instant_checkup_api_call', { username: this.user.get('username'), time: timeAfter - timeBefore });
        }
        this.tag = this.instantCheckup.originalType || this.instantCheckup.type;
        if (this.instantCheckup.type === this.appConfig.Shared.InstantCheckup.Type.INVALID) {
          this.appWebBridge.logEventInBranchAndFirebaseFromiOS({
            branch: { name: 'instantCheckupFaceNotDetected' },
            firebase: { name: 'instantCheckupFaceNotDetected' },
          });
        }
        if (this.tag === this.appConfig.Shared.InstantCheckup.Type.INVALID) {
          this.tag = this.appConfig.Shared.InstantCheckup.Type.FULL_FACE;
        }
        if (this.checkupType !== this.appConfig.Shared.InstantCheckup.Type.HAIR_FRONT) this.processData();
        if (this.checkupType === this.appConfig.Shared.InstantCheckup.Type.HAIR_FRONT) this.processHairData();
        if (!this.internalUser
          && this.instantCheckup.type !== this.appConfig.Shared.InstantCheckup.Type.INVALID
          && !this.user?.isPaid()
          && !(typeof this.user.get('ratedApp') === 'boolean')) {
          this.localStorageService.addCheckupCount(this.instantCheckup.objectId);
        }
      }
      if (!instantCheckup) {
        this.notify('No Skin Analysis Found.');
        this.back();
      }
    }));
  }

  /**
   * Sets the boolean for each experiments.
   * 'instant_checkup_banner' - shows banner based on the state of photo.
   * 'instant_checkup_minimal_zoom' - sets the zoom-in + crop to detections feature.
   * 'instant_checkup_concern_click_helper' - shows a helper animation on how to click the concern button in the header.
   */
  async processData(): Promise<any> {
    this.loadImageAndRenderIssues();
    this.checkForExperiments();
    return 0;
  }
  async processHairData(): Promise<any> {
    this.checkForExperiments();
    // this.ui.imageLoaded = true;
    return 0;
  }

  checkForExperiments(): void {
    this.experiments?.forEach((experiment: any): void => {
      if (experiment.key === 'instant_checkup_banner') this.showBannerBasedOnPhotoType();
      if (experiment.key === 'instant_checkup_minimal_zoom') this.minimumZoomExperiment = true;
      if (experiment.key === 'instant_checkup_concern_click_helper') this.isConcernHelperExpEnabled = true;
      if (experiment.key === 'ai_contrast') {
        this.increaseContrastImage = true;
        this.contrastValue = experiment.variant.contrast;
      }
      if (experiment.key === 'handle_blur_photos') this.handleBlurPhotosExperiment = true;
    });
  }

  hairAiLoaded(loaded: boolean): void {
    this.ui.imageLoaded = true;
  }
  /**
   * Shows banner based on state of photo
   * 'photoReviewed' - Photo has been reviewed by doctors.
   * 'photoWillBeReviewed' - if user in new or unpaid user. Photo wil be reviewed after order.
   * 'photoReviewPending' - if user in new or unpaid user. Photo is waiting for review by doctors.
   */
  showBannerBasedOnPhotoType(): any {
    const refreshDate = this.user.get('refreshDate');
    const orderState = this.user.get('orderState') || this.appConfig.Shared.User.OrderState.UNPAID;

    if (this.instantCheckup.createdAt <= refreshDate) {
      this.banner = 'photoReviewed';
    } else if ([this.appConfig.Shared.User.OrderState.UNPAID, this.appConfig.Shared.User.OrderState.NEW_USER].includes(orderState)) {
      this.banner = 'photoWillBeReviewed';
    } else {
      this.banner = 'photoReviewPending';
    }
  }

  /**
   * Load the image into canvas and renders all the marking of issues detected.
   * If image fetch fails, it reties for 3 times.
   * process the 'instantCheckup.aiResponse.result' which is AI detections and filters based on gender,
   * then filters out all the Not Detected concerns from the results.
   * Passes all valid concern to find description for each concern.
   * */
  loadImageAndRenderIssues(): any {
    this.image = new Image();
    this.image.crossOrigin = 'Anonymous';
    (<Window> this.windowRef.nativeWindow).performance.mark('instant-checkup-image-loading-start');
    this.image.onload = (): void => {
      this.afterImageLoad();
    };
    this.image.onerror = (): void => {
      if (this.retryImageCount === 3) {
        this.notify('Something went wrong. Try Again. #(103)');
        this.back();
        return;
      }
      this.image.src = this.instantCheckup?.compressedImagePath || this.instantCheckup?.imagePath;
      this.retryImageCount += 1;
    };
    this.image.src = this.instantCheckup?.compressedImagePath || this.instantCheckup?.imagePath;
    if (![
      this.appConfig.Shared.InstantCheckup.Type.FULL_FACE,
      this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE,
      this.appConfig.Shared.InstantCheckup.Type.SIDE_FACE,
      this.appConfig.Shared.InstantCheckup.Type.LEFT_SIDE_FACE,
      this.appConfig.Shared.InstantCheckup.Type.RIGHT_SIDE_FACE,
    ].includes(this.checkupType)) return 0;
    if (this.instantCheckup?.extraData?.className === 'SupportChat' && this.instantCheckup?.extraData?.supportTicketId) {
      return 0;
    }
    let results = [];
    if (this.instantCheckup?.aiResponse && this.instantCheckup.aiResponse.result) {
      results.push(...this.instantCheckup.aiResponse.result);
    } else if (this.instantCheckup.resultObject) {
      results.push(...JSON.parse(this.instantCheckup.resultObject));
    }
    const filteredResult = results.filter((each: any): boolean => {
      if (each.showForGender && this.user?.get('Gender')?.toLowerCase() === each.showForGender?.toLowerCase()) return true;
      if (!each.showForGender) return true;
      return false;
    });
    results = filteredResult;
    if (results.length && this.user) {
      results.forEach((each: any): void => {
        if (each.Condition !== 'Not Detected') this.result.push(each);
      });
      return this.prepareDescriptionOfConcern(this.result, this.user.get('PrivateMainConcernClass'));
    }
    return 0;
  }

  /**
   * Checks if image needs rotation and reads the orientation of image from 'EXIF' package.
   * IF EXIF package doesn't read orientation within 5 secs, then it writes the image as it is.
   */
  async afterImageLoad(): Promise<void> {
    this.eventLogger.trackEvent('instant_checkup_image_loading');
    const rotationNeeded = this.commonUtil.rotationNeeded();
    if (this.checkupType === this.appConfig.Shared.InstantCheckup.Type.HAIR_FRONT) return;
    if (rotationNeeded) {
      const interval = setTimeout((): Promise<any> => this.prepareCanvas(-1), 5000);
      EXIF.getData(this.image, (): void => {
        clearInterval(interval);
        const orientation = EXIF.getTag(this.image, 'Orientation');
        this.prepareCanvas(orientation);
      });
    } else this.prepareCanvas(-1);
  }

  /**
   * 1. Prepares the canvas
   * 2. Reads the original images height & width from aI response. Because the image u are reading might be compressed & resized image,
   *    but the markings are based on original image co-ordination. Else uses the current image's width & height.
   * 3. Sets the width & height in canvas context.
   * 4. Rotates the canvas based on orientations and draws the image into it.
   * 5. Finally passes the canvas to draw detection on it.
   * @param orientation - orientation of image read from EXIF.
   * @returns {Promise<any>}
   */
  async prepareCanvas(orientation: any): Promise<any> {
    try {
      this.orientation = orientation;
      if (!this.canvas) {
        this.canvas = { nativeElement: '' };
        this.canvas.nativeElement = this.windowRef.nativeWindow.document.getElementById('canvasImage');
      }
      if (!this.canvasIssueMarking) {
        this.canvasIssueMarking = { nativeElement: '' };
        this.canvasIssueMarking.nativeElement = this.windowRef.nativeWindow.document.getElementById('canvasIssueMarking');
      }
      if (this.increaseContrastImage) {
        this.canvas.nativeElement.style.filter = `contrast(${this.contrastValue})`;
      }
      this.canvasContext = this.canvas.nativeElement.getContext('2d');
      this.issueMarkingContext = this.canvasIssueMarking.nativeElement.getContext('2d');
      this.instantCheckup.dimension = {};
      if (this.instantCheckup?.aiResponse
        && this.instantCheckup.aiResponse?.imageHeight
        && this.instantCheckup.aiResponse?.imageWidth) {
        this.instantCheckup.dimension = {
          width: this.instantCheckup.aiResponse.imageWidth,
          height: this.instantCheckup.aiResponse.imageHeight,
        };
      } else {
        this.instantCheckup.dimension = { width: this.image.width, height: this.image.height };
      }
      this.canvasIssueMarking.nativeElement.width = this.instantCheckup.dimension.width;
      this.canvasIssueMarking.nativeElement.height = this.instantCheckup.dimension.height;

      if ([5, 6, 7, 8].includes(this.orientation)) {
        this.canvas.nativeElement.width = this.instantCheckup.dimension.height;
        this.canvas.nativeElement.height = this.instantCheckup.dimension.width;
        this.commonUtil.rotateCanvasBasedOnOrientation(this.orientation, this.canvasContext, this.canvas);
        this.canvasContext.drawImage(this.image,
          0,
          0,
          this.image.width,
          this.image.height,
          0,
          0,
          this.canvas.nativeElement.height,
          this.canvas.nativeElement.width);
      } else {
        this.canvas.nativeElement.width = this.instantCheckup.dimension.width;
        this.canvas.nativeElement.height = this.instantCheckup.dimension.height;
        this.commonUtil.rotateCanvasBasedOnOrientation(this.orientation, this.canvasContext, this.canvas);
        this.canvasContext.drawImage(
          this.image,
          0,
          0,
          this.image.width,
          this.image.height,
          0,
          0,
          this.canvas.nativeElement.width,
          this.canvas.nativeElement.height);
      }

      this.zone.run((): void => {
        this.canvas.nativeElement.style.display = 'block';
        this.canvasIssueMarking.nativeElement.style.display = 'block';
        this.ui.imageLoaded = true;
        this.checksPhotoTypeAndDrawMarking();
      });
    } catch (err) {
      this.notify('Unable to load image. Try Again.');
      this.back(1);
    }
    (<Window> this.windowRef.nativeWindow).performance.mark('instant-checkup-image-loading-end');
    const measure: any = performance
      .measure('instant-checkup-duration', 'instant-checkup-image-loading-start', 'instant-checkup-image-loading-end');
    this.eventLogger.trackEvent('instant_checkup_image_loading', {
      duration: measure.duration,
    });
  }

  /**
   * Calls 'prepareCanvasToDrawDetectionOfConcern' when its a face type photo, which will have detections.
   * Other photo types doesn't have detections.
   */
  checksPhotoTypeAndDrawMarking(): void {
    if (![
      this.appConfig.Shared.InstantCheckup.Type.FULL_FACE,
      this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE,
      this.appConfig.Shared.InstantCheckup.Type.SIDE_FACE,
      this.appConfig.Shared.InstantCheckup.Type.LEFT_SIDE_FACE,
      this.appConfig.Shared.InstantCheckup.Type.RIGHT_SIDE_FACE,
    ].includes(this.checkupType)) {
      return;
    }
    if (this.ui.imageLoaded && this.result.length) {
      setTimeout((): void => this.prepareCanvasToDrawDetectionOfConcern(this.result[0], 0, true), 100);
    }
  }

  /**
   * 1. Clears the canvas of detection marking to redraw marking of new concern & not the photo drawn canvas.
   * 2. Scrolls the concern clicked in header to focus in center.
   * 3. Resets the zoom and scroll when same concern is clicked.
   * 4. Call to draw detections
   * 5. Sends the 2nd concern element to the tour component, which show the helper animation to click on it.
   * @param concern - concern for which detections has to be drawn.
   * @param index - index of concern.
   * @param {boolean} skipEventTracking
   */
  prepareCanvasToDrawDetectionOfConcern(concern: any, index: any, skipEventTracking?: boolean): void {
    if (!skipEventTracking) {
      this.clickedOnConcerns = true;
      this.eventLogger.trackEvent(
        'instant_checkup_result_region_click',
        {
          issue_tapped: concern.ProblemName,
          id: this.instantCheckup.objectId,
          username: this.user.get('username'),
          type: this.checkupType,
          autoZoomExp: this.minimumZoomExperiment,
        });
    }
    this.issueMarkingContext.clearRect(0, 0, this.canvasIssueMarking.nativeElement.width, this.canvasIssueMarking.nativeElement.height);

    if (!this.problemNameContainer) {
      this.problemNameContainer = { nativeElement: undefined };
      this.problemNameContainer.nativeElement = this.windowRef.nativeWindow.document.getElementById('problemNameContainer');
    }
    if (!this.problemNameContainer.nativeElement) return;

    const [currentCard]: HTMLElement[] = this.problemNameContainer.nativeElement.getElementsByClassName(`card_${index}`);
    const startPointOfDiv = Math.abs(currentCard.offsetLeft);
    const scrollView = this.problemNameContainer.nativeElement;
    if (scrollView.scroll instanceof Function) {
      scrollView.scroll((startPointOfDiv - (this.windowRef.nativeWindow.innerWidth / 2)) + (currentCard.offsetWidth / 2), 0);
    }

    if (this.ui.selectedCard === concern.class) {
      this.canvasHolder.nativeElement.style.transform = '';
      if (this.content) this.content.nativeElement.scrollTo(0, 0);
    } else this.ui.selectedCard = concern.class;
    this.markDetectionsOfConcern(concern);
    if (this.isConcernHelperExpEnabled) this.showTourToClickConcerns();

    const confidences = concern?.confidences.length;
    if (confidences) {
      const detectedCount = confidences.length;
      if (this.concernSeverityThreshold[concern.problemName]) {
        const thresholdRange = this.concernSeverityThreshold[concern.problemName];
        if (detectedCount > thresholdRange.moderate) this.severity = this.concernSeverity.Severe;
        else if (detectedCount > thresholdRange.mild) this.severity = this.concernSeverity.Moderate;
        else this.severity = this.concernSeverity.Mild;
      } else {
        this.severity = this.concernSeverity.Moderate;
      }
    }

    if (this.swiper) {
      this.swiper.swiperRef.slideTo(index);
    }
  }

  onSlideChange(swiper: Swiper): void {
    const { activeIndex }: { activeIndex: number } = swiper;
    const selectedItem = this.result[activeIndex];
    if (selectedItem) {
      this.ui.selectedCard = selectedItem.class;
      this.changeDetectionRef.detectChanges();
      this.prepareCanvasToDrawDetectionOfConcern(selectedItem, activeIndex);
    }
  }

  getSeverityTextColor(): string {
    if (this.severity === 'Mild') return 'tw-text-black';
    if (this.severity === 'Moderate') return 'tw-text-yellow-500';
    if (this.severity === 'Severe') return 'tw-text-orange-500';
    return '';
  }

  /**
   * Checks if helper is already shown and ignores it.
   * Else shows helper for 2nd concern card to help user focus on it.
   */
  showTourToClickConcerns(): void {
    if (this.clickedOnConcerns) return;
    if (this.localStorageService.getValue('CureSkin/checkupTourTaken')) return;
    setTimeout((): void => {
      if (this.result.length > 1) {
        [this.tourElement] = this.windowRef.nativeWindow.document.getElementsByClassName('card_1');
      }
    }, 1000);
  }

  onCloseTour(): void {
    this.localStorageService.setValue('CureSkin/checkupTourTaken', true);
    this.clickedOnConcerns = true;
    delete this.tourElement;
  }

  /**
   * If any concern is clicked it shows detection of that, else shows all concerns detection by default.
   * Checks the shape of detection and calls specific function to draw that shape.
   * Calls the zoom experiment to crop and show the minimum area of detection.
   * @param problem
   */
  markDetectionsOfConcern(concern: any): void {
    this.minimumAreaOfDetection = { max: { x: Number.MIN_VALUE, y: Number.MIN_VALUE }, min: { x: Number.MAX_VALUE, y: Number.MAX_VALUE } };
    const container = [];
    if (this.ui.selectedCard === null) {
      container.push(...this.result);
      container.shift();
    } else container.push(concern);
    container.forEach((each: any): void => {
      const item = each;
      if (!each.showForGender || this.user.get('Gender') === each.showForGender) {
        if (item.BoundingBoxes) this.drawIssuesInCircle(item);
        if (item.PolygonPointsSequences) this.drawIssuesInPolygon(item);
      }
    });
    if (this.minimumZoomExperiment && this.ui.selectedCard !== null) this.focusAndZoomMinimumArea();
  }

  /**
   * Scales the image and zooms it to show only the area of photo where maximum of detections are located.
   * Blurs out the other area by drawing a rectangle box around.
   */
  focusAndZoomMinimumArea(noScroll?: boolean): void {
    const BUFFER_IN_PX = 50;
    const MAX_SCALE = 3;
    const MIN_SCALE = 1;
    const MAX_SCALE_PERCENTAGE = 0.8;
    let translateX = 0;
    let translateY = 0;

    /**
     * Calculates Header, Footer & Content height of the page
     */
    const pageHeaderHeight = this.header?.nativeElement.clientHeight;
    const pageFooterHeight = this.footer?.nativeElement.clientHeight || 0;
    const pageContentHeight = this.windowRef.nativeWindow.innerHeight - (pageFooterHeight + pageHeaderHeight);
    const parentOffsetTop = this.canvasHolder.nativeElement.parentElement.offsetTop;

    /**
     * Calculates image's width & height + canvas width & height.
     */
    const imageWidth = this.instantCheckup.dimension.width;
    const imageHeight = this.instantCheckup.dimension.height;
    const imageHolderWidth = this.canvasHolder.nativeElement.clientWidth;
    const imageHolderHeight = this.canvasHolder.nativeElement.clientHeight;

    /**
     * From the min point calculated, it adds some buffer px to it and set as 'cropX, cropY'.
     * Which is the starting point of minimum area.
     * It also calculates width & height of area to be cropped by adding buffer to it and set as 'cropWidth & cropHeight'.
     * These calculations are with respect to images width & height. (ex: 2500 * 3800 size image)
     */
    const { max, min }: any = this.minimumAreaOfDetection;
    const cropX = Math.max(min.x - BUFFER_IN_PX, 0);
    const cropY = Math.max(min.y - BUFFER_IN_PX, 0);
    let cropWidth = Math.min((max.x - min.x) + (BUFFER_IN_PX * 2), imageWidth);
    let cropHeight = Math.min((max.y - min.y) + (BUFFER_IN_PX * 2), imageHeight);

    /**
     * Calculates all the co-ordinates with respect to screen's width & height. (ex: 360 * 640)
     * eg: leftSpace - space between holder left start (0px) to start of cropped box (cropX)
     * eg: topSpace - space between holder top start (0px) to start of cropped box (cropY)
     */
    const croppedCordsToScreen: any = {
      width: (((cropWidth * 100) / imageWidth) * imageHolderWidth) / 100,
      height: (((cropHeight * 100) / imageHeight) * imageHolderHeight) / 100,
      x: (((cropX * 100) / imageWidth) * imageHolderWidth) / 100,
      y: (((cropY * 100) / imageHeight) * imageHolderHeight) / 100,
      leftSpace: (((cropX * 100) / imageWidth) * imageHolderWidth) / 100,
      rightSpace: (imageHolderWidth * (imageWidth - (cropX + cropWidth))) / imageWidth,
      topSpace: (((cropY * 100) / imageHeight) * imageHolderHeight) / 100,
      bottomSpace: (imageHolderHeight * (imageHeight - (cropY + cropHeight))) / imageHeight,
    };

    /**
     * Resizes cropWidth & cropHeight comparing it with image's W & H.
     * Because, cropX, cropY, cropWidth, cropHeight properties have buffer added to them. so it may overflow original images W & H.
     */
    if (cropX + cropWidth > imageWidth) {
      cropWidth -= (cropX + cropWidth) - imageWidth;
    }
    if (cropY + cropHeight > imageHeight) {
      cropHeight -= (cropY + cropHeight) - imageHeight;
    }

    /**
     * Calculates line width for rectangle box (cropped area) with respect to image W vs screen W, max is 4;
     * Reason - If image is too big to screen width, then line has to be thicker.
     * Draws rectangle to the cropped area.
     * Draws blurry 4 piece of rectangle around cropped area to make the cropped area focused.
     */
    const lineWidth = Math.max(imageWidth / this.windowRef.nativeWindow.innerWidth, 4);
    this.issueMarkingContext.beginPath();
    this.issueMarkingContext.rect(cropX + (lineWidth), cropY + (lineWidth), cropWidth - (lineWidth * 2), cropHeight - (lineWidth * 2));
    this.issueMarkingContext.lineWidth = lineWidth;
    this.issueMarkingContext.strokeStyle = 'rgba(255, 255, 255, 0.65)';
    this.issueMarkingContext.stroke();
    this.issueMarkingContext.closePath();
    this.issueMarkingContext.beginPath();
    this.issueMarkingContext.fillStyle = 'rgba(0, 0, 0, 0.5)';
    this.issueMarkingContext.fillRect(0, 0, imageWidth, cropY);
    this.issueMarkingContext.fillRect(0, cropY, cropX, cropHeight);
    this.issueMarkingContext.fillRect(0, (cropHeight + cropY), imageWidth, imageHeight - (cropHeight + cropY));
    this.issueMarkingContext.fillRect((cropX + cropWidth), cropY, imageWidth - (cropX + cropWidth), cropHeight);
    this.issueMarkingContext.closePath();

    /**
     * Now we have to zoom in the cropped box to user's view.
     * scale - no.of times to scale up the cropped box to fit. zoom scale is dynamic.
     * If cropped area height is more then cropped are width, then we consider the factor with respect to Height.
     * we recalculate 'scale' based on MIN and MAX scale defined
     * & MAX_SCALE_PERCENTAGE (how much percentage it should use out of calculated scale)
     * Note: Commented code is not necessary. Wrote it for some edge but couldn't recall now. Keep the code. It might be helpfull in future.
     */
    let scale = imageWidth / cropWidth;
    if (cropHeight > cropWidth) {
      scale = imageHeight / cropHeight;
      // const contentHeight = this.windowRef.nativeWindow.innerHeight - (pageHeaderHeight + pageFooterHeight);
      // if ((croppedCordsToScreen.height * scale) > contentHeight) {
      //   scale = contentHeight / croppedCordsToScreen.height;
      // }
    }
    scale = Math.max(Math.min(scale, MAX_SCALE) * MAX_SCALE_PERCENTAGE, MIN_SCALE);

    /**
     * Recalculate co-ordinates after zoom with scale factor.
     */
    const cordsAfterZoom: any = {
      width: croppedCordsToScreen.width * scale,
      height: croppedCordsToScreen.height * scale,
      x: croppedCordsToScreen.x * scale,
      y: croppedCordsToScreen.y * scale,
      rightSpace: croppedCordsToScreen.rightSpace * scale,
      leftSpace: croppedCordsToScreen.leftSpace * scale,
      topSpace: croppedCordsToScreen.topSpace * scale,
      bottomSpace: croppedCordsToScreen.bottomSpace * scale,
    };

    /**
     * Till now we have cropped & zoomed the box.
     * Now we have to bring the zoomed box to center of view port.
     * So we calculate space left over x & y axis.
     * i.e If screen W is 500px & cropped box W is 400px then (500-400)/2 = 50px need on both sides.
     */
    cordsAfterZoom.spaceNeededToCenterX = (imageHolderWidth - cordsAfterZoom.width) / 2;
    cordsAfterZoom.spaceNeededToCenterY = (imageHolderHeight - cordsAfterZoom.height) / 2;

    /**
     * 1. if space needed is available on both side, then x (start point) - space needed.
     * 2. if right space < left space, then
     ** // || - screen, [] - cropped box, {} - actual image
     * // screen width - (left space + crop width) - gives overflowed x space (ex: {|   [   | ] }  )
     * // translateX(-overflowed) - brings cropped box right end to screen end. (ex: { |   [   ]| }  )
     * // now add right space to overflowed value to move cropped box to max left end filling the space. (ex: { |  [   ] |}  )
     * 3. if left space < right screen, then translateX = 0
     */

    if (cordsAfterZoom.rightSpace >= cordsAfterZoom.spaceNeededToCenterX
      && cordsAfterZoom.leftSpace >= cordsAfterZoom.spaceNeededToCenterX) {
      translateX = Math.min(0, -(croppedCordsToScreen.x - (cordsAfterZoom.spaceNeededToCenterX / scale)));
    } else if (cordsAfterZoom.rightSpace < cordsAfterZoom.leftSpace) {
      // find available right padding and use it to adjust x axis
      translateX = ((imageHolderWidth - (cordsAfterZoom.leftSpace + cordsAfterZoom.width)) / scale) - (cordsAfterZoom.rightSpace / scale);
    } else {
      // its already padded to max it can, so don't need to translate it.
      translateX = 0;
    }

    if (cordsAfterZoom.topSpace >= cordsAfterZoom.spaceNeededToCenterY
      && cordsAfterZoom.bottomSpace >= cordsAfterZoom.spaceNeededToCenterY) {
      translateY = Math.min(0, -(croppedCordsToScreen.y - (cordsAfterZoom.spaceNeededToCenterY / scale)));
    } else if (cordsAfterZoom.bottomSpace < cordsAfterZoom.topSpace) {
      translateY = ((imageHolderHeight - (cordsAfterZoom.topSpace + cordsAfterZoom.height)) / scale) - (cordsAfterZoom.bottomSpace / scale);
    } else {
      translateY = 0;
    }

    /**
     * Sets the scale(zoom) + translate(center alignment) factor in canvas style.
     * If height of canvas itself is greater than view port,
     * then we have to bring the canvas Y axis to user's view by scrolling the overflowed Y axis.
     * MAX_SCROLL - overflowed height of canvas. i.e canvasHeight - pageContent(flex-content) height
     */
    this.canvasHolder.nativeElement.style.transform = `scale(${scale}) translateX(${translateX}px) translateY(${translateY}px)`;
    const MAX_SCROLL = (this.canvasHolder.nativeElement.clientHeight + parentOffsetTop) - pageContentHeight;
    const pxToCenterAlignOnYAxis = parentOffsetTop
      + ((cordsAfterZoom.topSpace + (translateY * scale)) - ((pageContentHeight - cordsAfterZoom.height) / 2));
    if (!noScroll && this.content) this.content.nativeElement.scrollTo(0, Math.min(pxToCenterAlignOnYAxis, MAX_SCROLL));
  }

  /**
   * 'BoundingBoxes' array holds the array of detections.
   * Extracts the cords and draws circle in the issueMarking canvas.
   * From the 2 points - (x1, y1) (x2, y2) stores the minimum x & maximum y point.
   */
  drawIssuesInCircle(concern: any): void {
    const { max, min }: any = this.minimumAreaOfDetection;
    const SCALE = 0.6; // scale down the radius to 60% of actual radius.
    concern.BoundingBoxes.forEach((each: any): void => {
      if (!each) return;
      const points = each.split(',');
      const point1x = Number(points[0]);
      const point1y = Number(points[1]);
      const point2x = Number(points[2]);
      const point2y = Number(points[3]);
      const radius = Math.abs(point1x - point2x) * SCALE;
      this.issueMarkingContext.beginPath();
      this.issueMarkingContext.setLineDash([]);
      this.issueMarkingContext.arc((point1x + point2x) / 2, (point1y + point2y) / 2, radius, 0, 2 * Math.PI);
      this.issueMarkingContext.lineWidth = (this.image.height / this.image.width) * 4;
      this.issueMarkingContext.strokeStyle = '#ed0400';
      this.issueMarkingContext.stroke();
      this.issueMarkingContext.closePath();

      if (point1x < min.x) {
        min.x = point1x;
      }
      if (point2x > max.x) {
        max.x = point2x;
      }
      if (point1y < min.y) {
        min.y = point1y;
      }
      if (point2y > max.y) {
        max.y = point2y;
      }
    });
  }

  /**
   * 'PolygonPointsSequences' array holds the array of detections.
   * Extracts the cords and draws tiny lines in the issueMarking canvas to form a polygon.
   * From on array of points - (x1, y1) stores the minimum x & maximum y point.
   */
  drawIssuesInPolygon(concern: any): void {
    const { max, min }: any = this.minimumAreaOfDetection;
    concern.PolygonPointsSequences.forEach((each: any): void => {
      each.push(each[0]);
      each.forEach((cords: any, index: any): void => {
        if (!cords) return;
        const points = cords.split(',');
        const pointX = Number(points[0]);
        const pointY = Number(points[1]);

        if (pointX < min.x) {
          min.x = pointX;
        }

        if (pointY < min.y) {
          min.y = pointY;
        }

        if (pointX > max.x) {
          max.x = pointX;
        }
        if (pointY > max.y) {
          max.y = pointY;
        }
        if (index === 0) {
          this.issueMarkingContext.beginPath();
          this.issueMarkingContext.setLineDash([]);
          this.issueMarkingContext.moveTo(pointX, pointY);
          return;
        }
        this.issueMarkingContext.setLineDash([]);
        this.issueMarkingContext.lineTo(pointX, pointY);
        this.issueMarkingContext.lineWidth = (this.image.height / this.image.width) * 4;
        this.issueMarkingContext.stroke();
        this.issueMarkingContext.strokeStyle = '#ed0400';
        if (index === each.length - 1) {
          this.issueMarkingContext.closePath();
        }
      });
    });
    this.minimumAreaOfDetection = { max, min };
  }

  /**
   * Fetches the description for each concern from the shared config file and stores it.
   * Calls 'checksPhotoTypeAndDrawMarking' to start the drawing process.
   */
  prepareDescriptionOfConcern(concern: any[], mainConcern: any): void {
    const defaultState = [];
    const detectedIssues = concern
      .filter((x: any): boolean => (x.Condition === 'Detected'))
      .map((item: any): string => item.ProblemName);
    switch (mainConcern) {
      case 'ACNE_OR_PIMPLES': {
        if (!detectedIssues.length) {
          defaultState.push('acneOrWhiteheads_None');
          break;
        }
        if (detectedIssues.includes('Comedones')) defaultState.push('acneOrWhiteheads_Comedones');
        if (!detectedIssues.includes('Comedones') && detectedIssues.includes('Inflammatory Acne')) {
          defaultState.push('acneOrWhiteheads_NotComedones_PIMPLE');
        }
        if (detectedIssues.includes('Dark spots')) defaultState.push('acneOrWhiteheads_DarkSpots');
        if (detectedIssues.includes('Acne scars')) defaultState.push('acneOrWhiteheads_AcneScars');
        defaultState.push('acneOrWhiteheads_All');
        break;
      }
      case 'DARK_SPOTS_OR_MARK': {
        if (!detectedIssues.length) {
          defaultState.push('darkSpots_None');
          break;
        }
        if (detectedIssues.includes('Dark spots')) defaultState.push('darkSpots_DarkSpots');
        if (detectedIssues.includes('Inflammatory Acne') || detectedIssues.includes('Comedones')) {
          defaultState.push('darkSpots_Pimple_OR_Comodones');
        }
        break;
      }
      case 'ACNE_SCARS': {
        if (!detectedIssues.length) {
          defaultState.push('scars_None');
          break;
        }
        if (detectedIssues.includes('Acne scars')) defaultState.push('scars_AcneScars');
        if (!detectedIssues.includes('Acne scars') || detectedIssues.includes('Other scars')) {
          defaultState.push('scras_NotAcneScars_OtherScars');
        }
        if (detectedIssues.includes('Comedones') || detectedIssues.includes('Inflammatory Acne')) {
          defaultState.push('scras_Pimple_OR_Comedones');
        }
        break;
      }
      case 'PIGMENTATION':
      case 'DULL_SKIN': {
        if (detectedIssues.includes('Dark spots')) defaultState.push('skintone_DarkSpots');
        if (detectedIssues.includes('Comedones') || detectedIssues.includes('Inflammatory Acne')) {
          defaultState.push('skintone_Comedones_Or_Pimple');
        }
        defaultState.push('skintone_All');
        break;
      }
      default: {
        if (!detectedIssues.length) {
          defaultState.push('nothing_Detected');
          break;
        }
        if (detectedIssues.includes('Comedones')) defaultState.push('nothing_Comedones');
        if (detectedIssues.includes('Inflammatory Acne')) defaultState.push('nothing_Pimple');
        if (detectedIssues.includes('Dark spots')) defaultState.push('nothing_DarkSpots');
        if (detectedIssues.includes('Acne scars')) defaultState.push('nothing_AcneScars');
        if (detectedIssues.includes('Other scars')) defaultState.push('nothing_OtherScars');
      }
    }
    if (detectedIssues.length > 1) this.detectedIssuesText = defaultState;
    this.checksPhotoTypeAndDrawMarking();
  }

  /**
   * This function decides where to take the user to when they click 'continue' button in footer.
   * 1. if no main-concern, then it navigates user to either mainconcern selection page or photo taking page based on experiment.
   * 2. If there is any redirect url set it local-storage, it takes them to there.
   * 3. Else to chat page.
   */
  async continueToNext(): Promise<any> {
    const redirectTo = this.localStorageService.getValue('CureSkin/redirectUrl');
    if (redirectTo) return this.conn.redirectToLastKnowLocation();
    this.eventLogger.trackEvent('continue_to_chat_from_instant_checkup', { username: this.user.get('username') });
    if (this.user.get('followupTreeTriggered')) {
      this.followUp = await this.conn.findRecentFollowUp({
        State: [
          this.appConfig.Shared.Followup.State.PENDING,
          this.appConfig.Shared.Followup.State.WAITING_FOR_IMAGE,
        ],
        ready: false,
      });
      return this.router.navigate([`/chatV2/${this.followUp?.id}`], {
        queryParams: { type: 'followUp' },
      });
    }
    const routeString = await this.checkForRegimenAndNavigate();
    if (routeString) {
      return this.router.navigate(['/user'], { queryParams: { tab: 'regimen', class: routeString } });
    }
    return this.router.navigate(['/user'], { queryParams: { tab: 'home' } });
  }

  async checkForRegimenAndNavigate(): Promise<string> {
    const regimenClasses = [this.appConfig.Shared.Regimen.Class.FACE, this.appConfig.Shared.Regimen.Class.HAIR];
    const regimens = await this.conn.fetchRegimens(null, true);

    const foundClass = regimenClasses.find((regimenClass: any): boolean => {
      const includesTag = this.tag.includes(regimenClass);
      return includesTag && !this.hasPaidRegimen(regimens, regimenClass);
    });

    return foundClass || '';
  }

  private hasPaidRegimen(regimens: any[], regimenClass: string): boolean {
    return regimens.some((regimen: any): boolean => regimen?.orderPlaced && regimen?.class === regimenClass);
  }

  /**
   * When Check again button is clicked.
   * 1. Calls native to open camera when its inside native app.
   * 2. Open Native camera through web when experiment is enabled.
   * 3. Routes to capture page, which is a web-cam(video) based capturing experience. We capture the image of video.
   */
  async goCheckAgain(): Promise<any> {
    const expectedPhotoType = this.localStorageService.getValue('expectedFacePhoto')
    || this.localStorageService.getValue('checkupListExpectedFacePhoto') || this.tag;
    this.eventLogger.trackEvent('check_again', { username: this.user.get('username'), type: this.tag });
    this.eventLogger.trackPeopleIncrement({ people_take_photo_clicked: 1 });
    if (this.appWebBridge.isAppWebBridgeLoaded()) {
      this.appWebBridge.notifyWebLoginToken();
      const extraData = { ...this.queryParams };
      delete extraData.tag;
      this.appWebBridge.notifyCaptureImage(expectedPhotoType, {}, extraData);
    } else if (this.cameraInput) {
      this.cameraInput.nativeElement.click();
    } else {
      this.windowRef.nativeWindow.document.getElementById('cameraInput').click();
    }
  }

  /**
   * Callback of native camera picture upload. i.e Native camera opened through web, not through app bridge.
   */
  async uploadImageFromNativeCamera(event: any): Promise<any> {
    this.nativeCameraImage = event.target.files[0];
    await this.dataStore.set('IMAGE_FILE', { file: this.nativeCameraImage });
    const extraData = { ...(this.route.snapshot.queryParams || {}) };
    this.router.navigate(['/user/instantCheckup/capture'],
      { queryParams: { tag: this.tag, nativeCameraFile: true, ...extraData }, replaceUrl: true });
  }

  deleteInstantCheckup(confirmDelete?: boolean): void {
    if (this.ui.deleteLoading || !this.instantCheckup) return;
    this.ui.deleteLoading = true;
    this.conn.deleteInstantCheckup(this.instantCheckup.objectId, confirmDelete)
      .then((): void => {
        this.notify('Deleted Successfully');
        this.back();
        this.ui.deleteLoading = false;
      })
      .catch((err: any): void => {
        const message = err.message || err;
        this.ui.popUpModal = {
          title: this.appConfig.Shared.String.DELETE_PHOTO,
          open: true,
          okText: this.appConfig.Shared.String.DELETE,
          cancelText: this.appConfig.Shared.String.KEEP_PHOTO,
          message: { text: message },
          type: err.code === 409 ? this.appConfig.Dialog.CONFIRMATION : this.appConfig.Dialog.ALERT,
        };
        this.ui.deleteLoading = false;
      });
  }

  popUpClosed(result: any): void {
    this.ui.popUpModal = { open: false };
    if (result.clickOnYes) {
      this.deleteInstantCheckup(true);
    }
  }

  notify(message: any): void {
    this.broadcast.broadcast('NOTIFY', { message });
  }

  /**
   * Doesn't handle back locally when any popup is opened.
   */
  handleBackPress(): Promise<any> {
    if (this.ui.popUpModal) return Promise.resolve(false);
    return Promise.resolve(true);
  }

  back(step?: number): void {
    this.broadcast.broadcast('NAVIGATION_BACK', { step });
  }

  openTab(url: string): void {
    this.conn.navigateToURL(url);
  }

  logConcernClicks(): void {
    if (!this.clickedOnConcerns && this.instantCheckup) {
      this.eventLogger.trackEvent('instant_checkup_result_region_click',
        {
          issue_tapped: 'NO_CLICKS',
          id: this.instantCheckup.objectId,
          username: this.user.get('username'),
          type: this.checkupType,
          autoZoomExp: this.minimumZoomExperiment,
        });
    }
  }

  ngOnDestroy(): void {
    this.logConcernClicks();
    this.currentComponent.remove(this);
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
