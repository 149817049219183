import { Component, EventEmitter, Input, OnDestroy, Output, ViewContainerRef } from '@angular/core';
import { WindowRefService } from '@services/window-ref-service';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from '../../app/app.config';

@Component({
  selector: 'pop-up-modal',
  templateUrl: './pop-up-modal.html',
  styleUrls: ['./pop-up-modal.scss'],
  standalone: false,
})
export class PopUpModalComponent implements OnDestroy {
  @Output('close') close: EventEmitter<any> = new EventEmitter();
  message: any = {};
  okText: string;
  cancelText: string;
  type: string;
  result: any = {};
  title: any;
  callback: Function;
  requestCode: number = 0;
  inputs: Array<any>;
  options: Array<any> = [];
  titleIcon: string;
  config: any = {};
  user: any;
  @Input('config')
  set configuration(config: any) {
    this.config = config;
    if (!config) return;
    this.message = config.message;
    this.options = config.options;
    this.title = config.title;
    this.titleIcon = config.titleIcon;
    this.okText = config.okText;
    this.type = config.type;
    this.requestCode = config.requestCode;
    this.inputs = config.inputs;
    this.callback = config.callback;
    this.cancelText = config.cancelText;
    this.result = { type: this.type, requestCode: this.requestCode };
    this.show = config.open;
    if (this.show) {
      this.windowRef.replaceHash('./#popup', (): void => { this.close.emit(this.result); });
      this.openPopUp();
    }
  }
  @Input() show: boolean = false;
  radioSelection: any;
  constructor(private windowRef: WindowRefService,
    public appConfig: AppConfig,
    public broadcast: BroadcastService,
    private conn: ConnectionService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.user = this.conn.getActingUser();
  }

  ngOnInit(): void {
    delete this.radioSelection;
    this.type = this.type || this.appConfig.Dialog.CONFIRMATION;
  }

  onClickInput(input: any): void {
    this.result = { type: this.type, requestCode: this.requestCode, input: { text: input }, clickOnYes: true };
    this.closePopUp();
  }

  optionSingleSelectionChange(selectedIndex: number): void {
    this.options.forEach((each_: { selected: boolean; }, index: number): void => {
      const each = each_;
      each.selected = (index === selectedIndex);
    });
  }

  onOptionConfirm(): void {
    this.result = {
      type: this.type,
      requestCode: this.requestCode,
      clickOnYes: true,
      options: this.options.filter((each: { selected: boolean }): boolean => each.selected),
    };
    this.closePopUp();
  }

  onClickYes(): void {
    this.result = { type: this.type, requestCode: this.requestCode, clickOnYes: true };
    this.closePopUp();
  }

  onClickNo(): void {
    this.result = { type: this.type, requestCode: this.requestCode, clickOnNo: true };
    this.closePopUp();
  }

  selectedRadioInput(input: any): void {
    this.radioSelection = input;
    this.onClickInput(this.radioSelection);
  }

  openPopUp(): void {
    if (this.viewContainerRef.element) {
      this.windowRef.nativeWindow.document.body.appendChild(this.viewContainerRef.element.nativeElement);
    }
  }

  selectedMultiInput(input: any): void {
    this.result = { type: this.type, requestCode: this.requestCode, input: { text: input }, clickOnYes: true };
    this.close.emit(this.result);
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  async closePopUp(): Promise<any> {
    if (this.callback) {
      this.callback(this.result);
      return;
    }
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  stopEvent(event: Event): void {
    event.stopImmediatePropagation();
  }

  ngOnDestroy(): void {
    if (this.viewContainerRef.element) this.viewContainerRef.element.nativeElement.remove();
  }
}
