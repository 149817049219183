import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'app-analysis-banner',
  templateUrl: './analysis-banner.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class AnalysisBannerComponent implements OnInit {
  @Output('analysisOfTheDay') analysisOfTheDay: EventEmitter<void> = new EventEmitter();
  bannerPositionBottom: boolean;
  userLanguage: string;

  constructor(public conn: ConnectionService,
              public appConfig: AppConfig) { }

  async ngOnInit(): Promise<void> {
    const user = this.conn.getActingUser();
    this.userLanguage = user.get('languagePreference');
    this.bannerPositionBottom = user?.get('InstantCheckupTaken') || false;
  }

  handleClick(): void {
    this.analysisOfTheDay.emit();
  }
}
