import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'missed-doctor-call',
  templateUrl: './missed-doctor-call.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class MissedDoctorCallComponent implements OnInit {
  @Output('openReAcquisitionPage') openReAcquisitionPage: EventEmitter<any> = new EventEmitter();
  constructor(
    public appConfig: AppConfig,
  ) { }

  ngOnInit(): void {
  }

  handleClick(): void {
    this.openReAcquisitionPage.emit();
  }
}
