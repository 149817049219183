import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ConnectionService } from '@services/connection-service';
import { WindowRefService } from '@services/window-ref-service';
import { EventLoggerService } from '@services/event-logger-service';
import { CurrentComponentService } from '@services/current-component';
import { BroadcastService } from '@services/broadcast-service';
import { ApiClientConstant } from '@cureskin/api-client/src/constant/api-client.constant';
import { AppConfig } from '../app.config';

@Component({
  selector: 'app-article',
  templateUrl: './article.html',
  styleUrls: ['./article.scss'],
  standalone: false,
})
export class ArticleComponent implements OnDestroy {
  // Static ids of products for which article title needs to be hidden and no top and x padding.
  articleWithNoTitle: string[] = ['o16m2t065L', 'AgBFTMS6TU', 'zFGHoKDMpM', 'fEBIcvDwi4'];
  @ViewChild('articleBlock', { static: false })
  private articleBlock: ElementRef;
  subscriptions: Array<Subscription> = [];
  user: any;
  products: Array<any> = [];
  innerHTML: any;
  cssLink: any;
  clickToAction: string;
  totalSP: number;
  totalMRP: number;
  discount: any = 0;
  window: any;
  article: any;
  title: string = '';
  patientName: any;
  shareData: any = {};
  experiments: any = [];
  cart: any;
  cartItems: any = [];
  expEnable: boolean = false;
  toolbarIcons: Array<any> = [{
    clickId: 'cart_page',
    name: this.appConfig.Toolbar.ICONS.CART,
    callback: (): void => this.goToCart(),
  }];
  paid: boolean = false;
  articleHeaderImage: string = '';
  constructor(private conn: ConnectionService,
    private router: Router,
    private route: ActivatedRoute,
    private windowRef: WindowRefService,
    private dom: DomSanitizer,
    private eventLogger: EventLoggerService,
    public appConfig: AppConfig,
    private currentComponent: CurrentComponentService,
    private broadcast: BroadcastService) {
  }

  async ngOnInit(): Promise<any> {
    this.currentComponent.set(this);
    this.window = this.windowRef.nativeWindow;
    this.user = this.conn.getActingUser();
    if (!this.user) return;
    if (this.user.isPaid()) this.paid = true;
    this.toolbarIcons[0].showCartUI = true;
    this.getCart();
    this.experiments = await this.conn.findUserActiveExperiments(this.user.get('username'));
    this.experiments.forEach(async (experiment: any): Promise<any> => {
      if (experiment.key === 'gentle_to_deep') {
        this.expEnable = true;
      }
    });
    await this.subscribeToUrlQueryParams();
  }

  goToCart(): void {
    this.router.navigate(['cart']);
  }

  async getCart(): Promise<void> {
    this.cart = await this.conn.getCart();
    this.cartItems = this.cart.get('lineItems');
    if (this.cartItems.length) {
      this.toolbarIcons[0].cart = true;
      let totalItems: number = 0;
      this.cartItems.forEach((element: any): void => {
        totalItems += element.quantity;
      });
      this.toolbarIcons[0].cartValue = totalItems || null;
    } else {
      this.toolbarIcons[0].cartValue = null;
    }
  }

  async subscribeToUrlQueryParams(): Promise<any> {
    const articleId = this.route.snapshot.params.id;
    this.eventLogger.trackEvent('article_opened', { articleId, type: 'ARTICLE_OPEN' });
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ articleId, pageName: 'article' }));
    this.eventLogger.trackInParse('PAGE_OPEN', articleId);
    this.article = await this.conn.getArticleById(articleId, { translate: true });
    if (this.article) {
      this.loadData(this.article);

      const url = `https://cdn.cureskin.com/app/img/articleBanner/${articleId}.png`;
      const image = new Image();
      image.src = url;
      image.onload = (event: Event): void => {
        if (event) this.articleHeaderImage = url;
      };
    } else this.back();
  }

  async loadData(article: any): Promise<void> {
    this.article = article;

    if (article.get('cssLink')) {
      this.cssLink = this.dom.bypassSecurityTrustResourceUrl(article.get('cssLink'));
    }
    if (article.get('scriptLink')) {
      const script = this.windowRef.nativeWindow.document.createElement('script');
      script.src = article.get('scriptLink');
      script.async = true;
      script.type = 'text/javascript';
      this.articleBlock.nativeElement.appendChild(script);
    }

    let html = article.get('html');
    html = html.toString().replace(/<a href/gi, '<router navigateTo');
    this.innerHTML = this.dom.bypassSecurityTrustHtml(html);

    const parentProductId = this.route.snapshot.queryParams?.product;

    if (parentProductId) {
      this.products = await this.conn.findCatalogWithKey([parentProductId]);
    } else if (article.get('products') && article.get('products').length) this.products = article.get('products');
    else if (article.get('product')) this.products = [article.get('product')];

    this.clickToAction = article.get('clickToAction');
    this.shareData = article.get('shareData') || {};
    this.title = article.get('toolbarTitle');

    if (this.products.length) {
      let total = 0;
      this.products.forEach((each: any): void => total += each.get('price'));
      this.totalSP = Math.floor(total);
      total = 0;
      this.products.forEach((each: any): void => total += each.get('mrp'));
      this.totalMRP = total;
    }
    // this is temp exp for changing gentle to deep cleanse face wash
    if (!this.products.length && this.expEnable) {
      // id for deep cleanse
      const id = ['KdRMDPkNHr'];
      const productsFromParams: any = await this.conn.findProductsById(id);
      this.products = productsFromParams;
      let total = 0;
      this.products.forEach((each: any): void => total += each.get('price'));
      this.totalSP = Math.floor(total);
      total = 0;
      this.products.forEach((each: any): void => total += each.get('mrp'));
      this.totalMRP = total;
    }
    this.findDiscount();
    setTimeout((): void => this.loadEvents());
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  findDiscount(): void {
    if (this.totalMRP > this.totalSP) {
      this.discount = Math.floor(((this.totalMRP - this.totalSP) * 100) / this.totalMRP);
    } else this.discount = 0;
  }

  buyRegimen(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'article-buy-regimen' }));
    this.eventLogger.trackInParse('BUY_NOW', ApiClientConstant.Event.Type.ORDER);
  }

  async buttonAction(): Promise<any> {
    if (this.user) {
      const params: any = { username: this.user.get('username') };
      if (this.products[0]) params.product = this.products[0].id;
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'article-buy-addon' }));
      this.eventLogger.trackEvent('article_addon_buy_now', params);
    }

    if (this.article.get('skipListingPage')) {
      const queryParams = { type: this.appConfig.Shared.Order.Type.PRODUCT,
        products: this.products.map((each: any): void => each.id).join(',') };
      this.router.navigate(['user/checkout'], { queryParams });
      return;
    }
    let url;
    let queryParams;
    const skipLocationChange = false;
    switch (this.clickToAction) {
      case this.appConfig.Shared.ArticleData.clickToAction.BUY_PRODUCT: {
        const productId = this.products.map((each: any): void => each.id).join(',');
        if (this.cartItems.length) {
          this.cartItems.forEach(async (element: any): Promise<any> => {
            if (element.productId === productId) {
              const [productsFromParams]: any = await this.conn.findProductsById(element.productId);
              if (productsFromParams) {
                // eslint-disable-next-line no-param-reassign
                element.prescriptionRequired = productsFromParams.get('prescriptionRequired');
              }
              if (!element.prescriptionRequired && element.quantity < 5) {
                const params = {
                  productId: element.productId,
                  quantity: 1,
                };
                await this.conn.addProductToCart(params);
                this.router.navigate(['cart']);
                return;
              }
              if (element.prescriptionRequired && element.quantity < 3) {
                const params = {
                  productId: element.productId,
                  quantity: 1,
                };
                await this.conn.addProductToCart(params);
                this.router.navigate(['cart']);
                return;
              }
              if (!element.prescriptionRequired && element.quantity >= 5) {
                this.router.navigate(['cart']);
                this.broadcast.broadcast('NOTIFY', { message: 'Maximum quantity is limited to 5 per product' });
                return;
              }
              if (element.prescriptionRequired && element.quantity >= 3) {
                this.router.navigate(['cart']);
                this.broadcast.broadcast('NOTIFY', { message: 'Maximum quantity is limited to 3 per product' });
                return;
              }
            }
            const prod: any = this.cartItems.find((each: any): any => each.productId === productId);
            if (!prod) {
              const params = {
                productId,
                quantity: 1,
              };
              await this.conn.addProductToCart(params);
              this.router.navigate(['cart']);
            }
          });
        } else {
          const params = {
            productId,
            quantity: 1,
          };
          await this.conn.addProductToCart(params);
          this.router.navigate(['cart']);
        }
        this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'article-page-checkout' }));
        break;
      }
      case this.appConfig.Shared.ArticleData.clickToAction.BUY_REGIMEN: {
        url = 'user';
        queryParams = { tab: 'regimen' };
        if (this.article.get('class')) queryParams.class = this.article.get('class');
        break;
      }
      case this.appConfig.Shared.ArticleData.clickToAction.NONE: {
        url = 'user?tab=chat';
        break;
      }
      case this.appConfig.Shared.ArticleData.clickToAction.REFER:
      case this.appConfig.Shared.ArticleData.clickToAction.SHARE: {
        this.share();
        break;
      }
      default: break;
    }
    if (url) {
      this.open(url, { skipLocationChange, queryParams });
    }
  }

  open(url: any, params?: any): void {
    this.router.navigate([url], params);
  }

  navigationClick(event: any): void {
    if (event.target.tagName === 'ROUTER') {
      event.stopPropagation();
      event.preventDefault();
      event.stopImmediatePropagation();
      this.conn.navigateToURL(event.target.getAttribute('navigateTo'));
    }
  }

  async share(): Promise<any> {
    try {
      let text = '';
      const user = this.conn.getActingUser();
      if (this.shareData.link) {
        text = ` ${this.shareData.text} \n ${this.shareData.link} \n`;
        this.eventLogger.trackEvent('article_share_clicked', {
          mode: 'ARTICLE_SHARE',
          username: user.get('username'),
          article: this.article.id,
          type: 'SHARE',
        });
      } else {
        this.eventLogger.trackEvent('article_refer_and_earn_clicked', {
          mode: 'ARTICLE_REFER_AND_EARN',
          username: user.get('username'),
          article: this.article.id,
          type: 'REFER',
        });
      }
      this.broadcast.broadcast('SHARE', { text, type: 'APP' });
    } catch (err) {
      this.broadcast.broadcast('NOTIFY', { message: err.toString() });
    }
  }

  loadEvents(): any {
    const windowRef = this.windowRef.nativeWindow;
    const doc = windowRef.document;
    const lifestyle = doc.getElementById('lifestyle');
    const diet = doc.getElementById('diet') || (lifestyle && lifestyle.nextElementSibling);
    const name = doc.getElementById('name');
    const mobileNumber = doc.getElementById('mobileNumber');
    const question = doc.getElementById('question');
    const yes = doc.getElementById('yes');
    const no = doc.getElementById('no');

    windowRef.showDiet = (e: any): void => {
      let event = e;
      if (!event) {
        event = { target: doc.getElementById('b') };
      }
      if (diet.style.display === 'none') {
        diet.style.display = 'block';
        event.target.classList.add('selected');
        event.target.previousElementSibling.classList.remove('selected');
        lifestyle.style.display = 'none';
      }
    };

    windowRef.showLifestyle = (e: any): void => {
      let event = e;
      if (!event) {
        event = { target: doc.getElementById('a') };
      }
      if (lifestyle.style.display === 'none') {
        lifestyle.style.display = 'block';
        event.target.classList.add('selected');
        event.target.nextElementSibling.classList.remove('selected');
        diet.style.display = 'none';
      }
    };

    windowRef.yes = (): void => {
      question.style.display = 'none';
      yes.style.display = 'block';
    };

    windowRef.no = (): void => {
      question.style.display = 'none';
      no.style.display = 'block';
    };

    windowRef.changeText = (): void => {
      name.innerText = `Hello ${this.user.get('PatientName')}, spread the love.`;
      mobileNumber.innerText = `${this.user.get('MobileNumber')}`;
    };

    this.windowRef.nativeWindow.openUrl = this.open;
  }

  navigateTo(url: string = ''): void {
    if (!url) return;
    const queryParams: Record<string, any> = {};
    const [URL, qParamString]: string[] = url.split('?');
    if (qParamString) {
      qParamString.split('&').forEach((each: string): void => {
        const [key, value]: string[] = each.split('=');
        queryParams[key] = value;
      });
    }
    this.router.navigate([URL], { queryParams });
  }

  ngOnDestroy(): void {
    if (this.article && this.article.get('cssLink')) this.conn.removeStyle(this.article.get('cssLink'));
    this.currentComponent.remove(this);
  }
}
