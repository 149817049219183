import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { WindowRefService } from '@services/window-ref-service';
import { ConnectionService } from '@services/connection-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { EventLoggerService } from '@services/event-logger-service';
import { DataStoreService } from '@services/data-store-service';
import { AppConfig } from '../../../../../../app.config';

@Component({
  selector: 'article',
  templateUrl: './article.html',
  standalone: false,
})
export class ArticleComponent {
  chat: any;
  loading: boolean = true;
  imageSrc: any;
  url: any;
  regimenArticle: boolean = false;
  @Output() action: EventEmitter<any> = new EventEmitter();
  @Input()
  set data(data: any) {
    this.chat = data;
    if (!this.chat.get('Inputs')) {
      this.chat.set('Inputs', []);
    }
  }

  static parameters: Array<any> = [
    DomSanitizer,
    WindowRefService,
    ConnectionService,
    Router,
    AppWebBridgeService,
    AppConfig,
    EventLoggerService,
    DataStoreService,
  ];
  constructor(private dom: DomSanitizer,
              private window: WindowRefService,
              private conn: ConnectionService,
              private router: Router,
              private appBridge: AppWebBridgeService,
              private appConfig: AppConfig,
              private eventLogger: EventLoggerService,
              private dataStorage: DataStoreService) {
  }

  ngOnInit(): void {
    this.url = (this.chat.get('Metadata').split(';')[0]).split('contentUrl:')[1];
    this.regimenArticle = String(this.url).toLowerCase().includes('regimen');
    this.imageSrc = this.dom.bypassSecurityTrustResourceUrl((this.chat.get('Metadata').split(';')[1]).split('thumbnail:')[1]);
  }

  async openArticle(): Promise<any> {
    const { url }: any = this;
    this.eventLogger.trackEvent('chat_article_clicked', { url, type: 'CHAT_ARTICLE_CLICK' });
    if (this.chat.get('progressReportConfig')) {
      await this.conn.navigateToURL(`/user/report/${this.chat.id}`);
    } else if (url[0] === '/' || url.startsWith(this.conn.getBaseUrl())) {
      await this.conn.navigateToURL(url);
    } else if (url.includes('heallo-regimen-videos')) {
      this.dataStorage.set('VIDEO_URL', url);
      await this.router.navigate(['/video']);
    } else {
      await this.router.navigate(['/iframe'], { queryParams: { src: url } });
    }
  }

  openAction(): void {
    this.action.emit({ chat: this.chat, index: 0 });
  }
}
