import { Component, ViewChild, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { WindowRefService } from '@services/window-ref-service';
import { ConnectionService } from '@services/connection-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'camera',
  templateUrl: './camera.html',
  styleUrls: ['./camera.scss'],
  standalone: false,
})
export class CameraComponent implements OnDestroy, AfterViewInit {
  ui: { photoTaken: boolean, waitForUiGesture: boolean };
  @Output('resultantCanvas') resultantCanvas: EventEmitter<any> = new EventEmitter();
  @Output('close') close: EventEmitter<any> = new EventEmitter();
  @ViewChild('video', { static: false }) video: any;
  @ViewChild('audio', { static: false }) audio: any;
  @ViewChild('canvas', { static: false }) canvas: any;
  canvasCtx: any;
  counter: number;
  user: any;
  interval: any;
  autoFocus: boolean = false;

  constructor(private windowRef: WindowRefService,
    private conn: ConnectionService,
    private event: EventLoggerService,
    private appWebBridge: AppWebBridgeService,
    private broadcast: BroadcastService) {
  }

  ngOnInit(): void {
    this.user = this.conn.getCurrentUser();
    this.ui = { photoTaken: false, waitForUiGesture: false };
    this.counter = 0;
  }

  async ngAfterViewInit(): Promise<any> {
    const hasMediaSupport = (this.windowRef.nativeWindow.navigator.getUserMedia
      || this.windowRef.nativeWindow.navigator.webkitGetUserMedia
      || this.windowRef.nativeWindow.navigator.mozGetUserMedia
      || this.windowRef.nativeWindow.navigator.msGetUserMedia
      || this.windowRef.nativeWindow.navigator.mediaDevices);
    if (!hasMediaSupport) {
      this.event.trackEvent('webcam_not_supported', { username: this.user.get('username') });
      this.showError('Camera Not Supported. Kindly update the Chrome browser');
      return;
    }
    try {
      this.event.trackEvent('requesting_webcam_permissions', { username: this.user.get('username') });
      this.event.trackPeopleIncrement({ people_requesting_android_permissions: 1 });
      const stream: any = await this.windowRef.nativeWindow.navigator.mediaDevices.getUserMedia(
        {
          video: {
            facingMode: 'environment',
            width: { ideal: 1280 },
          },
        });
      this.event.trackEvent('have_android_permissions', { username: this.user.get('username') });
      this.event.trackPeopleIncrement({ people_have_android_permissions: 1 });
      this.video.nativeElement.srcObject = stream;
      await this.startVideo();
    } catch (err) {
      if (this.appWebBridge.isAppWebBridgeLoaded()) {
        return;
      }
      this.showError('Error accessing camera');
      this.event.trackEvent('camera_api_exception', { username: this.user.get('username') });
      this.event.trackEvent('error_opening_camera', { username: this.user.get('username') });
      this.event.trackPeopleIncrement({ people_requesting_android_permissions_failed: 1 });
    }
  }

  async startVideo(): Promise<any> {
    try {
      delete this.ui.waitForUiGesture;
      this.resetCameraOptions();
      this.audio.nativeElement.play();
      await this.video.nativeElement.play();
      this.enableCounterAndTakePhoto();
    } catch (err) {
      this.ui.waitForUiGesture = true;
    }
  }

  resetCameraOptions(): void {
    clearInterval(this.interval);
    this.canvasCtx = this.canvas.nativeElement.getContext('2d');
    this.video.nativeElement.style.visibility = 'visible';
    this.canvas.nativeElement.style.visibility = 'hidden';
    this.canvasCtx.clearRect(0, 0, this.video.nativeElement.videoWidth, this.video.nativeElement.videoHeight);
    this.ui.photoTaken = false;
    this.counter = 8;
  }

  enableCounterAndTakePhoto(): void {
    this.interval = setInterval(async (): Promise<any> => {
      if (this.counter === 0) {
        if (this.windowRef.nativeWindow.navigator.vibrate) {
          this.windowRef.nativeWindow.navigator.vibrate(100);
        }
        this.video.nativeElement.pause();
        this.ui.photoTaken = true;
        this.canvas.nativeElement.width = this.video.nativeElement.videoWidth;
        this.canvas.nativeElement.height = this.video.nativeElement.videoHeight;
        this.canvasCtx.drawImage(
          this.video.nativeElement,
          0,
          0,
          this.video.nativeElement.videoWidth,
          this.video.nativeElement.videoHeight);
        this.sendImage();
        clearInterval(this.interval);
      } else {
        this.counter -= 1;
        await this.audio.nativeElement.play();
      }
    }, 1000);
  }

  showError(message: any): void {
    this.broadcast.broadcast('NOTIFY', { message });
  }

  sendImage(): void {
    this.closeCamera();
    this.resultantCanvas.emit(this.canvas);
  }

  closeCamera(userCancel?: boolean): void {
    const stream = this.video.nativeElement.srcObject;
    if (stream) {
      const tracks = stream.getVideoTracks();
      tracks.forEach((track: any): void => track.stop());
    }
    clearInterval(this.interval);
    this.audio.nativeElement.src = undefined;
    this.video.nativeElement.style.display = 'none';
    this.audio.nativeElement.style.display = 'none';
    if (userCancel) {
      this.close.emit();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.closeCamera();
  }
}
