import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/index';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../app.config';

@Component({
  selector: 'user-checkup',
  templateUrl: './user-checkup.html',
  standalone: false,
})
export class UserCheckupComponent {
  user: any;
  username: any;
  selectedIndex: number;
  tabData: any = { count: 2 };
  toolbarIcons: Array<any> = [];
  experiments: any[];
  showRegimenChat: boolean;
  subscriptions: Subscription[] = [];

  @Input('tabUpdates') tabUpdates: any;
  constructor(private conn: ConnectionService,
    private router: Router,
    private eventLogger: EventLoggerService,
    private route: ActivatedRoute,
    private appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<any> {
    this.subscriptions.push(this.route.queryParams.subscribe(async (params: any): Promise<void> => {
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'checkup' }));
      this.username = params.username;
    }));
  }

  home(): void {
    const queryParams: any = { username: this.username, tab: 'home' };
    this.router.navigate(['/user'], { queryParams });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((each: Subscription): void => each.unsubscribe());
  }
}
