import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'complete-your-order',
  templateUrl: './complete-your-order.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class CompleteYourOrderComponent {
    @Input('productsInCart') productsInCart:any;
    @Input('cartDiscount') cartDiscount:number;
    @Input('totalSp') totalSp:number;
    @Input('levelFourBanners') levelFourBanners: boolean;
    @Input('userDrImage') userDrImage: any;
    @Output('goToCart') goToCart:EventEmitter<void> = new EventEmitter();

    handleClick():void {
      this.goToCart.emit();
    }
}
