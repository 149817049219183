import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'anytime-followup',
  templateUrl: './anytime-followup.html',
  standalone: false,
})
export class AnytimeFollowupComponent implements OnInit {
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public appConfig: AppConfig,
    private bottomSheetRef: MatBottomSheetRef) {}
  doctorProfileImageURL: boolean;
  ngOnInit(): void {
    this.doctorProfileImageURL = this.data.doctorProfileImageURL;
  }

  async handleClick(): Promise<void> {
    if (this.data?.callback) this.data.callback();
    this.closeBottomSheet();
  }

  closeBottomSheet(): void {
    this.bottomSheetRef.dismiss();
  }
}
