import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import SwiperCore, { Scrollbar, Autoplay, SwiperOptions, Pagination, Swiper } from 'swiper';

SwiperCore.use([Autoplay, Scrollbar, Pagination]);
@Component({
  selector: 'app-week1-part-two',
  templateUrl: './week1-part-two.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class Week1PartTwoComponent implements OnChanges {
  carouselConfigWeeklyJourney: SwiperOptions = {
    slidesPerView: 'auto',
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
  };
  @Input('user')user:any;
  @Input('pendingCheckInCall')pendingCheckInCall:any;
  @Output('openInstructions')openInstructions:EventEmitter<string> = new EventEmitter();
  @Output('openStaticArticle1')openStaticArticle1:EventEmitter<string> = new EventEmitter();
  @Output('openStaticArticle2')openStaticArticle2:EventEmitter<string> = new EventEmitter();
  constructor() { }
  ngOnChanges(): void {}
  handleInstructions(card:string):void {
    this.openInstructions.emit(card);
  }
  handleArticle1(card:string):void {
    this.openStaticArticle1.emit(card);
  }
  handleArticle2(card:string):void {
    this.openStaticArticle2.emit(card);
  }
}
