import { Component, ElementRef, Input, NgZone, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { WindowRefService } from '@services/window-ref-service';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { DataStoreService } from '@services/data-store-service';
import { EventLoggerService } from '@services/event-logger-service';
import { TimeService } from '@services/time-service';
import { FollowUpReport } from '../app-constant-types';
import { AppConfig } from '../app.config';

@Component({
  selector: 'user-followup-report',
  templateUrl: './user-followup-report.html',
  styleUrls: ['./user-followup-report.scss'],
  standalone: false,
})

export class UserFollowupReportComponent {
  loading: boolean = true;
  username: string;
  user: any;
  instantCheckups: Array<any> = [];
  hideTitle: boolean = false;
  followupReport: any;
  report: FollowUpReport = { imageConfig: { after: {}, before: {} } };
  comparisonSliders: any[];
  animateProgressBar: boolean = false;
  throttle: boolean;
  interval: any;
  progressBarStages: any[];
  reportStagesLanguageStringId: string[] = Object.values(this.appConfig.Shared.FollowUpReport.Stages);
  isInternalUser: boolean;
  isHelpfull: boolean;
  afterSaveFeedback: boolean;
  feedback: any;
  showFeedbackQuestions: boolean;
  iAmUselessVariable: boolean = false;

  @ViewChild('audio', { static: false }) audio: ElementRef;
  // eslint-disable-next-line new-cap
  @ViewChildren('scrollContainer') scrollContainer: QueryList<ElementRef>;
  // eslint-disable-next-line new-cap
  @ViewChildren('progressBar') progressBar: QueryList<ElementRef>;
  // eslint-disable-next-line new-cap
  @ViewChildren('feedbackSection') feedbackSection: QueryList<ElementRef>;
  @Input('assistantId') assistantId: any;

  constructor(private conn: ConnectionService,
    private broadcast: BroadcastService,
    public route: ActivatedRoute,
    public appConfig: AppConfig,
    private appWebBridge: AppWebBridgeService,
    private window: WindowRefService,
    private commonUtil: CommonUtilityHelper,
    private dataStore: DataStoreService,
    private zone: NgZone,
    private eventLogger: EventLoggerService,
    private timeService: TimeService) {
  }

  async ngOnInit(): Promise<any> {
    this.hideTitle = !!this.assistantId;
    this.isInternalUser = this.conn.isInternalUser();
    this.user = await this.conn.getActingUser();
    this.username = this.user?.get('username');
    try {
      await this.loadDataForV2();
    } catch (error) {
      this.showError(error.message);
      this.broadcast.broadcast('NAVIGATION_BACK');
    }
    this.loading = false;
    if (!this.report.trackingDetails) {
      this.report.trackingDetails = {};
    }
  }

  /**
   * v2 - In version 2, the data is store in FollowupReport table.
   * so params.id is followup report id. We fetch the data and process it.
   */
  async loadDataForV2(): Promise<any> {
    const followUpReportId = this.route.snapshot.params.id;
    this.followupReport = await this.conn.fetchFollowUpReportById(followUpReportId);
    this.report = JSON.parse(JSON.stringify(this.followupReport));
    this.feedback = this.report.feedback || {};
    if (((this.feedback?.isReportSatisfied === undefined)
      || (this.feedback?.isVoiceNoteUnderstandable === undefined)
      || (this.feedback?.doubtsCleared === undefined))
      && this.timeService.differenceInDays(new Date(), new Date(this.report.createdAt)) <= 7) {
      this.showFeedbackQuestions = true;
    }
    if (this.report.secondaryComparisons) {
      this.comparisonSliders = [this.followupReport, ...this.report.secondaryComparisons];
    } else {
      this.comparisonSliders = [this.followupReport];
    }
    await this.fetchProgressBarStageStrings();
    const interval = setInterval((): void => {
      if (this.scrollContainer.first) {
        this.scrollContainer.first.nativeElement.addEventListener('scroll', this.scrollListener());
        clearInterval(interval);
      }
    }, 150);
  }

  /**
   * These are language strings of progress bar stage.
   * eg: Excellent, Good, Bad, ...
   * we fetch the string by id stored in shared file and sort to get the result in same order.
   */
  async fetchProgressBarStageStrings(): Promise<void> {
    if (!this.report.isImageOnlyReport) {
      const stagesLanguageId = this.reportStagesLanguageStringId;
      const stages = await this.conn.getLanguageStringsById(stagesLanguageId);
      const stagesSorted = [];
      stagesLanguageId.forEach((id: string): void => {
        stages.forEach((each: any): void => {
          if (each.id === id) stagesSorted.push(each);
        });
      });
      this.progressBarStages = stagesSorted;
    }
  }

  /**
   * Listens for the scroll and when the treatment progress bar section is reached, it sets the flag to animate the progress.
   * Then it removes the listener. The animations lifetime is only once.
   */
  scrollListener(): (event: any) => any {
    return (event: any): void => {
      if (this.throttle && this.report.trackingDetails.scrollTillBottom) return;
      this.interval = setTimeout((): boolean => this.throttle = false, 150);
      this.throttle = true;
      const scrollContainer = event.target;
      const progressBarPosition = scrollContainer.scrollTop + scrollContainer.clientHeight;
      if (progressBarPosition >= this.progressBar?.first?.nativeElement.offsetTop) {
        this.animateProgressBar = true;
        clearInterval(this.interval);
      }
      if (progressBarPosition >= this.feedbackSection?.first?.nativeElement.offsetTop) {
        if (!this.report.trackingDetails?.scrollTillBottom) {
          this.report.trackingDetails.scrollTillBottom = true;
          this.eventLogger.cleverTapEvent('scrollTillBottom', JSON.stringify({ followupReportId: this.report.objectId,
            pageName: 'checkup' }));
          this.conn.trackVoiceNotesAndScrollEvents({ objectId: this.report.objectId,
            eventName: 'scrollTillBottom',
            trackingTable: 'followUpReport' });
        }
        event.target.removeEventListener('scroll', this.scrollListener());
      }
    };
  }

  findTypeOfReport(): string {
    let type: string;
    if ([this.appConfig.Shared.InstantCheckup.Type.FULL_FACE,
      this.appConfig.Shared.InstantCheckup.Type.SIDE_FACE,
      this.appConfig.Shared.InstantCheckup.Type.LEFT_SIDE_FACE,
      this.appConfig.Shared.InstantCheckup.Type.RIGHT_SIDE_FACE,
      this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE].includes(this.instantCheckups[0].type)) {
      type = this.appConfig.Shared.Regimen.Class.FACE;
    } else if ([this.appConfig.Shared.InstantCheckup.Type.HAIR,
      this.appConfig.Shared.InstantCheckup.Type.HAIR_FRONT,
      this.appConfig.Shared.InstantCheckup.Type.HAIR_TOP].includes(this.instantCheckups[0].type)) {
      type = this.appConfig.Shared.Regimen.Class.HAIR;
    } else type = this.appConfig.Shared.Regimen.Class.BODY;
    return type;
  }

  openConsentForm(): void {
    this.conn.navigateToURL(`/chatV2/${this.report.supportTicket?.objectId}?type=supportTicket&username=${this.username}`
    + `&reportId=${this.report.objectId}`);
  }

  viewRegimen(): void {
    this.conn.navigateToURL(`/user?tab=regimen&class=${this.report.type}&username=${this.username}`);
  }

  async onAudioPlay(): Promise<void> {
    if (this.report.trackingDetails?.audioPlayed) return;
    this.report.trackingDetails.audioPlayed = true;
    this.eventLogger.cleverTapEvent('audioPlayed', JSON.stringify({ followupReportId: this.report.objectId, pageName: 'checkup' }));
    await this.conn.trackVoiceNotesAndScrollEvents({ objectId: this.report.objectId,
      eventName: 'audioPlayed',
      trackingTable: 'followUpReport' });
  }

  async onAudioPlayEnd(): Promise<void> {
    if (this.report?.trackingDetails?.audioFinished) return;
    this.report.trackingDetails.audioFinished = true;
    this.eventLogger.cleverTapEvent('audioFinished', JSON.stringify({ followupReportId: this.report.objectId, pageName: 'checkup' }));
    await this.conn.trackVoiceNotesAndScrollEvents({ objectId: this.report.objectId,
      eventName: 'audioFinished',
      trackingTable: 'followUpReport' });
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  showError(message: any): void {
    this.broadcast.broadcast('NOTIFY', { message });
  }

  async updateFeedback(isHelpfull: boolean): Promise<void> {
    if (this.conn.isInternalUser()) return;
    this.isHelpfull = isHelpfull;
    this.followupReport.set('feedback', { feedback: isHelpfull });
    await this.followupReport.save();
    this.afterSaveFeedback = true;
  }

  async saveFeedback(key: string, value: boolean): Promise<void> {
    if (this.conn.isInternalUser()) return;
    this.feedback[key] = value;
    this.followupReport.set('feedback', this.feedback);
    try {
      await this.followupReport.save();
      if ((this.feedback?.isReportSatisfied !== undefined)
        && (this.feedback?.isVoiceNoteUnderstandable !== undefined)
        && (this.feedback?.doubtsCleared !== undefined)) {
        this.afterSaveFeedback = true;
      }
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.message });
    }
  }

  async trackEvent(action: any): Promise<void> {
    switch (action) {
      case 'play': {
        await this.onAudioPlay();
        break;
      }
      case 'completed': {
        await this.onAudioPlayEnd();
        break;
      }
      default:
    }
  }
}
