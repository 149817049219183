import { Directive } from '@angular/core';

@Directive({
  selector: 'regimen-offer-product-title',
  host: {
    class: 'tw-font-body tw-font-bold tw-text-700 tw-text-teal-600',
  },
  standalone: false,
})
export class RegimenOfferProductTitleDirective {

}
