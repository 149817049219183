import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BroadcastService } from '@services/broadcast-service';

@Component({
  selector: 'i-frame',
  templateUrl: './iframe.html',
  standalone: false,
})
export class IframeComponent {
  src: any;
  loading: boolean = true;
  title: any;
  static parameters: any = [ActivatedRoute, DomSanitizer, BroadcastService];
  constructor(
    private router: ActivatedRoute,
    private dom: DomSanitizer,
    private broadcast: BroadcastService,
  ) {}

  ngOnInit(): void {
    this.router.queryParams.subscribe((params: any): void => {
      this.title = params.title || 'CureSkin';
      if (!params.src) this.broadcast.broadcast('NAVIGATION_BACK');
      else this.src = this.dom.bypassSecurityTrustResourceUrl(params.src);
    });
  }

  menuAction(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  loaded(): any {
    this.loading = false;
  }
}
