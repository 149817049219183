import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-quiz-banner',
  templateUrl: './quiz-banner.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class QuizBannerComponent implements OnChanges {
@Input('userStatus')userStatus:any;
@Input('showGameBanner')showGameBanner:any;
@Output('openGame')openGame:EventEmitter<void> = new EventEmitter();
constructor() { }
ngOnChanges(): void {}
handleClick():void {
  this.openGame.emit();
}
}
