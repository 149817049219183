import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'regimen-concern-selection',
  templateUrl: './regimen-concern-selection.html',
  standalone: false,
})

export class RegimenConcernSelectionComponent implements OnInit {
  @Input('class') set setConcernClass(concernClass: string) {
    this.selectedConcernClass = concernClass;
  }
  @Input('gender') gender: string = 'female';
  @Output('onSelect') onSelect: EventEmitter<string> = new EventEmitter<string>();
  selectedConcernClass: string;
  faceIconUrl: string;
  hairIconUrl: string;

  constructor(public appConfig: AppConfig,
    readonly conn: ConnectionService,
    private renderer: Renderer2) { }

  async ngOnInit(): Promise<void> {
    await this.setIconUrls();
  }

  onSelectConcern(concernClass: string): void {
    this.selectedConcernClass = concernClass;
    this.onSelect.emit(this.selectedConcernClass);
  }

  async setIconUrls(): Promise<void> {
    if (this.gender === this.appConfig.Shared.Gender.MALE) {
      this.faceIconUrl = 'https://cdn.cureskin.com/app/img/rebranded-male-face.svg';
      this.hairIconUrl = 'https://cdn.cureskin.com/app/img/rebranded-male-hair.svg';
    } else {
      this.faceIconUrl = 'https://cdn.cureskin.com/app/img/rebranded-female-face.svg';
      this.hairIconUrl = 'https://cdn.cureskin.com/app/img/rebranded-female-hair.svg';
    }
  }
}
