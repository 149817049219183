import { ChangeDetectionStrategy, Component, Inject, InjectionToken, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export const ICON_PATH = new InjectionToken<string>('icon-path');

@Component({
  selector: 'cs-icon',
  template: `
    <mat-icon style="height: unset; width: unset;"
          [svgIcon]="iconName" *ngIf="!isCdnIcon"></mat-icon>
    <img [src]="cdnIconUrl" *ngIf="isCdnIcon">
  `,
  styles: [':host {display: inline-flex;}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class IconComponent {
  private name: string = '';
  isCdnIcon: boolean = false;
  cdnIconUrl: SafeResourceUrl | null = null;
  @Input() set iconName(icon: string) {
    if (!icon) return;
    this.name = icon;
    this.isCdnIcon = this.isValidUrl(icon);
    if (this.isCdnIcon) {
      this.cdnIconUrl = icon;
    } else {
      this.registerIcon(icon);
    }
  }
  get iconName(): string {
    return this.name;
  }

  private iconPath: string = '';

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    // eslint-disable-next-line new-cap
    @Inject(ICON_PATH) iconPath: string,
  ) {
    this.iconPath = iconPath;
  }

  registerIcon(icon: string): void {
    const path = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/${icon}.svg`);
    this.iconRegistry.addSvgIcon(icon, path);
  }

  private isValidUrl(url: string): boolean {
    try {
      return Boolean(new URL(url));
    } catch {
      return false;
    }
  }
}
