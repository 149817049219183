import { Component, Output, Input, OnInit, EventEmitter } from '@angular/core';
import { TimeService } from '@services/time-service';

@Component({
  selector: 'order-received',
  templateUrl: './order-received.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class OrderReceivedComponent implements OnInit {
  @Input('order') order: any;
  @Input('isRebrandingBanner') isRebrandingBanner: boolean;
  @Output('addEvents') addEvents: EventEmitter<any> = new EventEmitter();
  orderPlacedDate: any;
  showNewOrderPlacedContent:boolean = false;
  constructor(
    private readonly timeService: TimeService,
  ) { }
  ngOnInit(): void {
    this.orderPlacedDate = this.order.get('actualCreatedAt');
    const differenceOfOrderPlacedAndCurrentDate = this.timeService.differenceInDays(new Date(), this.orderPlacedDate);
    if (differenceOfOrderPlacedAndCurrentDate > 2) this.showNewOrderPlacedContent = true;
  }

  handleClick(): void {
    this.addEvents.emit();
  }
}
