import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-notification',
  template: '<div [ngClass]=\'{"open": messageText}\'> {{messageText}} </div>',
  standalone: false,
})
export class NotificationComponent {
  messageText: any = '';
  interval: any;
  @Input('message')
  set message(text: string) {
    if (!text) return;
    this.openSnackBar(text);
  }

  @Output('messageChange') messageChange: EventEmitter<string> = new EventEmitter();

  ngOnInit(): void {}

  openSnackBar(message: string): void {
    clearInterval(this.interval);
    if (message.toLowerCase().includes('unable to connect to the parse api')) {
      this.messageText = 'Please check the internet connectivity.';
    } else this.messageText = message.substring(0, 150);
    this.interval = setTimeout((): void => {
      this.messageText = '';
      this.messageChange.emit();
    }, 3000);
  }
}
