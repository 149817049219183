import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { AovSaleComponent } from './aov-sale.component';

@NgModule({
  imports: [
    CommonModule,
    CsIconModule,
  ],
  declarations: [AovSaleComponent],
  exports: [AovSaleComponent],
})
export class AovSaleModule {}
