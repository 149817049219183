import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'photo-blur',
  templateUrl: './photo-blur.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class PhotoBlurComponent implements OnInit {
  @Output('startFollowUp') startFollowUp: EventEmitter<any> = new EventEmitter();
  constructor(
    public appConfig: AppConfig,
  ) { }

  ngOnInit(): void {
  }

  handleClick(): void {
    this.startFollowUp.emit('WAITING_FOR_IMAGE_BANNER_CLICKED');
  }
}
