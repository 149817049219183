import { Component, EventEmitter, Output } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from 'client/app/app.config';
import { BroadcastService } from '@services/broadcast-service';
import { Router } from '@angular/router';
import { SKIP_HAIR_TREE_REGIMEN } from 'e2e/src/shared/constants';

@Component({
  selector: 'hair-dandruff-banner',
  templateUrl: './hair-dandruff-regimen.component.html',
  standalone: false,
})
export class HairDandruffRegimenBannerComponentComponent {
  user: any;
  showBanner: boolean = false;
  regimens: any;
  activeFaceRegimen: any;
  activeHairRegimen: any;
  skipHairCrossSellBannerTree: boolean = false;
  experiments: Array<any> = [];
  constructor(
    private eventLogger: EventLoggerService,
    private conn: ConnectionService,
    public appConfig: AppConfig,
    private broadcast: BroadcastService,
    private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
    if (this.user.get('Gender') === 'Male') return;
    const acneConcern = ['ACNE', 'ACNE_OR_PIMPLES', 'INFLAMMATORY_ACNE', 'COMEDONES'];
    const newMom = ['PREGNANT', 'NEW_MOM'];
    const regimens = await this.conn.fetchRegimens(null, true);
    this.regimens = regimens.filter((regimen: any): any => !regimen.expired && regimen.active);
    this.activeHairRegimen = this.regimens.find((regimen: any):any => regimen.delivered && regimen.class
    === this.appConfig.Shared.Regimen.Class.HAIR);
    this.activeFaceRegimen = this.regimens.find((regimen: any):any => regimen.delivered && regimen.class
    === this.appConfig.Shared.Regimen.Class.FACE);
    if (this.activeFaceRegimen && !this.activeHairRegimen
      && acneConcern.some((concern: any):any => this.user.get('PrivateMainConcern') === concern)
      && !newMom.some((tag: string):any => this.user.get('marketingTags').includes(tag))
    ) {
      this.showBanner = true;
    }
    this.experiments = await this.conn.findUserActiveExperiments();
    const experiment = this.experiments.find((experiment: any): any => experiment.key === 'skip_hair_cross_sell_banner_tree');
    if (experiment) {
      this.skipHairCrossSellBannerTree = true;
    }
  }

  async buyNowRegimen(): Promise<void> {
    try {
      await this.conn.allocateRegimen(SKIP_HAIR_TREE_REGIMEN, {});
      this.navigateTo('/user?tab=regimen&class=HAIR');
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.toString() });
    }
  }

  handleClick(): void {
    this.buyNowRegimen();
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'homepage-hair-fall-cross-sell-male-banner' }));
  }

  navigateTo(url: string = ''): void {
    if (!url) return;
    const queryParams: Record<string, any> = {};
    const [URL, qParamString]: string[] = url.split('?');
    if (qParamString) {
      qParamString.split('&').forEach((each: string): void => {
        const [key, value]: string[] = each.split('=');
        queryParams[key] = value;
      });
    }
    this.router.navigate([URL], { queryParams });
  }
}
