import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { HairVariantServices, VariantServices } from 'e2e/src/shared/variant-services';
import { LocalStorageService } from '@services/local-storage-service';
import { AppConfig } from 'client/app/app.config';

const VARIANT_ID = {
  variant_999: 'variant_999',
  variant_4999: 'variant_4999',
};

@Component({
  selector: 'choose-plan-info',
  templateUrl: './choose-plan-info.html',
  styleUrls: [],
  standalone: false,
})

export class ChoosePlanInfoComponent {
  tabData: any = { count: 3, selectedTab: 0 };
  planInfo: Array<string> = ['advance', 'pro', 'assist'];
  regimenClass: any = 'FACE';
  regimenPlan: any = 'advance';
  variantServices: any = VariantServices;
  hairVariants: any = HairVariantServices;
  isStandAlone: boolean = false;
  loading: boolean = false;

  @Input('selectedPlan') selectedPlan: string = 'advance';
  user: any;
  userLanguage: string;
  upsellPlans: Array<string> = ['introductory', 'advance', 'pro', 'assist'];
  standAlonePlans: Array<string> = ['introductory', 'advance'];
  regimenCoverImage: string = '';
  variantsInfo: any;
  isRegimenNewPropositionUI: boolean = false;
  optedForDoctorCall: boolean = false;
  regimenNewPropositionData: {
    regimenId: string,
    variantId: string,
    totalSellingPrice: number,
    productsCount: number,
    doctorImageUrl: string
  } = {
      regimenId: '',
      variantId: '',
      totalSellingPrice: 0,
      productsCount: 0,
      doctorImageUrl: '',
    };

  constructor(
    private conn: ConnectionService,
    private route: ActivatedRoute,
    public appConfig: AppConfig,
    private router: Router,
    private localStorage: LocalStorageService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.user = this.conn.getActingUser();
    this.route.queryParams.subscribe((params: any): void => {
      this.regimenClass = params.class;
      this.regimenPlan = params.plan;
      this.selectedPlan = this.regimenPlan;
      if (params.isStandAlone === 'true') this.isStandAlone = true;
      if (params.isRegimenNewPropositionUI === 'true') this.isRegimenNewPropositionUI = true;
      if (params.optedForDoctorCall === 'true') this.optedForDoctorCall = true;
      if (params.regimenId) this.regimenNewPropositionData.regimenId = params.regimenId;
      if (params.doctorImageUrl) this.regimenNewPropositionData.doctorImageUrl = params.doctorImageUrl;
    });
    this.variantsInfo = await this.getAndProcessVariantsInfo();
    this.userLanguage = this.user.get('languagePreference') || this.appConfig.Shared.Languages.EN;
    if (this.regimenPlan === 'introductory' && this.variantsInfo?.length === 4) {
      if (this.isStandAlone) this.tabData = { count: 2 };
      else this.tabData = { count: 4 };
      this.tabData.selectedTab = 0;
    } else if (this.isStandAlone) {
      this.tabData = { count: 2 };
      this.tabData.selectedTab = this.standAlonePlans.indexOf(this.regimenPlan);
    } else {
      const index = this.planInfo.indexOf(this.regimenPlan);
      this.tabData.selectedTab = index !== -1 ? index : 0;
    }
    await this.updateRegimenIcons();
    this.updateRegimenPropositionData(this.tabData.selectedTab);
    this.loading = false;
  }

  async updateRegimenIcons(): Promise<void> {
    if (!this.variantsInfo) return;
    // If not introductory plan, skip the first index
    let variantIndex = this.tabData?.selectedTab || 0;
    if (this.regimenPlan !== 'introductory' && this.variantsInfo?.length === 4) variantIndex = this.tabData.selectedTab + 1 || 0;
    const { combinedProductIds }: any = this.variantsInfo[variantIndex];
    const mediaLinkObj = await this.conn.fetchImageFromMediaLink(combinedProductIds);
    this.regimenCoverImage = mediaLinkObj[0]?.get('link');
  }

  async getAndProcessVariantsInfo(): Promise<void> {
    const regimens = await this.conn.fetchRegimens(null, true);
    const regimenInfo = regimens.find((each: any): boolean => each.class === this.regimenClass);
    regimenInfo?.variants.sort((variant1: any, variant2: any): Number => variant1.fixedPrice - variant2.fixedPrice);
    return regimenInfo?.variants;
  }

  back(): void {
    this.router.navigate(['/user'], {
      queryParams: { tab: 'regimen', class: this.regimenClass || 'FACE', init: 'true' },
      replaceUrl: true,
    });
  }

  async tabChange(index: any): Promise<void> {
    if (this.tabData?.count === 4) {
      this.selectedPlan = this.upsellPlans[index];
    } else if (this.tabData?.count === 2) {
      this.selectedPlan = this.standAlonePlans[index];
    } else {
      this.selectedPlan = this.planInfo[index];
    }
    this.updateRegimenIcons();

    if (this.isRegimenNewPropositionUI) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          plan: this.selectedPlan,
          optedForDoctorCall: this.optedForDoctorCall,
        },
        queryParamsHandling: 'merge',
      });
      this.updateRegimenPropositionData(index);
      this.updateRegimenKitInfo();
      this.handleMandatoryVariantCall();
    }
  }

  updateRegimenPropositionData(index: number): void {
    try {
      const variantsInfoIndex = this.regimenPlan !== 'introductory' && this.variantsInfo?.length === 4 ? index + 1 : index;
      this.regimenNewPropositionData.variantId = this.variantsInfo[variantsInfoIndex].variantId;
      this.regimenNewPropositionData.totalSellingPrice = this.variantsInfo[variantsInfoIndex].fixedPrice;
      this.regimenNewPropositionData.productsCount = this.variantsInfo[variantsInfoIndex].products.length;
    } catch (err) {
      // Handle error
    }
  }

  async updateRegimenKitInfo(): Promise<void> {
    try {
      await this.conn.updateRegimenInfo(this.regimenNewPropositionData.regimenId, this.regimenNewPropositionData.variantId);
    } catch (err) {
      // Handle error
    }
  }

  // Note: This block is for 2999 variant (variant ID: variant_4999) mandatory call
  // Please remove this once backend solution is implemented
  // If the user has opted for doctor call, no change needed
  async handleMandatoryVariantCall(): Promise<void> {
    try {
      const mandatoryVariantCall = this.regimenNewPropositionData.variantId === VARIANT_ID.variant_4999;
      if (!this.optedForDoctorCall) {
        await this.conn.optForRegimenDoctorCall(this.regimenNewPropositionData.regimenId, mandatoryVariantCall);
        this.optedForDoctorCall = mandatoryVariantCall;
        if (mandatoryVariantCall) {
          this.user.set('hasUserOptedForCall', false);
          await this.user.save();
        }
      } else {
        const hasUserOptedForCall = this.user?.get('hasUserOptedForCall') || false;
        const is999Variant = this.regimenNewPropositionData.variantId === VARIANT_ID.variant_999;
        if ((!hasUserOptedForCall && !mandatoryVariantCall) || is999Variant) {
          await this.conn.optForRegimenDoctorCall(this.regimenNewPropositionData.regimenId, false);
          this.optedForDoctorCall = false;
        }
      }
    } catch (err) {
      // Handle error
    }
  }
}
