import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'order-list-sheet',
  templateUrl: './order-list-sheet.html',
  styleUrls: [],
  standalone: false,
})
export class OrderListSheetComponent implements OnInit {
  selectedOrder: string = '';
  orderInTransitStages: any[] = [...this.appConfig.Shared.Order.Stage.orderPackedStages,
    ...this.appConfig.Shared.Order.Stage.orderPlacedStages,
    ...this.appConfig.Shared.Order.Stage.orderDispatchedStages,
    ...this.appConfig.Shared.Order.Stage.orderDeliveryFailedStages,
    ...this.appConfig.Shared.Order.Stage.orderOutForDeliveryStages];
  orderDeliveredStages: any[] = [...this.appConfig.Shared.Order.Stage.orderDeliveredStages];
  orderCanceledStages: any[] = [...this.appConfig.Shared.Order.Stage.orderCanceledStages];

  maxImages: number = 5;
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  public appConfig: AppConfig,
  private bottomSheetRef: MatBottomSheetRef,
  private eventLogger: EventLoggerService,
  ) { }

  ngOnInit(): void {
    this.setStageOfOrder();
    this.eventLogger.cleverTapEvent('popUpView', JSON.stringify({ name: 'order-list-popup', pageName: 'order-tree-list' }));
  }

  setStageOfOrder(): void {
    this.data.content = this.data.content.map((element: any): any => {
      if (this.orderInTransitStages.includes(element.stage)) {
        return { ...element, stage: 'In Transit' };
      }
      if (this.orderDeliveredStages.includes(element.stage)) {
        return { ...element, stage: 'Delivered' };
      }
      if (this.orderCanceledStages.includes(element.stage)) {
        return { ...element, stage: 'Canceled' };
      }
      return element;
    });
  }

  async openNativePopup(): Promise<void> {
    const matchingOrder = this.data.content.find((order: any): any => order.orderId === this.selectedOrder);
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'order-list-popup-continue' }));
    const json = {
      type: 'multi_option_single_select',
      clickOnYes: true,
      options: [matchingOrder],
    };
    if (this.data?.callback) this.data.callback(json);
    this.bottomSheetRef.dismiss();
  }

  closedPopup(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'order-list-popup-closed' }));
    this.bottomSheetRef.dismiss();
  }

  mayBeLater(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'notification-popup-maybe-later' }));
    this.bottomSheetRef.dismiss();
  }
  getVisibleImages(images: string[]): string[] {
    return images.slice(0, this.maxImages);
  }
  getImagesAndCount(order: any): { visibleImages: string[]; noImageCount: number } {
    const imagesWithValues = order.images?.filter(Boolean) || [];
    const noImageCount = (order.images?.length || 0) - imagesWithValues.length;

    return {
      visibleImages: this.getVisibleImages(imagesWithValues),
      noImageCount,
    };
  }

  hasAnyImages(order: any): boolean {
    return order.images?.some(Boolean) || false;
  }
}
