import { Directive, HostListener, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[beforeBottomView]',
  standalone: false,
})
export class BeforeBottomViewDirective {
  @Input() beforeBottomView: Function;
  el: ElementRef;

  static parameters: any = [ElementRef];
  constructor(el: ElementRef) {
    this.el = el;
  }

  @HostListener('scroll', ['$event'])
  confirmFirst(): void {
    const elm = this.el.nativeElement;
    const threshold = 150;
    if (elm.scrollTop + elm.offsetHeight + threshold >= elm.scrollHeight) {
      this.beforeBottomView();
    }
  }
}
