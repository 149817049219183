import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { fromSelectors } from '@store/selectors';
import { Store } from '@ngrx/store';
import { DomSanitizer } from '@angular/platform-browser';
import { EventLoggerService } from '@services/event-logger-service';
import { BroadcastService } from '@services/broadcast-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'support-component-selection',
  templateUrl: './support-component-selection.html',
  styleUrls: ['./support-component-selection.scss'],
  standalone: false,
})
export class SupportComponentSelectionComponent implements OnInit, OnDestroy {
  @Input('question')
  set Question(question: any) {
    this.question = question;
    this.initializeQuestionMap();
  }
  @Input('questions')
  set Questions(questions: Array<any>) {
    this.questions = questions;
    this.initializeQuestionMap();
  }
  @Input('newSupportQuestionId') newSupportQuestionId: string;
  @Input('loading') inProcess: boolean;
  experiments$: Observable<any[]> = this.store.select(fromSelectors.selectExperiments);

  question: any;
  questions: Array<any>;
  subscriptions: Array<Subscription> = [];
  user: any;
  userLanguage: string;
  questionMap: { [key: number]: { expanded: boolean; questions?: Array<any>; question?: any; inProcess: boolean; } } = {};

  queryParams: Params;
  constructor(
    public domSanitizer: DomSanitizer,
    public appConfig: AppConfig,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private connectionService: ConnectionService,
    private eventLoggerService: EventLoggerService,
    private activatedRoute: ActivatedRoute,
    private broadcastService: BroadcastService,
  ) {
    this.queryParams = { ...this.route.snapshot.queryParams };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((each: Subscription): void => each.unsubscribe());
  }

  ngOnInit(): void {
    this.user = this.connectionService.getActingUser();
  }

  async openQuestion(question: Record<string, any>, index: number): Promise<void> {
    this.eventLoggerService.trackEvent('SUPPORT_QUESTION_CLICK', { id: question.id });
    if (question.objectId === '8L9eKnSUPe') {
      await this.submitQuery('8L9eKnSUPe');
      return;
    }
    switch (question.listViewType) {
      case 'Expand': {
        Object.keys(this.questionMap).forEach((key: string, i: number): void => {
          if (index === i) {
            return;
          }
          this.questionMap[key].expanded = false;
        });
        this.questionMap[index].expanded = !this.questionMap[index].expanded;
        return;
      }
      case 'Next':
      default: {
        await this.openQuestionNext(question);
      }
    }
  }

  private async openQuestionNext(question: Record<string, any>): Promise<void> {
    const queryParams = { ...this.route.snapshot.queryParams };
    if (question.outputType === this.appConfig.Shared.Support.outputType.COMPONENT) {
      if (question.outputComponent === this.appConfig.Shared.Support.component.TREE) {
        const { supportTicketId }: { supportTicketId: string } = await this.connectionService
          .createTicket(this.newSupportQuestionId ? this.newSupportQuestionId : question.objectId, undefined, queryParams);
        await this.router.navigate([`/support/ticket/${supportTicketId}`], { queryParams, replaceUrl: true });
        return;
      }
      await this.router.navigate([`/support/${question.objectId}`], { queryParams });
    } if (question.outputType === this.appConfig.Shared.Support.outputType.URL) {
      await this.connectionService.navigateToURL(question.outputUrl);
    }
  }

  private initializeQuestionMap(): void {
    if (!this.question || !this.questions) {
      return;
    }
    if (this.question.outputComponent !== this.appConfig.Shared.Support.component.SELECT_SUB_QUERY) {
      return;
    }
    this.questions.forEach((question: any, index: number): void => {
      if (!this.questionMap[index]) {
        this.questionMap[index] = { expanded: false, inProcess: false, questions: [], question: this.questions[index] };
      }
    });
  }

  async viewAllQuery(): Promise<void> {
    await this.connectionService
      .navigateToURL(`/support/tickets?username=${this.activatedRoute.snapshot.queryParams.username || this.user.get('username')}`);
  }

  async submitQuery(questionId: string): Promise<void> {
    let newQuestionId = questionId;
    if (questionId === '8L9eKnSUPe' && this.newSupportQuestionId) {
      newQuestionId = this.newSupportQuestionId;
    }
    if (this.inProcess) return;
    this.inProcess = true;
    try {
      const { supportTicketId }: { supportTicketId: string } = await this.connectionService.createTicket(newQuestionId);
      await this.router.navigate([`/support/ticket/${supportTicketId}`], { queryParams: this.queryParams, replaceUrl: true });
    } catch (error) {
      this.broadcastService.broadcast('NOTIFY', { message: error.toString() });
    } finally {
      this.inProcess = false;
    }
  }
}
