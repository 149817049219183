import { EnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { WindowRefService } from '@services/window-ref-service';

type initFn = (
  windowRefService: WindowRefService,
  connectionService: ConnectionService,
  eventLoggerService: EventLoggerService
) => () => Promise<void>;

export const initApp: initFn = (
  windowRefService: WindowRefService,
  connectionService: ConnectionService,
  eventLoggerService: EventLoggerService,
): (() => Promise<void>) => async (): Promise<void> => {
  // Initialize the app
  connectionService.initialize();
  eventLoggerService?.trackEvent('web_app_loaded');
  if (connectionService?.getActingUser()) {
    eventLoggerService?.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'web_app_loaded' }));
  }

  const url = windowRefService.nativeWindow.location.href;
  const queryString = url.split('?')[1] || '';
  const queryParams: any = Object.fromEntries(new URLSearchParams(queryString));

  await connectionService.updateActingUser(queryParams);
};

export const appInitializerProvider: EnvironmentProviders = provideAppInitializer((): any => {
  const windowRefService: WindowRefService = inject(WindowRefService);
  const connectionService: ConnectionService = inject(ConnectionService);
  const eventLoggerService: EventLoggerService = inject(EventLoggerService);

  const initializerFn = initApp(windowRefService, connectionService, eventLoggerService);
  return initializerFn();
});
