import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConnectionService } from '@services/connection-service';

@Component({
  selector: 'app-explore-contact-banners',
  templateUrl: './explore-contact-banners.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class ExploreContactBannersComponent implements OnInit {
  @Input('userStatus') userStatus: any;
  @Input('user') user: any;
  @Output('optedForSupport') optedForSupport: EventEmitter<string> = new EventEmitter();
  @Output('exploreSectionRedirect') exploreSectionRedirect: EventEmitter<void> = new EventEmitter();
  isExploreSectionExperimentOn: boolean = false;

  constructor(public conn: ConnectionService) { }

  async ngOnInit(): Promise<void> {
    const experiments = await this.conn.findUserActiveExperiments();
    experiments.forEach((exp: any): void => {
      if (exp.key === 'explore_tab') {
        this.isExploreSectionExperimentOn = true;
      }
    });
  }

  handleSupport(param: string): void {
    this.optedForSupport.emit(param);
  }

  handleExplore(): void {
    this.exploreSectionRedirect.emit();
  }
}
