import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-referral-banner',
  templateUrl: './referral-banner.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class ReferralBannerComponent implements OnInit {
@Input('userStatus')userStatus:any;
@Output('referBannerClick')referBannerClick:EventEmitter<void> = new EventEmitter();
constructor() { }

ngOnInit(): void {}
handleClick():void {
  this.referBannerClick.emit();
}
}
