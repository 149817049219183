import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { WindowRefService } from '@services/window-ref-service';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'app-checkup-view-container',
  templateUrl: './checkup-view-container.component.html',
  styles: [':host {@apply tw-block tw-h-full; }'],
  standalone: false,
})
export class CheckupViewContainerComponent implements OnInit {
  loading: boolean = true;
  user: any;
  experiments: any[];
  showInstantCheckupV1: boolean = false;
  isFromListView: boolean = false;
  instantCheckup: any;

  constructor(
    public appConfig: AppConfig,
    private route: ActivatedRoute,
    private conn: ConnectionService,
    private windowRef: WindowRefService,
    private eventLogger: EventLoggerService,
  ) { }

  async ngOnInit(): Promise<any> {
    this.user = this.conn.getActingUser();
    this.isFromListView = this.route.snapshot.queryParams.fromList;
    this.experiments = await this.conn.findUserActiveExperiments();
    this.experiments.forEach((experiment: any): void => {
      if (experiment.key === 'instant_checkup_with_slider') this.showInstantCheckupV1 = true;
    });
    this.loading = false;

    const instantCheckupId: string = this.route.snapshot.params.id;
    let queryUsername: string | null = null;
    if (this.route.snapshot.queryParams.username) queryUsername = this.route.snapshot.queryParams.username;

    const timeBefore = this.windowRef.nativeWindow.performance.now();
    [this.instantCheckup] = await this.conn.fetchInstantCheckup({
      ...(queryUsername && { userId: queryUsername }),
      id: [instantCheckupId],
    });
    const timeAfter = this.windowRef.nativeWindow.performance.now();

    // TODO: Remove once AI responce send INVALID type for hair front photo where no landmarks are detected.
    if (this.instantCheckup.type === this.appConfig.Shared.InstantCheckup.Type.HAIR_FRONT
      && !this.instantCheckup?.aiResponse?.fb_landmarks?.CHIN_GNATHION?.length) {
      this.showInstantCheckupV1 = false;
    }

    if (this.instantCheckup.type === this.appConfig.Shared.InstantCheckup.Type.HAIR_FRONT) {
      this.eventLogger.trackEvent('instant_checkup_api_call', { username: this.user.get('username'), time: timeAfter - timeBefore });
    }
  }
}
