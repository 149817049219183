import { Directive } from '@angular/core';

@Directive({
  selector: 'regimen-offer-product-caption',
  host: {
    class: 'tw-font-body tw-text-300 tw-text-black',
  },
  standalone: false,
})
export class RegimenOfferProductCaptionDirective {

}
