import { Component, Input, Output, EventEmitter } from '@angular/core';

const REGIMEN_IMG_BACKGROUND = {
  variant_1499: 'tw-bg-teal-100',
  variant_4999: 'tw-bg-blue-100',
  variant_999: 'tw-bg-teal-100',
  variant_1999: 'tw-bg-orange-100',
};

@Component({
  selector: 'app-voice-note-banner',
  templateUrl: './voice-note-banner.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class VoiceNoteBannerComponent {
@Input('docImg') docImg:string;
@Input('voiceUrl') voiceUrl:string;
@Input('trackEventAudio') trackEventAudio:any;
@Input('OrderApproval') OrderApproval:any;
@Output('trackVoiceEvent') trackVoiceEvent:EventEmitter<any> = new EventEmitter();
@Input('swiperCardsBanner') swiperCardsBanner:any;
@Input('newHomeVoiceNoteBannerExperiment') newHomeVoiceNoteBannerExperiment:boolean;
@Input('message') message:string;
@Input('isDoctorVerifiedKit') isDoctorVerifiedKit?: boolean;
@Input('regimen') regimen?: any;
@Input('doctorName') doctorName?: string;
@Input('kitImageUrl') kitImageUrl?: string;
regimenCoverBgColor: string = '';

ngOnInit(): void {
  this.setRegimenCoverBackgroundColor();
}

handleClick(event:any):void {
  this.trackVoiceEvent.emit(event);
}

setRegimenCoverBackgroundColor(): void {
  const regimenVariantId = this?.regimen?.selectedVariantId;
  this.regimenCoverBgColor = REGIMEN_IMG_BACKGROUND[regimenVariantId] || REGIMEN_IMG_BACKGROUND.variant_1499;
}
}
