/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { BroadcastService } from '@services/broadcast-service';
import { WindowRefService } from '@services/window-ref-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { LocalStorageService } from '@services/local-storage-service';
import { ApiClientConstant } from '@cureskin/api-client/src/constant/api-client.constant';
import { fromActions } from '../actions';
import { fromSelectors } from '../selectors';
import { AppConfig } from '../../app.config';

@Injectable()
export class OrderEffects {
  constructor(private actions$: Actions,
    private conn: ConnectionService,
    private store: Store,
    private router: Router,
    private eventLogger: EventLoggerService,
    private broadcastService: BroadcastService,
    private windowRef: WindowRefService,
    private appConfig: AppConfig,
    private localStorageService: LocalStorageService,
    private appWebBridgeService: AppWebBridgeService) {
  }

  orderSuccessBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.OrderSuccessBegin),
      concatLatestFrom((): any => [this.store.select(fromSelectors.selectQueryParams),
        this.store.select(fromSelectors.selectExperiments)]),
      map(async ([, params, experiments_]: any[]): Promise<void> => {
        let experiments = experiments_;
        if (!experiments?.length) experiments = await this.conn.findUserActiveExperiments();
        const { paymentId, amount, orderId, orderType }: any = params;
        this.eventLogger.trackEvent('ORDER_SUCCESS_PAGE', { orderType, orderId, amount });
        this.eventLogger.trackInParse('ORDER_PLACED', ApiClientConstant.Event.Type.ORDER);
        this.store.dispatch(fromActions.ConfigUpdate({ referralCashValue: this.appConfig.Shared.REFERRAL_CASH_VALUE }));
        await this.processRegimenOrder(orderId, orderType, experiments);
        if (!paymentId) return;
        this.eventLogger.trackEvent('payment_success', { paymentId, type: 'PAYMENT_SUCCESS' });
      }),
    );
  }, { dispatch: false });

  orderPaymentFetchBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.OrderFetchPaymentBegin),
      concatLatestFrom((): any => this.store.select(fromSelectors.selectQueryParams)),
      exhaustMap(async ([_, params]: any[]): Promise<any> => this.conn.findPaymentById(params.paymentId)),
      map((payment: any): any => {
        this.store.dispatch(fromActions.OrderStateUpdate({ payment }));
        this.processPayment(payment);
      }),
      catchError((error: any, caught: Observable<unknown>): Observable<unknown> => {
        return caught;
      }),
    );
  }, { dispatch: false });

  orderRetryCodBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.OrderRetryInCodMode),
      concatLatestFrom((): any => this.store.select(fromSelectors.selectOrderPayment)),
      exhaustMap(async ([, payment]: any[]): Promise<any> => {
        this.store.dispatch(fromActions.OrderStateUpdate({ inProcess: true }));
        await this.placeCodOrder(payment);
        return fromActions.OrderStateUpdate({ inProcess: false });
      }),
      catchError((error: any, caught: Observable<unknown>): Observable<unknown> => {
        this.eventLogger.trackEvent('place_cod_order_after_online_failure_failed');
        this.store.dispatch(fromActions.OrderStateUpdate({ inProcess: false }));
        this.store.dispatch(fromActions.OrderRetryOnlinePayment());
        this.showError(error.toString());
        return caught;
      }),
    );
  });

  orderRetryOnlinePayment$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.OrderRetryOnlinePayment),
      concatLatestFrom((): any => this.store.select(fromSelectors.selectOrderPayment)),
      map(([, payment]: any[]): Promise<void> => this.tryAgain(payment)),
    );
  }, { dispatch: false });

  fetchOrdersBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.OrderFetchBegin),
      exhaustMap(async (): Promise<any> => {
        const orders: any[] = await this.conn.fetchOrders({ user: this.conn.getActingUser(),
          stage: { $nin: [this.appConfig.Shared.Order.Stage.INITIAL] } },
        ['productInfo', 'regimen', 'amount', 'createdAt', 'type', 'stage', 'orderNumber']);
        return fromActions.OrderStateUpdate({ orders: JSON.parse(JSON.stringify(orders)) });
      }),
    );
  });

  processPayment(payment: any): void {
    if (!payment) {
      this.back();
      return;
    }
    const data = {
      paymentId: payment.id,
      orderId: payment.get('order').id,
      orderType: payment.get('order').get('type'),
    };
    this.eventLogger.trackEvent('payment_attempt_failure', data);
    this.eventLogger.setUserProperty({ people_payment_attempt: 'Failure' });
    this.eventLogger.trackPeopleIncrement({ people_payment_attempt_failures: 1 });
  }

  async placeCodOrder(payment: any): Promise<void> {
    const order = payment.get('order');
    this.eventLogger.trackEvent('place_cod_order_after_online_failure_initiated');
    const newPayment: any = await this.conn.initiateOrderPayment({ orderId: order.id,
      gateway: this.appConfig.Shared.Order.PaymentType.COD.toLowerCase(),
      customTab: this.appWebBridgeService.isCustomTabSupported(),
      paymentId: payment.id,
      paymentType: this.appConfig.Shared.Order.PaymentType.COD });
    if (newPayment.payment?.get('preCondition')?.includes(this.appConfig.Shared.Payment.PreCondition.COD_CONFIRMATION_FEE)) {
      this.eventLogger.trackEvent('place_cod_order_asking_ccod_charges');
      await this.tryAgain(payment);
      return;
    }
    this.eventLogger.trackEvent('place_cod_order_after_online_failure_success');
    await this.conn.navigateToURL(
      `/user/order/success?orderId=${order.id}&amount=${order.get('amount')}&orderType=${order.get('type')}`,
      false,
      true);
  }

  async tryAgain(payment: any): Promise<void> {
    await this.conn.navigateToURL(`/user/order/${payment.get('order').id}/payment?back=home`);
  }

  /**
   * Process the regimen order
   * 1. checks if order has gift pending to be claimed &  shows the gift card in UI.
   * 2. checks if there is any addon product available in experiment to show after purchase & shows after 5 secs.
   */
  async processRegimenOrder(orderId: string, orderType: string, experiments: any[]): Promise<void> {
    let addOnProductExp;
    experiments.forEach((each: any): void => {
      if (each.key === 'addon_product_after_regimen_order') addOnProductExp = each;
    });
    if (!orderId || orderType !== this.appConfig.Shared.Order.Type.REGIMEN) return;
    const [order]: any[] = await this.conn.fetchOrders({ objectId: orderId }, ['regimen', 'isGiftClaimPending'], []);
    const regimenOrder = order;
    if (regimenOrder.get('isGiftClaimPending')) {
      this.store.dispatch(fromActions.OrderStateUpdate({ surpriseGiftClaimPending: true }));
      this.eventLogger.trackEvent('order_surprise_gift_visited');
    }
    const trackOrderId = this.localStorageService.getJsonValue('orderFBTrack');
    if (!trackOrderId[orderId]) {
      trackOrderId[orderId] = true;
      this.localStorageService.setJsonValue('orderFBTrack', trackOrderId);
    }
    const addOnProduct = await this.checkForAddOnAfterPurchase(addOnProductExp, regimenOrder);
    if (addOnProduct) {
      setTimeout((): void => {
        this.router.navigate(['/user/order/addon'],
          { queryParams: { productId: addOnProduct.id, back: 'home' }, replaceUrl: true });
      }, 5000);
    }
  }

  /**
   * 1. Checks is after purchase addon experiment is enabled.
   * 2. Checks if any addon is available in experiment.variant[CLASS] for that regimen order class.
   * Eg: experiment.variant.FACE, experiment.variant.BODY
   * 3. If variant is present, checks if the ordered regimen concern has to be excluded out of showing this suggestion.
   * 4. Then if class is FACE, checks if mentioned concerns in variant are detedted in user recent N (regimenVariant.checkupLimit) photos.
   * Eg: variant - { concernDetected: ['ACNE', 'COMEDONES'] } then in user recent N photos, if ACNE or COMEDONE is detected,
   *      only then this order is vavlid for addon suggestion.
   */
  async checkForAddOnAfterPurchase(addOnProductExp: any, order: any): Promise<any> {
    let product;
    let regimen;
    let excludeSuggestion: boolean = false;
    const user = this.conn.getActingUser();
    if (!addOnProductExp || !user) return product;
    try {
      const orderedRegimenClass = order.get('regimen').get('class');
      regimen = order.get('regimen');
      const regimenVariant = order ? addOnProductExp.variant[orderedRegimenClass] : null;
      if (regimenVariant) {
        [product] = await this.conn.findProductsById([regimenVariant.productId]);
        if (regimenVariant.excludableRegimenConcerns && regimen.concernsInEnglish) {
          excludeSuggestion = !!regimen.concernInEnglish
            .find((each: any): boolean => regimenVariant.excludableRegimenConcerns.includes(each));
        }
        if (orderedRegimenClass === this.appConfig.Shared.Regimen.Class.FACE
          && regimenVariant.concernDetected
          && regimenVariant.concernDetected.length
          && !excludeSuggestion) {
          const concernDetectedList: any = await this.conn.getInstantCheckupConcernDetectionCount(
            [orderedRegimenClass], regimenVariant.checkupLimit);
          const detected = Object.keys(concernDetectedList)
            .filter((each: any): boolean => regimenVariant.concernDetected.includes(each) && concernDetectedList[each] > 0);
          if (!detected.length) product = null;
        }
      }
    } catch (err) {
      product = null;
    }
    return product;
  }

  showError(message: string): void {
    this.broadcastService.broadcast('NOTIFY', { message });
  }

  back(): void {
    this.broadcastService.broadcast('NAVIGATION_BACK');
  }
}
