import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styles: [':host { @apply tw-flex tw-flex-col tw-min-w-[144px] tw-w-36; }'],
  standalone: false,
})
export class ProductCardComponent {
  @Input() product: any;
  @Input() productInCart: any;
  @Output() gotoProduct: EventEmitter<any> = new EventEmitter();
  @Output() addToCart: EventEmitter<any> = new EventEmitter();
  @Output() reomveFromCart: EventEmitter<any> = new EventEmitter();
}
