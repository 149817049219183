import { Component } from '@angular/core';
import { Observable } from 'rxjs/index';
import { Store } from '@ngrx/store';
import { ConnectionService } from '@services/connection-service';
import { fromSelectors } from '@store/selectors';
import { fromActions } from '@store/actions';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'shop-by-category',
  templateUrl: './shop-by-category.html',
  styleUrls: ['./shop-by-category.scss'],
  standalone: false,
})

export class ShopByCategoryComponent {
  selectedConcernClass: string;
  reorderProducts$: Observable<object[]> = this.store.select(fromSelectors.selectHomePageReorderProducts);
  isReorderProductLoading$: Observable<boolean> = this.store.select(fromSelectors.selectReorderProductsLoading);
  addOnProducts$: Observable<object[]> = this.store.select(fromSelectors.selectHomePageAddons);
  isAddOnProductLoading$: Observable<boolean> = this.store.select(fromSelectors.selectAddonProductsLoading);
  regimens: any[];
  isPaidUser: boolean;
  user: any = {};

  constructor(public appConfig: AppConfig,
    private conn: ConnectionService,
    private eventLogger: EventLoggerService,
    private store: Store) {
    const username = this.conn.getActingUser().get('username');
    this.user = this.conn.getActingUser();
    if (!this.user.isPaid()) {
      this.conn.updateLocalUser();
    }
    this.isPaidUser = [
      this.appConfig.Shared.User.OrderState.PROCESSING,
      this.appConfig.Shared.User.OrderState.DELIVERED,
    ].includes(this.conn.getActingUser().get('orderState'));
    this.store.dispatch(fromActions.HomePageAddonBegin({ payload: { username, tag: ['ADD_ON'], responseArray: true, limit: 5 } }));
    this.store.dispatch(fromActions.HomePageReorderBegin({
      payload: { username, tag: ['RE_ORDER'], responseArray: true, limit: 5 },
    }));
  }

  async onSelectConcern(concernClass: string): Promise<void> {
    this.selectedConcernClass = concernClass;
    this.regimens = await this.conn.fetchRegimens();
    const regimenForClass = this.regimens.find((each: any): boolean => !each.expired && each.class === concernClass);
    if (regimenForClass) this.conn.navigateToURL(`/user?tab=regimen&class=${concernClass}`);
    else this.conn.navigateToURL(`/mainConcern?class=${this.selectedConcernClass}&force=true`);
  }

  viewAll(pageName: string): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: `view-all-${pageName}` }));
    this.conn.navigateToURL(`/user/order/reorder?from=${pageName}`);
  }
}
