import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { WindowRefService } from '@services/window-ref-service';
import { BroadcastService } from '@services/broadcast-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'user-order-addon',
  templateUrl: './user-order-addon.html',
  styleUrls: ['./user-order-addon.scss'],
  standalone: false,
})
export class UserOrderAddonComponent {
  addOnProduct: any;
  note: string;
  user: any;

  constructor(private conn: ConnectionService,
    private eventLogger: EventLoggerService,
    private appConfig: AppConfig,
    private windowRef: WindowRefService,
    private broadcast: BroadcastService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
    const { productId }: any = this.route.snapshot.queryParams;
    if (!productId) {
      this.back();
      return;
    }
    [this.addOnProduct] = await this.conn.findProductsById([productId]);
    const logParams = { username: this.user.get('username'), productId };
    this.eventLogger.trackEvent('addon_product_after_regimen_order_visited', logParams);
  }

  async openArticleOfAddon(): Promise<void> {
    const addonArticle = this.addOnProduct.get('article');
    if ((addonArticle && addonArticle.id) || this.addOnProduct.get('articleId')) {
      await this.router.navigate(
        [`/article/${addonArticle.id || this.addOnProduct.get('articleId')}?product=${this.addOnProduct.id}`]);
    } else await this.buyProduct(true);
  }

  async buyProduct(confirm: boolean): Promise<void> {
    const logParams = { username: this.user.get('username'), productId: this.addOnProduct.id };
    if (!confirm) {
      this.eventLogger.trackEvent('addon_product_after_regimen_order_skip', logParams);
      await this.router.navigate(['user'], { queryParams: { back: 'home', tab: 'chat' }, replaceUrl: true });
      return;
    }
    this.eventLogger.trackEvent('addon_product_after_regimen_order_buy', logParams);
    await this.router.navigate(['/user/checkout'], { queryParams: { type: this.appConfig.Shared.Order.Type.PRODUCT,
      products: this.addOnProduct.id } });
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}
