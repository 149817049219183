import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CsIconModule } from '@components/icon';
import { NgClass, NgIf } from '@angular/common';
import { LoadingModule } from '@components/loading';

@Component({
  selector: 'product-cta-container',
  templateUrl: './product-cta-container.component.html',
  imports: [
    CsIconModule,
    NgClass,
    NgIf,
    LoadingModule,
  ],
})
export class ProductCtaContainer {
  @Input() isPaidUser: boolean;
  @Input() product: any;
  @Input() discount: number;
  @Input() isAddRemovePending: boolean;
  @Input() productInCart: any;
  @Input() buyNowExperiment: boolean;
  @Input() clickToAction?: any;
  @Input() isDiscontinued?: boolean;
  @Input() innerHTML?: any;
  @Input() appConfig: any;

  @Output() addToCart: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeFromCart: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleBuyNow: EventEmitter<void> = new EventEmitter<void>();

  onAddToCart(): void {
    this.addToCart.emit();
  }

  onRemoveFromCart(): void {
    this.removeFromCart.emit();
  }

  onBuyNow(): void {
    this.handleBuyNow.emit();
  }
}
