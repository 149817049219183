import { Component } from '@angular/core';
import { ConnectionService } from '@services/connection-service';

@Component({
  selector: 'refund-policy',
  templateUrl: './refund-policy.html',
  styleUrls: [],
  standalone: false,
})

export class RefundPolicyComponent {
  constructor(private conn: ConnectionService) {
  }

  navigateTo(url: string): void {
    this.conn.navigateToURL(url);
  }
}
