import { Component } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { LocalStorageService } from '@services/local-storage-service';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';
import { LoginService } from '@services/login-service/login.service';
import { WindowRefService } from '@services/window-ref-service';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { Router } from '@angular/router';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'acquisition-user-query',
  templateUrl: 're-acquisition-user-query.html',
  standalone: false,
})

export class ReAcquisitionUserQueryComponent {
  userQuery: string;
  selectedConcern: 'hair' | 'face' = 'face';
  constructor(
    private conn: ConnectionService,
    private localStorageService: LocalStorageService,
    private broadcast: BroadcastService,
    private eventLogger: EventLoggerService,
    public appConfig: AppConfig,
    private loginService: LoginService,
    private windowRefService: WindowRefService,
    private router: Router,
    public commonUtilityHelper: CommonUtilityHelper) {
  }

  ngOnInit(): void {
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 're-acquisition-user-query' }));
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  async submit(): Promise<void> {
    try {
      this.localStorageService.setValue('userQuery', this.userQuery);
      this.router.navigate(['/re-acquisition/take-photo'], { queryParams: { selectedConcern: this.selectedConcern } });
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.toString() });
    }
  }

  skip(): void {
    this.router.navigate(['/user'], { queryParams: { tab: 'home' } });
  }
  changeConcern(selectedConcern: 'face' | 'hair'): void {
    this.selectedConcern = selectedConcern;
  }
}
