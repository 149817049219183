import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appDrawDetections]',
  standalone: false,
})
export class DrawDetectionsDirective {
  @Input() appDrawDetections: any;
  @Input() config: { top: number, left: number, ratio: number };
  canvas: HTMLCanvasElement;
  canvasContext: CanvasRenderingContext2D;

  scale: number = 1;
  angle: number = 0;
  lineDashOffset: number = 0;
  incrementing: boolean = true;
  constructor(elementRef: ElementRef<HTMLCanvasElement>) {
    this.canvas = elementRef.nativeElement;
    this.canvasContext = elementRef.nativeElement.getContext('2d');
  }
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!this.appDrawDetections || !this.config) return;
    this.drawDetections();
  }

  drawDetections(): void {
    if (this.appDrawDetections.BoundingBoxes) this.drawCircles(this.appDrawDetections.BoundingBoxes);
    if (this.appDrawDetections.PolygonPointsSequences) this.drawPolygons(this.appDrawDetections.PolygonPointsSequences);
    requestAnimationFrame((): void => this.animate());
  }

  animate(): void {
    this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.updateScale();
    this.drawDetections();
  }

  updateScale(): void {
    this.angle += Math.PI / 150; // Adjust the increment to change the speed of oscillation
    this.scale = 0.9 + Math.abs(Math.sin(this.angle)) * 0.3; // Adjust the range to match 0.9 to 1.2
    this.lineDashOffset += 0.25;
  }

  drawCircles(circles: string[]): void {
    circles.forEach((pointString: string, index: number): void => {
      const point: number[] = pointString.split(',').map((x: string): number => +x);

      const centerX = (((point[0] + point[2]) / 2 - this.config.left) * this.config.ratio);
      const centerY = (((point[1] + point[3]) / 2 - this.config.top) * this.config.ratio);
      const radius = (Math.abs(point[0] - point[2]) * this.config.ratio) / 2;
      const scaledX = centerX - (centerX * this.scale);
      const scaledY = centerY - (centerY * this.scale);
      this.canvasContext.beginPath();
      this.canvasContext.setTransform(this.scale, 0, 0, this.scale, scaledX, scaledY);
      this.canvasContext.arc(centerX, centerY, radius, 0, 2 * Math.PI);
      this.canvasContext.setTransform(1, 0, 0, 1, 0, 0);
      this.canvasContext.lineWidth = 2;
      this.canvasContext.strokeStyle = 'rgba(237, 4, 0)';
      this.canvasContext.stroke();
      this.canvasContext.closePath();
    });
  }

  drawPolygons(polygons: (string[] | { points: string[], color: string })[]): void {
    polygons.forEach((polygon: (string[] | { points: string[], color: string })): void => {
      this.canvasContext.beginPath();
      this.canvasContext.setLineDash([7, 3, 7, 5]);
      this.canvasContext.lineDashOffset = this.lineDashOffset;
      const points = ('points' in polygon) ? polygon.points : polygon;
      points.forEach((pointString: string | number[], index: number): void => {
        const point = typeof pointString === 'string'
          ? pointString.split(',').map((x: string): number => +x)
          : pointString;
        const pointX = ((point[0] - this.config.left) * this.config.ratio);
        const pointY = ((point[1] - this.config.top) * this.config.ratio);
        if (index === 0) this.canvasContext.moveTo(pointX, pointY);
        else this.canvasContext.lineTo(pointX, pointY);
      });
      this.canvasContext.lineWidth = 1.5;
      this.canvasContext.strokeStyle = ('color' in polygon) ? polygon.color : 'rgb(237, 4, 0)';
      this.canvasContext.stroke();
      this.canvasContext.closePath();
    });
  }
}
