import { Component, ElementRef, HostListener, Inject, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { WindowRefService } from '@services/window-ref-service';
import { TimeService } from '@services/time-service';
import { EventLoggerService } from '@services/event-logger-service';
import { Catalog } from '@cureskin/api-client/src/server-api';
import { Router } from '@angular/router';
import { KnowMoreClicked } from '@shared/regimen-instruction/instruction-card/instruction-card.component';
import { AppConfig } from '../../app.config';
import { CollapsableCardItem, CollapsableCardState, FollowUpReport } from '../../app-constant-types';

@Component({
  selector: 'regimen-instruction',
  templateUrl: './regimen-instruction.html',
  standalone: false,
})
export class RegimenInstructionComponent implements AfterViewInit, OnDestroy {
  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef<HTMLVideoElement>;
  @ViewChild('morningAndNightTabs', { static: false }) morningAndNightTabs: ElementRef<HTMLElement>;
  @ViewChild('nightElementRelative') set nightBlockMain(block: ElementRef<HTMLElement>) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };
    /**
 * This is used to check is the element is in view port or not, intersection ratio greater than 0 && isIntersecting true means,
 * screen is scrolled to the specified block
 */
    const observer = new IntersectionObserver(async ([entry]: any, observe: any): Promise<any> => {
      if (entry?.intersectionRatio > 0 && entry?.isIntersecting) {
        this.isDayTime = false;
      } else {
        this.isDayTime = true;
      }
    }, options);
    if (block?.nativeElement) {
      observer?.observe(block?.nativeElement);
    }
  }

  @ViewChild('nightElement') set nightBlock(block: ElementRef<HTMLElement>) {
    if (!block) return;
    if (this.onNightBlockClick || this.onMorningBlockClick) return;

    // Add check for initial load
    if (this.initialLoadComplete) return;
    this.initialLoadComplete = true;

    const MORNING_START_HOUR = 6;
    const NIGHT_START_HOUR = 20;

    // Only set these values during initial load
    if (this.todayDay === undefined) {
      this.todayDay = new Date().getDate();
      this.todayDayVal = new Date().getDay();
    }

    const hours = new Date().getHours();
    // Morning: 6 AM to 7:59 PM (20:00)
    // Night: 8 PM to 5:59 AM
    this.isDayTime = hours >= MORNING_START_HOUR && hours < NIGHT_START_HOUR;

    // Only auto-scroll to night section if it's between 8 PM and 5:59 AM
    const shouldScrollToNight = hours >= NIGHT_START_HOUR || (hours < MORNING_START_HOUR);
    if (shouldScrollToNight && !this.onMorningBlockClick) {
      this.onNightClick(block.nativeElement);
    }
  }
  regimen: any = {};
  dayAfterDelivery: number = -1;
  changeInstructionForRepairProduct: boolean = true;
  morningProducts: any[] = [];
  nightProducts: any[] = [];
  weekArray: any[] = [];
  currentDate: any;
  todayDay: number;
  todayDayVal: any;
  supportTicketId: any;
  onNightBlockClick: boolean = false;
  isCustomInstruction: boolean = false;
  isLoading: boolean = false;
  onMorningBlockClick: boolean = false;
  thumbsUpDown: boolean = false;
  isDayTime: boolean;
  days: any[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'];
  monthNames: any[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  isPlaying: boolean = false;
  unChangedTodayDay: number = new Date().getDate();
  @Input('regimen')
  set regimenObject(r: any) {
    if (this.regimen?.concern === r?.concern) return;
    this.regimen = JSON.parse(JSON.stringify(r));
    this.initialLoadComplete = false;
    this.processRegimen();
    this.checkForInstructionUpdate();
  }
  user: any;
  @Input('supportTicketId')
  set supportTicket(id: any) {
    this.supportTicketId = id;
  }
  @Input('regimenContentElement') regimenContentElement: HTMLElement;
  @Input('experiments')
  set experiments(data: any) { }
  @Input('newInstructionUIExperiment') newInstructionUIExperiment: boolean;
  @Input('scrollingDown') scrollingDown: boolean;
  thumbnailImageUrl: string;
  thumbnailImageUrlRebranding: string;
  videoURL: string;
  productIds: Array<any> = [];
  videoPlayed: boolean = false;
  daysData: any = [];
  userLanguage: string;
  isTabsVisible: boolean;
  productUpdatePopupEnabled: boolean = false;
  allocatedDoctor: Table.User;
  defaultDoctorImage: string = 'https://cdn.cureskin.com/app/img/dr-charu-main-concern.png';
  userDrImage: string;
  regimenModifications: string[] = [];
  latestFollowupReport: FollowUpReport;
  selectedDate: Date = new Date();
  collapsableCard: CollapsableCardState = {
    onHold: [],
    removed: [],
    instructionChanged: [],
  };
  removedProducts: Catalog[];
  showGoToCart: boolean = false;
  dontShowCTA: boolean = false;
  private initialLoadComplete: boolean = false;

  constructor(private conn: ConnectionService,
    private broadcast: BroadcastService,
    public appConfig: AppConfig,
    private appBridge: AppWebBridgeService,
    private window: WindowRefService,
    private timerService: TimeService,
    private eventLoggerService: EventLoggerService,
    private readonly router: Router,
    // eslint-disable-next-line new-cap
    @Inject(DOCUMENT) private document: Document) { }

  @HostListener('document:fullscreenchange')
  onFullscreenChange(): void {
    if (!this.document.fullscreenElement) {
      this.closeVideo();
    }
  }

  async ngOnInit(): Promise<void> {
    this.onMorningBlockClick = false;
    this.onNightBlockClick = false;
    this.getWeeklyCalendar();
    this.user = this.conn.getActingUser();
    if (!this.user?.get('instructionsFeedbackResponse') && this.supportTicketId) {
      this.thumbsUpDown = true;
    }
    if (this.regimen?.active && this.newInstructionUIExperiment) {
      await this.getRegimenModificationsAndDoctorInfo().then((r: any): void => this.handleAutoPopup());
    }
  }

  ngAfterViewInit(): void {
    this.setupIntersectionObserver();
  }

  setupIntersectionObserver(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.7,
    };

    const observer = new IntersectionObserver(([entry]: IntersectionObserverEntry[]): void => {
      this.onIntersection(entry.isIntersecting);
    }, options);
    if (this.morningAndNightTabs?.nativeElement) {
      observer.observe(this.morningAndNightTabs.nativeElement);
    }
  }

  onIntersection(isIntersecting: boolean): void {
    if (this.isTabsVisible !== isIntersecting) {
      this.isTabsVisible = isIntersecting;
    }
  }

  // For variants, we need to manually update the instruction
  // as it doesn't give the translated strings
  async checkForInstructionUpdate(): Promise<void> {
    const hasVariants = this.regimen?.variants && this.regimen?.variants?.length;
    const hasTranslatedInstructionSet = this.regimen.morning[0]?.instructionSet?.areaOfApplication;
    if (hasVariants && !hasTranslatedInstructionSet) {
      await this.updateInstruction();
    }
  }

  async updateInstruction(): Promise<void> {
    this.isLoading = true;
    const regimens = await this.conn.fetchRegimens(null, true, true);
    const regimenInfo = regimens.find((each: any): boolean => each.class === this.regimen.class);
    this.regimen.morning = this.regimen?.morning?.map((product: any, index: number): any => {
      const updatedProduct = { ...product };
      delete updatedProduct?.instructionSet;
      updatedProduct.instructionSet = JSON.parse(JSON.stringify(regimenInfo?.morning[index]?.instructionSet));
      return updatedProduct;
    });

    this.regimen.night = this.regimen?.night?.map((product: any, index: number): any => {
      const updatedProduct = { ...product };
      delete updatedProduct?.instructionSet;
      updatedProduct.instructionSet = JSON.parse(JSON.stringify(regimenInfo?.night[index]?.instructionSet));
      return updatedProduct;
    });

    await this.processRegimen();
  }

  async processRegimen(): Promise<any> {
    this.isLoading = true;
    this.userLanguage = this.user?.get('languagePreference') || this.appConfig.Shared.Languages.EN;
    delete this.videoURL;
    delete this.thumbnailImageUrl;
    delete this.thumbnailImageUrlRebranding;
    this.morningProducts = [];
    this.nightProducts = [];
    this.productIds = [];
    await this.regimen?.morning?.forEach((e: any): void => {
      const each = e;
      if (!this.productIds.includes(e.product.objectId)) this.productIds.push(e.product.objectId);
      if (each.instructionSet) {
        const set = each.instructionSet;
        const prod: any = each.product?.instructions?.find((element: any): any => element.title === set.title);
        set.paidInstructionVideo = prod?.paidInstructionVideo;
        set.unpaidInstructionVideo = prod?.unpaidInstructionVideo;
        if (set.quantityUnit) set.quantityUnit = this.formatString(set.quantityUnit);
        if (set.areaOfApplication) set.areaOfApplication = this.formatString(set.areaOfApplication);
        if (set.frequencyOfApplication) set.frequencyOfApplication = this.formatString(set.frequencyOfApplication);
        if (set.frequencyOfApplicationEnglish) set.frequencyOfApplicationEnglish = this.formatString(set.frequencyOfApplicationEnglish);
        if (set.frequencyOfApplicationEnglish) set.newFrequencyOfApplication = this.formatFrequency(set);
        if (set.newFrequencyOfApplication) set.displayDays = this.formatStringDays(set.newFrequencyOfApplication);
        if (set.durationOfApplication) set.durationOfApplication = this.formatString(set.durationOfApplication);
        this.morningProducts.push(each);
      }
    });
    await this.regimen?.night?.forEach((e: any): void => {
      const each = e;
      if (!this.productIds.includes(e.product.objectId)) this.productIds.push(e.product.objectId);
      if (each.instructionSet) {
        const set = each.instructionSet;
        const prod: any = each.product?.instructions?.find((element: any): any => element.title === set.title);
        set.paidInstructionVideo = prod?.paidInstructionVideo;
        set.unpaidInstructionVideo = prod?.unpaidInstructionVideo;
        if (set.quantityUnit) set.quantityUnit = this.formatString(set.quantityUnit);
        if (set.areaOfApplication) set.areaOfApplication = this.formatString(set.areaOfApplication);
        if (set.frequencyOfApplication) set.frequencyOfApplication = this.formatString(set.frequencyOfApplication);
        if (set.frequencyOfApplicationEnglish) set.frequencyOfApplicationEnglish = this.formatString(set.frequencyOfApplicationEnglish);
        if (set.frequencyOfApplicationEnglish) set.newFrequencyOfApplication = this.formatFrequency(set);
        if (set.newFrequencyOfApplication) set.displayDays = this.formatStringDays(set.newFrequencyOfApplication);
        if (set.durationOfApplication) set.durationOfApplication = this.formatString(set.durationOfApplication);
        this.nightProducts.push(each);
      }
    });
    await this.checkForInstructionVideo(this.userLanguage);
    this.checkForRepairProductInstructionsAlert();
    this.updateCollapsableCardOnHoldStatus();
    this.eventLoggerService.cleverTapEvent('pageOpen', JSON.stringify({ regimenId: this.regimen.regimenId, pageName: 'instruction' }));
    this.isLoading = false;
  }

  /**
   * Updates the collapsableCard status by checking onHold status
   * of products in morning and night regimens
   */
  private updateCollapsableCardOnHoldStatus(): void {
    // Reset onHold array
    this.collapsableCard.onHold = [];

    // Check morning products
    this.morningProducts?.forEach((product: any): void => {
      if (product.onHold) {
        this.collapsableCard.onHold.push({
          productId: product.product.objectId,
          isOpen: false,
        });
      }
    });

    // Check night products
    this.nightProducts?.forEach((product: any): void => {
      if (product.onHold) {
        this.collapsableCard.onHold.push({
          productId: product.product.objectId,
          isOpen: false,
        });
      }
    });
  }

  /**
   * Updates the collapsableCard status by checking removed products
   * from latest followup
   */
  private async updateCollapsableCardRemovedStatus(): Promise<void> {
    // Reset removed array
    this.collapsableCard.removed = [];

    // Only proceed if we have a latest followup report
    if (!this.latestFollowupReport?.createdAt) {
      return;
    }

    const listOfChangedRegimenProduct = await this.conn.getListOfChangedRegimenProduct(
      this.regimen.objectId,
      'productRemoved,instructionChanged',
    );

    const latestFollowupDate = new Date(this.latestFollowupReport.createdAt)
      .toISOString()
      .split('T')[0];
    // Get all removed products details
    this.removedProducts = await Promise.all(
      listOfChangedRegimenProduct
        .filter((item: any): boolean => {
          const itemDate = new Date(item.createdAt);
          itemDate.setHours(0, 0, 0, 0);

          const followupDate = new Date(this.latestFollowupReport.createdAt);
          followupDate.setHours(0, 0, 0, 0);
          return itemDate >= followupDate && item.actionQuestionUniqueId === 'productRemoved';
        })
        .map(async (item: any): Promise<Catalog> => {
          const [product] = await this.conn.findProductsById(item.product.objectId);
          return JSON.parse(JSON.stringify(product));
        }),
    );

    const pastRegimens = await this.conn.getPastRegimens(this.user, new Date(latestFollowupDate), this.regimen.class);

    // Filter and add to collapsableCard.removed
    (listOfChangedRegimenProduct as any)
      .filter((item: any): boolean => {
        const itemDate = new Date(item.createdAt).toISOString().split('T')[0];
        return itemDate === latestFollowupDate;
      })
      .forEach((item: any): void => {
        if (item?.actionQuestionUniqueId === 'productRemoved') {
          this.collapsableCard.removed.push({
            productId: item.product.objectId,
            isOpen: false,
          });
        } else if (item?.actionQuestionUniqueId === 'instructionChanged') {
          this.collapsableCard.instructionChanged.push(item.product.objectId);
        }
      });

    await this.processRemovedInstructions(pastRegimens);
  }

  private async processRemovedInstructions(pastRegimens: any[]): Promise<void> {
    // Create map of latest regimens for removed products
    const latestRegimenMap = this.createLatestRegimenMap(pastRegimens);
    // Process each removed product
    await Promise.all(this.removedProducts.map(async (removedProduct: any): Promise<void> => {
      const latestRegimen = latestRegimenMap.get(removedProduct.objectId);
      if (!latestRegimen) return;

      // Process morning and night products
      await Promise.all([
        this.processTimeProducts('morning', latestRegimen, removedProduct),
        this.processTimeProducts('night', latestRegimen, removedProduct),
      ]);
    }));
  }

  private createLatestRegimenMap(pastRegimens: any[]): Map<string, any> {
    const latestRegimenMap = new Map();

    [...pastRegimens].reverse().forEach((pastRegimen: any): void => {
      const productIds = (pastRegimen.get('combinedProductIds') || '').split('_');

      this.removedProducts.forEach((removedProduct: any): void => {
        if (!latestRegimenMap.has(removedProduct.objectId)
            && productIds.includes(removedProduct.objectId)) {
          latestRegimenMap.set(removedProduct.objectId, pastRegimen);
        }
      });
    });

    return latestRegimenMap;
  }

  private async processTimeProducts(timeOfDay: 'morning' | 'night', latestRegimen: any, removedProduct: any): Promise<void> {
    const products = latestRegimen.get(timeOfDay) || [];

    // Process all products first and collect results
    const productsToAdd = await Promise.all(
        products
            .filter((product: any): any => {
                const matches: boolean = product.product.id === removedProduct.objectId;
                return matches;
            })
            .map(async (product: any) => {
                if (product.instructionSet) {
                    await this.updateInstructionSetLanguages(product.instructionSet);
                }
                return {
                    ...product,
                    removedProduct,
                };
            })
    );

    // Then update the target array atomically
    const targetArray = timeOfDay === 'morning' ? this.morningProducts : this.nightProducts;
    productsToAdd.forEach(newProduct => {
        const exists = targetArray.some(existingProduct =>
            existingProduct.product.id === newProduct.product.id
        );

        if (!exists) {
            targetArray.push(newProduct);

        }
    });
  }

  private async updateInstructionSetLanguages(set: any): Promise<void> {
    const languageFields = [
      { key: 'areaOfApplication', stringKey: 'areaOfApplicationLanguageString' },
      { key: 'frequencyOfApplication', stringKey: 'frequencyOfApplicationLanguageString' },
      { key: 'quantityUnit', stringKey: 'quantityUnitLanguageString' },
      { key: 'durationOfApplication', stringKey: 'durationOfApplicationLanguageString' },
    ];

    await Promise.all(
      languageFields
        // tslint:disable-next-line:typedef
        .filter((field) => set[field.stringKey])
        // tslint:disable-next-line:typedef
        .map(async (field) => {
          // tslint:disable-next-line:typedef
          const [response] = await this.conn.getLanguageStringsById(set[field.stringKey].id);
          // eslint-disable-next-line no-param-reassign
          set[field.key] = response.get(this.userLanguage);
        }),
    );
  }

  /**
 * This method is used to get the current week starting from monday
 */
  getWeeklyCalendar(): void {
    this.weekArray = [];
    this.currentDate = new Date();
    if (!this.newInstructionUIExperiment) {
      for (let i = 1; i <= 7; i += 1) {
        const first = this.currentDate.getDate() - this.currentDate.getDay() + i;
        const day = new Date(this.currentDate.setDate(first)).toISOString().slice(0, 10);
        const dayValue = this.days[new Date(day).getDay()];
        const month = this.monthNames[new Date(day).getMonth()];
        const date = new Date(day).getDate();
        const obj = { dayValue, month, date };
        this.weekArray.push(obj);
      }
    } else {
      // New logic to place today in the middle of the week array
      for (let i = -3; i <= 3; i += 1) {
        const day = new Date(this.currentDate);
        day.setDate(this.currentDate.getDate() + i);
        const dayValue = this.days[day.getDay()];
        const month = this.monthNames[day.getMonth()];
        const date = day.getDate();

        const obj = {
          dayValue,
          month,
          date,
          fullDate: day, // Store full date for comparison
          isSelected: this.isSameDay(day, this.selectedDate),
        };
        this.weekArray.push(obj);
      }
    }
  }

  checkForRepairProductInstructionsAlert(): void {
    if (this.regimen.deliveredDate) {
      const todayDate: any = new Date();
      const regimenDeliveredDate = new Date(this.regimen.deliveredDate);
      const afterDeliveryDate1: any = this.timerService.addDays(regimenDeliveredDate, 1);
      if (todayDate > afterDeliveryDate1) {
        this.changeInstructionForRepairProduct = false;
        return;
      }
      if (todayDate.getDate() === regimenDeliveredDate.getDate()) this.dayAfterDelivery = 0;
      else if (todayDate.getDate() === afterDeliveryDate1.getDate()) this.dayAfterDelivery = 1;
      else this.changeInstructionForRepairProduct = false;
    }
  }

  formatString(text: string): string {
    return text.replace('N/A', '').trim();
  }

  /**
   * This method is used to format the instructions frequency of usage to days array.
   */
  formatFrequency(data: any, isOldUi: boolean = false): any {
    let text: string;
    if (isOldUi) {
      text = data;
    } else {
      text = data.frequencyOfApplicationEnglish;
    }
    if (!text) return '';
    const daysArray = text.split('/');
    const dayObjList = Object.keys(this.appConfig.Shared.FrequencyOfUsage.Days);
    const hasAllElems = daysArray.every((elem: any): any => dayObjList.includes(elem));
    if (isOldUi && !hasAllElems) {
      return text;
    }
    if (!isOldUi && !hasAllElems) {
      if (text.toLowerCase() !== 'all days') {
        // eslint-disable-next-line no-param-reassign
        data.isCustomInstruction = true;
      }
      return '';
    }
    this.daysData = [];
    daysArray.forEach((element: any): any => {
      if (this.appConfig.Shared.FrequencyOfUsage.Days[element]) {
        this.daysData.push(this.appConfig.Shared.FrequencyOfUsage.Days[element]);
      }
    });
    return this.daysData;
  }

  /**
   * This is used to format the array to a string with commas and & opeartor at last
   */
  formatStringDays(list: any): any {
    if (!list?.length) {
      return '';
    }
    if (list.length === 1) {
      return list.toString();
    }
    if (list.length === 2) {
      return list.join(' & ');
    }
    return `${list.slice(0, -1).join(', ')}, & ${list.slice(-1)}`;
  }

  private async checkForInstructionVideo(language: string): Promise<any> {
    if (this.appBridge.requestOSInformation() !== 'iOS' && !this.window.isSafariBrowser()) {
      const filenameArray = this.productIds;
      filenameArray.push(language);
      const fileName = filenameArray.join('-');
      const videoURL = `https://cdn.cureskin.com/regimen-products-video/${fileName}.webm`;
      const videoFileExists = await this.findIfProductVideoExists(videoURL);
      if (videoFileExists) {
        this.videoURL = videoURL;
        this.thumbnailImageUrl = `https://cdn.cureskin.com/regimen-products-video/regimen-instruction-thumbnail-${language}.jpg`;
        this.thumbnailImageUrlRebranding = `https://cdn.cureskin.com/regimen-products-video/regimen-instruction-thumbnail-${language}.jpg`;
        this.eventLoggerService.trackEvent('REGIMEN_PRODUCT_VIDEO_FOUND', { url: videoURL });
      } else {
        this.eventLoggerService.trackEvent('REGIMEN_PRODUCT_VIDEO_NOT_FOUND',
          { url: videoURL, class: this.regimen.class, regimenId: this.regimen.regimenId });
        if (language !== this.appConfig.Shared.Languages.EN) {
          await this.checkForInstructionVideo(this.appConfig.Shared.Languages.EN);
        }
      }
      this.scrollToTopOfPage();
    }
  }

  logVideoPlay(): void {
    if (this.videoPlayed) return;
    this.videoPlayed = true;
    this.eventLoggerService.trackInFirebaseAndBranch('REGIMEN_PRODUCT_VIDEO_PLAYED');
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'play-regimen-instruction-video', class: this.regimen.class }));
    this.eventLoggerService.trackEvent('REGIMEN_PRODUCT_VIDEO_PLAYED',
      { url: this.videoURL, class: this.regimen.class, regimenId: this.regimen.regimenId });
  }

  closeVideo(): any {
    if (!this.isPlaying) return;
    this.videoPlayer.nativeElement.pause();
    this.videoPlayer.nativeElement.currentTime = 0;
    this.videoPlayer.nativeElement.parentElement.classList.add('tw-hidden');
    this.isPlaying = false;
  }

  async findIfProductVideoExists(url: any): Promise<boolean> {
    try {
      const http = new XMLHttpRequest();
      http.open('HEAD', url, false);
      http.send();
      return Promise.resolve(http.status !== 404);
    } catch (err) {
      return Promise.resolve(false);
    }
  }

  myDiet(): void {
    let articleId = '3PMLGVSMfZ';
    if (this.regimen.orderPlaced || this.regimen.delivered) {
      const { params = {} }: any = this.regimen.dietAndLifestyleQuestion || {};
      if (params.url) {
        if (params.url.includes('addonProduct/')) articleId = params.url.split('addonProduct/')[1];
        if (params.url.includes('article/')) articleId = params.url.split('article/')[1];
      }
      this.conn.navigateToURL(`/article/${articleId}`);
    } else this.broadcast.broadcast('NOTIFY', { message: 'Diet plan will be available once treatment is started' });
  }

  myDietNew(): void {
    this.conn.navigateToURL('/notice');
  }

  onNightClick(element?: HTMLElement): void {
    this.isLoading = true;
    const windowRef = this.window.nativeWindow;
    const doc = windowRef.document;
    const nightBlockScroll = doc.getElementById('nightBlockScroll');
    if (element) {
      element.scrollIntoView(true);
    } else {
      this.isDayTime = false;
      this.onNightBlockClick = true;
      nightBlockScroll.scrollIntoView(true);
    }
    this.isLoading = false;
  }
  onMorningClick(): void {
    this.isLoading = true;
    const windowRef = this.window.nativeWindow;
    const doc = windowRef.document;
    const morningBlock = doc.getElementById('morningBlock');
    if (morningBlock) {
      this.onNightBlockClick = false;
      this.onMorningBlockClick = true;
      this.isDayTime = true;
      morningBlock.scrollIntoView(true);
    }
    this.isLoading = false;
  }

  onPlayButtonClick(instructionItem: any): void {
    const activeUser = this.user || this.conn.getActingUser();
    this.userLanguage = activeUser?.get('languagePreference') || this.appConfig.Shared.Languages.EN;
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'play-product-instruction-video' }));
      const videoPlayer = this.videoPlayer.nativeElement;
      videoPlayer.parentElement.classList.remove('tw-hidden');
      if (activeUser?.isPaid()) {
        videoPlayer.src = instructionItem.instructionSet.paidInstructionVideo[this.userLanguage]
        || instructionItem.instructionSet.paidInstructionVideo.en;
      } else {
        videoPlayer.src = instructionItem.instructionSet.unpaidInstructionVideo[this.userLanguage]
        || instructionItem.instructionSet.unpaidInstructionVideo.en;
      }
      const playPromise = videoPlayer.play();
      if (playPromise && !this.isPlaying) {
        playPromise.then(():void => {
          this.isPlaying = true;
        });
      }
    }
  }

  scrollToTopOfPage(): void {
    if (this.regimenContentElement) this.regimenContentElement.scrollTop = 0;
  }

  async thumpsUpDownRes(response: boolean): Promise<void> {
    if (response) {
      this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'instructionsHelpful' }));
    } else {
      this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'instructionsNotHelpful' }));
    }
    await this.conn.updateSupportTicketFeedback(this.supportTicketId, response);
    this.user.set('instructionsFeedbackResponse', response ? 'yes' : 'no');
    await this.user.save();
    this.thumbsUpDown = false;
  }

  toggleProductUpdatePopup(event?: any): void {
    this.showGoToCart = event?.fromInstruction === KnowMoreClicked.NOT_YET_STARTED;
    this.dontShowCTA = event?.fromInstruction === KnowMoreClicked.NEW;
    this.productUpdatePopupEnabled = !this.productUpdatePopupEnabled;
    const eventName = !this.productUpdatePopupEnabled
      ? 'instruction-page-product-update-pop-up-closed'
      : 'instruction-page-product-update-pop-up-opened';
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: eventName }));
  }

  handleAutoPopup(): void {
    if (!this.latestFollowupReport?.regimenModifications?.length) {
      return;
    }

    const storageKey = 'product_update_popup_count';
    const storageObjectIdKey = 'product_update_popup_report_id';
    const DEFAULT_COUNT = 0;
    const RADIX_DECIMAL = 10;
    const MAX_COUNT = 2;

    const storedReportId = localStorage.getItem(storageObjectIdKey);
    const currentReportId = this.latestFollowupReport.objectId;

    // Reset count if report ID is different
    if (storedReportId !== currentReportId) {
      localStorage.setItem(storageKey, `${DEFAULT_COUNT}`);
      localStorage.setItem(storageObjectIdKey, currentReportId);
    }

    const popupCount = parseInt(localStorage.getItem(storageKey) || `${DEFAULT_COUNT}`, RADIX_DECIMAL);

    if (popupCount < MAX_COUNT) {
      localStorage.setItem(storageKey, (popupCount + 1).toString());

      if (!this.productUpdatePopupEnabled) {
        this.toggleProductUpdatePopup(KnowMoreClicked.AUTO);
      }
    }
  }

  async getRegimenModificationsAndDoctorInfo(): Promise<void> {
    const response = await this.getAllFollowUpsOfUser();

    if (!response || response.length === 0) return;

    this.latestFollowupReport = JSON.parse(JSON.stringify(response[0]));
    this.userDrImage = this.latestFollowupReport.doctor.doctorDisplayImage || this.latestFollowupReport.doctor.DoctorImageThumbnail
      || this.defaultDoctorImage;
    this.regimenModifications = this.latestFollowupReport.regimenModifications;

    await this.updateCollapsableCardRemovedStatus();
  }

  async getAllFollowUpsOfUser(): Promise<any> {
    const where = { user: this.user, isSecondaryComparison: false };
    return this.conn.fetchFollowUpReports({ where, descending: 'createdAt', include: ['doctor'] });
  }

  private isSameDay(date1: Date, date2: Date): boolean {
    return date1.getDate() === date2.getDate()
           && date1.getMonth() === date2.getMonth()
           && date1.getFullYear() === date2.getFullYear();
  }

  async onDateSelect(selectedDay: any): Promise<void> {
    this.selectedDate = new Date(selectedDay.fullDate);

    // Update selected state in weekArray
    // tslint:disable-next-line:typedef
    this.weekArray = this.weekArray.map((day) => ({
      ...day,
      isSelected: this.isSameDay(day.fullDate, this.selectedDate),
    }));

    // Create new references to trigger change detection
    this.todayDay = selectedDay.fullDate.getDate();
    this.todayDayVal = selectedDay.fullDate.getDay();
  }

  /**
   * Toggles the isOpen value for a product in either onHold or removed arrays
   * @param productId - The ID of the product to toggle
   */
  toggleProductCollapse(productId: string): void {
    // Check in onHold array
    const onHoldProduct = this.collapsableCard.onHold.find(
      (item: CollapsableCardItem): boolean => item.productId === productId,
    );
    if (onHoldProduct) {
      onHoldProduct.isOpen = !onHoldProduct.isOpen;
    }

    // Check in removed array
    const removedProduct = this.collapsableCard.removed.find(
      (item: CollapsableCardItem): boolean => item.productId === productId,
    );
    if (removedProduct) {
      removedProduct.isOpen = !removedProduct.isOpen;
    }
  }

  goToCart(): void {
    this.router.navigate(['cart']);
    const eventName = 'instruction-page-product-update-pop-up-go-to-cart';
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: eventName }));
  }

  ngOnDestroy(): void {
    this.initialLoadComplete = false;
  }
}
