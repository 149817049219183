import { Component, ViewChild } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { AddressFormComponent } from '@shared/address-form/address-form.component';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { WindowRefService } from '@services/window-ref-service';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'user-profile-info',
  templateUrl: './user-profile-edit.html',
  standalone: false,
})
export class UserProfileEditComponent {
  user: any;
  saveInProcess: boolean;
  formDetails: { [key: string]: unknown } = {};
  editFlag: boolean = true;
  address: any;
  allowedAccess: boolean;
  popUpModal: any = {};
  isEdit: boolean;
  loading: boolean = true;
  isLocatingAddress: boolean;
  valid: boolean = false;
  @ViewChild(AddressFormComponent) userFormComponent: AddressFormComponent;
  notificationReminders: { whatsappOptInStatus: boolean, usageReminderOptInStatus: boolean, waterReminderOptInStatus: boolean };

  constructor(private conn: ConnectionService,
    private broadcast: BroadcastService,
    private appWebBridge: AppWebBridgeService,
    private window: WindowRefService,
    private appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<any> {
    this.user = await this.conn.getActingUser();
    this.setUserNotificationReminders();
    this.loadFormData(this.user);
  }

  loadFormData(user: any): void {
    this.user = user;
    if (this.user) {
      this.formDetails = this.user?.get('contactDetails') || {};
      this.formDetails.contactName = this.user?.get('PatientName');
      this.formDetails.contactNumber = this.user?.get('MobileNumber');
      this.formDetails.alternateNumber = this.user?.get('alternateNumber');
    }
  }

  showPopup(): void {
    this.popUpModal = {
      open: true,
      title: this.appConfig.Shared.String.INVALID_PINCODE,
      type: this.appConfig.Dialog.CONFIRMATION,
      cancelText: this.appConfig.Shared.String.NO,
      okText: this.appConfig.Shared.String.OK,
      message: { type: this.appConfig.Shared.String.PINCODE },
    };
  }

  popUpClosed(result: any): void {
    this.popUpModal = { open: false };
    if (result.clickOnYes) {
      this.getLocationDetails();
    }
  }

  async saveForm(): Promise<any> {
    await this.userFormComponent.validateForm('parent');
    if (this.userFormComponent.pinCodeError) {
      this.showPopup();
    }
    if (!this.valid || this.saveInProcess) return;
    if (this.allowedAccess) {
      if (!this.userFormComponent.pinCodeError) {
        this.saveInProcess = true;
        try {
          await this.conn.updateUserData({
            alternateNumber: String(this.formDetails.alternateNumber || ''),
            PatientName: this.formDetails.contactName,
            contactDetails: this.formDetails,
          });
        } catch (error) {
          this.broadcast.broadcast('NOTIFY', { message: error.toString() });
        } finally {
          this.saveInProcess = false;
          this.editFlag = false;
        }
        this.back();
      }
    } else if (!this.allowedAccess && !this.userFormComponent.pinCodeError) {
      this.saveInProcess = true;
      try {
        await this.conn.updateUserData({
          alternateNumber: String(this.formDetails.alternateNumber || ''),
          PatientName: this.formDetails.contactName,
          contactDetails: this.formDetails,
        });
      } catch (error) {
        this.broadcast.broadcast('NOTIFY', { message: error.toString() });
      } finally {
        this.saveInProcess = false;
        this.editFlag = false;
      }
      this.back();
    }
  }

  getLocationDetails(): any {
    this.isLocatingAddress = true;
    if (this.appWebBridge.getLocationDetails()) return;
    this.window.nativeWindow.navigator.geolocation.getCurrentPosition((location: any): void => {
      this.getDetailedLocationFromCoordinates({ latitude: location.coords.latitude, longitude: location.coords.longitude });
    }, (): any => {
      this.isLocatingAddress = false;
      this.broadcast.broadcast('NOTIFY', { message: 'Location permission denied. Kindly enable permission in your browser settings.' });
    });
    this.allowedAccess = true;
  }

  /**
   * We call api to get array of address available around that latitude and longitude, From that array
   * 1. We pick the 'postal_code' and set it in address form (which will call a api to fetch city,state of that pincode)
   * 2. We pick the buildingDetails by filtering address which has type substring 'locality' and set it in address form.
   */
  async getDetailedLocationFromCoordinates(location: { [key: string]: string }): Promise<void> {
    const data = await this.conn.getDetailedLocationFromCoordinates(location);
    this.isLocatingAddress = false;
    let pincode: number;
    try {
      data.results.forEach((result: { [key: string]: any }): void => {
        if (result.types.includes('postal_code')) {
          result.address_components.forEach((addressComponent: { [key: string]: any }): void => {
            if (!pincode) pincode = Number(addressComponent.long_name);
          });
        }
      });
      if (pincode) this.userFormComponent.addressForm.controls.zipCode.setValue(pincode);
      const localityArr: any[] = data?.results[0]?.address_components || [];
      let locality: string;
      localityArr.forEach((each: { [key: string]: any }): void => {
        const found: boolean = !!each.types.find((type: string): boolean => type.toLowerCase().includes('locality'));
        if (found) locality = locality ? `${locality}, ${each.long_name}` : each.long_name;
      });
    } catch (err) {
      this.broadcast.broadcast('NOTIFY', { message: 'Error while getting location.' });
    }
  }

  async setUserNotificationReminders(): Promise<void> {
    const whatsappOptInStatus = this.user?.get('whatsappOptInStatus');
    const usageReminderOptInStatus = this.user?.get('usageReminderOptInStatus');
    const waterReminderOptInStatus = this.user?.get('waterReminderOptInStatus');
    this.notificationReminders = { whatsappOptInStatus, usageReminderOptInStatus, waterReminderOptInStatus };
  }

  async toggleNotificationSetting(event: any, type: string): Promise<void> {
    event.preventDefault();
    this.notificationReminders[type] = !this.notificationReminders[type];
    this.notificationReminders = { ...this.notificationReminders };
    await this.conn.updateUserData({ [type]: this.notificationReminders[type] });
    this.broadcast.broadcast('NOTIFY', { message: 'Reminder updated' });
  }

  enableEdit(): void {
    this.editFlag = true;
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}
