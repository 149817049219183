import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EventLoggerService } from '@services/event-logger-service';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { ApiClientConstant } from '@cureskin/api-client';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'user-view-regimen-consultation',
  templateUrl: './user-view-regimen-consultation.html',
  styleUrls: ['../user-view-regimen.scss'],
  standalone: false,
})
export class UserViewRegimenConsultationComponent implements AfterViewInit {
  user: any;
  showServiceDetail: boolean = false;
  socialProofCardIndex: number = 0;
  banners: any[];
  alternateRegimen: boolean;
  @ViewChild('socialProofCard', { static: false }) socialProofCard: ElementRef;
  @Input('regimen')
  set regimenObject(regimen: any) {
    if (!regimen) return;
    this.regimen = regimen;
    if (this.regimen.regimenId.includes('alternate')) this.alternateRegimen = true;
  }
  @Input('experiments')
  set experiments(data: any) {
    data.forEach((each: any): void => {
      if (each.key === 'regimen_banner') this.banners = each.variant.banners;
    });
  }
  @Input('socialProof')
  set proof(data: Array<any>) {
    this.socialProof = data;
  }
  regimen: any;
  regimenType: string;
  socialProof: Array<any> = [];
  socialProofContent: string = '';
  patientName: string;
  allocatedDoctor: any;
  constructor(
    private eventLogger: EventLoggerService,
    public appConfig: AppConfig,
    private conn: ConnectionService,
    private router: Router,
    private broadcast: BroadcastService) {}

  ngOnInit(): void {
    this.patientName = this.findUserDisplayName(this.user);
    this.user = this.conn.getActingUser();
    if (this.user?.get('allocatedDoctor') && this.user?.get('allocatedDoctor')?.id) {
      this.allocatedDoctor = this.user.get('allocatedDoctor');
    }
    this.regimenType = this.regimen.class.toLowerCase();
  }

  async startAgain(): Promise<any> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'unpaid-reanswer-tree' }));
    await this.conn.disablePreviousConsultationSessions();
    return this.conn.navigateToURL('/mainConcern?force=true');
  }

  ngAfterViewInit(): void {
    setTimeout((): void => this.loadNewSocialProof());
  }

  findUserDisplayName(user: any): string {
    const name = user?.get('PatientName') || '';
    return name.trim().replace(/\w\S*/g, (txt: string): string => (txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()));
  }

  loadNewSocialProof(): void {
    if (!this.socialProof.length || !this.socialProofCard || !this.socialProofCard.nativeElement) return;
    const ind: number = Math.floor(Math.random() * this.socialProof.length);
    this.socialProofCard.nativeElement.classList.remove(`bg-${this.socialProofCardIndex}`);
    this.socialProofCardIndex += 1;
    this.socialProofCardIndex = (this.socialProofCardIndex % 3) + 1;
    this.socialProofCard.nativeElement.classList.add(`bg-${this.socialProofCardIndex}`);
    let html: string = this.socialProof[ind].get('content');
    html = html.slice(1, html.length - 2);
    this.socialProofContent = html;
    setTimeout((): void => this.loadNewSocialProof(), 5 * 1000);
  }
  openTestimonial(): void {
    this.eventLogger.trackInParse('TESTIMONIAL', ApiClientConstant.Event.Type.ORDER);
    this.eventLogger.trackEvent('open_testimonial', { username: this.user.get('username') });
    this.eventLogger.trackInElasticSearch({ username: this.user.get('username'),
      added: new Date(),
      type: 'webApp',
      message: 'User clicked on testimonial link',
      event: 'OPEN_TESTIMONIAL' });
  }

  openDoctorProfile(): void {
    if (!this.allocatedDoctor) return;
    this.eventLogger.trackEvent('doctor_profile_clicked', { username: this.user.get('username') });
    this.router.navigate(['/user/regimen/doctor'],
      { queryParams: { class: this.regimen.class, username: this.user.get('username'), regimenId: this.regimen.regimenId } });
  }

  expandServices(serviceDetailElement: HTMLElement): void {
    this.showServiceDetail = !this.showServiceDetail;
    setTimeout((): void => {
      if (serviceDetailElement) serviceDetailElement.scrollIntoView();
    });
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}