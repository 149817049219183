import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'packing-order',
  templateUrl: './packing-order.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class PackingOrderComponent implements OnInit {
  @Input('isRebrandingBanner') isRebrandingBanner: boolean;
  @Input('order') order: any;
  @Output('addEvents') addEvents: EventEmitter<any> = new EventEmitter();
  user: any;
  constructor(
    public appConfig: AppConfig,
    private conn: ConnectionService,
  ) { }

  ngOnInit(): void {
    this.user = this.conn.getActingUser();
  }

  handleClick(): void {
    this.addEvents.emit();
  }
}
