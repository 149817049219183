import { Component } from '@angular/core';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';

@Component({
  selector: 'about',
  templateUrl: './about.html',
  standalone: false,
})
export class AboutComponent {
  user: any;
  static parameters: any = [BroadcastService, ConnectionService];
  constructor(
    private broadcast: BroadcastService,
    private conn: ConnectionService,
  ) {
    this.user = this.conn.getActingUser();
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}
