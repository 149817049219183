import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'input-switch',
  templateUrl: './switch.html',
  styleUrls: ['./switch.scss'],
  standalone: false,
})
export class SwitchComponent {
  @Input('colorClass') colorClass: string = 'bg-dark';
  @Input('checked') checked: boolean;
  @Input('disabled') disabled: boolean;
  @Output('checkedChange') checkedChange: EventEmitter<boolean> = new EventEmitter();
  @Output('callback') callback: EventEmitter<boolean> = new EventEmitter();
  constructor() {}

  changeState(event: any): void {
    if (this.disabled) {
      event.stopPropagation();
      return;
    }
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
    this.callback.emit(this.checked);
    event.stopPropagation();
  }
}
