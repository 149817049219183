import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'report-ready',
  templateUrl: './report-ready.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class ReportReadyComponent implements OnInit {
    @Input('followUpReport') followUpReport: any;
    @Output('openFollowUpReport') openFollowUpReport: EventEmitter<any> = new EventEmitter();
    constructor(
    public appConfig: AppConfig,
    ) { }

    ngOnInit(): void {
    }

    handleClick(): void {
      this.openFollowUpReport.emit();
    }
}
