import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgIf } from '@angular/common';
import { WindowRefService } from '@services/window-ref-service';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.html',
  imports: [NgIf],
})
export class ReadMoreComponent implements OnInit {
  @Input() text!: string;
  @Input('isExpanded') isExpanded: boolean = false;
  @ViewChild('textContainer') textContainer!: ElementRef;
  @ViewChild('textContent') textContent!: ElementRef;

  isOverflowing: boolean = false;
  displayText: string = '';
  originalText: string = '';
  containerHeight: number = 0;

  constructor(private readonly renderer: Renderer2,
    private readonly windowRef: WindowRefService) {}

  ngOnInit(): void {
    this.originalText = this.text;
    this.displayText = this.text;

    setTimeout((): void => {
      requestAnimationFrame((): void => {
        this.checkOverflow();
      });
    }, 300);
  }

  checkOverflow(): void {
    const container = this.textContainer.nativeElement;
    const content = this.textContent.nativeElement;

    this.renderer.setStyle(container, 'height', 'auto');
    const contentHeight = content.getBoundingClientRect().height;
    const containerHeight = container.offsetHeight;
    this.containerHeight = containerHeight;
    this.renderer.setStyle(container, 'height', '6rem');

    if (contentHeight > containerHeight) {
      this.isOverflowing = true;
      this.trimText();
    } else {
      this.isOverflowing = false;
      this.displayText = this.originalText;
    }
  }

  private trimText(): void {
    const container = this.textContainer.nativeElement;
    const maxHeight = this.containerHeight; // 6rem

    const text: string = this.originalText;
    const words: string[] = text.split(' ');

    // Create temporary span for measurement
    const tempSpan = this.renderer.createElement('span');
    this.renderer.setStyle(tempSpan, 'visibility', 'hidden');
    this.renderer.setStyle(tempSpan, 'position', 'absolute');
    this.renderer.setStyle(tempSpan, 'width', `${container.clientWidth - 32}px`);
    this.renderer.setStyle(tempSpan, 'padding', '2px');

    // Copy existing styles
    const computedStyle = this.windowRef.nativeWindow.getComputedStyle(this.textContent.nativeElement);
    this.renderer.setStyle(tempSpan, 'font-size', computedStyle.fontSize);
    this.renderer.setStyle(tempSpan, 'line-height', computedStyle.lineHeight);
    this.renderer.setStyle(tempSpan, 'font-family', computedStyle.fontFamily);

    this.renderer.appendChild(this.windowRef.nativeWindow.document.body, tempSpan);

    // Binary search for better performance with long text
    let left = 0;
    let right = words.length;
    let lastGoodCount = 0;

    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      tempSpan.innerText = `${words.slice(0, mid).join(' ')}... Read More`;

      if (tempSpan.getBoundingClientRect().height <= maxHeight - 4) { // Reduced padding slightly
        lastGoodCount = mid;
        left = mid + 1;
      } else {
        right = mid - 1;
      }
    }

    // Clean up
    this.renderer.removeChild(this.windowRef.nativeWindow.document.body, tempSpan);

    this.displayText = this.isExpanded
      ? this.originalText
      : words.slice(0, lastGoodCount).join(' ').trim();
  }

  toggleText(): void {
    this.isExpanded = !this.isExpanded;
    const container = this.textContainer.nativeElement;

    if (this.isExpanded) {
      this.displayText = this.originalText;
      this.renderer?.removeClass(container, 'tw-h-[60px]');
      this.renderer.setStyle(container, 'height', 'auto');
    } else {
      this.renderer?.addClass(container, 'tw-h-[60px]');
      this.renderer.removeStyle(container, 'height');
      this.trimText();
    }
  }

  closeText(): void {
    this.isExpanded = false;
    const container = this.textContainer.nativeElement;
    this.renderer?.addClass(container, 'tw-h-[60px]');
    this.renderer.removeStyle(container, 'height');
    this.trimText();
  }
}
