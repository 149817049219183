import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'checkup-scheduled',
  templateUrl: './checkup-scheduled.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class CheckupScheduledComponent implements OnInit {
  @Input('followUp') followUp: any;
  @Output('trackEvent') trackEvent: EventEmitter<any> = new EventEmitter();
  constructor(
    public appConfig: AppConfig,
  ) { }

  ngOnInit(): void {
  }

  handleClick(): void {
    this.trackEvent.emit('SCHEDULED_FOLLOWUP_BANNER_CLICKED');
  }
}
