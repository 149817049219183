import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { fromActions } from '@store/actions';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '@services/local-storage-service';
import { PaymentService } from '@services/payment-service';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { RazorpayService } from '@services/razorpay-service';
import { WindowRefService } from '@services/window-ref-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { CurrentComponentService } from '@services/current-component';
import { ApiClientConstant, ParseKeys, Table } from '@cureskin/api-client';
import { Observable } from 'rxjs';
import { PaymentMethodsTranslations } from 'e2e/src/shared/constants';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CCODBottomSheetComponent } from '@shared/bottom-sheet-layouts/ccod-bottom-sheet/ccod-bottom-sheet.component';
import { PrepaidDiscountSheetComponent } from '@shared/bottom-sheet-layouts/prepaid-discount/prepaid-discount-sheet.component';
import { CodHandlingFeeSheetComponent } from '@shared/bottom-sheet-layouts/cod-handling-fee-sheet/cod-handling-fee-sheet.component';
import { OrderHelper } from 'client/helper/order-helper';
import { ConfirmCodOrderComponent } from '@shared/bottom-sheet-layouts/confirm-cod-order/confirm-cod-order.component';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'user-order-payment',
  templateUrl: './user-order-payment.html',
  styleUrls: ['./user-order-payment.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})

export class UserOrderPaymentComponent implements OnDestroy {
  @ViewChild('paymentPage') paymentPage: ElementRef;
  @ViewChild('couponInput') couponInput: ElementRef;

  order: any;
  user: any;
  discount: number;
  razorpay: any;
  paytmConfig: any;
  paytmServerUrl: string;
  selectedPaymentMode: string;
  orderSP: number = 0;
  orderMRP: number;
  orderActualSP: number;
  orderBeforeOfferPrice: number;
  paymentGateways: any = {
    RAZORPAY: ['CARD', 'WALLET', 'NET_BANKING', 'UPI'],
    PAYTM: ['PAYTM'],
    COD: ['COD'],
    CCOD: ['COD'],
    PAY_PAL: ['PAY_PAL'],
  };
  walletBalance: number;
  paymentMethods: Array<{ title: string, mode: string, img?: string, desc?: string }> = [];
  forceRemoveCODOption: boolean = false;
  appliedCoupon: any;
  appliedWalletBalance: any;
  couponLoading: boolean = false;
  paymentProcessing: boolean = false;
  couponCode: string = '';
  couponExperimentActive: boolean = false;
  razorpayNativePayment: boolean = false;
  orderSummary: any = {};
  showOrderDetail: boolean;
  isPrepaidDiscountEnabled: boolean = false;
  showPrepaidDiscountInfo: boolean = false;
  prepaidDiscountCoupon: string = 'PREPAID100';
  prepaidDiscountKey: string = 'prepaid_offer';
  paymentMethodsTranslations: any;
  paymentMethodsAvailable: Array<any> = [
    {
      title: 'Credit/Debit Card',
      mode: AppConfig.Shared.Payment.paymentModes.CARD,
      img: 'https://assets.cureskin.com/img/app/card.svg',
    },
    {
      title: 'Netbanking',
      mode: AppConfig.Shared.Payment.paymentModes.NET_BANKING,
      img: 'https://assets.cureskin.com/img/app/netbanking.svg',
    },
    {
      title: 'UPI',
      mode: AppConfig.Shared.Payment.paymentModes.UPI,
      img: 'https://assets.cureskin.com/img/app/upi.svg',
    },
    {
      title: 'PayTm',
      mode: AppConfig.Shared.Payment.paymentModes.PAYTM,
      img: 'https://assets.cureskin.com/img/app/Paytm.svg',
    },
    {
      title: 'Mobile Wallets',
      mode: AppConfig.Shared.Payment.paymentModes.WALLET,
      img: 'https://assets.cureskin.com/img/app/wallet.svg',
    },
    {
      title: 'Cash On  Delivery',
      mode: AppConfig.Shared.Payment.paymentModes.COD,
      img: 'https://assets.cureskin.com/img/app/rupee.svg',
    },
  ];
  banners: Array<any> = [];
  enableOtherPayments: boolean = false;
  offerTimer: any = {};
  popUp: { open: boolean, type?: 'ORDER_SUMMARY' | 'COD_CONFIRMATION', data?: any, orderData?: any } = { open: false };
  experiments: any[];
  payment: any;
  paymentSecretKey: string;
  showCouponsSection: boolean = false;
  codConfirmationFee: boolean;
  enableChooseCouponExperiment: boolean;
  loading: boolean;
  coupons: any;
  isUPISelected: boolean = true;
  isPaidUser: boolean = false;
  interval: any;
  showCCODCharge: boolean = false;
  CCODInfo: any = {};
  cashBalance: any;
  startTimer:number;
  disableCod: boolean = false;
  isFirstOrder: boolean;
  isRegimenOrder: boolean;
  isCCODNotIncluded: boolean;
  isCodHandlingFeeEnabled: boolean;
  codHandlingFee: number = 0;
  showCodConfirmationBottomSheet: boolean = false;

  constructor(private readonly broadcast: BroadcastService,
    private readonly route: ActivatedRoute,
    private readonly conn: ConnectionService,
    private readonly razorpayService: RazorpayService,
    private readonly windowRef: WindowRefService,
    private readonly eventLogger: EventLoggerService,
    private readonly http: HttpClient,
    public readonly appConfig: AppConfig,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly appWebBridgeService: AppWebBridgeService,
    private readonly currentComponent: CurrentComponentService,
    private readonly locationService: Location,
    private readonly store: Store,
    private readonly bottomSheet: MatBottomSheet,
    private readonly paymentService: PaymentService,
    private readonly changeDetection: ChangeDetectorRef,
    private readonly localStorage: LocalStorageService,
    private readonly orderHelper: OrderHelper,
  ) {
    this.currentComponent.set(this);
    this.razorpayService.initialize();
  }

  /**
   * 1. Fetches the order by calling 'checkoutAndCreateOrder' api, which won't recreate order if exists. It returns the order data.
   * 2. Check if order stage is valid to visit the payment page. only INITIAL, PAYMENT_PENDING stage order are allowed.
   */
  async ngOnInit(): Promise<any> {
    this.startTimer = new Date().getTime();
    this.user = this.conn.getActingUser();
    this.paymentMethodsTranslations = PaymentMethodsTranslations;
    try {
      this.order = await this.conn.checkoutAndCreateOrder({ orderId: this.route.snapshot.params.id });
      await this.checkStatusOfOrder();
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.message });
      this.back();
      return;
    }
    this.getPaytmServerUrl();
    this.isPaidUser = [
      this.appConfig.Shared.User.OrderState.PROCESSING,
      this.appConfig.Shared.User.OrderState.DELIVERED,
    ].includes(this.user.get('orderState'));
    await this.processOrderData();
    this.checkForPaymentServices();
    this.setEventOnProductsOrder();
    this.setEventOnRegimenOrder();
    const time = new Date().getTime() - this.startTimer;
    this.eventLogger.trackEvent('payment_page_loading_time', { timeInMilliSec: time, userId: this.user.id });
    if (this.isPrepaidDiscountEnabled) {
      const hasUserAlreadySeenThePrepaidOffer = this.localStorage.getValue(this.prepaidDiscountKey);
      if (hasUserAlreadySeenThePrepaidOffer) {
        this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'online_discount_preapplied' }));
        await this.applyPrepaidCoupon();
      }
      this.prepaidDiscountInfoLogic();
    }
    this.changeDetection.detectChanges();

    const eventPayload = {
      pageName: 'payment_page_visited',
      isCODEnabled: this.order?.get('paymentGateway').includes(this.appConfig.Shared.Payment.paymentModes.COD),
      isCCODEnabled: this.order?.get('paymentGateway').includes(this.appConfig.Shared.Payment.paymentModes.CCOD),
      orderState: this.user.get('orderState'),
    };
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify(eventPayload));
  }

  prepaidDiscountInfoLogic(): void {
    const prepaidState = this.doesOrderHasPrepaidCouponApplied();
    const hasUserAlreadySeenThePrepaidOffer = this.localStorage.getValue(this.prepaidDiscountKey);
    this.showPrepaidDiscountInfo = prepaidState || hasUserAlreadySeenThePrepaidOffer;
  }

  getTranslatedString(title: string): string {
    const getTranslatedString = this.paymentMethodsTranslations[title];
    const userLanguage = this.user.get('languagePreference');
    if (getTranslatedString && userLanguage && userLanguage !== 'en') {
      return getTranslatedString[userLanguage];
    }
    return title;
  }

  checkForPaymentServices(): void {
    const chargesAndDiscounts = this.order?.get('chargesAndDiscounts');
    if (chargesAndDiscounts && chargesAndDiscounts.length) {
      this.CCODInfo = chargesAndDiscounts.find(
        (each: any): any => each.description === this.appConfig.Shared.Payment.Charges.COD_PROCESSING_CHARGES);
      this.showCCODCharge = true;
    }
  }

  /**
   * 1. Process the experiments.
   * 2. Process valid payment gateways for the order.
   * 3. Process total, discount, coupons, walletBalance of the order.
   * 4. If order amount is 0, then we by default initiate a online order.
   * 5. Else waits for payment selection and sets UPI as default payment option.
   *
   */
  async processOrderData(paymentMethod: any = ''): Promise<any> {
    this.cashBalance = await this.conn.fetchCashBalance();
    await this.processExperiments();
    this.processPaymentGateways();
    if (this.disableCod) {
      this.paymentMethods = this.paymentMethodsAvailable.filter((paymentMethod:any):any => (
        paymentMethod.mode !== this.appConfig.Shared.Payment.paymentModes.COD));
    } else {
      this.paymentMethods = this.paymentMethodsAvailable;
    }
    this.orderMRP = this.order.get('actualPrice');
    // To make the MRP uniform across the app
    await this.newVariantDiscountCalculation();
    this.orderSP = this.order.get('amount') - (this.order.get('paidAmount') || 0);
    this.orderBeforeOfferPrice = this.order.get('orderBeforeOfferPrice');
    this.walletBalance = this.order.get('walletAmount');
    this.calculateCouponDiscount();
    if (this.appliedWalletBalance) {
      let balance = this.walletBalance - (this.appliedWalletBalance.amount || 0);
      if (balance < 0) balance = 0;
      this.walletBalance = balance;
    }
    if (this.orderSP === 0) {
      await this.selectPaymentMethod(this.appConfig.Shared.Order.PaymentType.ONLINE);
    } else if (!paymentMethod) this.selectedPaymentMode = this.appConfig.Shared.Payment.paymentModes.UPI;
    else this.selectedPaymentMode = paymentMethod;
  }

  async newVariantDiscountCalculation(): Promise<void> {
    if (this.order.get('type') === 'REGIMEN' && this.order.get('regimen')) {
      const project: Array<ParseKeys<Table.Regimen>> = ['fixedPriceMRP'];
      const regimen = await this.conn.findRegimenById(this.order.get('regimen')?.id, project);
      if (regimen.get('fixedPriceMRP')) {
        const fixedPriceMRP = regimen.get('fixedPriceMRP');
        this.orderMRP = fixedPriceMRP;
      }
    }
  }

  /**
   * 1. 'choose_coupon_in_payment' - controls choose coupon feature (from list of available coupons).
   * 2. 'show_coupon_code' - controls whole coupon feature's visibility.
   * 3. 'checkout_banner' - controls banners, show banners mentioned in exp.variants.
   * 4. 'paypal_offer_banner' - add paypal offer banner to existing banners & adds PAYPAL as one of payment option.
   * 5. 'razorpay_native_payment' - opens NATIVE razorpay sdk, else WEB sdk.
   * 6. 'offer_timer_throughout_checkout' - shows offer discount timer.
   */
  async processExperiments(): Promise<void> {
    this.experiments = await this.conn.findUserActiveExperiments();
    this.experiments.forEach(async (experiment: any): Promise<void> => {
      if (experiment.key === 'choose_coupon_in_payment') {
        this.enableChooseCouponExperiment = true;
        this.fetchCoupons();
      } else if (experiment.key === 'checkout_banner') {
        this.banners.push(...experiment.variant.banners);
      } else if (experiment.key === 'paypal_offer_banner') {
        this.banners.push({ image: experiment.variant.imageLink });
        this.paymentMethodsAvailable.unshift({
          title: 'PayPal',
          mode: this.appConfig.Shared.Payment.paymentModes.PAY_PAL,
          img: 'https://assets.cureskin.com/img/app/payPal.svg',
          desc: experiment.variant.text || '50% upto to ₹500. For first-time PayPal users only.',
        });
      } else if (experiment.key === 'razorpay_native_payment') {
        this.razorpayNativePayment = true;
      } else if (experiment.key === 'offer_timer_throughout_checkout') {
        const timeLeft: any = (new Date(Number(experiment.variant.time)).getTime() - new Date().getTime());
        if (timeLeft > 0) {
          this.offerTimer = { endTime: timeLeft,
            title: experiment.variant.title[this.user.get('languagePreference') || this.appConfig.Shared.Languages.EN] };
        }
      } else if (experiment.key === 'prepaid_discount' && this.shouldEnablePrepaidDiscount()) {
        this.isPrepaidDiscountEnabled = true;
        this.prepaidDiscountCoupon = experiment.variant?.couponCode;
      } else if (experiment.key === 'cod_handling_fees' && this.shouldEnableCodHandlingFee()) {
        const codHandlingFeeData = this.order?.get('chargesAndDiscounts')?.find(
          (item: any):boolean => item.description === this.appConfig.Shared.Payment.Charges.COD_HANDLING_FEE);
        if (codHandlingFeeData) {
          this.codHandlingFee = codHandlingFeeData.amount;
          this.isCodHandlingFeeEnabled = true;
          this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'COD_handling_fee_subtext_shown' }));
        }
      } else if (experiment.key === 'double_confirm_cod_order' && this.orderHelper.isOrderTypeRegimen(this.order.get('type'))) {
        this.showCodConfirmationBottomSheet = true;
      }
    });
  }

  shouldEnablePrepaidDiscount(): boolean {
    this.isRegimenOrder = this.order?.get('type') === this.appConfig.Shared.Order.Type.REGIMEN;
    this.isFirstOrder = this.user?.get('orderState') === this.appConfig.Shared.User.OrderState.NEW_USER;
    this.isCCODNotIncluded = !this.order?.get('paymentGateway').includes(this.appConfig.Shared.Payment.paymentModes.CCOD);

    return this.isRegimenOrder && !this.isPaidUser && this.isFirstOrder && this.isCCODNotIncluded;
  }

  shouldEnableCodHandlingFee(): boolean {
    this.isRegimenOrder = this.order?.get('type') === this.appConfig.Shared.Order.Type.REGIMEN;
    this.isCCODNotIncluded = !this.order?.get('paymentGateway').includes(this.appConfig.Shared.Payment.paymentModes.CCOD);
    return this.isRegimenOrder && !this.isPaidUser && this.isCCODNotIncluded;
  }

  async fetchCoupons(): Promise<void> {
    this.coupons = JSON.parse(JSON.stringify(await this.conn.findAllActiveCoupons()));
  }

  /**
   * To track product orders
   */
  setEventOnProductsOrder(): void {
    if (this.order?.get('type') === this.appConfig.Shared.Order.Type.PRODUCT) {
      this.eventLogger.trackInFirebaseAndBranch('PRODUCT_VIEWED');
    }
  }
  /**
 * To track retention regimen orders
 */
  setEventOnRegimenOrder(): void {
    if (this.order?.get('type') === this.appConfig.Shared.Order.Type.REGIMEN && this.user.isPaid()) {
      this.eventLogger.trackInFirebaseAndBranch('REGIMEN_VIEWED');
    }
  }

  /**
   * 1. Filter the payment gateways mentioned in order and shows only those for this specific order.
   * 2. When RAZORPAY is one of the allowed gateway, then only we show OTHER payments section in UI (which will open razorpay home).
   */
  processPaymentGateways(): void {
    this.removeCODOptionIfDietOrder();
    const allowedPaymentModes = this.getAllowedPaymentModes();
    this.filterPaymentMethods(allowedPaymentModes);
    this.enableOtherPayments = this.isRazorpayEnabled();
  }

  private removeCODOptionIfDietOrder(): void {
    if (this.order?.get('serviceInfo')) {
      const isDietOrder = this.order?.get('serviceInfo')?.some((service: any): any => service.type
    === this.appConfig.Shared.Order.Type.DIET_CONSULTATION);
      if (isDietOrder) {
        this.disableCod = true;
        this.logCleverTapEventForDisableCOD();
      }
    }
  }

  private logCleverTapEventForDisableCOD(): void {
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'cod_not_available_shown' }));
  }

  private getAllowedPaymentModes(): string[] {
    const orderPaymentGateways: string[] = this.order.get('paymentGateway') || Object.keys(this.paymentGateways);
    const allowedPaymentModes = [];
    orderPaymentGateways.forEach((each: any): void => {
      allowedPaymentModes.push(...(this.paymentGateways[each] || []));
    });
    this.disableCod = !(orderPaymentGateways.includes('COD') || orderPaymentGateways.includes('CCOD'));
    if (this.disableCod) this.logCleverTapEventForDisableCOD();
    return allowedPaymentModes;
  }

  private filterPaymentMethods(allowedPaymentModes: string[]): void {
    this.paymentMethodsAvailable = this.paymentMethodsAvailable.filter((each: any): boolean => (
      each.mode === this.appConfig.Shared.Payment.PaymentSource.COD || allowedPaymentModes.includes(each.mode)
    ));
  }

  private isRazorpayEnabled(): boolean {
    const orderPaymentGateways: string[] = this.order.get('paymentGateway') || Object.keys(this.paymentGateways);
    return orderPaymentGateways.includes(this.appConfig.Shared.Payment.PaymentSource.RAZORPAY);
  }

  applyCouponCode(code: string): void {
    this.couponCode = code;
  }

  getPaytmServerUrl(): void {
    switch (this.windowRef.nativeWindow.location.hostname) {
      case 'app.cureskin.com': {
        this.paytmServerUrl = 'https://securegw.paytm.in/theia/processTransaction';
        break;
      }
      default: {
        this.paytmServerUrl = 'https://pguat.paytm.com/oltp-web/processTransaction';
        break;
      }
    }
  }

  selectPaymentMethod(paymentMode: string): void {
    if (this.loading) return;
    if (this.disableCod && paymentMode === this.appConfig.Shared.Payment.paymentModes.COD) return;
    this.selectedPaymentMode = paymentMode;
    if (!this.isPaidUser) {
      this.updateOrderPrice();
      this.discount = Math.floor(((this.orderMRP - this.orderSP) * 100) / this.orderMRP);
    }
    this.eventLogger.cleverTapEvent('paymentMode', JSON.stringify({ paymentMode: this.selectedPaymentMode }));
    this.appWebBridgeService.logEventInBranchAndFirebaseFromiOS({
      branch: { name: 'clickPaymentTypeSelected' },
      firebase: { name: 'clickPaymentTypeSelected' },
    });
    if (this.isPrepaidDiscountEnabled) {
      if (paymentMode !== this.appConfig.Shared.Payment.paymentModes.UPI) {
        this.removePrepaidCouponIfNotUPI(paymentMode);
      } else {
        const hasUserAlreadySeenThePrepaidOffer = this.localStorage.getValue(this.prepaidDiscountKey);
        if (hasUserAlreadySeenThePrepaidOffer) {
          this.applyPrepaidCoupon();
        }
      }
    }
  }

  async applyPrepaidCoupon(): Promise<void> {
    const isUPIPayment = this.selectedPaymentMode === this.appConfig.Shared.Payment.paymentModes.UPI;
    if (!this.doesOrderHasPrepaidCouponApplied() && isUPIPayment) {
      this.loading = true;
      this.changeDetection.detectChanges();
      this.applyCouponCode(this.prepaidDiscountCoupon);
      await this.applyCoupon(this.selectedPaymentMode, true);
      this.loading = false;
      this.changeDetection.detectChanges();
    }
  }

  async removePrepaidCouponIfNotUPI(paymentMode: string): Promise<void> {
    this.loading = true;
    if (this.doesOrderHasPrepaidCouponApplied()) {
      this.order = await this.conn.removeDiscountCoupon(this.prepaidDiscountCoupon, this.route.snapshot.params.id);
      this.order = await this.conn.checkoutAndCreateOrder({ orderId: this.route.snapshot.params.id });
      this.orderSP = this.order.get('amount') - (this.order.get('paidAmount') || 0);
      this.calculateCouponDiscount();
    }
    this.loading = false;
    delete this.appliedCoupon;
    this.changeDetection.detectChanges();
  }

  doesOrderHasPrepaidCouponApplied(): boolean {
    const appliedCoupons = this.order?.get('couponInfo');
    const hasPrepaidCouponApplied = appliedCoupons?.some((coupon: any): boolean => coupon?.code === this.prepaidDiscountCoupon);
    return hasPrepaidCouponApplied;
  }

  async checkStatusOfOrder(): Promise<any> {
    const paymentPendingStages = [this.appConfig.Shared.Order.Stage.INITIAL, this.appConfig.Shared.Order.Stage.ONLINE_PAYMENT_PENDING];
    if (paymentPendingStages.includes(this.order.get('stage'))) {
      return;
    }
    this.back();
  }

  /**
   * 1. Initiates the payment foe selected payment mode.
   * 2. Backend gives the payment object.
   * 3. If order price is 0, we show order success page.
   * 4. If payment has 'preCondition' mentioned of type (COD_CONFIRMATION_FEE), then it shows a popup asking for COD fee.
   * 5. Else opens the payment page.
   */
  async placeOrder(): Promise<void> {
    if (this.couponLoading) return;
    this.loading = true;
    this.logPurchaseEvents();
    const isCODPayment = this.selectedPaymentMode === this.appConfig.Shared.Payment.paymentModes.COD;
    if (isCODPayment && this.isPrepaidDiscountEnabled && !this.localStorage.getValue(this.prepaidDiscountKey) && this.isFirstOrder) {
      this.localStorage.setValue(this.prepaidDiscountKey, true);
      this.showPrepaidDiscountInfo = true;
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'online_discount_popup_shown' }));
      this.bottomSheet.open(PrepaidDiscountSheetComponent, {
        data: {
          orderSP: this.orderSP,
          userLanguage: this.user?.get('languagePreference'),
          prepaidClick: async (): Promise<void> => {
            this.loading = true;
            this.bottomSheet.dismiss();
            this.selectedPaymentMode = this.appConfig.Shared.Payment.paymentModes.UPI;
            await this.applyPrepaidCoupon();
            this.prepaidDiscountInfoLogic();
            this.loading = false;
            this.changeDetection.detectChanges();
          },
          codClick: (): void => {
            this.initiateOrderPayment();
            this.bottomSheet.dismiss();
            this.loading = false;
            this.changeDetection.detectChanges();
          },
          onDismiss: async (): Promise<void> => {
            this.loading = true;
            this.selectedPaymentMode = this.appConfig.Shared.Payment.paymentModes.UPI;
            await this.applyPrepaidCoupon();
            this.prepaidDiscountInfoLogic();
            this.loading = false;
            this.changeDetection.detectChanges();
          },
        },
        disableClose: true,
      });
    } else if (!this.paymentService.getCodHandlingFeePopupState() && this.isCodHandlingFeeEnabled && isCODPayment) {
      this.paymentService.setcodHandlingFeePopupShown();
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'COD_handling_fee_popup_shown' }));
      this.bottomSheet.open(CodHandlingFeeSheetComponent, {
        data: {
          charges: this.codHandlingFee,
          orderSP: this.orderSP,
          userLanguage: this.user?.get('languagePreference'),
          handleOnlinePayment: (): void => {
            this.loading = true;
            this.bottomSheet.dismiss();
            this.selectedPaymentMode = this.appConfig.Shared.Payment.paymentModes.UPI;
            this.updateOrderPrice();
            this.loading = false;
            this.changeDetection.detectChanges();
          },
          handleCodPayment: (): void => {
            this.loading = true;
            this.bottomSheet.dismiss();
            this.updateOrderPrice();
            this.initiateOrderPayment();
            this.bottomSheet.dismiss();
            this.changeDetection.detectChanges();
          },
          onDismiss: (): void => {
            this.loading = false;
            this.changeDetection.detectChanges();
          },
        },
      });
      this.loading = false;
    } else if (isCODPayment && this.showCodConfirmationBottomSheet) {
      const confirmOrderBottomSheetRef = this.bottomSheet.open(ConfirmCodOrderComponent, {
        data: {
          orderSp: this.orderSP,
          confirmCodOrder: (): void => {
            this.initiateOrderPayment();
          },
          payOnline: (): void => {
            this.selectedPaymentMode = this.appConfig.Shared.Payment.paymentModes.UPI;
          },
          backdropClass: 'bottomsheet-blur-overlay-sm',
        },
      });
      this.handleBottomSheetDismiss(confirmOrderBottomSheetRef);
    } else {
      await this.initiateOrderPayment();
    }
  }

  public async initiateOrderPayment(): Promise<void> {
    this.loading = true;
    const { COD, ONLINE }: { COD: string; ONLINE: string } = this.appConfig.Shared.Order.PaymentType;
    const payload = {
      orderId: this.order.id,
      gateway: this.selectedPaymentMode.toLowerCase(),
      customTab: this.appWebBridgeService.isCustomTabSupported(),
      paymentType: this.selectedPaymentMode === COD ? COD : ONLINE,
    };
    try {
      const result = await this.conn.initiateOrderPayment(payload);
      this.loading = false;
      this.payment = result?.payment;
      this.paymentSecretKey = result?.key;
      if (this.orderSP === 0) {
        this.moveToSuccessPage();
      } else {
        this.handleOrderPayment();
      }
    } catch (error: any) {
      this.loading = false;
      this.eventLogger.logError(error);
      this.notify('Some issue with the payment. Contact Team for further queries.');
    }
  }

  public updateOrderPrice(): void {
    if (this.selectedPaymentMode === this.appConfig.Shared.Order.PaymentType.COD && this.isCodHandlingFeeEnabled) {
      this.orderSP = this.order.get('amount') + this.codHandlingFee;
      return;
    }
    this.orderSP = this.order.get('amount') - (this.order.get('paidAmount') || 0);
  }

  public logPurchaseEvents(): void {
    this.eventLogger.trackInParse(`CLICK_BUY_${this.selectedPaymentMode}`, ApiClientConstant.Event.Type.ORDER);
    this.eventLogger.cleverTapEvent('paymentPageOpened', JSON.stringify({
      MRP: this.order?.get('actualPrice'),
      FinalPrice: this.order?.get('amount'),
      method: this.selectedPaymentMode,
      Orderid: this.order?.id,
      orderType: this.order?.get('type'),
    }));
    this.appWebBridgeService.logEventInBranchAndFirebaseFromiOS({
      branch: { name: 'clickOnPay' },
      firebase: { name: 'clickOnPay' },
    });
  }

  private handleOrderPayment(): void {
    if (this.payment
        && this.payment.get('preCondition')
        && this.payment.get('preCondition').includes(this.appConfig.Shared.Payment.PreCondition.COD_CONFIRMATION_FEE)) {
      this.showCodConfirmationFeePopup();
    } else {
      this.openPayment();
    }
    this.changeDetection.detectChanges();
  }

  /**
   * For COD order and order with price as 0, we move to order success page.
   * Else opens the payment sdk for selected mode.
   */
  openPayment(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'pay-now' }));
    switch (this.selectedPaymentMode) {
      case this.appConfig.Shared.Payment.paymentModes.PAY_PAL:
      case this.appConfig.Shared.Payment.paymentModes.NET_BANKING:
      case this.appConfig.Shared.Payment.paymentModes.UPI:
      case this.appConfig.Shared.Payment.paymentModes.CARD:
      case this.appConfig.Shared.Payment.paymentModes.WALLET:
      case this.appConfig.Shared.Payment.paymentModes.OTHER:
      case this.appConfig.Shared.Payment.paymentModes.PAYTM: {
        if (this.orderSP > 0) this.payThrough(this.selectedPaymentMode);
        else this.moveToSuccessPage();
        break;
      }
      case this.appConfig.Shared.Payment.paymentModes.COD: {
        this.processCODPurchase();
        break;
      }
      default: break;
    }
  }

  processCODPurchase(): void {
    this.eventLogger.trackEvent('cod_order', { username: this.user.get('username') });
    this.eventLogger.trackInElasticSearch({ username: this.user.get('username'),
      added: new Date(),
      type: 'webApp',
      orderType: this.order.get('type'),
      orderId: this.order.id,
      message: 'User placed COD order',
      event: 'COD_ORDER' });
    this.moveToSuccessPage();
  }

  async moveToSuccessPage(): Promise<void> {
    // Fetch the order and get GokWik data, if any
    const order = new Table.Order();
    order.id = this.order.id;
    await order.fetch();
    const gokWikData = this.getGokWikData(order);

    // Show loading spinner and track event in analytics
    this.showLoadingSpinner();
    this.trackOrderSuccessEvent();

    // Navigate to success page
    this.navigateToSuccessPage(order, gokWikData);
  }

  private getGokWikData(order: any): any {
    let gokWikData = {};
    if (order.get('gokWik')?.create) {
      gokWikData = {
        request_id: order.get('gokWik').create.request_id,
        gokwik_oid: order.get('gokWik').create.gokwik_oid,
        total: order.get('amount'),
        moid: order.get('gokWik').create.moid,
        mid: order.get('gokWik').create.mid,
        phone: order.get('gokWik').create.phone,
        order_type: order.get('paymentType') === 'COD' ? 'cod' : 'non-gk',
      };
    }
    return gokWikData;
  }

  private showLoadingSpinner(): void {
    this.loading = true;
  }

  private trackOrderSuccessEvent(): void {
    this.eventLogger.trackEvent('order_success');
    this.eventLogger.trackInParse('ORDER_SUCCESSFUL', ApiClientConstant.Event.Type.ORDER);
  }

  private navigateToSuccessPage(order: any, gokWikData: any): void {
    this.router.navigate(
      ['/user/orders/success'],
      {
        queryParams: {
          orderId: order.id,
          orderType: order.get('type'),
          amount: order.get('amount'),
          gokWik: encodeURIComponent(JSON.stringify(gokWikData)),
        },
      });
  }

  /**
   * Receives the 'key' payment key and sends to razorpay sdk with all other data to open the sdk payment page.
   * On failure retries 3 times to open the sdk.
   */
  openRazorpayPayment(payment: any, key: any, url: any, paymentMethod: string, retryCount: number = 3): void {
    const handleSuccess = (options: any): void => {
      if (!this.razorpayNativePayment || !this.appWebBridgeService.requestRazorpayPayment(options)) {
        this.razorpay = new this.windowRef.nativeWindow.Razorpay(options);
        this.razorpay.open();
      } else {
        this.broadcast.broadcast('LOADING', { status: false });
      }
      this.eventLogger.timeEvent('time_payment_initiate_to_complete');
    };

    const handleError = (err: any): void => {
      this.broadcast.broadcast('LOADING', { status: false });
      this.notify(err.message || err.toString());
    };

    const handleTimeout = (): void => {
      if (retryCount) {
        setTimeout((): void => this.openRazorpayPayment(payment, key, url, paymentMethod, retryCount - 1), 1000);
      } else {
        this.notify('Unable to open payment');
        this.broadcast.broadcast('LOADING', { status: false });
      }
    };

    this.eventLogger.trackEvent('payment_razorpay', { username: this.user.get('username') });
    this.trackRazorpayEvent('user selected razorpay payment');

    this.broadcast.broadcast('LOADING', { status: true });

    if (this.windowRef.nativeWindow.Razorpay) {
      this.getRazorpayOptions(payment, url, key, paymentMethod)
        .then(handleSuccess)
        .catch(handleError);
    } else {
      handleTimeout();
    }
  }

  private trackRazorpayEvent(event: any): void {
    if (event && event.detail && event.detail.event) {
      const eventType = event.detail.event;
      const eventData = event.detail.payload;
      this.eventLogger.trackEvent(`payment_razorpay_${eventType}`, { username: this.user.get('username') });
      this.eventLogger.trackInElasticSearch({ username: this.user.get('username'),
        added: new Date(),
        type: 'webApp',
        message: `razorpay event: ${eventType}`,
        event: `PAYMENT_RAZORPAY_${eventType}`,
        data: eventData });
    }
  }

  /**
   * We call api to generate hash for paytm payment for this order.
   * 1. We fill the payment hash config data in form and we submit it.
   * 2. Form data will hit the paytm server and load the paytm payment page on successful authentication.
   */
  openPaytmPayment(payment: any, url: any): void {
    this.trackPaytmPayment();
    this.generatePaytmHash(payment, url).subscribe((data: any): void => {
      this.paytmConfig = data;
      this.eventLogger.timeEvent('time_payment_initiate_to_complete');
      setTimeout((): void => this.submitPaytmForm(), 500);
    });
  }

  private trackPaytmPayment(): void {
    this.eventLogger.trackEvent('payment_paytm', { username: this.user.get('username') });
    this.eventLogger.trackInElasticSearch({
      username: this.user.get('username'),
      added: new Date(),
      type: 'webApp',
      message: 'User selected paytm payment',
      event: 'PAYMENT_PAYTM',
    });
  }

  private generatePaytmHash(payment: any, url: any): Observable<any> {
    return this.http.post(`${url}/api/paytm/generateHashForOrder`, {
      orderId: payment.id,
      url,
    });
  }

  private submitPaytmForm(): void {
    const form = this.windowRef.nativeWindow.document.formPaytm;
    if (form) {
      form.submit();
      this.broadcast.broadcast('LOADING', { status: false });
    }
  }

  /**
   * OTHER type - Opens the razorpay sdk home page with list of all possible payment options.
   */
  async openRazorpayHome(): Promise<void> {
    if (this.loading) return;
    this.loading = true;
    this.selectedPaymentMode = this.appConfig.Shared.Payment.paymentModes.OTHER;
    if (!this.isPaidUser) {
      this.updateOrderPrice();
      this.discount = Math.floor(((this.orderMRP - this.orderSP) * 100) / this.orderMRP);
    }
    await this.removePrepaidCouponIfNotUPI(this.selectedPaymentMode);
    this.loading = false;
    this.placeOrder();
  }

  /**
   * Checks the payment mode and redirects to specific sdk.
   * In case of paypal, if sends the payment.gatewayPayment which holds the gateway links of paypal payment page.
   */
  payThrough(paymentMode: string): void {
    if (!this.payment) {
      this.broadcast.broadcast('NOTIFY', { message: 'No payment found. Contact Team.' });
      return;
    }

    this.broadcast.broadcast('LOADING', { status: true });

    const url = this.conn.getParseUrl();
    const key = this.paymentSecretKey;

    this.trackPaymentEvents(paymentMode);
    const razorpayPaymentMethod = this.getRazorpayPaymentMethod(paymentMode);

    switch (paymentMode) {
      case this.appConfig.Shared.Payment.paymentModes.PAYTM:
        this.openPaytmPayment(this.payment, url);
        break;
      case this.appConfig.Shared.Payment.paymentModes.PAY_PAL:
        this.openPayPalPayment(this.payment.get('gatewayPayment'));
        break;
      default:
        this.openRazorpayPayment(this.payment, key, url, razorpayPaymentMethod, 3);
        break;
    }
  }

  private trackPaymentEvents(paymentMode: string): void {
    this.eventLogger.trackEvent('time_signup_to_payment_initiate', {});
    this.eventLogger.timeEvent('time_payment_initiate_to_complete', true);
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'payment' }));
    this.appWebBridgeService.logEventInBranchAndFirebaseFromiOS({
      branch: { name: 'pageOpenPaymentPage' },
      firebase: { name: 'pageOpenPaymentPage' },
    });
  }

  private getRazorpayPaymentMethod(paymentMode: string): string {
    switch (paymentMode) {
      case this.appConfig.Shared.Payment.paymentModes.NET_BANKING:
        return 'netbanking';
      case this.appConfig.Shared.Payment.paymentModes.UPI:
        return 'upi';
      case this.appConfig.Shared.Payment.paymentModes.CARD:
        return 'card';
      case this.appConfig.Shared.Payment.paymentModes.WALLET:
        return 'wallet';
      default:
        return null;
    }
  }

  /**
   * Prepares the config json for razorpay sdk by setting the payment key and other data of user, order, payment mode,
   */
  async getRazorpayOptions(payment: any, url: any, key: any, paymentMethod: string): Promise<any> {
    const description = this.getDescription(payment);

    const options = {
      key,
      order_id: payment.get('razorpayOrderId'),
      callback_url: `${url}/api/razorpay/callbackForOrder`,
      amount: payment.get('TransactionAmount'),
      name: 'CureSkin',
      description,
      image: 'https://cdn.cureskin.com/app/img/logo-512.png',
      modal: {
        ondismiss: (): void => this.zone.run((): void => {
          this.broadcast.broadcast('LOADING', { status: false });
        }),
      },
      prefill: {
        contact: payment.get('UserMobileNumber') || this.user.get('MobileNumber'),
        email: payment.get('UserEmailId') || 'payments@cureskin.com',
        method: paymentMethod,
      },
      notes: this.getNotes(payment),
    };

    return Promise.resolve(options);
  }

  getDescription(payment: any): string {
    if (payment.get('preCondition')
      && payment.get('preCondition').includes(this.appConfig.Shared.Payment.PreCondition.COD_CONFIRMATION_FEE)) {
      return 'Cod Charges';
    }
    return `${payment.get('paymentPlan').charAt(0).toUpperCase()}${payment.get('paymentPlan').slice(1).toLowerCase()} Charges`;
  }

  getNotes(payment: any): any {
    return {
      order_number: this.order.get('orderNumber'),
      userId: payment.get('userId'),
      regimenId: payment.get('regimenId'),
      paymentType: payment.get('paymentPlan'),
      paymentTableObjectId: payment.id,
    };
  }

  /**
   * On pay fee confirmation, we go back(). which will close the popup.
   * And set the UPI as payment mode to pay the fee of 20RS online to confirm the COD order.
   */
  payCodConfirmationFee(): void {
    this.eventLogger.trackEvent(`PAY_COD_CONFIRMATION_FEE_${this.selectedPaymentMode}`,
      { type: ApiClientConstant.Event.Type.ORDER });
    this.paymentMethods = this.paymentMethodsAvailable.filter((each: any): boolean => (
      each.mode !== this.appConfig.Shared.Payment.paymentModes.COD
    ));
    this.codConfirmationFee = true;
    this.selectedPaymentMode = this.appConfig.Shared.Payment.paymentModes.UPI;
    this.changeDetection.detectChanges();
  }

  /**
   * Resets the COD fee process and continues the order in online mode.
   * Since the payment mode is set as UPI in 'payCodConfirmationFee' function itself. So we simply call placeOrder() again,
   * which will call api to update the order payment option as UPI and removes the 'preCondition' from payment response.
   */
  resetCodFeeAndPayOnline(): void {
    this.codConfirmationFee = false;
    this.eventLogger.trackEvent(`CANCEL_COD_CONFIRMATION_FEE_${this.selectedPaymentMode}`,
      { type: ApiClientConstant.Event.Type.ORDER });
    this.placeOrder();
  }

  notify(message: any): void {
    this.broadcast.broadcast('NOTIFY', { message });
  }

  /**
   * 1. Fetches the coupons (of type NORMAL, USER_REFERRAL) applied to the order and store in appliedCoupon,
   *    which will not show apply coupon feature if its already applied.
   * 2. Fetches the applied wallet balance, which is a coupon of type 'CURE_SKIN_CASH'.
   * 3. we add the sum of coupon value + applied wallet balance value to SP price of order (not MRP).
   * 4. Finally discount is calculated.
   */
  calculateCouponDiscount(): void {
    const couponInfo = this.order.get('couponInfo') || [];
    this.appliedCoupon = couponInfo.find((coupon: any): boolean => [
      this.appConfig.Shared.Coupon.TYPE.NORMAL,
      this.appConfig.Shared.Coupon.TYPE.USER_REFERRAL,
    ].includes(coupon.type));
    this.appliedWalletBalance = couponInfo.find((coupon: any): boolean => [
      this.appConfig.Shared.Coupon.TYPE.CURE_SKIN_CASH,
    ].includes(coupon.type));
    const actualSP = this.orderSP + (this.appliedWalletBalance?.amount || 0) + (this.appliedCoupon?.amount || 0);
    this.orderActualSP = actualSP;
    if (this.order.get('type') === this.appConfig.Shared.Order.Type.REGIMEN) {
      if (this.isPrepaidDiscountEnabled) {
        this.discount = Math.floor(((this.orderMRP - this.orderSP) * 100) / this.orderMRP);
      } else {
        this.discount = Math.floor(((this.orderMRP - actualSP) * 100) / this.orderMRP);
      }
    } else {
      this.discount = Math.floor(((this.orderMRP - this.orderSP) * 100) / this.orderMRP);
    }
    if (this.appliedCoupon) {
      this.showCouponsSection = false;
    }
  }

  /**
   * Applies coupon to the order adn we re-fetch the order to re-calculate discount on latest order data.
   */
  async applyCoupon(paymentMode: any = '', skipToastMessage: boolean = false): Promise<any> {
    if (!this.couponCode || this.couponLoading) return;
    try {
      this.couponLoading = true;
      await this.conn.applyDiscountWith(this.couponCode, this.order.id);
      if (!skipToastMessage) this.notify('Coupon Applied');
      this.order = await this.conn.checkoutAndCreateOrder({ orderId: this.order.id });
      await this.processOrderData(paymentMode);
      this.orderSP = this.order.get('amount') - (this.order.get('paidAmount') || 0);
      this.calculateCouponDiscount();
    } catch (err) {
      const error = JSON.parse(JSON.stringify(err));
      this.notify(error?.message || err.toString());
      // eslint-disable-next-line consistent-return
      Promise.reject(err);
    } finally {
      this.couponLoading = false;
    }
    this.changeDetection.detectChanges();
  }

  /**
   * Process the gateway links and filters the approved one and sends to android to open the link in a new tab.
   * Else opens in web browser.
   */
  private async openPayPalPayment(payPalResponse: any): Promise<any> {
    const { href }: any = payPalResponse.links.find((each: any): boolean => each.rel === 'approve');
    const approvalURL = `${href}&locale.x=en_IN&country.x=IN`;
    this.eventLogger.timeEvent('time_payment_initiate_to_complete');
    if (this.appWebBridgeService.requestOpenInOverlayWindow(approvalURL)) {
      return;
    }
    this.windowRef.nativeWindow.location = approvalURL;
  }

  openCouponsSection(): void {
    this.showCouponsSection = true;
    setTimeout((): void => {
      this.couponInput.nativeElement.focus();
    });
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'apply-coupon' }));
    if (this.paymentPage?.nativeElement) {
      setTimeout((): void => this.paymentPage.nativeElement?.scroll(0, this.paymentPage.nativeElement.scrollHeight));
    }
  }

  showOrderDetailsPopup(): void {
    const products = this.order.get('productInfo');
    this.orderSummary = {
      products,
      services: this.order.get('services'),
      totalSP: this.orderSP,
      totalMRP: this.orderMRP,
    };
    this.popUp.open = true;
    this.popUp.type = 'ORDER_SUMMARY';
  }

  showCodConfirmationFeePopup(): void {
    this.popUp.open = true;
    this.popUp.data = this.payment;
    this.popUp.orderData = this.order;
    this.popUp.data.total = this.orderSP;
    this.popUp.type = 'COD_CONFIRMATION';
    this.bottomSheet.open(CCODBottomSheetComponent, {
      data: {
        popupData: this.popUp.data,
        orderData: this.popUp.orderData,
        isCCODChargeApplicable: this.showCCODCharge,
        callback: (): void => {
          this.payCodConfirmationFee();
        },
      },
    });
  }

  /**
   * If razorpay popup is open, wwe close the popup annd then go back.
   */
  async handleBackPress(): Promise<boolean> {
    if (this.razorpay && this.razorpay.close) {
      this.razorpay.close();
      this.locationService.back();
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  }

  /**
   * We close the razorpay popup and empty the cart products.
   */
  ngOnDestroy(): void {
    if (this.razorpay && this.razorpay.close) {
      this.razorpay.close();
    }
    this.store.dispatch(fromActions.CartUpdateProductsBegin({ products: {} }));
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  handleBottomSheetDismiss(ref: MatBottomSheetRef<any>): void {
    ref.afterDismissed().subscribe((): void => {
      this.loading = false;
      this.changeDetection.detectChanges();
    });
  }
}
