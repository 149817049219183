import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'checkup-anytime',
  templateUrl: './checkup-anytime.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class CheckUpAnytimeComponent implements OnInit {
  @Input('followUp') followUp: any;
  @Output('startFollowUp') startFollowUp: EventEmitter<any> = new EventEmitter();
  constructor(
    public appConfig: AppConfig,
  ) { }

  ngOnInit(): void {
  }

  handleClick(): void {
    this.startFollowUp.emit('ANYTIME_FOLLOWUP_BANNER');
  }
}
