import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { LocalStorageService } from '@services/local-storage-service';
import { EventLoggerService } from '@services/event-logger-service';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { AppConfig } from '../../app.config';
import { GenderFormErrorMessages, GenderFormErrorMessagesType } from '../../../../e2e/src/shared/constants';

@Component({
  selector: 'onboarding-gender',
  templateUrl: './onboarding-gender.html',
  standalone: false,
})
export class OnboardingGenderComponent implements OnInit {
  gender: string = '';
  loading: boolean;
  patientName: string = '';
  age: string = '';
  valid: boolean = true;
  startTimer: number = 0;
  nameError: boolean = false;
  ageError: boolean = false;
  nameErrorMessage: string = '';
  ageErrorMessage: string = '';
  saveGenderInfo: boolean = false;
  protected readonly GenderFormErrorMessages: GenderFormErrorMessagesType = GenderFormErrorMessages;
  @ViewChild('nameInput') myNameInputField: ElementRef;
  @ViewChild('ageInput') myAgeInputField: ElementRef;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  constructor(public conn: ConnectionService,
    private router: Router,
    private storage: LocalStorageService,
    private eventLogger: EventLoggerService,
    public appConfig: AppConfig,
    public commonUtilityHelper: CommonUtilityHelper,
    private route: ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {
    this.startTimer = new Date().getTime();
    this.gender = this.storage.getValue('CureSkin/gender') || this.appConfig.Shared.Gender.NONE;

    let fromLocationPage: string;
    this.route.queryParams.subscribe((params: any): void => {
      fromLocationPage = params?.fromLocationPage;
    });
    if (fromLocationPage) {
      const user = this.conn.getActingUser();
      this.patientName = user?.get('PatientName');
      this.age = user?.get('Age');
      const userGender = user?.get('Gender');
      this.markGenderTo(userGender);
    }
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'onboarding-gender-selection' }));
    await this.conn.logPrivacyPolicyDate();
    const time = new Date().getTime() - this.startTimer;
    this.eventLogger.trackEvent('gender_screen_time', { timeInMillSec: time });
  }

  validateName(): void {
    // eslint-disable-next-line max-len
    const numbersAndSpecialCharacterRegex: RegExp = /[0-9$&+.,:;=?@#|"'<>^*()%!_{}\\/]|[\u{1F300}-\u{1FAFF}\u{1F600}-\u{1F64F}\u{2700}-\u{27BF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}]/u;
    if (numbersAndSpecialCharacterRegex.test(this.patientName)) {
      this.valid = false;
      this.nameError = true;
      this.myNameInputField.nativeElement.focus();
      this.nameErrorMessage = GenderFormErrorMessages.numberInName;
      return;
    }
    if (this.patientName?.split(' ')?.length > 0) {
      if (this.patientName.split(' ')[0] === '') {
        this.valid = false;
        this.nameError = true;
        this.myNameInputField.nativeElement.focus();
        this.nameErrorMessage = GenderFormErrorMessages.emptyName;
        return;
      }
    }
    if (this.patientName?.split(' ')?.length > 3) {
      this.valid = false;
      this.nameError = true;
      this.myNameInputField.nativeElement.focus();
      this.nameErrorMessage = GenderFormErrorMessages.maxNameLength;
      return;
    }
    this.valid = true;
    this.nameError = false;
  }

  onNameChange(): void {
    this.validateName();
  }

  markGenderTo(gender: string): void {
    this.gender = gender;
  }

  /**
   * After updating gender, age, name information, we call 'redirectToLastKnowLocation' which will route user to any cache url
   * or '/onboarding/concern' (which is the next step of onboarding).
   * @returns {Promise<any>}
   */
  async updateGender(): Promise<any> {
    this.saveGenderInfo = true;
    this.validateName();
    setTimeout((): void => {
      this.scrollToBottom();
    }, 100);
    if (!this.valid) return;
    if (!this.age) {
      this.ageErrorMessage = GenderFormErrorMessages.enterAge;
      this.ageError = true;
      this.myAgeInputField.nativeElement.focus();
      return;
    }
    if (![this.appConfig.Shared.Gender.FEMALE, this.appConfig.Shared.Gender.MALE].includes(this.gender)) {
      this.eventLogger.trackEvent('gender_not_selected', {});
      this.eventLogger.setUserProperty({ people_gender: 'NotSelected' });
      return;
    }
    this.loading = true;
    this.eventLogger.trackEvent('gender_selected', { gender: this.gender });
    this.eventLogger.setUserProperty({ people_gender: this.gender });
    if (Number(this.age) <= this.appConfig.Shared.User.Age.MIN_AGE) {
      this.myAgeInputField.nativeElement.focus();
      this.ageErrorMessage = GenderFormErrorMessages.underAged;
      this.eventLogger.trackEvent('age_validation_failed', { age: Number(this.age) });
      delete this.age;
      this.loading = false;
      return;
    }
    if (Number(this.age) >= 100) {
      this.myAgeInputField.nativeElement.focus();
      this.ageErrorMessage = GenderFormErrorMessages.invalidAge;
      this.eventLogger.trackEvent('age_validation_failed', { age: Number(this.age) });
      delete this.age;
      this.loading = false;
      return;
    }
    const time = new Date().getTime() - this.startTimer;
    this.eventLogger.trackEvent('onboarding_gender_page_time', { timeInMilliSec: time });

    await this.conn.updateUserData({ Gender: this.gender, Age: String(this.age), PatientName: this.patientName });

    await this.router.navigate(['/onboarding/location']);
  }

  scrollToBottom(): void {
    if (this.scrollContainer) {
      const container = this.scrollContainer.nativeElement;
      container.scrollTop = container.scrollHeight;
    }
  }

  // This method is for disabling input field paste event.
  onInputPaste(event: ClipboardEvent): void {
    event.preventDefault();
  }

  isNameValid(name: string): boolean {
    return /^[a-zA-Z ]+$/.test(name);
  }

  sanitizeAgeInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    this.age = input.value;
  }
}
