import { Directive } from '@angular/core';

@Directive({
  selector: 'take-photo-banner-caption',
  host: {
    class: 'tw-font-body tw-text-200 tw-text-gray-80 tw-font-medium',
  },
  standalone: false,
})

export class TakePhotoBannerCaptionDirective {
  data: any;
}
