import { Component, Output, Input, OnInit, EventEmitter, input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'regimen-preparation-banner',
  templateUrl: './regimen-prep.component.html',
  standalone: false,
})
export class RegimenPrepComponent implements OnInit {
  @Input('regimenClass')regimenClass: string;
  constructor(
    private router:Router,
  ) { }
  ngOnInit(): void { }

  async handleClick():Promise<void> {
    this.router.navigate(['/user'], { queryParams: { tab: 'regimen', class: this.regimenClass } });
  }
}
