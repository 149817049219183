import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { HairTestimonialObject, TestimonialStoriesObject, TestimonialStory } from 'e2e/src/shared/constants';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'testimonials-description',
  templateUrl: './testimonials-description.html',
  styleUrls: [],
  standalone: false,
})

export class TestimonialsDescComponent implements OnInit {
  @Input('showAsCarousel') showAsCarousel: boolean = false;
  @Input('testimonialIndex') testimonialIndex: number = 0;
  testimonialID: number;
  testimonial: TestimonialStory;
  user: any;
  concern: string;
  constructor(private route: ActivatedRoute,
    private broadcast: BroadcastService,
    private conn: ConnectionService,
    private eventLogger: EventLoggerService,
    private appConfig: AppConfig,
    private router: Router) {}

  ngOnInit(): void {
    if (!this.showAsCarousel) {
      this.route.paramMap.subscribe((params: ParamMap): void => {
        this.testimonialID = Number(params.get('testimonialId'));
        this.concern = params?.get('type');
      });
    } else {
      this.testimonialID = this.testimonialIndex;
    }
    this.user = this.conn.getActingUser();
    const language = this.user?.get('languagePreference') || this.appConfig.Shared.Languages.EN;
    if (this.concern === 'HAIR') {
      this.testimonial = HairTestimonialObject[language][this.testimonialID];
    } else {
      this.testimonial = TestimonialStoriesObject[language][this.testimonialID];
    }
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'testimonials-description' }));
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  redirectToRegimenPage(): void {
    this.router.navigate(['/user'], { queryParams: { tab: 'regimen' } });
  }
}
