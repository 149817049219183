import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service/index';
import { WindowRefService } from '@services/window-ref-service/index';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'doctor-view',
  templateUrl: './doctor-view.html',
  standalone: false,
})
export class DoctorViewComponent {
  doctor: any;

  constructor(private broadcast: BroadcastService,
    private window: WindowRefService,
    public appConfig: AppConfig,
    private route: ActivatedRoute,
    private conn: ConnectionService) {}

  async ngOnInit(): Promise<void> {
    this.doctor = await this.conn.findUserByObjectId(this.route.snapshot.params.id, false, { translate: true });
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}
