import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as EXIF from 'exif-js';
import { Router } from '@angular/router';
import { WindowRefService } from '@services/window-ref-service';
import { ConnectionService } from '@services/connection-service';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { AppConfig } from '../../../../../../app.config';

@Component({
  selector: 'image-card',
  templateUrl: './image-card.html',
  styleUrls: ['./image-card.scss'],
  standalone: false,
})
export class ImageCardComponent {
  chat: any;
  images: Array<any>;
  loading: boolean = true;
  orientation: any;
  instantCheckupId: any;
  username: any;
  @ViewChild('imgHolder', { static: false }) imgHolder: ElementRef;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @Input()
  set data(images: Array<any>) {
    this.images = images.map((each: any): void => each);
    this.chat = this.images.pop();
    if (this.images.length > 1) this.clubbed = true;
    setTimeout((): void => this.loadImage());
  }
  @Output('close') close: EventEmitter<any> = new EventEmitter();
  @Output('expandImages') expandImages: EventEmitter<any> = new EventEmitter();
  @Output('openImageModal') openImageModal: EventEmitter<any> = new EventEmitter<any>();
  @Input('inMultiView') inMultiView: boolean = false;
  clubbed: boolean = false;

  constructor(private dom: DomSanitizer,
    private appConfig: AppConfig,
    private window: WindowRefService,
    private conn: ConnectionService,
    private router: Router,
    private commonUtil: CommonUtilityHelper) {
  }

  ngOnInit(): void {
    this.username = this.conn.getActingUser().get('username');
  }

  loadImage(): any {
    const image: any = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = (): void => {
      this.loading = false;
      setTimeout((): void => this.drawImage(image), 0);
    };
    image.onerror = async (): Promise<any> => {
      this.loading = true;
      if (this.clubbed) {
        if (this.images.length > 1) this.chat = this.images.pop();
        else {
          if (await this.isImageDeleted(image.src)) this.chat.set('imageDeleted', true);
          else this.chat.set('unableToFetch', true);
          return;
        }
        image.src = this.getImageUrl(this.chat);
        return;
      }
      if (await this.isImageDeleted(image.src)) this.chat.set('imageDeleted', true);
      else this.chat.set('unableToFetch', true);
    };
    image.src = this.getImageUrl(this.chat);
  }

  async isImageDeleted(src: any): Promise<any> {
    const result: any = await fetch(src).catch((): Object => ({ status: 500 }));
    return result.status === 404;
  }

  drawImage(image: any): void {
    const rotationNeeded = this.commonUtil.rotationNeeded();
    if (rotationNeeded) {
      EXIF.getData(image, (): void => {
        this.orientation = EXIF.getTag(image, 'Orientation') || 0;
        this.checkForOrientationAndDrawImage(image);
      });
    } else {
      this.orientation = -1;
      this.checkForOrientationAndDrawImage(image);
    }
  }

  checkForOrientationAndDrawImage(image: any): void {
    const cardWidth = this.window.nativeWindow.innerWidth * 0.50;
    if (!this.imgHolder || !this.imgHolder.nativeElement) {
      this.imgHolder = { nativeElement: '' };
      this.imgHolder.nativeElement = this.window.nativeWindow.document.getElementById('imgHolder');
    }
    if (!this.canvas || !this.canvas.nativeElement) {
      this.canvas = { nativeElement: '' };
      this.canvas.nativeElement = this.window.nativeWindow.document.getElementById('chatImageCanvas');
    }
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.canvas.nativeElement.width = cardWidth;
    if ([5, 6, 7, 8].includes(this.orientation)) {
      if (image.height <= cardWidth) {
        this.canvas.nativeElement.height = image.width * (cardWidth / image.height);
      } else {
        this.canvas.nativeElement.height = image.width / (image.height / cardWidth);
      }
      this.commonUtil.rotateCanvasBasedOnOrientation(this.orientation, ctx, this.canvas);
      ctx.drawImage(image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        this.canvas.nativeElement.height,
        this.canvas.nativeElement.width);
    } else {
      if (image.width <= cardWidth) {
        this.canvas.nativeElement.height = image.height * (cardWidth / image.width);
      } else {
        this.canvas.nativeElement.height = image.height / (image.width / cardWidth);
      }
      this.commonUtil.rotateCanvasBasedOnOrientation(this.orientation, ctx, this.canvas);
      ctx.drawImage(image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        this.canvas.nativeElement.width,
        this.canvas.nativeElement.height);
    }
    this.imgHolder.nativeElement.style.width = `${this.canvas.nativeElement.clientWidth}px`;
  }

  async openImage(): Promise<any> {
    if (!this.instantCheckupId) {
      const instantCheckup = await this.conn.fetchInstantCheckupByImageUrl(this.conn.getActingUser(), this.chat.get('Message'));
      this.instantCheckupId = instantCheckup?.id;
    }
    if (!this.instantCheckupId) {
      return this.openImageModal.emit();
    }
    if (this.inMultiView) {
      return this.close.emit(this.instantCheckupId);
    }

    let queryParams: any = {};
    if (this.chat?.className === 'SupportChat') {
      queryParams = { ...(this.chat?.get('context') || {}) };
    }
    return this.router.navigate([`/user/instantCheckup/${this.instantCheckupId}`], { queryParams });
  }

  getImageUrl(chat: any): string {
    return chat.get('tempCompressedImage') || chat.get('tempThumbnailUrl') || chat.get('tempUrl') || chat.get('Message');
  }
}
