import { Component, OnDestroy, NgZone, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { ConnectionService } from '@services/connection-service';
import { LocalStorageService } from '@services/local-storage-service';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';
import { LoginService } from '@services/login-service/login.service';
import { WindowRefService } from '@services/window-ref-service';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'acquisition-waiting-for-call',
  templateUrl: './re-acquisition-waiting-for-call.html',
  standalone: false,
})

export class ReAcquisitionUserWaitingForCallComponent {
  constructor(
    private conn: ConnectionService,
    private storage: LocalStorageService,
    private broadcast: BroadcastService,
    private eventLogger: EventLoggerService,
    public appConfig: AppConfig,
    private loginService: LoginService,
    private windowRefService: WindowRefService,
    public commonUtilityHelper: CommonUtilityHelper) {
  }

  ngOnInit(): void {
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 're-acquisition-waiting-for-call' }));
  }

  back(): void {
    this.conn.navigateToURL('/user?tab=home', false, true);
  }

  submit(): void {
    this.conn.navigateToURL('/mainConcern', false, true);
  }
}
