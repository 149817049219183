import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EventLoggerService } from '@services/event-logger-service';
import { ConnectionService } from '@services/connection-service';

@Component({
  selector: 'n-p-s-banner',
  templateUrl: './n-p-s-banner.html',
  styleUrls: ['./n-p-s-banner.scss'],
  standalone: false,
})
export class NPSBannerComponent {
  @Input('feedback') feedback: any;
  user: any;
  userLanguage: string;
  languageWithIssues: Array<string> = ['ta', 'te'];
  npsBannerExperiment: boolean = false;

  constructor(private router: Router, private eventLogger: EventLoggerService, private conn: ConnectionService) {
  }
  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
    this.userLanguage = this.user.get('languagePreference');
    const experiments = await this.conn.findUserActiveExperiments();
    experiments.forEach((exp: any): void => {
      if (exp.key === 'nps_banner_updated_ui') {
        this.npsBannerExperiment = true;
      }
    });
  }

  get checkIfLanguageWithUIIssues(): boolean {
    return this.languageWithIssues.includes(this.userLanguage);
  }

  async openFeedback(): Promise<void> {
    this.eventLogger.trackEvent('NPS_BANNER_CLICKED', { id: this.feedback?.id });
    await this.router.navigate(['/feedback'], { queryParams: { feedbackId: this.feedback?.id } });
  }
}
