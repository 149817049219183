import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BroadcastService } from '@services/broadcast-service';
import { DataStoreService } from '@services/data-store-service';

@Component({
  selector: 'video-frame',
  templateUrl: './video.html',
  standalone: false,
})
export class VideoComponent implements OnDestroy {
  src: SafeResourceUrl;
  loading: boolean = true;
  constructor(private router: ActivatedRoute,
    private dom: DomSanitizer,
    private broadcast: BroadcastService,
    private dataStorage: DataStoreService,
    private route: ActivatedRoute) {}

  async ngOnInit(): Promise<any> {
    const src = this.dataStorage.get('VIDEO_URL', '') || this.route.snapshot.queryParams.src;
    this.src = this.dom.bypassSecurityTrustResourceUrl(src);
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  ngOnDestroy(): void {
    this.dataStorage.set('VIDEO_URL', '');
  }
}
