import { Component } from '@angular/core';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { Router } from '@angular/router';

@Component({
  selector: 'insta-live-banner',
  templateUrl: './insta-live-banner.html',
  styleUrls: ['./insta-live-banner.scss'],
  standalone: false,
})
export class InstaLiveBannerComponent {
  instaLiveInfo: any = {};
  isUpcoming: boolean = false;
  isLive: boolean = false;
  isExpired: boolean = false;
  liveTime: string = '';

  constructor(private conn: ConnectionService,
    private broadcast: BroadcastService,
    private router: Router,
    private eventLogger: EventLoggerService) {}

  async ngOnInit(): Promise<void> {
    const infoArray = await this.conn.getInstaLiveUpdate();
    if (infoArray.length) {
      this.instaLiveInfo = infoArray[0];
      this.findTheLiveStatus();
    }
  }

  findTheLiveStatus(): void {
    if (this.instaLiveInfo) {
      const showFrom = this.instaLiveInfo.get('showFrom');
      const showTill = this.instaLiveInfo.get('showTill');
      const startTime = this.instaLiveInfo.get('startTime');
      const currentTime = new Date();
      if (currentTime > startTime && currentTime < showTill) {
        this.isLive = true;
      } else if (showFrom < currentTime && currentTime < showTill) {
        this.isUpcoming = true;
        this.setLiveTime(startTime);
      } else if (currentTime > showTill) {
        this.isExpired = true;
      }
    }
  }

  handleStripClick(): void {
    if (this.isUpcoming) {
      this.router.navigate(['/insta-live']);
    } else if (this.isLive) {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'insta-live-open' }));
      this.redirectToInstagram();
    }
  }

  redirectToInstagram(): void {
    const instaLink = this.instaLiveInfo?.get('instaLink');
    if (instaLink) this.broadcast.broadcast('OPEN_IN_NEW_TAB', { url: instaLink });
  }

  setLiveTime(liveTime: string): void {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const timings = new Date(liveTime);
    const day = timings.getDate();
    const month = timings.getMonth();
    const hours = timings.getHours();
    const amOrPm = hours >= 12 ? 'pm' : 'am';
    const hourFormatted = hours > 12 ? hours % 12 : hours;
    this.liveTime = `${this.findOrdinalSuffixOf(day)} ${monthNames[month]} | ${hourFormatted}:00 ${amOrPm}`;
  }

  findOrdinalSuffixOf(day: number): string {
    const j = day % 10;
    const k = day % 100;
    if (j === 1 && k !== 11) {
      return `${day}st`;
    }
    if (j === 2 && k !== 12) {
      return `${day}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${day}rd`;
    }
    return `${day}th`;
  }
}
