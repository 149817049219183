import { Component, ElementRef, ViewChild, Input } from '@angular/core';
import { ConnectionService } from '@services/connection-service';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.html',
  styleUrls: ['./progress-bar.scss'],
  standalone: false,
})
export class ProgressBarComponent {
  @ViewChild('slider', { static: false }) slider: ElementRef;
  @ViewChild('range', { static: false }) range: ElementRef;
  @Input('value') value: number;
  @Input('bgTemplate') bgTemplate: number;
  @Input('animate')
  set setAnimate(status: boolean) {
    if (status) this.animateBar();
  }
  enableAnimation: boolean = false;
  @Input('stagesLanguageString')
  set setStages(data: any[]) {
    if (data) {
      this.stages = data.map((each: any): string => each.get(this.conn.getUserLanguage));
      setTimeout((): void => {
        this.fraction = this.range.nativeElement.clientWidth / this.stages.length;
      });
    }
  }
  stages: string[] = [];
  sliderValue: any = 5;
  fraction: number = 0;
  static parameters: Array<any> = [ConnectionService];
  constructor(private conn: ConnectionService) {}
  async ngOnInit(): Promise<void> {
  }

  animateBar(): void {
    const DELAY_FOR_PROGRESS_TO_COMPLETE = 1500;
    setTimeout((): void => {
      this.slider.nativeElement.style.left = `${(this.value * this.fraction) + (this.fraction / 2)}px`;
      setTimeout((): boolean => this.enableAnimation = true, DELAY_FOR_PROGRESS_TO_COMPLETE);
    });
  }
}
