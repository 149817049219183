import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { SupportQuestionI } from '@store/modals';

@Component({
  selector: 'get-support-query',
  templateUrl: './get-support-query.html',
  standalone: false,
})
export class GetSupportQueryComponent {
  @Output('onQueryChange') onQueryChange: EventEmitter<string> = new EventEmitter();
  @Input('message') message: string;
  @Input('question')
  set updateQuestion(question: SupportQuestionI) {
    this.question = question;
    if (this.question.textMaxLength) {
      this.maxLength = this.question.textMaxLength;
    } else {
      delete this.maxLength;
    }
  }

  question: SupportQuestionI;
  queryParams: Params;
  inProcess: boolean;
  maxLength: number;

  constructor(
    private conn: ConnectionService,
    private router: Router,
    private broadcastService: BroadcastService,
    private route: ActivatedRoute,
  ) {
    this.queryParams = { ...this.route.snapshot.queryParams };
  }

  async submitQuery(): Promise<void> {
    if (this.inProcess) return;
    this.inProcess = true;
    try {
      const { supportTicketId }: { supportTicketId: string } = await this.conn.createTicket(this.question.objectId);
      await this.router.navigate([`/support/ticket/${supportTicketId}`], { queryParams: this.queryParams, replaceUrl: true });
    } catch (error) {
      this.broadcastService.broadcast('NOTIFY', { message: error.toString() });
    } finally {
      this.inProcess = false;
    }
  }
}
