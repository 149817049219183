import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as EXIF from 'exif-js';
import { ConnectionService } from '@services/connection-service';
import { WindowRefService } from '@services/window-ref-service';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { EventLoggerService } from '@services/event-logger-service';
import { BroadcastService } from '@services/broadcast-service';
import { FollowUpReport } from '../../app-constant-types';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'comparison-slider',
  templateUrl: './comparison-slider.html',
  styleUrls: ['./comparison-slider.scss'],
  standalone: false,
})
export class ComparisonSliderComponent {
  imageLoaded: boolean[] = [false, false];
  instantCheckups: Array<any> = [];
  isInternalUser: boolean;

  MIN_IMAGE_WIDTH: number = 360; // the view port of comparison box is to be 360 * 360. Do not change it.

  @ViewChild('slider', { static: false }) slider: ElementRef;
  @ViewChild('container', { static: false }) container: ElementRef;
  @ViewChild('imgH1', { static: false }) imgH1: ElementRef;
  @ViewChild('imgH2', { static: false }) imgH2: ElementRef;
  @ViewChild('imageOne', { static: false }) imageOne: ElementRef;
  @ViewChild('imageTwo', { static: false }) imageTwo: ElementRef;

  report: FollowUpReport = { imageConfig: { after: {}, before: {} } };
  @Input('report') reportParseObject: any;
  @Input('index') index: number;
  @Input('sliderLength') sliderLength: number;
  @Input('user') user: any;
  @Output('back') back: EventEmitter<any> = new EventEmitter();

  constructor(private conn: ConnectionService,
    public appConfig: AppConfig,
    private window: WindowRefService,
    private commonUtil: CommonUtilityHelper,
    private eventLogger: EventLoggerService,
    private broadcast: BroadcastService) {
  }

  async ngOnInit(): Promise<any> {
    this.isInternalUser = this.conn.isInternalUser();
    try {
      if (this.report.assistantId) await this.loadDataForV1();
      else await this.loadDataForV2();
    } catch (error) {
      this.showError(error.message);
      this.back.emit();
    }
  }

  /**
   * In version 2, the data is from Followup Report table.
   * 1. We fetch the report
   * 2. Fetch 2 image(before, after) from the report. we prefer compressed image if stored, else original image.
   * 3. Fetch the orientation and set it.
   * 4. Call 'LoadImage' to load the 'after' image 1st then the 'before' image.
   * 5. We set the report as 'read':'true'.
   */
  async loadDataForV2(): Promise<any> {
    if (!this.reportParseObject.id) {
      this.reportParseObject = await this.conn.fetchFollowUpReportById(this.reportParseObject.objectId,
        ['afterInstantCheckup', 'beforeInstantCheckup', 'imageConfig']);
    }
    this.report = JSON.parse(JSON.stringify(this.reportParseObject));
    this.eventLogger.trackEvent('followup_report_visited',
      { username: this.user.get('username'), doctor: this.report.doctor ? this.report.doctor.username : '' });
    this.instantCheckups = await this.loadInstantCheckups([this.report.afterInstantCheckup.objectId,
      this.report.beforeInstantCheckup.objectId]);
    if (this.instantCheckups.length !== 2) {
      throw new Error('Report not found. Contact CureSkin team.');
    }
    this.report.afterInstantCheckup = this.instantCheckups[0];
    this.report.beforeInstantCheckup = this.instantCheckups[1];
    this.report.imageConfig.after.imageURL = this.report.imageConfig.after.compressedImage
      ? this.instantCheckups[0]?.compressedImagePath
      : this.instantCheckups[0]?.imagePath;
    this.report.imageConfig.before.imageURL = this.report.imageConfig.before.compressedImage
      ? this.instantCheckups[1]?.compressedImagePath
      : this.instantCheckups[1]?.imagePath;
    this.report.imageConfig.after.orientation = this.instantCheckups[0].orientation;
    this.report.imageConfig.before.orientation = this.instantCheckups[1].orientation;
    await this.loadImage(0, this.report.imageConfig.after);
    if (!this.isInternalUser) {
      this.reportParseObject.set('read', true);
      await this.reportParseObject.save();
    }
  }

  /**
   * In version 1, the data is from Assistant table. (fallback code for old users)
   * 1. We fetch the assistant and process it to get the necessary data.
   * 2. Fetch 2 image(before, after) from the report. we prefer compressed image if stored, else original image.
   * 3. Fetch the orientation and set it.
   * 4. Call 'LoadImage' to load the 'after' image 1st then the 'before' image.
   * 5. We set the report as 'read':'true'.
   */
  async loadDataForV1(): Promise<any> {
    const assistant = await this.conn.fetchAssistantById(this.report.assistantId, true);
    const report = assistant.get('progressReportConfig');
    this.report.createdAt = assistant.createdAt;
    this.report.imageConfig.after = report.imageConfig[0];
    this.report.imageConfig.before = report.imageConfig[1];
    const checkups = await this.loadInstantCheckups([report.id1, report.id2]);
    this.report.imageConfig.after.imageURL = this.report.imageConfig.after.compressedImage
      ? checkups[0]?.compressedImagePath
      : checkups[0]?.imagePath;
    this.report.imageConfig.before.imageURL = this.report.imageConfig.before.compressedImage
      ? checkups[1]?.compressedImagePath
      : checkups[1]?.imagePath;
    this.report.imageConfig.after.orientation = checkups[0].orientation;
    this.report.imageConfig.before.orientation = checkups[1].orientation;
    this.instantCheckups = this.swapCheckupsBasedOnLatestFirst(checkups);
    this.report.afterInstantCheckup = this.instantCheckups[0];
    this.report.beforeInstantCheckup = this.instantCheckups[1];
    await this.loadImage(0, this.report.imageConfig.after);
  }

  /**
   * Fetches the checkups and sort it if result doesn't preserve the same order.
   */
  async loadInstantCheckups(ids: string[]): Promise<any> {
    const username: string = this.user.get('username');
    const data = await this.conn.fetchInstantCheckup({ userId: username,
      id: ids,
      project: ['orientation', 'imagePath', 'compressedImagePath'] });
    if (!data || data.length !== 2) {
      this.showError('Report not found. Contact CureSkin team.');
      this.back.emit();
      return [];
    }
    if (ids[0] !== data[0].objectId) data.reverse();
    return data;
  }

  swapCheckupsBasedOnLatestFirst(checkups: Array<any>): Array<any> {
    if ((new Date(checkups[0].createdAt)) < (new Date(checkups[1].createdAt))) {
      checkups.reverse();
      [this.report.imageConfig.after, this.report.imageConfig.before] = [this.report.imageConfig.before, this.report.imageConfig.after];
    }
    return checkups;
  }

  /**
   * @param {number} index - index of checkup to load. i. either Before or After.
   * @param config - the config of after or before image which hold data like (orientation, crop co-ordinates, width and so)
   * 1. Fetch the image, and on after load callback, we look for orientation from EXIF and rotate the canvas accordingly.
   * 2. Write the image into canvas.
   * 3. We adjust the top, left co-ordinates in order to focus the 360 x 360 area of which doctor has choosen to be shown.
   * 4. Sets the event listeners for sliders.
   * @returns {Promise<any>}
   */
  async loadImage(index: number, config: any): Promise<any> {
    const image: any = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = async (): Promise<any> => {
      const rotationNeeded = this.commonUtil.rotationNeeded();
      if (rotationNeeded) {
        EXIF.getData(image, async (): Promise<void> => {
          const orientation = EXIF.getTag(image, 'Orientation') || 0;
          await this.checkForOrientationAndDrawImage(index, image, config, orientation);
        });
      } else {
        await this.checkForOrientationAndDrawImage(index, image, config, -1);
      }
      this.setsEventListenersForSliders(index);
    };
    image.src = config.imageURL;
  }

  async checkForOrientationAndDrawImage(index: number, image: any, config: any, orientation_: number): Promise<void> {
    const canvas = index === 0 ? this.imageOne : this.imageTwo;
    const ctx = canvas.nativeElement.getContext('2d');
    const orientation = config.orientation || orientation_;
    if ([5, 6, 7, 8].includes(orientation)) {
      canvas.nativeElement.width = image.height;
      canvas.nativeElement.height = image.width;
      this.commonUtil.rotateCanvasBasedOnOrientation(orientation, ctx, canvas);
      ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.nativeElement.height, canvas.nativeElement.width);
    } else {
      canvas.nativeElement.height = image.height;
      canvas.nativeElement.width = image.width;
      this.commonUtil.rotateCanvasBasedOnOrientation(orientation, ctx, canvas);
      ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.nativeElement.width, canvas.nativeElement.height);
    }

    if (config.width) {
      canvas.nativeElement.style.width = config.width;
      // getElementById is not avaialble in ngOnInit
      if (this.window.nativeWindow.document.getElementById('imgContainer')) {
        this.window.nativeWindow.document.getElementById('imgContainer').style.width = config.width;
      }
    }
    if (config.height) {
      canvas.nativeElement.style.height = config.height;
    }
    const adjustLeft = (this.MIN_IMAGE_WIDTH - this.container.nativeElement.offsetWidth) / 2;
    canvas.nativeElement.style.top = `-${config.top}px`;
    if (index === 0) {
      canvas.nativeElement.style.left = '0px';
    } else {
      canvas.nativeElement.style.left = `-${config.left || 0}px`;
    }
    if (adjustLeft > 0) canvas.nativeElement.style.left = `-${config.left + adjustLeft}px`;
    this.imageLoaded[index] = true;
  }

  /**
   * Based on the movement of event cursor pointer, we move the slide on same axis.
   * The Front image container's width is also adjusted based on the movement of cursor.
   */
  slideStart(e: any): any {
    e.preventDefault();
    const event = e.targetTouches ? e.targetTouches[0] : e;
    const slider = this.slider.nativeElement;
    const imgTop = this.imgH2.nativeElement;
    const imgBehind = this.imgH1.nativeElement.getBoundingClientRect();
    if ((imgBehind.x && event.clientX - 15 < imgBehind.x)
      || (imgBehind.right && event.clientX + 15 > imgBehind.right)) return;
    slider.style.left = `${Math.abs(event.clientX - imgBehind.left)}px`;
    imgTop.style.width = `${Math.abs(event.clientX - imgBehind.left)}px`;
  }

  /**
   * Sets the mouse and touch event listeners on slider.
   * Calls to load the second image once 1st image is done. Then finally sets the event listeners.
   * @param {number} index
   */
  setsEventListenersForSliders(index: number): void {
    if (index === 0) {
      this.loadImage(1, this.report.imageConfig.before);
      return;
    }
    this.slider.nativeElement.addEventListener('mousedown', (): void => {
      this.container.nativeElement.addEventListener('mousemove', (event: any): void => {
        this.slideStart(event);
      });
    });

    this.slider.nativeElement.addEventListener('mouseup', (): void => {
      this.container.nativeElement.removeEventListener('mousemove', (event: any): void => {
        this.slideStart(event);
      });
    });

    this.container.nativeElement.addEventListener('mouseout', (): void => {
      this.container.nativeElement.removeEventListener('mousemove', (event: any): void => {
        this.slideStart(event);
      });
    });

    this.slider.nativeElement.addEventListener('touchmove', (event: any): void => {
      this.slideStart(event);
    });
    this.slider.nativeElement.addEventListener('touchend', (): void => {
    });
  }

  showError(message: any): void {
    this.broadcast.broadcast('NOTIFY', { message });
  }
}
