import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AppConfig } from '../../app/app.config';
import { ToolbarType } from './toolbar.type';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.html',
  styleUrls: ['./toolbar.scss'],
  standalone: false,
})
export class ToolbarComponent {
  @ViewChild('toolbarContainer', { static: false }) toolbarContainer: ElementRef;
  @Input('icons') icons: Array<ToolbarType.Icon> = [];
  @Input('disableBack') disableBack: boolean;
  @Input('bg') bg: string;
  @Input('enableMenu') enableMenu: boolean;
  @Input('leftIconColor') leftIconColor: string;
  @Output('onAction') onAction: EventEmitter<any> = new EventEmitter();

  constructor(public appConfig: AppConfig) {}
  onClick(icon: ToolbarType.Icon): void {
    if (icon.callback) {
      if (icon.name === 'CART' && !icon.cartValue) {
        return;
      }
      icon.callback();
    }
  }
}
