import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs/index';
import { Store } from '@ngrx/store';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { AddressFormComponent } from '@shared/address-form/address-form.component';
import * as Types from '@store/modals';
import { fromSelectors } from '@store/selectors';
import { fromActions } from '@store/actions';
import { CurrentComponentService } from '@services/current-component';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { WindowRefService } from '@services/window-ref-service';
import { AddressBookI } from '@store/modals';
import { Table } from '@cureskin/api-client';
import { EventLoggerService } from '@services/event-logger-service';
import { UserProfileEditComponent } from '../../user-profile/user-profile-edit/user-profile-edit.component';

@Component({
  selector: 'user-address-edit',
  templateUrl: './user-address-edit.html',
  styleUrls: ['./user-address-edit.scss'],
  standalone: false,
})
export class UserAddressEditComponent implements OnDestroy {
  address: any;
  addressForm: AddressBookI;
  user: any;
  allowedAccess: boolean;
  popUpModal: any = {};
  saveInProcess$: Observable<boolean> = this.store.select(fromSelectors.selectAddressSaveInProcess);
  isEdit: boolean;
  loading: boolean = true;
  isLocatingAddress: boolean;
  subscriptions: Subscription[] = [];
  experiments: Array<any> = [];
  autoLocationPermissionEnabled: boolean = false;
  autoFillLocationEnabled: boolean = false;
  hideLocation: boolean = false;
  isAddressAutofilled: boolean = false;
  isAddressEditedAfterAutofilling: boolean = false;
  shouldEnableUseCurrentLocationButton: boolean = false;
  startTimer:number;

  @ViewChild(AddressFormComponent) addressFormComponent: AddressFormComponent;
  @ViewChild(UserProfileEditComponent) profileEdit: UserProfileEditComponent;

  constructor(private conn: ConnectionService,
    private broadcast: BroadcastService,
    private route: ActivatedRoute,
    private store: Store,
    private currentComponentService: CurrentComponentService,
    private appWebBridge: AppWebBridgeService,
    private eventLogger: EventLoggerService,
    private window: WindowRefService,
  ) {
    this.currentComponentService.set(this);
  }

  async ngOnInit(): Promise<void> {
    this.startTimer = new Date().getTime();
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'address-page' }));
    this.appWebBridge.logEventInBranchAndFirebaseFromiOS({
      branch: { name: 'pageOpenAddressPage' },
      firebase: { name: 'pageOpenAddressPage' },
    });
    this.eventLogger.cleverTapEvent('shippingAddressPageOpened', JSON.stringify({}));
    this.experiments = await this.conn.findUserActiveExperiments();
    this.experiments.forEach((each: any): void => {
      if (each.key === 'auto_ask_location_permission') this.autoLocationPermissionEnabled = true;
      if (each.key === 'auto_fill_address_from_location') this.autoFillLocationEnabled = true;
      if (each.key === 'use_current_location_button_enable') this.shouldEnableUseCurrentLocationButton = true;
    });
    this.hideLocation = !this.shouldEnableUseCurrentLocationButton;
    this.user = this.conn.getActingUser();
    const addressEditId: string = this.route.snapshot.params.id;
    this.isEdit = !!(addressEditId?.length);
    await this.fetchOrCreateAddress(addressEditId);
    this.loading = false;
    /**
     * Native app will broadcast gps location through this broadcast.
     */
    this.subscriptions.push(this.broadcast.on<{ [key: string]: string }>('LOCATION_UPDATE')
      .subscribe(async (latLong: { [key: string]: string }): Promise<void> => {
        await this.getDetailedLocationFromCoordinates(latLong);
        this.isLocatingAddress = false;
      }));
    if (this.autoFillLocationEnabled && !this.address.id) {
      const latLong = this.user?.get('lastLocation');
      if (latLong) {
        this.isAddressAutofilled = true;
        this.eventLogger.cleverTapEvent('autofilled_address_from_location');
        this.broadcast.broadcast('NOTIFY', { message: 'Autofilling location' });
        await this.getDetailedLocationFromCoordinates(latLong);
      }
    }
    if (this.autoLocationPermissionEnabled) {
      this.getLocationDetails();
    }
    const time = new Date().getTime() - this.startTimer;
    this.eventLogger.trackEvent('address_screen_loading_time', { timeInMilliSec: time, userId: this.user.id });
  }

  showPopup(): void {
    this.popUpModal = {
      open: true,
    };
  }

  async fetchOrCreateAddress(addressId?: string): Promise<void> {
    if (addressId) this.address = await this.conn.fetchAddressById(addressId);
    else {
      this.address = new Table.AddressBook();
      this.address.set('user', this.user);
    }
    if (!this.address) {
      this.broadcast.broadcast('NOTIFY', { message: 'Address not found.' });
      return;
    }
    const addressForm: Types.AddressBookI = JSON.parse(JSON.stringify(this.address));
    if (!addressId) {
      addressForm.contactName = this.user?.get('PatientName');
    }
    this.addressForm = addressForm;
  }

  /**
   * After save (effect.ts) it will trigger 'NAVIGATION_BACK',
   * that should not point to 'handleBackPress' and simply go one page back (to checkout page).
   * So We remove the instance from 'currentComponentService' service, before saving address.
   *
   * Note: Use of 'handleBackPress' in this component is when they click 'back' without filling address. (we have to go 2 pages back)
   * So if they wish to fill address, we have to save address and go one page back. (so we remove instance before save).
   * @returns {Promise<void>}
   */
  async saveAddress(): Promise<void> {
    if (this.addressFormComponent.editedCount > 5 && this.isAddressAutofilled) {
      this.isAddressEditedAfterAutofilling = true;
      this.eventLogger.cleverTapEvent('edited_autofilled_address_from_location');
      this.address.addUnique('labels', 'edited_after_autofill');
      this.address.save();
    } else if (this.isAddressAutofilled) {
      this.address.addUnique('labels', 'autofilled_unedited');
      this.address.save();
    }
    await this.addressFormComponent.validateForm('parent');
    if (!this.addressFormComponent.addressForm.valid) return;
    if (this.addressFormComponent.pinCodeError) {
      this.showPopup();
    }
    if (this.allowedAccess) {
      if (!this.addressFormComponent.pinCodeError) {
        this.currentComponentService.remove(this);
        this.addressForm.mobileNumber = String(this.addressForm.mobileNumber);
        this.store.dispatch(fromActions.AddressBookSaveBegin({ addressId: this.address.id,
          address: this.addressForm as Types.AddressBookI }));
      }
    } else if (!this.allowedAccess && !this.addressFormComponent.pinCodeError) {
      this.currentComponentService.remove(this);
      this.addressForm.mobileNumber = String(this.addressForm.mobileNumber);
      this.store.dispatch(fromActions.AddressBookSaveBegin({ addressId: this.address.id,
        address: this.addressForm as Types.AddressBookI }));
    }
  }

  closePopup(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'no-autofill-pincode' }));
    this.popUpModal = { open: false };
    this.back();
  }

  onClickYes(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'yes-autofill-pincode' }));
    this.closePopup();
    this.getLocationDetails();
  }

  getLocationDetails(): any {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'use-my-current-location' }));
    this.isLocatingAddress = true;
    if (this.appWebBridge.getLocationDetails()) return;
    this.window.nativeWindow.navigator.geolocation.getCurrentPosition((location: any): void => {
      this.getDetailedLocationFromCoordinates({ latitude: location.coords.latitude, longitude: location.coords.longitude });
    }, (): any => {
      this.isLocatingAddress = false;
      this.broadcast.broadcast('NOTIFY', { message: 'Location permission denied. Kindly enable permission in your browser settings.' });
    });
    this.allowedAccess = true;
  }

  /**
   * We call api to get array of address available around that latitude and longitude, From that array
   * 1. We pick the 'postal_code' and set it in address form (which will call a api to fetch city,state of that pincode)
   * 2. We pick the buildingDetails by filtering address which has type substring 'locality' and set it in address form.
   */
  async getDetailedLocationFromCoordinates(location: { [key: string]: string }): Promise<void> {
    const data = await this.conn.getDetailedLocationFromCoordinates(location);
    this.isLocatingAddress = false;
    let pincode: number;
    try {
      data.results.forEach((result: { [key: string]: any }): void => {
        if (result.types.includes('postal_code')) {
          result.address_components.forEach((addressComponent: { [key: string]: any }): void => {
            if (!pincode) pincode = Number(addressComponent.long_name);
          });
        }
      });
      if (pincode) this.addressFormComponent.addressForm.controls.zipCode.setValue(pincode);
      // Note: Don't fill the locality on auto detect location.
      // const localityArr: any[] = data?.results[0]?.address_components || [];
      // let locality: string;
      // localityArr.forEach((each: { [key: string]: any }): void => {
      //   const found: boolean = !!each.types.find((type: string): boolean => type.toLowerCase().includes('locality'));
      //   if (found) locality = locality ? `${locality}, ${each.long_name}` : each.long_name;
      // });
      // if (!this.addressFormComponent.pinCodeError) {
      //   this.addressFormComponent.addressForm.controls.buildingDetails.setValue(locality);
      // }
      this.address.set('latLong', location);
      this.address.set('reverseGeocodeAPIResponse', data);
      if (this.address.id) {
        await this.address.save();
      }
    } catch (err) {
      this.broadcast.broadcast('NOTIFY', { message: 'Error while getting location.' });
    }
  }

  /**
   * On back, we have to go back 2 pages (to BUY NOW page) if its from checkout page.
   * We remove instance from 'currentComponentService',
   * so that it won't point back to 'handleBackPress' in the process of going back '2 pages'.
   * @returns {Promise<boolean>}
   */
  async handleBackPress(): Promise<boolean> {
    if (this.route.snapshot.queryParams.fromCheckout) {
      this.currentComponentService.remove(this);
      this.broadcast.broadcast('NAVIGATION_BACK', { step: 2 });
      return true;
    }
    return false;
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((each: any): void => each.unsubscribe());
    this.currentComponentService.remove(this);
  }
}
