import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { DirectivesModule } from '@directives/directives.module';
import { CsIconModule } from '@components/icon';
import { ButtonModule } from '@components/button';
import { InputModule } from '@components/inputs';
import { LoadingModule } from '@components/loading';
import { QuizGameComponent } from './quiz-game.component';

@NgModule({
  imports: [
    CommonModule,
    ToolbarModule,
    LoadingModule,
    ButtonModule,
    InputModule,
    CsIconModule,
    LoadingModule,
  ],
  declarations: [QuizGameComponent],
  exports: [],
})
export class QuizGameModule {}
