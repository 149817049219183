import { Directive } from '@angular/core';

@Directive({
  selector: '[appNoCopy], [no-copy]',
  host: {
    '(copy)': '$event.preventDefault()',
    '(contextmenu)': '$event.preventDefault()',
    '(selectstart)': '$event.preventDefault()',
  },
})
export class NoCopyDirective {}
