import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { LocalStorageService } from '@services/local-storage-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { BroadcastService } from '@services/broadcast-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'onboarding-concern',
  templateUrl: './onboarding-concern.html',
  standalone: false,
})
export class OnboardingConcernComponent {
  ui: { loading: boolean } = { loading: false };
  user: any;
  selectedConcernClass: string;
  concernList: string[];
  isOnboardingTakePhotoExpEnabled: boolean;
  startTimer:number;

  constructor(
    public conn: ConnectionService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private eventLogger: EventLoggerService,
    private broadcast: BroadcastService,
    public appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private appWebBridge: AppWebBridgeService) {
  }

  async ngOnInit(): Promise<void> {
    this.startTimer = new Date().getTime();
    this.concernList = [this.appConfig.Shared.Regimen.Class.FACE,
      this.appConfig.Shared.Regimen.Class.BODY,
      this.appConfig.Shared.Regimen.Class.HAIR];
    this.user = this.conn.getActingUser();
    this.selectedConcernClass = this.localStorageService.getValue('Onboarding/PrivateMainConcernClass')
      || this.activatedRoute.snapshot.queryParams.class || this.appConfig.Shared.Regimen.Class.FACE;
    const redirectUrl: string = this.localStorageService.getValue('CureSkin/redirectUrl') || '';
    if (redirectUrl) {
      const classPreference: any = this.conn.findRelativePathAndQueryString(redirectUrl)[1];
      if (classPreference.class) {
        this.localStorageService.delete('CureSkin/redirectUrl');
        this.selectedConcernClass = classPreference.class;
      }
    }
    const time = new Date().getTime() - this.startTimer;
    this.eventLogger.trackEvent('onboarding_concern_page_opening_time', { timeInMilliSec: time, userId: this.user.id });
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'onboarding-concern-selection' }));
    this.appWebBridge.logEventInBranchAndFirebaseFromiOS({
      branch: { name: 'pageOpenonboardingConcernSelection' },
      firebase: { name: 'pageOpenonboardingConcernSelection' },
    });
  }

  async updateConcern(selectedConcernClass: any): Promise<void> {
    this.selectedConcernClass = selectedConcernClass;
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: this.selectedConcernClass }));
    this.ui.loading = true;
    if (!this.user) {
      this.broadcast.broadcast('NOTIFY', { message: 'User not found. Kindly login.' });
      return Promise.resolve();
    }
    try {
      this.localStorageService.setValue('Onboarding/PrivateMainConcernClass', this.selectedConcernClass);
      const queryParams: Record<string, string> = { class: this.selectedConcernClass };
      if (this.activatedRoute.snapshot.queryParams.redirectOnBack) {
        queryParams.redirectOnBack = this.activatedRoute.snapshot.queryParams.redirectOnBack;
      }
      const time = new Date().getTime() - this.startTimer;
      this.eventLogger.trackEvent('onboarding_concern_time_spent', { timeInMilliSec: time, userId: this.user.id });
      await this.router.navigate(['/mainConcern'], { queryParams });
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.toString() });
    } finally {
      this.ui.loading = false;
    }
    return Promise.resolve();
  }
}
