import { Component } from '@angular/core';
import { EventLoggerService } from '@services/event-logger-service';
import { ConnectionService } from '@services/connection-service';

@Component({
  selector: 'report-getting-ready',
  imports: [],
  templateUrl: './report-getting-ready.html',
  styles: '',
})
export class ReportGettingReadyComponent {
  user: Table.User;
  doctor: { firstName: string; image: string } = { firstName: '', image: '' };

  constructor(private readonly eventLogger: EventLoggerService, private readonly conn: ConnectionService) {}

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
    await this.getDoctorData();
  }

  async getDoctorData(): Promise<void> {
    const doctor = this.user?.get('allocatedDoctor');
    if (doctor) {
      const allocatedDoctor = await this.conn.findUserByObjectId(doctor.id);
      const doctorName = allocatedDoctor.get('DoctorDisplayName') || allocatedDoctor.get('username');
      this.doctor.firstName = doctorName.split(' ').slice(0, 2).join(' ') || '';
      this.doctor.image = allocatedDoctor.get('DoctorImageThumbnail');
    }
  }

  handleClick(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'level-two-report-in-process-banner' }));
  }
}
