import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'cod-handling-fee-sheet',
  templateUrl: './cod-handling-fee-sheet.component.html',
  standalone: false,
})
export class CodHandlingFeeSheetComponent {
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  private bottomSheetRef: MatBottomSheetRef,
  public appConfig: AppConfig) {
  }

  loading: boolean = false;

  handleOnlineOptionClick(): void {
    if (this.data?.handleOnlinePayment) {
      this.data.handleOnlinePayment();
    }
  }

  handleCODOptionClick(): void {
    if (this.data?.handleCodPayment) {
      this.data.handleCodPayment();
    }
  }

  closeBottomSheet(): void {
    if (this.data?.handleOnDismiss) {
      this.data.handleOnDismiss();
    }
    this.bottomSheetRef.dismiss();
  }
}
