import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { WindowRefService } from '@services/window-ref-service/index';
import { ConnectionService } from '@services/connection-service/index';
import { BroadcastService } from '@services/broadcast-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'order-update',
  templateUrl: './order-update.html',
  styleUrls: ['./order-update.scss'],
  standalone: false,
})
export class OrderUpdateComponent {
  @Input('order') order: Record<string, any>;
  isiOSDevice: boolean = false;

  constructor(private dom: DomSanitizer,
    private window: WindowRefService,
    private conn: ConnectionService,
    private router: Router,
    private broadcast: BroadcastService,
    public appConfig: AppConfig,
    private appWebBridge: AppWebBridgeService) {
  }

  ngOnInit(): void {
    this.isiOSDevice = this.appWebBridge.requestOSInformation() === 'iOS' || this.window.isSafariBrowser();
  }

  trackOrder(): void {
    if (this.isiOSDevice) this.conn.navigateToURL(this.order.trackingURL);
    else this.broadcast.broadcast('OPEN_IN_NEW_TAB', { url: this.order.trackingURL });
  }

  editProducts(): void {
    const productsId: string[] = (this.order.productInfo as Array<any>)?.map((each: any): string => each.id);
    this.conn.navigateToURL(`/user/order/reorder?products=${productsId.join(',')}`);
  }

  editAddressBook(): void {
    this.conn.navigateToURL(`/user/address?orderId=${this.order.objectId}`);
  }

  viewOrder(): void {
    this.router.navigate([`/user/order/${this.order.objectId}`]);
  }
}
