import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'boost-your-regimen',
  templateUrl: './boost-your-regimen.html',
  standalone: false,
})
export class BoostYourRegimenComponent {
  @Input('isProgressiveProductBanner') isProgressiveProductBanner: any;
  @Input('progressiveProduct') progressiveProduct: any;
  @Input('doctorProductsInCart') doctorProductsInCart: any;
  @Input('isBoostProductBanner') isBoostProductBanner: any;
  @Input('isBoostProductLoading') isBoostProductLoading: any;
  @Input('boostProductList') boostProductList: any;
  @Input('boostProductTagName') boostProductTagName: any;
  @Output() viewProduct: EventEmitter<any> = new EventEmitter();
  @Output() fetchBoostProductByType: EventEmitter<any> = new EventEmitter();
  @Output() proceedToBuy: EventEmitter<any> = new EventEmitter();
  @Output() showPopup: EventEmitter<any> = new EventEmitter();
  @Output() getCart: EventEmitter<any> = new EventEmitter();
}
