/* eslint-disable complexity */
/* eslint-disable keyword-spacing */
/* eslint-disable angular/log */
import { Component, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Location, PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, Event, NavigationError } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { WindowRefService } from '@services/window-ref-service';
import { EventLoggerService } from '@services/event-logger-service';
import { LocalStorageService } from '@services/local-storage-service';
import { BroadcastService } from '@services/broadcast-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { CurrentComponentService } from '@services/current-component';
import { CookieService } from '@services/cookie-service';
import { DataStoreService } from '@services/data-store-service';
import { RazorpayService } from '@services/razorpay-service';
import { Table } from '@cureskin/api-client';
import { WebWorker } from '@services/web-worker';
import { AnimationOptions } from 'ngx-lottie';
import { SentryErrorHandler } from '@services/sentry-logger';
import { fromSelectors } from '@store/selectors';
import { fromActions } from '@store/actions';
import { H as Highlight } from 'highlight.run';
import { ExperimentService } from '@services/experiment';
import { AppConfig } from './app.config';
import { PageOpenEvent } from './app-constant-types';

interface UploadProgressBarType {
  show: boolean;
  percentageLoaded: number;
  subscription?: Subscription;
}

@Component({
  selector: 'app',
  templateUrl: './app.html',
  styleUrls: ['./app.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class AppComponent implements OnDestroy {
  notifyMessage: string;
  showLoadingOverlay: boolean = false;
  showLinearLoading: boolean = false;
  subscriptions: Array<Subscription> = [];
  openShareOptions: boolean;
  shareData: any;
  isInternalUser: boolean;
  isRegimenExpired: boolean = false;
  cashValue: number;
  blockAskingFeedback: boolean = true;
  popUpModal: any = {};
  userConfirmation: any;
  userPendingConfirmations: Array<any> = [];
  processedAfterLoginChecks: boolean;
  isNative: boolean;
  showAddToHomePrompt: boolean;
  popUp: any = {};
  blockAddToHomePrompt: boolean;
  experiments: any[] = [];
  user: any;
  maxValue: number;
  uploadingProgressBar: UploadProgressBarType = { show: false, percentageLoaded: 0 };
  lastVisitedUrl: string;
  pagesData: { [key: string]: boolean } = {};
  currentRoute: any;
  options: AnimationOptions = {
    path: '/assets/animation.json',
  };
  styles: Partial<CSSStyleDeclaration> = {
    position: 'fixed',
    bottom: '0',
  };
  showConfettiAnimation: boolean = false;
  pagesInclude: any = [
    'cart', 'user?tab=regimen', 'user?tab=explore', 'user/order/reorder?from=reorderProducts',
    'user/order/reorder?from=recommendedProducts',
  ];
  cashClaimed: number = 0;
  regimens: any[] = [];

  constructor(private conn: ConnectionService,
    private eventLogger: EventLoggerService,
    private windowRef: WindowRefService,
    public route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private broadcast: BroadcastService,
    private appWebBridge: AppWebBridgeService,
    private location: Location,
    private router: Router,
    private currentComponent: CurrentComponentService,
    private appConfig: AppConfig,
    private cookieService: CookieService,
    private http: HttpClient,
    private zone: NgZone,
    private dataStore: DataStoreService,
    private webWorker: WebWorker,
    private razorpayService: RazorpayService,
    private store: Store,
    private sentryService: SentryErrorHandler,
    private experimentService: ExperimentService) {
    this.windowRef.nativeWindow.ApplicationClass = this;
    this.user = this.conn.getActingUser();
    this.currentRoute = '';
    this.router.events.subscribe((event: Event): any => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.setPageVisitedInfo(this.currentRoute);
      }
    });

    this.broadcast.on<{ [key: string]: string }>('LOCATION_UPDATE')
      .subscribe(async (lastLocation: { [key: string]: string }): Promise<void> => {
        const locationData = await this.getDetailedLocationFromCoordinates(lastLocation);
        await this.conn.updateUserData({
          lastLocation: lastLocation || {},
          lastLocationCity: locationData.city?.toLowerCase(),
          lastLocationState: locationData.state?.toLowerCase(),
        });
      });
  }

  async getDetailedLocationFromCoordinates(location: { [key: string]: string }): Promise<{ city: string, state: string }> {
    let pincode: number;
    const locationData: { city: string, state: string } = { city: '', state: '' };
    const data = await this.conn.getDetailedLocationFromCoordinates(location);
    try {
      data.results.forEach((result: { [key: string]: any }): void => {
        if (result.types.includes('postal_code')) {
          result.address_components.forEach((addressComponent: { [key: string]: any }): void => {
            if (!pincode) pincode = Number(addressComponent.long_name);
          });
        }
      });
      if (pincode) {
        const pinCodeData = await this.conn.getPinCodeInfo(pincode);
        locationData.state = pinCodeData && this.getFormatted(pinCodeData.get('state'));
        locationData.city = pinCodeData && this.getFormatted(pinCodeData.get('district'));
      }
      return locationData;
    } catch (err) {
      return locationData;
    }
  }

  private getFormatted(name: string): string {
    if (!name) return '';
    return name.toLowerCase()
      .split(' ')
      .filter((each: string): boolean => !!each?.length)
      .map((each: string): string => `${each[0]}`.toUpperCase() + each.slice(1))
      .join(' ');
  }

  /**
   * Initializes Loggers, ConnectionService, Razorpay sdk and other third party analytics services.
   * Subscribes to router changes.
   * Checks for current logged in user and if user exist then following process happens
   *    1. Fetch and process experiments
   *    2. Checks user preferred language and load the specific website if its not i.e /hi, /kn, ...
   * If user is not logged in, then router subscription which got subscribed will route it to login page.
   */
  async ngOnInit(): Promise<any> {
    if (this.appWebBridge.isAppWebBridgeLoaded()) {
      this.windowRef.nativeWindow.document.getElementsByTagName('html')[0].classList.add('native');
    }
    /** add language code to html tag to control font-size for each language from _customBootstarp.scss file */
    this.windowRef.nativeWindow.document.getElementsByTagName('html')[0].classList.add(this.conn.currentWebSiteLanguage);
    this.appWebBridge.logWebAppVersion();

    this.subscribeToUrlChanges();
    this.loadBroadCastListener();
    if (this.appConfig.Shared.Server.production) this.initLoggers();
    this.appWebBridge.updateAppBridgeVersion();
    this.windowRef.nativeWindow.navigateStatus = this.windowRef.isMobileBrowser();
    this.clearData();
    await this.conn.updateLocalUser();
    const user = this.conn.getActingUser();
    if (!user) return;
    this.isInternalUser = this.conn.isInternalUser();
    if (this.isInternalUser) return;
    await this.conn.checkUserLanguageAndRedirect();
    await this.processExperiments();

    await this.processAfterLogin();
    this.razorpayService.initialize();
    const where: any = { user: this.user };
    where.source = { $eq: 'Explore Cash' };
    const rewards = (await this.conn.fetchRewards(where)).sort((reward: any): number => (reward.isClaimed ? -1 : 1));
    const reward = JSON.parse(JSON.stringify(rewards));
    if (reward?.length) {
      reward.forEach((element: any): any => {
        if (element.cashTransaction?.amount) {
          this.cashClaimed += element.cashTransaction.amount;
        }
      });
    }
  }

  initLoggers(): void {
    this.eventLogger.initialize();
    this.loadPerformanceTime();
    this.loadNetworkStatus();
    this.logWebAppVersion();
    this.sentryService.setUser(this.conn.getCurrentUser());
  }

  initHighlightIO(): void {
    Highlight.init('qe92w4d1', {
      environment: this.conn.isDevelopmentEnvironment ? 'development' : 'production',
    });
    Highlight.identify(this.conn.getCurrentUser().get('username'), {
      objectId: this.conn.getCurrentUser().id,
      mobileNumber: this.conn.getCurrentUser().get('MobileNumber'),
      name: this.conn.getCurrentUser().get('PatientName'),
    });
  }

  showPopup(): void {
    this.popUp = {
      open: true,
    };
  }

  closePopup(type?: any): void {
    this.popUp = { open: false };
    if (type === 'wallet') {
      this.router.navigate(['user/wallet']);
    } else {
      this.broadcast.broadcast('NAVIGATION_BACK');
    }
  }

  onClickYes(): void {
    this.closePopup();
  }

  /**
   * This method is used to set a pages array for user and set flag true if user have visited that page
   */

  async setPageVisitedInfo(url: any): Promise<void> {
    if (this.isInternalUser) {
      return;
    }
    if (!this.user) {
      this.user = this.conn.getActingUser();
    }
    let showPagesPopup$: any;
    this.store.select(fromSelectors.selectHomePageState).subscribe((data: any): void => {
      showPagesPopup$ = data.isSessionOn;
    });
    if (this.user && showPagesPopup$) {
      this.maxValue = this.appConfig.Shared.experimentVariants.MaxExploreCashLimit.MaxCash;
      if (this.user.isPaid() && this.user.get('orderState') === 'DELIVERED') {
        const pagesVisited = this.user.get('pagesVisited');
        if (pagesVisited) {
          this.pagesData = pagesVisited;
        }
        this.pagesInclude.forEach((element: string): any => {
          if (url.includes(element)) {
            this.showAnimationPopup(element);
          }
        });
        await this.user.save();
      }
    }
  }

  showAnimationPopup(element: any): any {
    if (!this.pagesData[element]) {
      this.pagesData[element] = true;
      this.showConfettiAnimation = true;
      this.addCash();
      this.user.set('pagesVisited', this.pagesData);
    }
  }

  /**
   * Clears localStorage experiments, dataStore service, local cookies.
   */
  clearData(): void {
    this.dataStore.clearDataStore();
    this.cookieService.deleteAll();
    this.localStorage.setExperiments([], this.conn.getCurrentUser());
  }

  async processExperiments(): Promise<void> {
    this.experiments = await this.conn.findUserActiveExperiments();
    this.experiments.forEach((each: any): void => {
      if (each.key === 'enable_highlight_io') this.initHighlightIO();
      // TODO: This experiment value update and all related code
      // will be removed once the experiment is concluded.
      if (each.key === 'multi_concern_ui') {
        this.experimentService.isMulticoncernUI = true;
      }
    });
  }

  /** Handles after login checks & process
   * 1. Fetches config from backend and process it.
   * 2. Notifies native app about login with the token.
   * 3. Checks for any pending userConfirmation actions to be taken
   * */
  async processAfterLogin(): Promise<any> {
    this.isInternalUser = this.conn.isInternalUser();
    const user = this.conn.getActingUser();
    if (!user || this.isInternalUser || this.processedAfterLoginChecks) return;
    const token = user.get('sessionToken');
    // If token is missing, try to fetch it once more
    // if still missing, log out the user.
    if (!token) {
      try {
        const refetchedUser = await this.conn.getActingUser().fetch();
        if (!refetchedUser?.get('sessionToken')) {
          const params = { message: 'logs out user if session token is not found' };
          this.eventLogger.trackEvent('missingSessionTokenuserLogout', params);
          await this.logoutUserIfSessionTokenIsMissing();
          return;
        }
      } catch (error: any) {
        const params = { message: error.toString(), error: true };
        this.eventLogger.trackEvent('missingSessionTokenuserLogout', params);
        await this.logoutUserIfSessionTokenIsMissing();
        return;
      }
    }
    this.processedAfterLoginChecks = true;
    this.appWebBridge.notifyWebLoginToken();
    this.appWebBridge.notifyUserLoggedIn();
    await this.fetchConfig();
    await this.setPropertiesOfUserInLoggerService();
    await this.takeUserConfirmationOnPendingList();
    this.checkForAddToHomeScreen(this.windowRef.nativeWindow.location.href);
  }

  async logoutUserIfSessionTokenIsMissing(): Promise<void> {
    await this.router.navigate(['/logout']);
  }

  /** Fetches config from api and checks minimum supported version of browser
   * Shows popup to update browser, if existing browser is in unsupported version
  */
  async fetchConfig(): Promise<void> {
    const config = await this.conn.fetchConfig();
    if (!config) return;
    this.dataStore.set('CONFIG', JSON.parse(JSON.stringify(config)));
    const appVersion = this.appWebBridge.requestAppVersion();
    if (appVersion && Number(appVersion) < this.getMinSupportedVersion(config)) {
      this.popUpModal = {
        open: true,
        title: this.appConfig.Shared.String.UPDATE_APP,
        type: this.appConfig.Dialog.ALERT,
        cancelText: this.appConfig.Shared.String.UPDATE_NOW,
        requestCode: 4,
        message: { type: this.appConfig.Shared.String.UPDATE_APP_DETAIL },
      };
    }
    // noinspection JSDeprecatedSymbols
    const windowAppVersion = this.windowRef.nativeWindow.navigator.appVersion || '';
    if (!this.windowRef.isSafariBrowser()
      && Number((windowAppVersion.split('Chrome/')[1] || '48').split(' ')[0].split('.')[0])
      <= (config.get('MinChromeVersion') || '48')) {
      this.popUpModal = {
        open: true,
        title: this.appConfig.Shared.String.UPDATE_APP,
        type: this.appConfig.Dialog.MULTI_BUTTON,
        okText: this.appConfig.Shared.String.SUBMIT,
        requestCode: 5,
        inputs: [
          { text: this.appConfig.Shared.String.UPDATE_CHROME },
          { text: this.appConfig.Shared.String.UPDATE_WEB_VIEW },
        ],
        message: { type: this.appConfig.Shared.String.UPDATE_BROWSER_DETAIL },
      };
    }
  }

  getMinSupportedVersion(config: any): number {
    if (this.appWebBridge.requestOSInformation() === 'iOS') return config.get('MinSupportedIOSVersion');
    return config.get('MinSupportedVersion');
  }

  /** Stores timestamp in local storage when user says "Feedback later"
   * and ask the user after 2 days i.e (48 * 60 * 60 * 1000) interval
   */
  isFeedbackAllowed(): void {
    const timeStamp = this.localStorage.getValue('CureSkin/feedbackLater');
    if (timeStamp) {
      const timeDiff = (new Date().getTime() - timeStamp);
      if (timeDiff > (48 * 60 * 60 * 1000)) this.blockAskingFeedback = false;
      else this.blockAskingFeedback = true;
    } else this.blockAskingFeedback = false;
  }

  /**
   * This method is used to claim a reward
   */
  async addCash(): Promise<Table.Reward> {
    this.showPopup();
    await this.calculateReward();
    const reward = new Table.Reward();
    reward.set('type', 'USER_INIT');
    reward.set('isClaimed', false);
    reward.set('amount', this.cashValue);
    reward.set('user', this.conn.getActingUser());
    reward.set('isReferrer', false);
    reward.set('source', 'Explore Cash');
    setTimeout((): void => {
      this.showConfettiAnimation = false;
    }, 2000);
    const claim = await reward.save();
    await this.conn.claimReward(claim.id);
    this.store.dispatch(fromActions.HomePageUpdate({ isSessionOn: false }));
    return claim;
  }
  /**
 * This method is used for calculating dynamic rewards between 1 to 20, 20 to 50 and 50 to 100 perecent
 * based on the number of pages visited.
 */
  calculateReward(): void {
    const { length }: any = Object.keys(this.pagesData);
    let remainingCash: number;
    let rewardCash: number;
    if (this.cashClaimed < this.maxValue) {
      remainingCash = this.maxValue - this.cashClaimed;
    }
    /**
     * This is used to calculate dynamic cash reward upto max limit given in the experiment.
     */
    switch (true) {
      case length <= 2:
        rewardCash = +Math.floor(Math.random() * 0.2 * remainingCash);
        rewardCash = Math.max(rewardCash, 1);
        break;
      case length > 2 && length <= 4:
        rewardCash = +Math.floor(Math.random() * 0.3 * remainingCash + 0.2 * remainingCash);
        rewardCash = Math.max(rewardCash, 1);
        break;
      case length > 4:
        rewardCash = +Math.floor(Math.random() * 0.5 * remainingCash + 0.5 * remainingCash);
        rewardCash = Math.max(rewardCash, 1);
        break;
      default:
        break;
    }
    if (rewardCash === 0 || rewardCash < 0) rewardCash = 1;
    this.cashValue = rewardCash;
  }

  /** Shows Feedback popup modal and ask for rating of application */
  async rateApp(): Promise<any> {
    this.isFeedbackAllowed();
    if (this.blockAskingFeedback) return;
    const user = await this.conn.getActingUser();
    if (!this.conn.isUserLoggedIn
      || this.isInternalUser
      || typeof user.get('ratedApp') === 'boolean') return;
    setTimeout((): void => {
      this.zone.run((): void => {
        this.popUpModal = {
          open: true,
          title: this.appConfig.Shared.String.FEEDBACK,
          type: this.appConfig.Dialog.RADIO_BUTTON,
          okText: this.appConfig.Shared.String.SUBMIT,
          requestCode: 1,
          inputs: [
            { text: this.appConfig.Shared.String.BAD },
            { text: this.appConfig.Shared.String.OK },
            { text: this.appConfig.Shared.String.GOOD },
            { text: this.appConfig.Shared.String.LATER },
          ],
          message: { type: this.appConfig.Shared.String.INSTANT_CHECKUP_RATING },
        };
      });
    });
  }

  /**
   * Sets some user level properties in the logger services.
   */
  async setPropertiesOfUserInLoggerService(): Promise<any> {
    if (!this.conn.isUserLoggedIn) return;
    const user = this.conn.getActingUser();
    const week = Math.floor(((new Date()).getTime() - new Date(user.get('createdAt')).getTime())
      / (1000 * 60 * 60 * 24 * 7));
    const days = Math.floor(((new Date()).getTime() - new Date(user.get('createdAt')).getTime())
      / (1000 * 60 * 60 * 24));
    const hours = Math.floor(((new Date()).getTime() - new Date(user.get('createdAt')).getTime())
      / (1000 * 60 * 60));
    this.eventLogger.setUserProperty({ HOURS_SINCE_INSTALL: hours });
    this.eventLogger.setUserProperty({ WEEK_SINCE_INSTALL: week });
    this.eventLogger.setUserProperty({ DAYS_SINCE_INSTALL: days });
    this.eventLogger.setUserProperty({ Gender: user.get('Gender') });
    const userActiveExperiments = this.experiments.length ? this.experiments : await this.conn.findUserActiveExperiments();
  }

  historyLength(): number {
    if (typeof this.windowRef.nativeWindow.historyStates === 'undefined') {
      this.windowRef.nativeWindow.historyStates = [];
    }
    return this.windowRef.nativeWindow.historyStates.length;
  }

  /** Pushes routed url to local historyState array
   * @params url - url to push in historyState
   */
  pushHistory(url: string): void {
    if (url === '/') return; // don't remove this line
    if (typeof this.windowRef.nativeWindow.historyStates === 'undefined') {
      this.windowRef.nativeWindow.historyStates = [];
    }
    this.windowRef.nativeWindow.historyStates.push({ url });
  }

  /** Handles back navigation
   * Goes back N pages if steps is provides
   * Else one page back
   * @params step - no.of pages to go back in history
   */
  popHistory(step: number = 0): void {
    delete this.lastVisitedUrl;
    if (!step) this.location.back();
    else this.windowRef.nativeWindow.history.go(step > 0 ? -step : step);
    if (typeof this.windowRef.nativeWindow.historyStates === 'undefined') {
      return;
    }
    this.windowRef.nativeWindow.historyStates.pop(); // current state
    this.windowRef.nativeWindow.historyStates.pop(); // previous state
  }

  /** Takes control of back navigation
   * @parms step - no.of pages to go back in the history
   */
  goBack(step?: number): void {
    /** force navigates to home page on back press, if "back=home" is present is any route */
    if (this.windowRef.nativeWindow.location.href.includes('back=home')) {
      this.conn.navigateToURL('/user?tab=home', false, true);
      return;
    }
    /** Calls Native back navigation, if back press is requested from home page
     * From home page we should not be going back in history even if its has pages in its stack
    */
    if (this.windowRef.nativeWindow.location.href.includes('/user?tab=home')) {
      this.appWebBridge.notifyRequestBack();
      return;
    }
    /** Calls Native back navigation, if previous url and current url are same
     * 1. If we are forcing user in particular route like onboarding, on second back press we call native back
     * 2. If we are showing same page twice, on second try we call native back to close app, so that user don't get stuck in forced routes
     */
    if (this.router.url === this.lastVisitedUrl) {
      this.appWebBridge.notifyRequestBack();
      return;
    }
    this.lastVisitedUrl = this.router.url;
    /** Call popHistory function to navigate back if it has at least 1 page in custom historyState stack.
     */
    if (this.historyLength() > 1) {
      this.popHistory(step);
    } else if (!this.windowRef.nativeWindow.location.href.includes('/user?tab=home')
      && this.conn.isUserLoggedIn) {
      /** On back press process final page is expected to be home page
       * so if its not in some case, then we force it and show it as final page
       */
      this.conn.navigateToURL('/user?tab=home', false, true);
    } else if (!this.appWebBridge.notifyRequestBack()) {
      /** Calls web back press function if native wrapper is not found */
      this.popHistory(step);
    }
  }

  /** Native Wrapper uses this function to execute broadcast of web app
   * eg: ApplicationClass.eventFromMobileDevice("NAVIGATION_BACK");
   */
  eventFromMobileDevice(eventName: string, value: string): void {
    try {
      this.broadcast.broadcast(eventName, JSON.parse(value));
    } catch (e) {
      this.broadcast.broadcast(eventName, value);
    }
  }

  goToWallet(): any {
    this.closePopup('wallet');
  }

  checkAnimation(): void {
    this.showConfettiAnimation = true;
  }

  loadPerformanceTime(): void {
    const startTime = new Date().getTime();
    let i: number = 10 ** 6;
    while (i) i -= 1;
    const endTime = new Date().getTime();
    const performanceTime = Number(`${endTime - startTime}`);
    this.eventLogger.trackEvent('web_performance_time', { duration: performanceTime });
    this.cookieService.set('PERFORMANCE_TIME', `${performanceTime}`);
  }

  loadNetworkStatus(): void {
    const startTime = new Date().getTime();
    this.http.get('https://assets.cureskin.com/temp/download-test.rtf', { responseType: 'blob' })
      .subscribe((): void => {
        const endTime = new Date().getTime();
        const networkTime = Number(`${endTime - startTime}`);
        this.eventLogger.trackEvent('web_network_time', { duration: networkTime });
        this.eventLogger?.setUserProperty({ web_network_time: networkTime });
        this.cookieService.set('NETWORK_TIME', `${networkTime}`);
      });
  }

  getURLName(url: string): string | null {
    if (typeof url !== 'string') {
      return null;
    }
    switch (true) {
      case url.includes('tab=home'):
        return 'home-page';
      case url.includes('tab=shop'):
        return 'shop-page';
      case url.includes('tab=regimen'):
        return 'regimen-page';
      case url.includes('tab=explore'):
        return 'regimen-page';
      case url.startsWith('/cart'):
        return 'cart-page';
      case url.startsWith('/support'):
        return 'support-page';
      default:
        return null;
    }
  }

  /** Subscribe to Route Events
   * Take control of route changes
   * Logs route change in logger service
 */
  private async subscribeToUrlChanges(): Promise<any> {
    let previousEvent: NavigationStart;
    this.router.events.subscribe(async (event: Event): Promise<any> => {
      if (event instanceof NavigationStart) {
        const previousUrl = this.getURLName(previousEvent?.url);
        const currentUrl = this.getURLName(event.url);
        const { performance }: { performance: Performance } = (<Window>this.windowRef.nativeWindow);
        performance.mark(`${currentUrl}-start`);
        if (previousUrl) {
          performance.mark(`${previousUrl}-end`);
          const measure = performance.measure('duration', `${currentUrl}-start`, `${previousUrl}-start`);

          this.eventLogger.trackEvent(`'${previousUrl}-time-spend'`, {
            duration: measure.duration,
          });
        }
        previousEvent = event;
        if (!this.processedAfterLoginChecks) this.processAfterLogin();
        if (event.url?.includes('user?tab=home')) {
          performance.mark('home-page-start');
        } else if (event?.url?.includes('user?tab=regimen')) {
          performance.mark('regimen-page-start');
        } else if (event?.url?.includes('user?tab=shop')) {
          performance.mark('shop-page-start');
        }
      }
      if (event instanceof NavigationEnd) {
        this.sendActivatedRoutesNameInEvent();

        this.checkForAddToHomeScreen(event.url);
        this.pushHistory(event.url);
        if (this.conn.isUserLoggedIn && !this.isInternalUser) {
          this.eventLogger.trackWebPage();
        }
        this.appWebBridge.notifyAssistantLoaded(event.url);
      }
    });
    this.route.queryParams.subscribe(({ uniqueId }: any): void => {
      if (uniqueId) {
        this.localStorage.setValue('user/capture/id', uniqueId);
      }
    });
    this.initReplaceHashFunction();
  }

  private sendActivatedRoutesNameInEvent(): void {
    // Get current activated route
    let { route }: { route: ActivatedRoute } = this;
    while (route.firstChild) {
      route = route.firstChild;
    }
    // Access pageOpenEvent from route data
    const { pageOpenEvent }: { pageOpenEvent?: PageOpenEvent } = route.snapshot.data;
    if (pageOpenEvent?.name) {
      this.eventLogger?.cleverTapEvent('openedPage', JSON.stringify({
        pageName: pageOpenEvent.name,
      }));
    }
  }

  /** Custom function to implement hash based navigations
   * @params url - url to replace in the history state eg: #profile
   * @params callbackOnBackPress - callback function to execute when hash is popped out from history state
 */
  initReplaceHashFunction(): void {
    this.windowRef.replaceHash = ((url: string, callbackOnBackPress: Function): void => {
      if (!(callbackOnBackPress instanceof Function)) throw new Error('callback not present for hash url change');
      this.windowRef.nativeWindow.history.pushState({}, null, url);
      this.pushHistory(url); // history pop is done twice, so pushing the HASH twice to use the same flow as router subscription does.
      this.pushHistory(url);
      /** We remove the callback function of onpopstate once its execution is triggered by 'location.back()'; */
      this.windowRef.nativeWindow.onpopstate = (): void => {
        callbackOnBackPress();
        this.windowRef.nativeWindow.onpopstate = (): void => { };
      };
    });
  }

  /** Checks if onboarding is completed and forces the route to specific onboarding page which is incomplete
   * 1. Checks if url is exceptional and ignore the checks below.
   * 2. Checks for Gender and Age, routes to gender page is its not collected still.
   * 3. Checks for MainConcern Class. i.e FACE, HAIR, BODY selection
   * 4. Checks for MainConcern, that is concerns of above selected CLASS.
   * 5. Checks if initial photos are updated or pending for above selected class (applies only to FACE class and with 0 regimens)
   * 6. else routes to base ('/') if all onboarding process are completed.
   * @params url - current url
   * @params inOboardingPage - is url part of /onboarding routes
   * @returns boolean - status of completion of onboarding process
 */
  // eslint-disable-next-line complexity
  async checkIncompleteOnboardingStepAndRedirect(url: string, inOboardingPage: boolean): Promise<boolean> {
    const user = this.conn.getActingUser();
    if (this.isUrlExceptionalForRouteCheck(url)) return true;
    if ((!user.get('Gender') || !user.get('Age')) || url.includes('fromLocationPage')) {
      this.cacheCurrentUrlAndRouteTo(url, '/onboarding/gender', inOboardingPage);
      return true;
    }
    const includesLocation = url.includes('/onboarding/location') && !user.get('lastLocation');

    if (includesLocation) {
      this.cacheCurrentUrlAndRouteTo(url, '/onboarding/location', inOboardingPage);
      return true;
    }
    // To not block the exisiting users on concern selection
    if (user.get('orderState') === this.appConfig.Shared.User.OrderState.DELIVERED) {
      this.isRegimenExpired = true;
      return false;
    }

    if (this.conn.userWithoutPrivateMainConcern()) {
      this.eventLogger.trackEvent('onboarding_experiment_mod_check', {
        mod: this.conn.getActingUser().get('mod20'),
        experiments: this.experiments,
      });
      if (!url.includes('/mainConcern')) {
        const mainConcernClass = this.localStorage.getValue('Onboarding/PrivateMainConcernClass') || user.get('PrivateMainConcernClass');
        if (mainConcernClass === this.appConfig.Shared.Regimen.Class.FACE) {
          await this.conn.updateExperimentLogsInUser({ inControl: true, 'inControlClass-app': mainConcernClass });
        }
        this.cacheCurrentUrlAndRouteTo(url, '/onboarding/concern', inOboardingPage);
      }
      return true;
    }
    if (inOboardingPage) {
      await this.conn.redirectToLastKnowLocation('/');
      return true;
    }
    return false;
  }

  /**
   * 1. Checks if url (source) & routeTo (destination) are same by eliminating query params and ignores it
   * 2. Checks if the 'url' is valid to be cached and sets it in local storaage.
   * 3. If 'url' is invalid to be cached, then we check if existing 'redirectUrl' is same as destination url, if yes then we call
   *    'redirectToLastKnowLocation()' function.
   * 4. else we route to destination (routeTo) url.
   * @param {string} url - url to be cached
   * @param {string} routeTo - url to be routed to.
   * @param {boolean} inOboardingPage
   */
  cacheCurrentUrlAndRouteTo(url: string, routeTo: string, inOboardingPage: boolean): void {
    const redirectUrl: string = this.localStorage.getValue('CureSkin/redirectUrl') || '';
    if (url.split('?')[0] !== routeTo.split('?')[0]) {
      if (this.isUrlValidToRedirect(url, inOboardingPage)) this.setRedirectURL(url);
      else if (redirectUrl.split('?')[0] === routeTo.split('?')[0]) this.conn.redirectToLastKnowLocation('/');
      this.conn.navigateToURL(routeTo);
    }
  }

  /**
   * Sets the url in local storage for redirection.
   * removes the language code and stores only the route url.
   * @param {string} url
   */
  setRedirectURL(url: string): void {
    let URL = url;
    const languageUrlPrefixes = Object.values(this.appConfig.Shared.Languages).map((each: string): string => `/${each}`);
    languageUrlPrefixes.forEach((each: any): void => {
      if (url.startsWith(each)) URL = URL.substring(3);
    });
    if (URL !== '/' && URL.split('?')[0] !== '/') this.localStorage.setValue('CureSkin/redirectUrl', URL);
  }

  /**
   * '/' & '/user?tab=home&init' & all onboarding related url's are not allowed to be cached for re-direction.
   * '/user?tab=home&init' - specifically routed from base ('/') routing module, so we ignore it.
   */
  isUrlValidToRedirect(url: string, inOboardingPage: boolean): boolean {
    if (url === '/' || inOboardingPage || url.includes('/user?tab=home&init')) return false;
    return true;
  }

  /**
   * Ignores route subscription check for below urls.
   * 1. 'user/instantcheckup' is placed here, because ppl are allowed to take photo and view photo in onboarding process itself.
   *    so this route is exceptional even if onboarding is only partially completed.
   */
  isUrlExceptionalForRouteCheck(url: string): boolean {
    const URL = url.toLowerCase();
    return URL.includes('user/instantcheckup')
      || URL.includes('/logout')
      || URL.includes('empty')
      || URL.includes('force')
      || URL.includes('iframe');
  }

  /** List of top level broadcasts */
  private loadBroadCastListener(): void {
    this.windowRef.nativeWindow.addEventListener('offline', (): void => this.broadcast.broadcast('CHECK_NETWORK_CONNECTION'));

    this.windowRef.nativeWindow.addEventListener('online', this.checkInternet());

    this.subscriptions.push(this.broadcast.on<{ version: number }>('UPDATE_APP_BRIDGE_VERSION')
      .subscribe(({ version }: { version: number }): void => {
        this.localStorage.setValue('CureSkin/appBridgeVersion', version);
      }));

    this.subscriptions.push(this.broadcast.on<boolean>('CHECK_FOR_PENDING_USER_CONFIRMATION')
      .subscribe((): Promise<any> => this.takeUserConfirmationOnPendingList()));

    this.subscriptions.push(this.broadcast.on<{ key: string; value: string }>('UPDATE_LOCAL_STORAGE')
      .subscribe(({ key, value }: { key: string; value: string }): void => {
        if (!key) {
          return;
        }
        this.localStorage.setValue(key, value);
      }));

    this.subscriptions.push(this.broadcast.on<{ status: boolean }>('LOADING')
      .subscribe(({ status }: { status?: boolean } = {}): void => {
        this.zone.run((): boolean => this.showLoadingOverlay = status);
      }));

    this.subscriptions.push(this.broadcast.on<{ status: boolean }>('LINEAR_LOADING')
      .subscribe(({ status }: { status?: boolean } = {}): void => {
        this.zone.run((): boolean => this.showLinearLoading = status);
      }));

    this.subscriptions.push(this.broadcast.on<UploadProgressBarType>('UPLOADING_PROGRESS')
      .subscribe((data: UploadProgressBarType): void => {
        this.zone.run((): void => {
          if (data.percentageLoaded === 100) {
            this.uploadingProgressBar.percentageLoaded = data.percentageLoaded;
            setTimeout((): any => this.uploadingProgressBar = data, 1000);
          } else this.uploadingProgressBar = data;
        });
      }));

    this.subscriptions.push(this.broadcast.on<boolean>('SHARE')
      .subscribe((data: any): any => {
        this.shareData = {
          description: data.linkDescription || 'Instant checkup and regimen.',
          title: data.linkTitle || 'Download CureSkin - a one-stop app for doctor-backed skin & hair solutions.',
          text: `${data.text || ''} Get free skin analysis by AI technology now.`,
          url: data.url,
        };
        if (!this.appWebBridge.isAppWebBridgeLoaded() || data.type !== 'REFER') {
          this.shareData.text = `${this.shareData.text} https://app.curesk.in/KSjEbBWqQN`;
        }
        if (this.appWebBridge.isAppWebBridgeLoaded()) {
          switch (data.type) {
            case 'REFER':
            case 'APP': {
              if (this.appWebBridge.requestShareAppWithText(this.shareData.title, this.shareData.description, this.shareData.text)) {
                return;
              }
              break;
            }
            case 'IMAGE': {
              if (this.appWebBridge.requestShareImageUrl(this.shareData.url, this.shareData.text)) {
                return;
              }
              break;
            }
            case 'IMAGE_ONLY': {
              if (this.appWebBridge.requestShareImageUrl(this.shareData.url)) {
                return;
              }
              break;
            }
            default:
          }
        }
        this.openShareOptions = true;
      }));

    this.subscriptions.push(this.broadcast.on<object>('OPEN_POPUP')
      .subscribe((popUpModal: Record<string, unknown>): unknown => (this.popUpModal = popUpModal)));

    this.subscriptions.push(this.broadcast.on<object>('INSTANT_CHECKUP_RATING')
      .subscribe((): Promise<any> => this.rateApp()));

    this.subscriptions.push(this.broadcast.on<object>('CHECK_NETWORK_CONNECTION')
      .subscribe((): void => {
        this.popUpModal = {
          open: true,
          titleIcon: 'cs-icon-wifi anim-zoom-io font35',
          type: this.appConfig.Dialog.ALERT,
          cancelText: this.appConfig.Shared.String.RETRY,
          callback: this.checkInternet(),
          message: { type: this.appConfig.Shared.String.OFFLINE },
        };
      }));

    this.subscriptions.push(this.broadcast.on<object>('OPEN_IN_NEW_TAB')
      .subscribe(({ url }: any): void => {
        if (this.appWebBridge.requestOpenInCustomTab(url)) return;
        this.windowRef.nativeWindow.open(url);
      }));

    this.subscriptions.push(this.broadcast.on<object>('NOTIFY')
      .subscribe(({ type, message }: { type: string, message: string | object }): void => {
        this.notifyMessage = (message instanceof Object) ? JSON.stringify(message) : message;
      }));

    this.subscriptions.push(this.broadcast.on<string>('NAVIGATION_BACK')
      .subscribe(({ step }: any = {}): void => {
        /** check if current component has handleBackPress function implements, and executes that.
         * Based on boolean returned by handleBackPress function it also calls goBack() if not handled (i.e returned false).
         */
        if (this.currentComponent.component && this.currentComponent.component.handleBackPress) {
          this.currentComponent.component.handleBackPress()
            .then((handled: any): void => {
              if (!handled) this.goBack();
            });
          return;
        }
        this.goBack(step);
      }));

    this.subscriptions.push(this.broadcast.on<string>('NAVIGATE_TO_PATH')
      .subscribe((url: string): void => this.zone.run((): void => {
        if (!url) {
          this.conn.redirectToLastKnowLocation();
          return;
        }
        const instantCheckupURLRegex = /\/user\/instantCheckup\/[a-zA-Z0-9]/;
        if (instantCheckupURLRegex.test(this.windowRef.nativeWindow.location.pathname) && instantCheckupURLRegex.test(url)) {
          this.conn.navigateToURL(url, false, true);
          return;
        }
        this.conn.navigateToURL(url);
      })));

    this.subscriptions.push(this.broadcast.on<string>('LOGOUT_INITIATE')
      .subscribe((): void => {
        this.conn.logout();
        this.eventLogger.logout();
      }));

    /** Handles After login process */
    this.subscriptions.push(this.broadcast.on<string>('NOTIFY_LOGIN')
      .subscribe(async (): Promise<void> => {
        this.appWebBridge.notifyWebLoginToken();
        this.appWebBridge.logEventInBranchAndFirebaseFromiOS({
          firebase: { name: 'LOGIN' },
          branch: { name: 'LOGIN' },
        });
        this.appWebBridge.notifyUserLoggedIn();
        this.eventLogger.setUser();
        this.sentryService.setUser(this.conn.getCurrentUser());
        this.eventLogger.trackEvent('login_success');
        await this.processExperiments();
        this.processAfterLogin();
      }));
  }

  private logWebAppVersion(): void {
    if (!this.windowRef.nativeWindow.caches) return;
    const user = this.conn.getActingUser();
    let webAppVersion = [];
    this.windowRef.nativeWindow.caches.keys().then((data: any): void => {
      webAppVersion = data;
      this.eventLogger.trackInElasticSearch({
        username: user ? user.get('username') : '',
        added: new Date(),
        type: 'webAppCacheVersion',
        message: webAppVersion,
        user_agent: this.windowRef.getUserAgent(),
        event: 'WEB_APP_CACHE_VERSION',
      });
    });
  }

  stopPropagation(event: any): void {
    event.stopPropagation();
  }

  hideMoreOption(): void {
    if (this.openShareOptions) this.openShareOptions = false;
  }

  shareApp(appname: string): void {
    if (appname === 'WHATSAPP' && this.shareData) {
      this.windowRef.nativeWindow.open(`https://api.whatsapp.com/send?text=${this.shareData.text} \n ${this.shareData.url || ''}`);
    } else if (appname === 'COPY') {
      const textArea = this.windowRef.nativeWindow.document.getElementById('clipboard');
      textArea.value = `${this.shareData?.text} \n ${this.shareData?.url || ''}`;
      textArea.select();
      this.windowRef.nativeWindow.document.execCommand('copy');
      this.broadcast.broadcast('NOTIFY', { message: 'Copied' });
    }
    this.openShareOptions = false;
  }

  /** Callback function of popup-modal close.
   * Based on the 'requestCode' attached with popup-modal data it handles the response of user given in popup.
   * @parms data - object denoting the type of modal and response from modal
  */
  async popUpClosed(data: any): Promise<any> {
    const store = this.windowRef.isSafariBrowser() ? 'App Store' : 'Play Store';
    if (data.requestCode === 1) { // App Feedback Modal response
      if (data.input && data.input.text === this.appConfig.Shared.String.LATER) {
        this.localStorage.setValue('CureSkin/feedbackLater', new Date().getTime());
        this.popUpModal.open = false;
        return;
      }
      if (data.input && data.input.text === this.appConfig.Shared.String.GOOD) {
        delete this.popUpModal;
        setTimeout((): void => {
          this.popUpModal = {
            open: true,
            title: this.appConfig.Shared.String.RATE_APP,
            type: this.appConfig.Dialog.CONFIRMATION,
            requestCode: 2,
            cancelText: this.appConfig.Shared.String.RATE_NOW,
            okText: this.appConfig.Shared.String.LATER,
            message: { type: this.appConfig.Shared.String.RATE_US },
          };
        }, 0);
        return;
      }
      await this.conn.updateUserData({ ratedApp: false });
      this.popUpModal.open = false;
    } else if (data.requestCode === 2) { // 'Rate App' Modal response
      if (data.clickOnNo) {
        let url;
        if (store.toLowerCase().includes('app')) url = 'https://itunes.apple.com/in/app/cureskin/id1447467785';
        if (store.toLowerCase().includes('play')) {
          url = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.heallo.skinexpert';
        }
        this.windowRef.nativeWindow.open(url);
        await this.conn.updateUserData({ ratedApp: true });
      }
      this.popUpModal.open = false;
    } else if (data.requestCode === 4) { // 'Update App' Modal response
      if (data.clickOnNo) {
        const url = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.heallo.skinexpert';
        this.windowRef.nativeWindow.open(url);
      }
    } else if (data.requestCode === 5) { // 'Update Android WebView' Modal response
      if (data.input && data.input.text === this.appConfig.Shared.String.UPDATE_WEB_VIEW) {
        const url = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.google.android.webview';
        this.windowRef.nativeWindow.open(url);
      }
      if (data.input && data.input.text === this.appConfig.Shared.String.UPDATE_CHROME) { // 'Update Android Chrome' Modal response
        const url = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.android.chrome';
        this.windowRef.nativeWindow.open(url);
      }
    } else if (data.type) this.popUpModal.open = false;
  }

  cancelUploadingFile(): void {
    if (this.uploadingProgressBar.subscription) {
      this.uploadingProgressBar.subscription.unsubscribe();
    }
    this.uploadingProgressBar = { show: false, percentageLoaded: 0 };
  }

  processInBackgroundWebWorker(): void {
    this.webWorker.initialize();
    this.webWorker.message({ function: 'loadImages', src: [] });
  }

  checkInternet(): () => void {
    return (): void => {
      if (this.windowRef.nativeWindow.navigator.onLine) {
        this.router.navigate(['/']);
        this.popUpModal.open = false;
      }
    };
  }

  /** Fetches list of confirmations to be taken by users and shows that in full screen popup.
   * Confirmations are basically popup with actions/data to be brought to users view immediately after app load.
   * */
  private async takeUserConfirmationOnPendingList(): Promise<any> {
    this.userPendingConfirmations = await this.conn.findUserConfirmationsPending();
    if (!this.userPendingConfirmations?.length) return;
    await this.updateUserConfirmation();
  }

  /** Populates confirmations one by one into the popup */
  async updateUserConfirmation(): Promise<any> {
    delete this.userConfirmation;
    this.userConfirmation = this.userPendingConfirmations.shift();
    if (this.userConfirmation) return;
    await this.takeUserConfirmationOnPendingList();
  }

  checkForAddToHomeScreen(url: string): void {
    if (this.windowRef.nativeWindow.installPrompt
      && !this.appWebBridge.isAppWebBridgeLoaded()
      && !this.isInternalUser
      && !this.blockAddToHomePrompt
      && url.includes('/user?tab=home')) {
      this.showAddToHomePrompt = true;
    } else this.showAddToHomePrompt = false;
  }

  addToHomeScreen(status: boolean, event: any): void {
    this.stopPropagation(event);
    if (status && this.windowRef.nativeWindow.installPrompt && !this.appWebBridge.isAppWebBridgeLoaded()) {
      this.windowRef.nativeWindow.installPrompt.prompt();
    }
    this.showAddToHomePrompt = false;
    this.blockAddToHomePrompt = true;
  }

  ngOnDestroy(): void {
    this.webWorker.terminate();
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
    this.clearData();
  }
}
