import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventLoggerService } from '@services/event-logger-service';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { ApiClientConstant, ParseKeys } from '@cureskin/api-client';
import { Swiper, SwiperOptions } from 'swiper';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'user-regimen-products-view',
  templateUrl: './user-regimen-products-view.html',
  styleUrls: ['./user-regimen-products-view.scss'],
  standalone: false,
})
export class UserRegimenProductsViewComponent {
  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef<HTMLVideoElement>;
  @ViewChild('swiperCardsBanner') swiperComponent: any;

  regimen: any;
  user: any;
  userLanguage: any;
  loading: boolean = true;
  experiments: any[];
  offerTimer: any = {};
  allocatedDoctor: any;
  activeExperiments: any[] = [];
  byPassLocalFetch: boolean = false;
  isPlaying: boolean = false;
  showProductCarousalPopUp: boolean = false;
  selectedImageIndex: number = 0;
  carouselConfig: SwiperOptions = {
    initialSlide: this.selectedImageIndex,
    slidesPerView: 1.2,
    spaceBetween: 20,
    autoHeight: false,
    speed: 600,
    pagination: false,
    centeredSlides: true,
    navigation: false,
    autoplay: false,
  };
  constructor(private eventLogger: EventLoggerService,
    private appConfig: AppConfig,
    private conn: ConnectionService,
    private router: Router,
    private broadcast: BroadcastService,
    private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    const regimenClass = this.route.snapshot.queryParams.class;
    const { regimenId }: any = this.route.snapshot.queryParams;
    this.experiments = await this.conn.findUserActiveExperiments();
    this.byPassLocalFetch = true;
    this.user = await this.conn.getActingUser();
    this.userLanguage = this.user?.get('languagePreference') || this.appConfig.Shared.Languages.EN;
    const include: Array<ParseKeys<Table.Regimen>> = ['morning.product' as 'morning', 'night.product' as 'night'];
    const regimens = await this.conn.fetchRegimens(regimenId, true, this.byPassLocalFetch, false, include);
    this.regimen = regimens.find((each: any): boolean => each.class === regimenClass);
    this.regimen.totalMRP = this.regimen?.fixedPriceMRP;
    this.regimen.totalSP = this.regimen?.fixedPrice;
    this.regimen.discount = Math.floor(((this.regimen.totalMRP - this.regimen.totalSP) * 100) / this.regimen.totalMRP);
    const uniqueProducts = {};
    [].concat(this.regimen.morning).concat(this.regimen.night)
      .forEach((item: any): void => {
        const product: any = item?.product?.instructions?.find((element: any): any => element.title === item?.instructionSet?.title);
        if (item?.instructionSet?.paidInstructionVideo) {
          // eslint-disable-next-line no-param-reassign
          item.instructionSet.paidInstructionVideo = product?.paidInstructionVideo;
        }
        // eslint-disable-next-line no-param-reassign
        item.instructionSet.unpaidInstructionVideo = product?.unpaidInstructionVideo;
        uniqueProducts[item.product.objectId] = item;
      });
    this.regimen.products = Object.values(uniqueProducts);
    if (this.user.get('allocatedDoctor')) {
      this.allocatedDoctor = await this.conn.findUserByObjectId(this.user.get('allocatedDoctor').id);
    }
    this.activeExperiments = await this.conn.findUserActiveExperiments();
    this.loading = false;
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'product-view-unpaid' }));
  }

  ngAfterViewInit(): void {
    if (this.swiperComponent && this.swiperComponent.swiper) {
      this.swiperComponent.swiper.update();
    }
  }

  buyRegimen(): void {
    this.eventLogger.trackInParse('BUY_NOW', ApiClientConstant.Event.Type.ORDER);
    this.eventLogger.trackEvent('BUY_NOW_CLICKED');
    this.eventLogger.trackInFirebaseAndBranch('BUY_NOW_CLICKED');
    if (this.regimen.class === this.appConfig.Shared.Regimen.Class.HAIR) {
      this.eventLogger.trackEvent('BUY_NOW_CLICKED_HAIR');
      this.eventLogger.trackInFirebaseAndBranch('BUY_NOW_CLICKED_HAIR');
    }
    this.router.navigate(['/user/checkout'],
      { queryParams: { type: 'REGIMEN', regimenId: this.regimen.regimenId }, replaceUrl: true });
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  playVideo(instructionSet: any): void {
    if (!instructionSet) return;

    const activeUser = this.user || this.conn.getActingUser();
    this.userLanguage = activeUser?.get('languagePreference') || this.appConfig.Shared.Languages.EN;
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'play-product-instruction-video' }));
      const videoPlayer = this.videoPlayer.nativeElement;
      videoPlayer.parentElement.classList.remove('tw-hidden');
      if (activeUser?.isPaid()) {
        videoPlayer.src = instructionSet.paidInstructionVideo[this.userLanguage]
        || instructionSet.paidInstructionVideo.en;
      } else {
        videoPlayer.src = instructionSet.unpaidInstructionVideo[this.userLanguage]
        || instructionSet.unpaidInstructionVideo.en;
      }

      const videoPlay = videoPlayer.play();
      if (videoPlay && !this.isPlaying) {
        videoPlay.then(():void => {
          this.isPlaying = true;
        });
      }
    }
  }

  closeVideo(): any {
    if (!this.isPlaying) return;
    this.videoPlayer.nativeElement.pause();
    this.videoPlayer.nativeElement.currentTime = 0;
    this.videoPlayer.nativeElement.parentElement.classList.add('tw-hidden');
    this.isPlaying = true;
  }

  openCarousalPopUp = (i: number): void => {
    this.selectedImageIndex = i;
    this.carouselConfig = { ...this.carouselConfig, initialSlide: this.selectedImageIndex };
    this.showProductCarousalPopUp = true;
    if (this.swiperComponent && this.swiperComponent.swiper) {
      this.swiperComponent.swiper.slideTo(this.selectedImageIndex, 0); // Slide to the selected index
      this.swiperComponent.swiper.update(); // Update the swiper instance
    }
  };

  closeCarousalPopUp = (): void => {
    this.showProductCarousalPopUp = false;
  };
}
