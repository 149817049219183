import { Component, Input } from '@angular/core';
import { AppConfig } from '../../app/app.config';

@Component({
  selector: 'translate-string',
  templateUrl: './translate-string.html',
  standalone: false,
})
export class TranslateStringComponent {
  static parameters: Array<any> = [AppConfig];
  @Input('key') key: string;
  constructor(private appConfig: AppConfig) {}
}
