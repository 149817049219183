import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-testimonials-banner',
  templateUrl: './testimonials.component.html',
  standalone: false,
})
export class TestimonialsBannerComponent {
  @Output('redirectToTestimonialsPage') redirectToTestimonialsPage: EventEmitter<any> = new EventEmitter();
  constructor() {
  }

  handleClick(): void {
    this.redirectToTestimonialsPage.emit();
  }
}
