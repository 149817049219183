import { Component, OnDestroy, NgZone, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { ConnectionService } from '@services/connection-service';
import { LocalStorageService } from '@services/local-storage-service';
import { BroadcastService } from '@services/broadcast-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { EventLoggerService } from '@services/event-logger-service';
import { LoginService } from '@services/login-service/login.service';
import { WindowRefService } from '@services/window-ref-service';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { AppConfig } from '../../app.config';
import { AuthConfig } from '../../app-constant-types';

@Component({
  selector: 'onboarding-otp',
  templateUrl: './onboarding-mobile-otp.html',
  standalone: false,
})

export class OnboardingMobileOtpComponent implements OnDestroy, AfterViewInit {
  mobileNumber: string;
  verificationCode: string;
  retryInterval: any;
  retryOTP: boolean = true;
  language: string;
  subscriptions: Array<Subscription> = [];
  startTimer: number;
  ui: { loading: boolean, error?: string, timeLefForOTPCall?: number } = { loading: false };
  @ViewChild('otpInput', { static: false }) otpInput: ElementRef;

  constructor(
    private conn: ConnectionService,
    private storage: LocalStorageService,
    private zone: NgZone,
    private broadcast: BroadcastService,
    private eventLogger: EventLoggerService,
    public appConfig: AppConfig,
    private loginService: LoginService,
    private windowRefService: WindowRefService,
    public commonUtilityHelper: CommonUtilityHelper,
    private appWebBridge: AppWebBridgeService) {
    this.mobileNumber = this.storage.getValue('CureSkin/mobileNumber') || '';
    this.language = this.storage.getValue('CureSkin/language') || '';
  }

  ngOnInit(): void {
    this.startTimer = new Date().getTime();
    this.subscriptions.push(this.broadcast.on<string>('OTP_RECEIVED')
      .subscribe((otp: string): void => this.zone.run((): void => this.validateOtp(`${otp}`.substring(0, 4)))));
    this.startResendOtpTimer();
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'onboarding-OTP-verification' }));
    const time = new Date().getTime() - this.startTimer;
    this.eventLogger.trackEvent('otp_screen_loading', { timeInMillSec: time });
    this.appWebBridge.logEventInBranchAndFirebaseFromiOS({
      branch: { name: 'pageOpenOtp' },
      firebase: { name: 'pageOpenOtp' },
    });
  }

  ngAfterViewInit(): void {
    this.checkForOtpInSafariBrowser();
  }

  checkForOtpInSafariBrowser(): any {
    if (!(this.windowRefService.isSafariBrowser())) return 0;
    this.subscriptions.push(interval(1000)
      .subscribe((): void => this.validateOtp(this.otpInput?.nativeElement.value)));
    return 0;
  }

  startResendOtpTimer(): void {
    this.retryOTP = true;
    this.clearRetryInterval();
    this.ui.timeLefForOTPCall = 30;
    this.retryInterval = setInterval((): void => this.zone.run((): void => {
      this.ui.timeLefForOTPCall -= 1;
      if (this.ui.timeLefForOTPCall === 0) {
        this.clearRetryInterval();
        this.sendOTPOnCall();
      }
    }), 1000);
  }

  validateOtp(otp: string): void {
    this.zone.run((): void => {
      if (!otp || otp.length !== 4) return;
      this.verificationCode = otp;
      this.verifyOtp();
    });
  }

  clearRetryInterval(): void {
    clearInterval(this.retryInterval);
    delete this.retryInterval;
  }

  sendOTPOnCall(): void {
    if (!this.retryOTP) return;
    this.eventLogger.trackEvent('otp_retry', {});
    this.conn.reSendOTP(this.storage.getValue('CureSkin/mobileNumber'))
      .then((): boolean => this.ui.loading = false)
      .catch((): void => {
        this.ui.loading = false;
        this.clearRetryInterval();
      });
  }

  async verifyOtp(): Promise<any> {
    if (this.ui.loading) return;
    if (this.verificationCode?.toString().length !== 4) {
      this.broadcast.broadcast('NOTIFY', { message: 'Enter a valid 4 digit verification code.' });
      return;
    }
    this.ui.loading = true;
    this.eventLogger.trackEvent('otp_requested_time');
    const otp = this.verificationCode;
    const mobileNumber = this.storage.getValue('CureSkin/mobileNumber');
    const authConfig: AuthConfig = { mobileNumber, OTP: otp };
    try {
      await this.loginService.authenticateUser(authConfig, { enableOTPVerifiedLog: true });
      const time = new Date().getTime() - this.startTimer;
      this.eventLogger.trackEvent('otp_verification_page_time_spent', { timeInMilliSec: time });
      this.eventLogger.cleverTapEvent('loginSuccess');
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: 'Please enter a correct OTP' });
      this.eventLogger.trackEvent('otp_failure', { error: error.toString() });
    } finally {
      this.retryOTP = false;
      this.clearRetryInterval();
      this.ui.loading = false;
      this.unsubscribe();
    }
  }

  editNumber(): void {
    this.eventLogger.trackEvent('otp_number_change', {});
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  navigateTo(url: string): void {
    this.conn.navigateToURL(url);
  }

  ngOnDestroy(): void {
    this.clearRetryInterval();
    this.unsubscribe();
  }

  public unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
