import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { WindowRefService } from '@services/window-ref-service';
import { EventLoggerService } from '@services/event-logger-service';
import { DataStoreService } from '@services/data-store-service';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'user-wallet-view',
  templateUrl: './user-wallet-view.html',
  styleUrls: ['./user-wallet-view.scss'],
  standalone: false,
})
export class UserWalletViewComponent {
  user: any;
  tabData: { selectedTab?: number, count?: number } = { count: 3 };
  selectedTabIndex: number = -1;
  tabAnimation: Array<string> = ['', ''];
  walletBalance: number = 0;
  totalEarned: number = 0;
  rewards: Array<any> = [];
  transactions: Array<any> = [];
  loading: boolean = true;
  scratchCard: { isClaimed: boolean, scratched: boolean, index: number, objectId: string, amount: number, source: boolean };
  regimen: any;
  products: Array<any> = [];
  imageDataValue: number = 0;
  referralCashValue: number;
  @ViewChild('canvasCard', { static: false }) canvasCard: ElementRef;
  claimCashPopup: { show: boolean, isClaimed?: boolean, inProgress?: boolean, code?: string, amount?: number } = { show: false };

  constructor(private conn: ConnectionService,
    private router: Router,
    private route: ActivatedRoute,
    private broadcast: BroadcastService,
    private appConfig: AppConfig,
    private window: WindowRefService,
    private eventLogger: EventLoggerService,
    private dataStore: DataStoreService,
    private commonUtil: CommonUtilityHelper) {
  }

  async ngOnInit(): Promise<any> {
    this.user = this.conn.getActingUser();
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'wallet' }));
    this.route.queryParams.subscribe((params: any): void => {
      if (params.tab === 'redeem') this.navigateTab(2);
      else if (params.tab === 'earn') this.navigateTab(1);
      else this.navigateTab(0);
    });
    this.walletBalance = await this.conn.fetchCashBalance();
    await this.fetchRewards();
    this.transactions = JSON.parse(JSON.stringify(await this.conn.getCureSkinCashTransactions(null, ['type', 'amount'])));
    this.loading = false;
    this.referralCashValue = this.appConfig.Shared.REFERRAL_CASH_VALUE;
    this.processTransactions();
    await this.fetchRegimen();
    await this.fetchProducts();
  }

  async fetchRewards(): Promise<any> {
    const where: any = { user: this.user };
    where.type = { $ne: this.appConfig.Shared.Reward.TYPE.REGIMEN_REFERRAL_REWARD };
    const rewards = (await this.conn.fetchRewards(where)).sort((reward: any): number => (reward.isClamed ? -1 : 1));
    this.rewards = JSON.parse(JSON.stringify(rewards));
    this.processRewards();
  }

  async fetchRegimen(): Promise<void> {
    this.regimen = (await this.conn.fetchRegimens())
      .filter((each: any): boolean => !each.orderPlaced && !each.delivered && !each.expired)
      .find((each: any): boolean => !this.commonUtil.isRegimenBeingPrepared(each));
    if (!this.regimen) return;
    const balance = this.regimen.fixedPrice - this.walletBalance;
    if (balance >= 0) {
      this.regimen.buyNowAt = balance;
      this.regimen.cashUsed = this.walletBalance;
    } else {
      this.regimen.buyNowAt = 0;
      this.regimen.cashUsed = this.walletBalance + balance;
    }
  }

  async fetchProducts(): Promise<void> {
    if (!this.conn.getActingUser().isPaid()) return;
    let products = this.dataStore.get('PRODUCTS').ADD_ON;
    if (!products.length) {
      const tags = ['ADD_ON', 'RE_ORDER'];
      const excludeTag = ['BOGO_PRODUCT'];
      products = await this.conn.findAllProductsForUser(tags, true, excludeTag);
      this.dataStore.set('PRODUCTS', { ADD_ON: products });
    }
    products = products.filter((
      each: any): boolean => each.get('price') !== 0 && each.get('inventoryStatus') !== this.appConfig.Shared.Inventory.Type.DISCONTINUED);

    products.forEach((each: any): void => {
      const balance = each.get('price') - this.walletBalance;
      if (balance >= 0) {
        each.set('buyNowAt', balance);
        each.set('cashUsed', this.walletBalance);
      } else {
        each.set('buyNowAt', 0);
        each.set('cashUsed', this.walletBalance + balance);
      }
    });
    this.products = products;
  }

  processRewards(): void {
    this.rewards.forEach((e: any): void => {
      const each = e;
      each.source = each.source || 'Referral';
    });
  }

  processTransactions(): void {
    this.totalEarned = 0;
    this.transactions.forEach((each: any): void => {
      this.totalEarned += (each.type === 1 ? each.amount : 0);
    });
  }

  /**
   * @param {number} type - (0 || 1) Two styles of canvas are there, we pick the style from this type variable.
   *
   * 1. We read the scratch card canvas, in which we do the scratch.
   * 2. We set width & height of canvas to 400 x 400. (its context width/height, not style width/height)
   * 3. We draw a filled rectangle in the canvas with a gradient style based on type.
   * 4. We fetch the heart svg image and draw it in the center of canvas.
   * 5. We divide the canvas into 3 portion(rows), and we read the data of canvas of the middle portion and store it.
   * 6. We add events to the scratch card, like touch, mouse movements.
   */
  prepareCanvas(type: number): void {
    this.imageDataValue = 0;
    const canvas = this.canvasCard.nativeElement;
    const ctx = canvas.getContext('2d');
    canvas.width = 400;
    canvas.height = 400;
    let grd;
    if (type === 1) {
      grd = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
      grd.addColorStop(0, '#BAF4E4');
      grd.addColorStop(0.9, '#F5CBA1');
    } else {
      grd = ctx.createLinearGradient(0, 50, canvas.width, canvas.height);
      grd.addColorStop(0, '#E79FA0');
      grd.addColorStop(0.2, '#E79FA0');
      grd.addColorStop(1, '#958EC4');
    }
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = (): void => {
      const SCALE = 0.9; // percentage of value to be used.
      const imgWidth = image.width * SCALE;
      const imgHeight = image.height * SCALE;
      ctx.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        ((canvas.width / 2) - imgWidth / 2),
        ((canvas.height / 2) - imgHeight / 2),
        imgWidth,
        imgHeight);
      const middlePortion = canvas.height / 3;
      const scratchImage = canvas.getContext('2d').getImageData(0, middlePortion, canvas.width, middlePortion * 2);
      scratchImage.data.forEach((value: any): void => this.imageDataValue += value);
    };
    image.src = '/assets/images/heart-trans.svg';
    this.addEvents(canvas);
  }

  /**
   * We add touch events for mobile devices and mouse events for web apps.
   * 1. We call 'paintScratchCard' function on scratch movement.
   * 2. Once movement comes to end, on end we call 'checkIfCardIsScratched' - to check the status of scratch card and make api calls.
   * Note: We store coordinates of scratch card in custom property 'coordinates', in order to avoid fetching in every time.
   * @param canvas
   */
  addEvents(canvas: any): void {
    canvas.addEventListener('touchstart', (): void => {
      this.canvasCard.nativeElement.coordinates = canvas.getBoundingClientRect();
      canvas.addEventListener('touchmove', (event: any): void => this.paintScratchCard(event));
    });
    canvas.addEventListener('touchend', (): void => {
      canvas.removeEventListener('touchmove', (event: any): void => this.paintScratchCard(event));
      this.checkIfCardIsScratched();
    });

    canvas.addEventListener('mousedown', (): void => {
      this.canvasCard.nativeElement.coordinates = canvas.getBoundingClientRect();
      canvas.addEventListener('mousemove', (event: any): void => this.paintScratchCard(event));
    });
    canvas.addEventListener('mouseout', (): void => {
      canvas.removeEventListener('mousemove', (event: any): void => this.paintScratchCard(event));
      this.checkIfCardIsScratched();
    });
    canvas.addEventListener('mouseup', (): void => {
      canvas.removeEventListener('mousemove', (event: any): void => this.paintScratchCard(event));
      this.checkIfCardIsScratched();
    });
  }

  /**
   * 1. We read the event co-ordinates from event.touches (in case of touch move), else event (in case of mouse move)
   * 2. We read the cords of scratch card, which we stored in custom property 'coordinates'.
   * 3. We subtract mouse pointer X,Y with scratch card position X,Y - to get (X,Y) of canvas where the touch happened.
   * 4. Then we draw a circle of fixed 40px radius at point (X,Y).
   * 5. X * SCALE - multiplication of scale is because the context width of canvas is 400px, but the style width is 150px.
   *    So since we are drawing circle in 400px context, we have to multiply the factor (400/150) in the (X, Y).
   *    (i.e) X of 150 is converted to X of 400
   */
  paintScratchCard(event: any): void {
    const cords = event.touches ? event.touches[0] : event;
    const canvas = this.canvasCard.nativeElement;
    const SCALE = canvas.width / canvas.coordinates.width;
    const ctx = canvas.getContext('2d');
    const x = cords.clientX - this.canvasCard.nativeElement.coordinates.x;
    const y = cords.clientY - this.canvasCard.nativeElement.coordinates.y;
    ctx.globalCompositeOperation = 'destination-out';
    ctx.beginPath();
    ctx.arc(x * SCALE, y * SCALE, 40, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();
  }

  /**
   * This function checks if card is scratched and make api call to allocated the reward.
   * 1. We read the image data of middle portion (card is split into equal 3 rows).
   * 2. We already have image data value of the card before scratch.
   * 3. We compare the difference and if at least 50% of data is scratched, then we consider scratch is done.
   * 4. We call api to allocate reward and enable paper bomb animation.
   * @returns {Promise<any>}
   */
  async checkIfCardIsScratched(): Promise<any> {
    if (this.scratchCard?.scratched) return;
    if (this.scratchCard) {
      let valuesLeft = 0;
      const canvas = this.canvasCard.nativeElement;
      const middlePortion = canvas.height / 3;
      const scratchImage = canvas.getContext('2d').getImageData(0, middlePortion, canvas.width, middlePortion * 2);
      scratchImage.data.forEach((value: any): void => valuesLeft += value);
      const percentageOccupied = 100 - ((valuesLeft * 100) / this.imageDataValue);
      if (percentageOccupied >= 50) {
        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
        try {
          await this.conn.claimReward(this.scratchCard.objectId);
          this.commonUtil.showPaperBombAnimation();
          this.scratchCard.scratched = true;
          this.walletBalance += this.scratchCard.amount;
          this.totalEarned += this.scratchCard.amount;
        } catch (err) {
          this.scratchCard.scratched = false;
          this.prepareCanvas(this.scratchCard.index % 2);
        }
      }
    }
  }

  /**
   * When a scratch card is clicked,
   * 1. Checks if its already claimed, then ignores it.
   * 2. Else, sets the data and prepares the scratch card canvas.
   *
   * Note: (index % 2) - returns 0 || 1, so we have two type of canvas style. It picks the style based on odd or even number.
   *
   * @param reward - reward object.
   * @param {number} index - index of card
   * @param {HTMLElement} card - Scratch card element reference.
   * @returns {any}
   */
  viewReward(reward: any, index: number, card: HTMLElement): any {
    if (reward.isClaimed) return 0;
    this.scratchCard = reward;
    this.scratchCard.index = index;
    setTimeout((): void => this.prepareCanvas(index % 2));
    return 0;
  }

  async closeScratch(): Promise<any> {
    this.scratchCard.isClaimed = this.scratchCard.scratched;
    delete this.scratchCard;
  }

  navigateTab(index: number): void {
    if (this.selectedTabIndex === index) return;
    this.selectedTabIndex = index;
    this.tabData.selectedTab = index;
  }

  tabChange(index: number): void {
    if (this.selectedTabIndex === index) return;
    let tab;
    if (index === 0) tab = 'reward';
    if (index === 1) tab = 'earn';
    if (index === 2) tab = 'redeem';
    this.router.navigate([], { queryParams: { tab, back: this.route.snapshot.queryParams.back },
      replaceUrl: true,
      relativeTo: this.route });
  }

  navigateTo(url: string): void {
    this.conn.navigateToURL(url);
  }

  copyText(): void {
    const code = this.window.nativeWindow.document.getElementById('referralCode');
    code.select();
    this.window.nativeWindow.document.execCommand('copy');
    this.broadcast.broadcast('NOTIFY', { message: 'Copied' });
  }

  async shareApp(): Promise<any> {
    const user = this.conn.getActingUser();
    this.eventLogger.trackEvent('wallet_refer_and_earn_clicked',
      { mode: 'REFER_AND_EARN', username: user.get('username'), type: 'REFER' });
    this.broadcast.broadcast('SHARE', { type: 'REFER' });
  }

  showClaimCash(): void {
    this.eventLogger.trackEvent('claim_cureskin_cash_btn_clicked');
    this.claimCashPopup = { show: true };
  }

  /**
   * This function adds cash reward by coupon code.
   */
  async applyCouponAndClaimCash(code: string): Promise<void> {
    if (this.claimCashPopup.inProgress) return;
    this.claimCashPopup.inProgress = true;
    try {
      this.claimCashPopup.amount = (await this.conn.applyGiftCard(code.toLowerCase())).amount;
      this.commonUtil.showPaperBombAnimation();
      this.walletBalance += this.claimCashPopup.amount ?? 0;
      this.totalEarned += this.claimCashPopup.amount ?? 0;
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.message || error.toString() });
      return;
    } finally {
      this.claimCashPopup.inProgress = false;
    }
    this.claimCashPopup.isClaimed = true;
  }

  shopCureskinCash(): void {
    this.claimCashPopup.show = false;
    this.navigateTab(2);
    this.back();
  }

  buyRegimen(): void {
    this.conn.navigateToURL(`/user/checkout?type=REGIMEN&regimenId=${this.regimen.regimenId}`);
  }

  buyProduct(item: any): void {
    this.conn.navigateToURL(`/user/checkout?type=PRODUCT&products=${item.id}`);
  }

  stopPropagation(event: any): void {
    event.stopPropagation();
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  backHome(): void {
    this.router.navigate(['user?tab=home']);
  }

  getImageSrc(item: any): string {
    const rebrandedImageWithBackground = item?.get('rebrandedImageWithBackground');
    const productUnboxedImage = item?.get('productUnboxedImage');
    const images = item?.get('images');

    if (rebrandedImageWithBackground?.length) {
      return rebrandedImageWithBackground[rebrandedImageWithBackground.length - 1];
    }

    if (productUnboxedImage?.length) {
      return productUnboxedImage[productUnboxedImage.length - 1];
    }

    if (images?.length) {
      return images[images.length - 1];
    }

    return '';
  }
}
