<div *ngIf="show" (click)="closePopUp()">
  <div class="tw-w-full tw-bg-white tw-absolute tw-bottom-0 tw-animate-slide_in_bottom" (click)="stopPropagation($event)">
  <div class="tw-bg-gray-100 tw-flex tw-p-4 tw-justify-between tw-items-center">
    <div class="tw-font-medium">
      <em class="cs-icon-phone-in-talk tw-text-300"></em>
      <span class="tw-pl-2.5 tw-font-body tw-text-300" i18n="@@callUs">Call Us</span>
    </div>
    <div class="tw-text-gray-700 tw-text-700 tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center" (click)="closePopUp()">
    <em class="cs-icon-close"></em>
    </div>
  </div>
  <a class="tw-p-5 tw-text-gray-800 tw-block" href="tel:080-4680-9361" (click)="onCallNeeded.emit()">
    <section>
    <div i18n="@@ourAdvisorsAreAviailBtwn">
      <span class="tw-text-300 tw-font-body">Our Skincare advisors are available between</span>
      <div class="tw-text-300 tw-font-bold tw-font-body">10AM - 7PM</div>
    </div>
    <hr class="tw-my-4" />
    <div>
      <span class="tw-mr-3 tw-font-body" i18n="@@tollFreeNo">Toll Free No</span>
      <span class="tw-text-400 tw-font-bold tw-font-body">080-4680-9361</span>
    </div>
    </section>
    <cs-button [class]="'primary w100 btn-xs mT15 shine'"><cs-button-text i18n="@@callNow">Call Now</cs-button-text></cs-button>
  </a>
  </div>
</div>