import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventLoggerService } from '@services/event-logger-service';
import { WindowRefService } from '@services/window-ref-service';
import SwiperCore, { Scrollbar, Autoplay, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper_angular';

SwiperCore.use([Autoplay, Scrollbar]);

@Component({
  selector: 'feed-image',
  templateUrl: './image.html',
  styleUrls: ['../../user-explore.scss'],
  standalone: false,
})
export class ImageComponent {
  MAX_WIDTH: number = 500;
  style: { width?: number, height?: number } = {};
  feed: any;
  @Input('feed') set setFeed(feed: any) {
    this.feed = JSON.parse(JSON.stringify(feed));
  }
  @Input('isLiked') isLiked: boolean;
  @Output('likeToggle') likeToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  playLikeAnimation: boolean = false;
  timeoutInterval: any;
  clickCount: number = 0;
  carouselConfig: SwiperOptions = {
    slidesPerView: 1,
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
    pagination: true,
    loop: false,
  };

  constructor(private window: WindowRefService,
    private eventLogger: EventLoggerService) {
  }

  ngOnInit(): void {
    let windowWidth = this.window.nativeWindow.innerWidth;
    if (this.window.nativeWindow.innerWidth > this.MAX_WIDTH) windowWidth = this.MAX_WIDTH;
    if (typeof this.feed.aspectRatio === 'string') {
      this.style.width = Number(this.feed.aspectRatio.split(':')[0]) * windowWidth;
      this.style.height = Number(this.feed.aspectRatio.split(':')[1]) * windowWidth;
    } else {
      this.style.width = windowWidth;
    }
  }

  onLikeClick(): void {
    this.clickCount += 1;
    if (this.clickCount === 1) {
      setTimeout(():void => {
        if (this.clickCount > 1) {
          this.isLiked = !this.isLiked;
          if (this.isLiked) {
            this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'explore-feed-liked' }));
            this.playHeartAnimation();
          }
          this.likeToggle.emit(this.isLiked);
        }
        this.clickCount = 0;
      }, 500);
    }
  }

  playHeartAnimation(): void {
    clearTimeout(this.timeoutInterval);
    this.playLikeAnimation = true;
    this.timeoutInterval = setTimeout((): boolean => this.playLikeAnimation = false, 500);
  }
}
