import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AppConfig } from 'client/app/app.config';
import { Subscription } from 'rxjs';

interface OrderCancelForm {
  reason: FormControl<string>;
  additionalComments: FormControl<string>;
}

@Component({
  selector: 'cancel-order',
  templateUrl: './cancel-order.component.html',
  standalone: false,
})
export class CancelOrderComponent {
  cancelReasons: Array<string> = [
    this.appConfig.Shared.Order.OrderCancelReasons.ORDER_MISTAKE,
    this.appConfig.Shared.Order.OrderCancelReasons.COSTLY,
    this.appConfig.Shared.Order.OrderCancelReasons.WILL_ORDER_LATER,
    this.appConfig.Shared.Order.OrderCancelReasons.PRODUCT_NOT_HAPPY,
    this.appConfig.Shared.Order.OrderCancelReasons.SOMETHING_ELSE,
  ];
  orderCancelForm: FormGroup<OrderCancelForm>;
  reasonSubscription: Subscription;
  isTextAreaLimitReached: boolean = false;
  cancelReason: string = '';

  constructor(
    // eslint-disable-next-line new-cap
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private readonly appConfig: AppConfig,
    private readonly formBuilder: FormBuilder,
    private readonly bottomSheetRef: MatBottomSheetRef,
  ) { }

  ngOnInit(): void {
    this.orderCancelForm = this.formBuilder.group({
      reason: ['', Validators.required],
      additionalComments: ['', Validators.maxLength(50)],
    });

    this.reasonSubscription = this.orderCancelForm.get('reason')?.valueChanges.subscribe((selectedReason: string): void => {
      if (selectedReason !== this.appConfig.Shared.Order.OrderCancelReasons.SOMETHING_ELSE) {
        this.orderCancelForm.get('additionalComments')?.setValue('');
        this.isTextAreaLimitReached = false;
      }
    });
  }

  onTextareaInput(event: Event): void {
    const textareaValue = (event.target as HTMLTextAreaElement)?.value.trim();
    if (textareaValue && (this.orderCancelForm.get('reason')?.value
      !== this.appConfig.Shared.Order.OrderCancelReasons.SOMETHING_ELSE)) {
      this.orderCancelForm.get('reason')?.setValue(this.appConfig.Shared.Order.OrderCancelReasons.SOMETHING_ELSE);
    }

    this.isTextAreaLimitReached = textareaValue.length === 50;
  }

  onReasonChange(selectedReason: string): void {
    if (selectedReason !== this.appConfig.Shared.Order.OrderCancelReasons.SOMETHING_ELSE) {
      this.orderCancelForm.get('additionalComments')?.setValue('');
    }
  }

  onCancelFormSubmit(): void {
    if (this.orderCancelForm.invalid) {
      return;
    }

    const selectedReason = this.orderCancelForm.value.reason;
    const { additionalComments }: { additionalComments?: string } = this.orderCancelForm.value;

    if (selectedReason === this.appConfig.Shared.Order.OrderCancelReasons.SOMETHING_ELSE) {
      this.cancelReason = additionalComments?.trim() || this.appConfig.Shared.Order.OrderCancelReasons.SOMETHING_ELSE;
    } else {
      this.cancelReason = selectedReason;
    }

    if (this.data?.callback) {
      this.data.callback(this.cancelReason);
    }
  }

  dontCancel(): void {
    this.bottomSheetRef.dismiss();
  }

  ngOnDestroy(): void {
    this.reasonSubscription?.unsubscribe();
  }
}
