import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { DataStoreService } from '@services/data-store-service';

@Component({
  selector: 'logout',
  templateUrl: './logout.html',
  standalone: false,
})
export class LogoutComponent {
  constructor(private conn: ConnectionService,
    private router: Router,
    private appWebBridge: AppWebBridgeService,
    private dataStore: DataStoreService) {
  }

  async ngOnInit(): Promise<any> {
    await this.conn.logout();
    this.dataStore.clearDataStore();
    this.verifyAndNavigateToBaseRoute();
  }

  verifyAndNavigateToBaseRoute(): void {
    setTimeout((): void => {
      if (this.conn.isUserLoggedIn) {
        this.verifyAndNavigateToBaseRoute();
        return;
      }
      if (this.appWebBridge.isAppWebBridgeLoaded()) {
        this.appWebBridge.notifyUserLogout();
        return;
      }
      this.router.navigate(['/']);
    }, 100);
  }
}
