import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'festive-banner',
  templateUrl: './festive-banner.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class FestiveBannerComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}
